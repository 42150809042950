import React, { useState, useEffect } from 'react'

import { Modal, Input, Button } from 'antd'
import { PrinterOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'
import { dbTodayjs, dbTodayjs2 } from '../../../utils/date'

import { useAuth } from '../../../contexts/AuthContextProvider'
import { genPdf } from '../../../utils/pdf'
import { nameTitleObj } from '../../../const/users'
import { dateTH, formatNumber, toDataURL } from '../../../utils/tools'

import logoImg from '../../../assets/images/logo.png'

const BillPdf = ({ uid, value, row, isRow, isText }) => {
  const { db } = useAuth()

  const [logo, setLogo] = useState()

  useEffect(() => {
    toDataURL(logoImg).then(data => {
      setLogo(data)
    })
  }, [])

  const getData = async (id, uid) => {
    try {
      const userSnap = await db.collection('/users').doc(id).get()
      const userData = userSnap.data()
      if (!uid) {
        return Promise.resolve({
          ...value,
          roomstart: dbTodayjs(userData?.roomstart),
          roomend: dbTodayjs(userData?.roomend),
          payment: {
            ...row
          }
        })
      }

      const paymentSnap = await db.collection('/installmentpayments').where('iid', '==', id).get()
      let payments = paymentSnap.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        date: dbTodayjs(doc.data()?.date),
        index: doc.data()?.date?.seconds
      }))
      payments = payments.sort((x, y) => x.index - y.index)

      const snap = await db.collection('/installments').doc(id).get()

      return Promise.resolve({
        ...snap.data(),
        roomstart: dbTodayjs(userData?.roomstart),
        roomend: dbTodayjs(userData?.roomend),
        startdate: dbTodayjs(snap.data()?.startdate),
        resigndate: dbTodayjs(snap.data()?.resigndate),
        installmentp: dbTodayjs2(snap.data()?.installmentp),
        payment: {
          ...payments[payments.length - 1]
        }
      })
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const getPdf = async (admin, parent) => {
    try {
      const id = (uid) ? uid : value.id
      const data = await getData(id, uid)
      const totalTuition = (data?.hrs && data?.tuition) ? (data.hrs * data.tuition) : ''
      const totalExpend = (data?.dormtotal ?? 0) + (data?.watertotal ?? 0) + (data?.electotal ?? 0) + (data?.shirttotal ?? 0) + (data?.othertotal ?? 0)
      const totalAdded = (data?.fee ?? 0) + (data?.coursefee ?? 0) + (data?.loan ?? 0)

      const docObj = {
        pageOrientation: 'portrait',
        content: [
          {
            columns: [
              {
                alignment: 'left',
                image: logo,
                width: 80,
                height: 80,
                margin: [8, 0, 0, 0]
              },
              {
                text: ' ',
                width: '*'
              },
              {
                width: 250,
                fontSize: 20,
                bold: true,
                alignment: 'left',
                stack: [
                  {
                    text: 'สถาบันพัฒนาชีวิตสู่ความยั่งยืน AIU'
                  },
                  {
                    text: '219/1 หมู่ 18 ต.อิสาณ อ.เมืองบุรีรัมย์'
                  },
                  {
                    text: 'จ.บุรีรัมย์ 31000 โทรศัพท์ 044-111237'
                  }
                ]
              }
            ]
          },
          {
            text: `วันที่ ${dateTH(dayjs().$d)}`,
            fontSize: 18,
            bold: true,
            alignment: 'center',
            margin: [0, 2, 0, 2]
          },
          {
            text: `เรื่อง แจ้งรายละเอียดค่าใช้จ่ายของนักเรียน ${nameTitleObj[data?.title]?.nameTh ?? ''}${data?.firstname ?? ''} ${data?.lastname ?? ''}`,
            fontSize: 18,
            bold: true,
            margin: [16, 0, 0, 4]
          },
          {
            table: {
              headerRows: 1,
              widths: [27, '*', 60, 90, 45, 75],
              body: [
                [
                  {
                    text: 'ลำดับ',
                    alignment: 'center',
                    bold: true
                  },
                  {
                    text: 'รายการ',
                    alignment: 'center',
                    bold: true
                  },
                  {
                    text: 'ระยะเวลา เรียน',
                    alignment: 'center',
                    bold: true
                  },
                  {
                    text: 'อัตราค่าใช้จ่าย',
                    alignment: 'center',
                    bold: true
                  },
                  {
                    text: 'จำนวน เงินรวม',
                    alignment: 'center',
                    bold: true
                  },
                  {
                    text: 'หมายเหตุ',
                    alignment: 'center',
                    bold: true
                  }
                ],
                [
                  {
                    text: '1',
                    alignment: 'center',
                    border: [true, false, true, false]
                  },
                  {
                    text: `เข้าเรียนวันที่ ${dateTH(data?.startdate?.$d)} ถึง`,
                    border: [true, false, true, false]
                  },
                  {
                    text: '',
                    alignment: 'center',
                    border: [true, false, true, false]
                  },
                  {
                    text: '',
                    alignment: 'center',
                    border: [true, false, true, false]
                  },
                  {
                    text: '',
                    border: [true, false, true, false]
                  },
                  {
                    rowSpan: 3,
                    text: data?.remarkfee ?? '',
                    lineHeight: 1.225,
                    border: [true, false, true, false]
                  }
                ],
                [
                  {
                    text: '',
                    border: [true, false, true, false]
                  },
                  {
                    text: `วันที่ ${dateTH(data?.resigndate?.$d)}`,
                    border: [true, false, true, false]
                  },
                  {
                    text: `${(data?.days) ? data.days + ' วัน' : ''}`,
                    alignment: 'center',
                    border: [true, false, true, false]
                  },
                  {
                    text: `${formatNumber(data?.tuition)} บาท`,
                    alignment: 'center',
                    border: [true, false, true, false]
                  },
                  {
                    text: '',
                    border: [true, false, true, false]
                  },
                  {}
                ],
                [
                  {
                    text: '',
                    border: [true, false, true, false]
                  },
                  {
                    text: '',
                    border: [true, false, true, false]
                  },
                  {
                    text: `${formatNumber(data?.hrs)} ชั่วโมง`,
                    alignment: 'center',
                    border: [true, false, true, false]
                  },
                  {
                    text: `(${formatNumber(data?.hrs)}ชม.*${formatNumber(data?.tuition)} บาท)`,
                    alignment: 'center',
                    border: [true, false, true, false]
                  },
                  {
                    text: formatNumber(totalTuition),
                    alignment: 'right',
                    bold: true,
                    border: [true, false, true, false]
                  },
                  {}
                ],
                [
                  {
                    text: '2',
                    alignment: 'center',
                    border: [true, true, true, false]
                  },
                  {
                    text: '-     ค่าลงทะเบียนเรียน',
                    border: [true, true, true, false]
                  },
                  {
                    text: '',
                    border: [true, true, true, false]
                  },
                  {
                    text: '',
                    alignment: 'right',
                    border: [true, true, true, false]
                  },
                  {
                    text: formatNumber(data?.fee),
                    alignment: 'right',
                    border: [true, true, true, false]
                  },
                  {
                    rowSpan: 8,
                    text: data?.remarkdorm ?? '',
                    lineHeight: 1.225,
                    border: [true, true, true, false]
                  }
                ],
                [
                  {
                    text: '',
                    border: [true, false, true, false]
                  },
                  {
                    text: '-     ค่าหลักสูตรวิชาชีพ',
                    border: [true, false, true, false]
                  },
                  {
                    text: '',
                    border: [true, false, true, false]
                  },
                  {
                    text: '',
                    alignment: 'right',
                    border: [true, false, true, false]
                  },
                  {
                    text: formatNumber(data?.coursefee),
                    alignment: 'right',
                    border: [true, false, true, false]
                  },
                  {}
                ],
                [
                  {
                    text: '',
                    border: [true, false, true, false]
                  },
                  {
                    text: '-     เงินกู้ระหว่างเรียน',
                    border: [true, false, true, false]
                  },
                  {
                    text: '',
                    border: [true, false, true, false]
                  },
                  {
                    text: '',
                    alignment: 'right',
                    border: [true, false, true, false]
                  },
                  {
                    text: formatNumber(data?.loan),
                    alignment: 'right',
                    border: [true, false, true, false]
                  },
                  {}
                ],
                [
                  {
                    text: '',
                    border: [true, false, true, false]
                  },
                  {
                    text: '-     ค่าหอพัก',
                    border: [true, false, true, false]
                  },
                  {
                    text: (!data?.days) ? '' : `${Math.round((data.days - 1) / 30)} เดือน`,
                    alignment: 'center',
                    border: [true, false, true, false]
                  },
                  {
                    text: (!data?.dorm) ? '' : `${formatNumber(data.dorm)}`,
                    alignment: 'right',
                    border: [true, false, true, false]
                  },
                  {
                    text: formatNumber(data?.dormtotal),
                    alignment: 'right',
                    border: [true, false, true, false]
                  },
                  {}
                ],
                [
                  {
                    text: '',
                    border: [true, false, true, false]
                  },
                  {
                    text: '-     ค่าน้ำ',
                    border: [true, false, true, false]
                  },
                  {
                    text: '',
                    border: [true, false, true, false]
                  },
                  {
                    text: (!data?.water) ? '' : `${formatNumber(data.water)}`,
                    alignment: 'right',
                    border: [true, false, true, false]
                  },
                  {
                    text: formatNumber(data?.watertotal),
                    alignment: 'right',
                    border: [true, false, true, false]
                  },
                  {}
                ],
                [
                  {
                    text: '',
                    border: [true, false, true, false]
                  },
                  {
                    text: '-     ค่าไฟ',
                    border: [true, false, true, false]
                  },
                  {
                    text: '',
                    border: [true, false, true, false]
                  },
                  {
                    text: (!data?.elec) ? '' : `${formatNumber(data.elec)}`,
                    alignment: 'right',
                    border: [true, false, true, false]
                  },
                  {
                    text: formatNumber(data?.electotal),
                    alignment: 'right',
                    border: [true, false, true, false]
                  },
                  {}
                ],
                [
                  {
                    text: '',
                    border: [true, false, true, false]
                  },
                  {
                    text: '-     ค่าเสื้อนักเรียน',
                    border: [true, false, true, false]
                  },
                  {
                    text: '',
                    border: [true, false, true, false]
                  },
                  {
                    text: (!data?.shirt) ? '' : `${formatNumber(data.shirt)}`,
                    alignment: 'right',
                    border: [true, false, true, false]
                  },
                  {
                    text: formatNumber(data?.shirttotal),
                    alignment: 'right',
                    border: [true, false, true, false]
                  },
                  {}
                ],
                [
                  {
                    text: '',
                    border: [true, false, true, false]
                  },
                  {
                    text: '-     ค่าอื่นๆ',
                    border: [true, false, true, false]
                  },
                  {
                    text: '',
                    border: [true, false, true, false]
                  },
                  {
                    text: (!data?.other) ? '' : `${formatNumber(data.other)}`,
                    alignment: 'right',
                    border: [true, false, true, false]
                  },
                  {
                    text: formatNumber(data?.othertotal),
                    alignment: 'right',
                    border: [true, false, true, false]
                  },
                  {}
                ],
                [
                  {
                    colSpan: 3,
                    text: 'รวมค่าใช้จ่ายทั้งหมด',
                    alignment: 'center',
                    bold: true
                  },
                  {},
                  {},
                  {
                    colSpan: 3,
                    text: `${formatNumber(totalTuition + totalExpend + totalAdded)} บาท`,
                    alignment: 'center',
                    bold: true
                  },
                  {},
                  {}
                ],
                [
                  {
                    colSpan: 3,
                    text: 'ค่าใช้จ่ายที่เรียกเก็บ',
                    alignment: 'center',
                    bold: true
                  },
                  {},
                  {},
                  {
                    colSpan: 3,
                    text: (!data?.installmentc) ? '' : `${formatNumber(data.installmentc)} บาท`,
                    alignment: 'center',
                    bold: true
                  },
                  {},
                  {}
                ]
              ]
            }
          },
          {
            text: 'หมายเหตุ',
            margin: [16, 8, 0, 0]
          },
          {
            text: '-  ธนาคารกรุงเทพ ชื่อบัญชี บริษัท เอไอยู เอ็ดดูเคชั่น กรุ๊ป จำกัด สาขาโรบินสัน บุรีรัมย์',
            margin: [24, 0, 0, 0]
          },
          {
            text: 'บัญชีออมทรัพย์ เลขที่ 635-7-13008-4',
            margin: [35, 0, 0, 0]
          },
          (isRow) && {
            text: `-  เงินสด ชำระเงินวันที่ ${dateTH(data?.payment?.date?.$d)} จำนวนเงิน ${formatNumber(data?.payment?.amount)} บาท`,
            margin: [24, 0, 0, 0]
          },
          {
            text: `-  ผ่อนชำระ ${data?.installmentn ?? ''} งวด งวดละ ${formatNumber(data?.installmentc)} บาท ${(data?.installmentp?.length) ? `เริ่มชำระ ${dateTH(data?.installmentp[0]?.$d)} - ${dateTH(data?.installmentp[1]?.$d)}` : ''}`,
            margin: [24, 0, 0, 0]
          },
          // {
          //   text: '- นักเรียนและผู้ปกครองรับทราบค่าใช้จ่ายที่เรียกเก็บ และยินยอมชำระเงินตามเงื่อนไขที่ตกลงข้างต้น จึงลงลายมือชื่อไว้เป็นหลักฐาน',
          //   fontSize: 14,
          //   margin: [0, 16, 0, 0]
          // },
          {
            margin: [0, 12, 0, 0],
            columns: [
              {
                alignment: 'center',
                stack: [
                  {
                    text: '........................................'
                  },
                  {
                    text: `(${admin})`
                  },
                  {
                    text: 'เจ้าหน้าที่ธุรการ'
                  }
                ]
              },
              {
                alignment: 'center',
                stack: [
                  {
                    text: '........................................'
                  },
                  {
                    text: `(${nameTitleObj[data?.title]?.nameTh ?? ''}${data?.firstname ?? ''} ${data?.lastname ?? ''})`
                  },
                  {
                    text: 'นักเรียน'
                  }
                ]
              },
              {
                alignment: 'center',
                stack: [
                  {
                    text: '........................................'
                  },
                  {
                    text: `(${parent})`
                  },
                  {
                    text: 'ผู้ปกครอง'
                  }
                ]
              }
            ]
          }
        ],
        defaultStyle: {
          fontSize: 17,
          font: 'THSarabunNew' // Roboto
        }
      }
      await genPdf(docObj, `Payment_${dayjs(data.payment.date).format('BBBB_MM_DD')}`)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const confirmGetPdf = () => {
    let admin = ''
    let parent = ''
    Modal.confirm({
      title: 'กรอกชื่อเจ้าหน้าที่ธุรการและผู้ปกครอง สำหรับเอกสารแจ้งยอดหนี้',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        getPdf(admin, parent)
      },
      onCancel() { },
      content: (
        <>
          <label>เจ้าหน้าที่ธุรการ</label>
          <Input defaultValue={admin} style={{ marginBottom: 24, borderRadius: 8 }} onChange={(e) => {
            admin = e.target.value
          }} />
          <label>ผู้ปกครอง</label>
          <Input defaultValue={parent} style={{ borderRadius: 8 }} onChange={(e) => {
            parent = e.target.value
          }} />
        </>
      )
    })
  }

  return (
    (isText) ?
      <Button
        icon={<PrinterOutlined style={{ fontSize: 14 }} />}
        onClick={() => {
          confirmGetPdf()
        }}
      >Print</Button> :
      <Button
        type='link'
        style={{ marginRight: 8 }}
        icon={<PrinterOutlined style={{ fontSize: 18 }} />}
        onClick={() => {
          confirmGetPdf()
        }}
      ></Button>
  )
}

export default BillPdf
