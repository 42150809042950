import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Form as AntdForm, Row, Col, Card, Button, Input, Select, Skeleton } from 'antd'
import { SaveOutlined, CloseOutlined } from '@ant-design/icons'

import { useAuth } from '../../contexts/AuthContextProvider'
import { nameTitleObj } from '../../const/users'
import { roleObj } from '../../const/roles'
import { pickBy } from '../../utils/tools'

const Form = () => {
  const navigate = useNavigate()
  const { db, functions, role } = useAuth()
  const { uid } = useParams()
  const [loading, setLoading] = useState(false)

  const { value } = useAsync(async () => {
    if (!uid) {
      return {}
    }

    const snap = await db.collection('/users').doc(uid).get()
    return {
      ...snap.data(),
      id: snap.id
    }
  }, [uid])

  const handleSubmit = async (formData) => {
    try {
      setLoading(true)
      const data = pickBy(formData)
      if (!uid) {
        const createUser = functions.httpsCallable('createUser-createUser')
        await createUser(data)
      } else {
        await db.collection('/users').doc(uid).set(data, {
          merge: true
        })
      }
      setLoading(false)
      navigate('/admin/users')
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  if (!value) return <Skeleton></Skeleton>
  return (
    <Card style={{ padding: 48, minHeight: '100%' }}>
      <div style={{ marginBottom: 16, fontSize: 24 }}>
        {(!uid) ? 'สร้าง' : ''}ผู้ใช้งาน
      </div>
      <AntdForm
        layout='vertical'
        onFinish={handleSubmit}
        initialValues={value}
      >
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='title' label='คำนำหน้า' >
              <Select placeholder='เลือก' >
                {((Object.keys(nameTitleObj)).map((o) => (
                  <Select.Option key={o} value={o}>{nameTitleObj[o].nameTh}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='firstname' label='ชื่อ' >
              <Input placeholder='ชื่อ' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='lastname' label='นามสกุล' >
              <Input placeholder='นามสกุล' />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='email' label='อีเมล' rules={[{ required: true, message: 'กรุณาใส่อีเมลด้วยค่ะ' }]}>
              <Input placeholder='อีเมล' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='role' label='ตำแหน่ง' >
              <Select placeholder='เลือก'>
                {((Object.keys(roleObj)).filter((o) => (o !== 'student')).map((o) => (
                  <Select.Option key={o} value={o}>{roleObj[o].name}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          {(role === 'superadmin') ? (
            <Col span={8}>
              <AntdForm.Item name='disabled' label='เปิดการใช้งาน' >
                <Select placeholder='เลือก' >
                  <Select.Option key='active' value={false}>เปิดการใช้งาน</Select.Option>
                  <Select.Option key='inactive' value={true}>ปิดการใช้งาน</Select.Option>
                </Select>
              </AntdForm.Item>
            </Col>
          ) : (
            <Col span={8}></Col>
          )}
        </Row>
        <div>
          <Button
            loading={loading}
            style={{ width: 168, height: 40, marginTop: 20 }}
            htmlType='submit'
            type='primary'
          ><SaveOutlined style={{ fontSize: 16 }} />บันทึก</Button>
          <Button
            loading={loading}
            style={{ height: 40, marginTop: 20, marginLeft: 20 }}
            onClick={() => {
              navigate(-1)
            }}
          ><CloseOutlined />ปิด</Button>
        </div>
      </AntdForm>
    </Card>
  )
}

export default Form
