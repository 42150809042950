import React from 'react'
import { useNavigate, Outlet } from 'react-router-dom'

import { Layout, Button, Image } from 'antd'
import { SettingOutlined, PoweroffOutlined } from '@ant-design/icons'

import { useAuth } from '../../contexts/AuthContextProvider'
import LayoutContextProvider from '../../contexts/LayoutContextProvider'
import Sidebar from './Sidebar'
import NoProfile from '../../assets/images/user.png'

const AdminLayout = () => {
  const navigate = useNavigate()
  const { auth, user, role } = useAuth()

  return (
    <Layout style={{ minHeight: '100%', color: '#F6F6F6' }}>
      <Layout>
        <Layout.Sider>
          <div style={{ width: '100%', padding: 20, marginTop: 20 }}>
            <div style={{ textAlign: 'center' }}>
              <Image
                src={(user?.imgurl) ? user.imgurl : NoProfile}
                width={100}
                preview={false}
              />
              <div>{user.firstname} {user.lastname}</div>
              {(role === 'student') && (
                <div>
                  <Button
                    type='text'
                    icon={<SettingOutlined style={{ fontSize: '14px', color: '#F6F6F6' }} />}
                    size='large'
                    onClick={() => {
                      navigate('/admin/student')
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <Sidebar />
          <div style={{ height: 100 }}></div>
          <div style={{ zIndex: 9, position: 'absolute', width: '100%', padding: 20, bottom: 0 }}>
            <div style={{ textAlign: 'center' }}>
              <Button
                type='text'
                icon={<PoweroffOutlined style={{ fontSize: '24px', color: '#F6F6F6' }} />}
                size='large'
                onClick={async () => {
                  await auth().signOut()
                  navigate('/login')
                }} />
            </div>
          </div>
        </Layout.Sider>
        <Layout.Content style={{ padding: 24 }}>
          <LayoutContextProvider>
            <Outlet />
          </LayoutContextProvider>
        </Layout.Content>
      </Layout>
    </Layout>
  )
}

export default AdminLayout
