import React from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Row, Col, Card, Button, Table, Dropdown, Skeleton } from 'antd'
import { PlusOutlined, EyeOutlined, CopyOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'
import { dbTodayjs } from '../../utils/date'

import { useAuth } from '../../contexts/AuthContextProvider'
import { formatNumber } from '../../utils/tools'
import { typeObj, statusObj } from '../../const/invoice'
import { isAllowed } from '../../route/roles'

import InvoicePdf from './Components/InvoicePdf'

const Form = () => {
  const navigate = useNavigate()
  const { db, role } = useAuth()
  const { cid } = useParams()

  const { value } = useAsync(async () => {
    const coopSnap = await db.collection('/coops').doc(cid).get()
    const coopData = coopSnap.data()

    const snap = await db.collection('/invoices').where('cid', '==', cid).get()
    const data = snap.docs.map((doc) => {
      const d = doc.data()
      return {
        ...d,
        id: doc.id,
        date: dbTodayjs(d?.date),
        paymentdate: dbTodayjs(d?.paymentdate)
      }
    })
    return {
      coop: coopData.name,
      invoices: data
    }
  }, [cid])

  const columns = [
    {
      title: 'Invoice No.',
      dataIndex: 'no',
      width: 150
    },
    {
      title: 'ประเภท Invoice',
      dataIndex: 'type',
      width: 150,
      render: data => typeObj[data]?.name ?? ''
    },
    {
      title: 'วัน/เดือน/ปี',
      dataIndex: 'date',
      width: 120,
      align: 'center',
      render: data => (
        <span>{(data) ? dayjs(data).format('DD/MM/BBBB') : ''}</span>
      )
    },
    {
      title: 'วันที่เรียกเก็บเงิน',
      dataIndex: 'paymentdate',
      width: 120,
      align: 'center',
      render: data => (
        <span>{(data) ? dayjs(data).format('DD/MM/BBBB') : ''}</span>
      )
    },
    {
      title: 'ยอดรวม (¥)',
      dataIndex: 'total',
      width: 120,
      align: 'center',
      render: data => formatNumber(data)
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      width: 150,
      align: 'center',
      render: data => (
        <span style={{ color: statusObj[data]?.color ?? '' }}>{statusObj[data]?.name ?? ''}</span>
      )
    },
    {
      title: 'เครื่องมือ',
      dataIndex: 'Operation',
      width: 150,
      render: (_, row) => (
        <div>
          {(isAllowed(role, 'edit', 'invoices')) && (
            <>
              <Button
                type='link'
                style={{ marginRight: 8 }}
                icon={<EyeOutlined style={{ fontSize: 18 }} />}
                onClick={() => {
                  navigate(`/admin/invoices/${cid}/${row.type}/${row.id}/edit`)
                }}
              ></Button>
              <Button
                type='link'
                style={{ marginRight: 8 }}
                icon={<CopyOutlined style={{ fontSize: 18 }} />}
                onClick={() => {
                  navigate(`/admin/invoices/${cid}/${row.type}/${row.id}/copy`)
                }}
              ></Button>
            </>
          )}
          <InvoicePdf value={row} />
        </div>
      )
    }
  ]

  if (!value) return <Skeleton></Skeleton>
  return (
    <div>
      <Card
        style={{ marginTop: 16 }}
        bodyStyle={{ padding: '32px 80px' }}
      >
        <div style={{ marginBottom: 24, fontSize: 24 }}>
          รายละเอียดสหกรณ์
        </div>
        <Row>
          <Col span={6} className='aiu-title'>สหกรณ์</Col>
          <Col span={18} className='aiu-content'>{value?.coop ?? ''}</Col>
        </Row>
        <Row style={{ margin: '48px 0 24px' }}>
          <Col flex='auto'>
            <div style={{ fontSize: 24 }}>
              รายละเอียดเอกสารเก็บเงิน
            </div>
          </Col>
          <Col flex='500px'>
            {(isAllowed(role, 'create', 'invoices')) && (
              <div style={{ float: 'right', fontSize: 14 }}>
                <Dropdown
                  menu={{
                    onClick: (e) => {
                      navigate(`/admin/invoices/${cid}/${e.key}/create`)
                    },
                    items: Object.keys(typeObj).map((o) => ({ key: o, label: typeObj[o].name }))
                  }}
                  placement='bottomRight'
                >
                  <Button
                    type='primary'
                    icon={<PlusOutlined style={{ fontSize: 14 }} />}
                  >
                    เพิ่มเอกสาร
                  </Button>
                </Dropdown>
              </div>
            )}
          </Col>
        </Row >
        <Table
          bordered
          rowKey='id'
          style={{ overflowX: 'auto' }}
          columns={columns}
          dataSource={value.invoices}
        />
      </Card>
    </div>
  )
}

export default Form
