import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Form as AntdForm, Card, Row, Col, Table, Modal, Input, Select, Button, Popconfirm, Tag, Space, Skeleton } from 'antd'
import { PlusOutlined, SearchOutlined, PrinterOutlined, EyeOutlined, DeleteOutlined, CheckOutlined, CloseOutlined, SettingOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'
import { dbTodayjs } from '../../utils/date'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { genCsv } from '../../utils/csv'
import { search } from '../../utils/search'
import { statusObj } from '../../const/jobs'
import { isAllowed } from '../../route/roles'

import SummaryCsv from './Components/SummaryCsv'

const List = () => {
  const navigate = useNavigate()
  const { db, role } = useAuth()
  const { getPageNo, onPageChange, getSearch, setSearch, getUsers, getCoops, getCompanies } = useLayoutContext()

  const [updateCount, setUpdateCount] = useState(0)

  const [initValue, setInitValue] = useState([])
  const [value, setValue] = useState([])
  const [qsVisible, setQsVisible] = useState(false)
  const [qs, setQs] = useState({})
  const [tags, setTags] = useState([])
  const [coopObj, setCoopObj] = useState([])
  const [companyObj, setCompanyObj] = useState([])

  const [searchForm] = AntdForm.useForm()

  const { loading } = useAsync(async () => {
    const coops = await getCoops(true)
    const coopData = coops.reduce((acc, o) => {
      acc[o.id] = {
        name: o.name
      }
      return acc
    }, {})
    setCoopObj(coopData)

    const companies = await getCompanies(true)
    const companyData = companies.reduce((acc, o) => {
      acc[o.id] = {
        name: o.name
      }
      return acc
    }, {})
    setCompanyObj(companyData)

    const users = await getUsers()
    const userObj = users.reduce((acc, o) => {
      acc[o.id] = o
      return acc
    }, {})

    const snap = await db.collection('/jobs').get()
    const data = snap.docs.map(doc => {
      const d = doc.data()
      return {
        ...d,
        id: doc.id,
        creator: userObj[d.creator]?.name ?? '',
        createddate: dbTodayjs(d?.createddate),
        interviewdate: dbTodayjs(d?.interviewdate)
      }
    })
    const q = getSearch('jobs')
    setQs(q || {})
    setInitValue(data)
  }, [updateCount])

  useEffect(() => {
    const tagList = Object.keys(qs).reduce((acc, o) => {
      return (!qs[o]) ? acc : [...acc, {
        key: o,
        value: (o === 'status') ? statusObj[qs[o]]?.name : (o === 'coopid') ? coopObj[qs[o]]?.name : (o === 'companyid') ? companyObj[qs[o]]?.name : qs[o],
        label: {
          bustype: 'ประเภทธุระกิจ',
          companyid: 'ชื่อบริษัท',
          coopid: 'ชื่อสหกรณ์',
          creator: 'ผู้สร้างงาน',
          status: 'สถานะ'
        }[o]
      }]
    }, [])
    setTags(tagList)
    const data = search(initValue, qs)
    setValue(data)
  }, [initValue, qs, coopObj, companyObj])

  const handleClose = (key) => {
    const newQs = {
      ...qs,
      [key]: null
    }
    setQs(newQs)
    setSearch('jobs', newQs)
    searchForm.setFieldsValue(newQs)
  }

  const handleDelete = async (row) => {
    try {
      await db.collection('/jobs').doc(row.id).delete()
      setUpdateCount(updateCount + 1)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const download = async (data) => {
    const list = data.map((o) => ({
      'ประเภทธุรกิจ': o?.bustype ?? '',
      'สหกรณ์': coopObj[o?.coopid]?.name ?? '',
      'บริษัท': companyObj[o?.companyid]?.name ?? '',
      'วันที่สร้าง': (o?.createddate) ? dayjs(o.createddate).format('DD/MM/YYYY') : '',
      'วันที่สัมภาษณ์': (o?.interviewdate) ? dayjs(o.interviewdate).format('DD/MM/YYYY') : '',
      'ผู้สร้างงาน': o?.creator ?? '',
      'จำนวนนักเรียน': o?.no ?? '',
      'คุณสมบัติ': o?.character ?? '',
      'สถานะ': statusObj[o?.status]?.name ?? ''
    }))
    try {
      await genCsv('job_list', list)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const columns = [
    {
      title: 'ประเภทธุรกิจ',
      dataIndex: 'bustype',
      width: 120
    },
    {
      title: 'สหกรณ์',
      dataIndex: 'coopid',
      width: 150,
      render: data => coopObj[data]?.name ?? ''
    },
    {
      title: 'บริษัท',
      dataIndex: 'companyid',
      width: 150,
      render: data => companyObj[data]?.name ?? ''
    },
    {
      title: 'วันที่สร้าง',
      dataIndex: 'createddate',
      width: 120,
      render: data => (
        <span>{(data) ? dayjs(data).format('D MMM BBBB') : ''}</span>
      )
    },
    {
      title: 'วันที่สัมภาษณ์',
      dataIndex: 'interviewdate',
      width: 120,
      render: data => (
        <span>{(data) ? dayjs(data).format('D MMM BBBB') : ''}</span>
      )
    },
    {
      title: 'ผู้สร้างงาน',
      dataIndex: 'creator',
      width: 150
    },
    {
      title: 'จำนวนนักเรียน',
      dataIndex: 'no',
      width: 125,
      align: 'center'
    },
    {
      title: 'คุณสมบัติ',
      dataIndex: 'character',
      width: 120
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      width: 120,
      render: data => (statusObj[data]?.name ?? '')
    },
    {
      title: 'เครื่องมือ',
      dataIndex: 'Operation',
      width: 175,
      render: (_, row) => (
        <div>
          <Button
            type='link'
            style={{ marginRight: '8px' }}
            icon={<EyeOutlined style={{ fontSize: 18 }} />}
            onClick={() => {
              navigate(`/admin/jobs/${row.id}/view`)
            }}
          ></Button>
          {(isAllowed(role, 'edit', 'jobs')) && (
            <Button
              type='link'
              style={{ marginRight: '8px' }}
              icon={<SettingOutlined style={{ fontSize: 18 }} />}
              onClick={() => {
                navigate(`/admin/jobs/${row.id}/edit`)
              }}
            ></Button>
          )}
          {(isAllowed(role, 'delete', 'jobs')) && (
            <Popconfirm
              title='Are you sure to delete this job?'
              onConfirm={() => handleDelete(row)}
              okText='Yes'
              cancelText='No'
            >
              <Button
                type='link'
                style={{ marginRight: '8px' }}
                danger
                icon={<DeleteOutlined style={{ fontSize: 18 }} />}
              ></Button>
            </Popconfirm>
          )}
        </div>
      )
    }
  ]

  if (loading) return <Skeleton></Skeleton>
  return (
    <Card style={{ minHeight: '100%' }}>
      <Row>
        <Col flex='auto'>
          <div style={{ fontSize: 24 }}>
            จัดหางาน
          </div>
          <div style={{ marginTop: 12 }}>
            {tags.map((o) => (
              <Tag
                key={o.key}
                closable
                onClose={() => handleClose(o.key)}
              >
                {o.label}: {o.value}
              </Tag>
            ))}
          </div>
        </Col>
        <Col flex='300px'>
          <div style={{ float: 'right', fontSize: 14 }}>
            <div>
              {(isAllowed(role, 'create', 'jobs')) && (
                <Button
                  type='primary'
                  icon={<PlusOutlined style={{ fontSize: 14 }} />}
                  onClick={() => {
                    navigate('/admin/jobs/create')
                  }}
                >
                  สร้างงาน
                </Button>
              )}
            </div>
            <div style={{ float: 'right', margin: '8px 0 16px 0' }}>
              <Button
                icon={<SearchOutlined />}
                style={{ margin: '0 8px' }}
                onClick={() => {
                  setQsVisible(true)
                }}
              ></Button>
              <Button
                icon={<PrinterOutlined />}
                onClick={() => {
                  download(value)
                }}
              ></Button>
            </div>
          </div>
        </Col>
      </Row >
      <Table
        bordered
        rowKey='id'
        style={{ overflowX: 'auto' }}
        columns={columns}
        dataSource={value}
        pagination={{
          total: value.length,
          current: getPageNo('jobs'),
          onChange(page) {
            onPageChange('jobs', page)
          }
        }}
      />
      <div style={{ marginTop: 24, fontSize: 20 }}>
        {(isAllowed(role, 'edit', 'jobs')) && (
          <SummaryCsv jobs={initValue} />
        )}
      </div>
      <Modal
        forceRender
        open={qsVisible}
        title={
          <div style={{ padding: '16px 24px', fontWeight: 'bold', fontSize: '18px', lineHeight: '23px', backgroundColor: '#228B90', color: '#F6F6F6', borderRadius: '5px 5px 0px 0px' }}>
            ค้นหา
          </div>
        }
        width='50%'
        closable={false}
        footer={
          <Row justify='center'>
            <Space align='center'>
              <Col>
                <Button
                  type='secondary'
                  size='medium'
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    const empty = {
                      bustype: null,
                      companyid: null,
                      coopid: null,
                      creator: null,
                      status: null
                    }
                    setQs(empty)
                    setSearch('jobs', empty)
                    searchForm.setFieldsValue(empty)
                  }}
                >
                  Clear Data
                </Button>
              </Col>
              <Col>
                <Button
                  size='medium'
                  style={{ color: '#FFF', backgroundColor: '#EE7930' }} icon={<CloseOutlined />}
                  onClick={() => {
                    setQsVisible(false)
                  }}
                >
                  ยกเลิก
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  size='medium'
                  icon={<CheckOutlined />}
                  onClick={() => {
                    const data = searchForm.getFieldsValue()
                    setQs(data)
                    setSearch('jobs', data)
                    setQsVisible(false)
                  }}
                >
                  ยืนยัน
                </Button>
              </Col>
            </Space>
          </Row>
        }
      >
        <AntdForm
          layout='vertical'
          initialValues={qs}
          style={{ margin: '8px 32px' }}
          form={searchForm}
          name='searchForm'
        >
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <AntdForm.Item name='bustype' label='ประเภทธุรกิจ' >
                <Input placeholder='ประเภทธุรกิจ' />
              </AntdForm.Item>
            </Col>
            <Col span={12}>
              <AntdForm.Item name='creator' label='ผู้สร้างงาน' >
                <Input placeholder='ผู้สร้างงาน' />
              </AntdForm.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <AntdForm.Item name='coopid' label='ชื่อสหกรณ์' >
                <Select placeholder='เลือก' >
                  {((Object.keys(coopObj)).map((o) => (
                    <Select.Option key={o} value={o}>{coopObj[o].name}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
            <Col span={12}>
              <AntdForm.Item name='companyid' label='ชื่อบริษัท' >
                <Select placeholder='เลือก' >
                  {((Object.keys(companyObj)).map((o) => (
                    <Select.Option key={o} value={o}>{companyObj[o].name}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <AntdForm.Item name='status' label='สถานะ' >
                <Select placeholder='เลือก' >
                  {((Object.keys(statusObj)).map((o) => (
                    <Select.Option key={o} value={o}>{statusObj[o].name}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
            <Col span={12}></Col>
          </Row>
        </AntdForm>
      </Modal>
    </Card >
  )
}

export default List
