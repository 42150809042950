import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Form as AntdForm, Card, Row, Col, Table, Modal, Input, Select, Button, Popconfirm, Tag, Space, Skeleton } from 'antd'
import { PlusOutlined, SearchOutlined, PrinterOutlined, EyeOutlined, DeleteOutlined, CheckOutlined, CloseOutlined, SettingOutlined } from '@ant-design/icons'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { genCsv } from '../../utils/csv'
import { search } from '../../utils/search'
import { statusObj } from '../../const/room'
import { isAllowed } from '../../route/roles'

const List = () => {
  const navigate = useNavigate()
  const { db, role } = useAuth()
  const { getPageNo, onPageChange, getSearch, setSearch, getCampuses } = useLayoutContext()

  const [updateCount, setUpdateCount] = useState(0)
  const [initValue, setInitValue] = useState([])
  const [value, setValue] = useState([])
  const [qsVisible, setQsVisible] = useState(false)
  const [qs, setQs] = useState({})
  const [tags, setTags] = useState([])

  const [campusObj, setCampusObj] = useState()
  const [summary, setSummary] = useState()

  const [searchForm] = AntdForm.useForm()

  const { loading } = useAsync(async () => {
    const campusData = await getCampuses()
    const campus = campusData.reduce((acc, o) => {
      acc[o.id] = o
      return acc
    }, {})
    setCampusObj(campus)

    const snap = await db.collection('/rooms').orderBy('cid').get()
    const data = snap.docs.map(doc => {
      const d = doc.data()
      const remain = d.total - (d?.students ?? []).length
      return {
        ...d,
        id: doc.id,
        remain: remain,
        status: (remain === 0) ? 'full' : 'available'
      }
    })

    const summary = data.reduce((acc, o) => {
      const key = `${o.cid}-${o.sex}`
      if (o.remain !== 0) {
        acc[key] = (acc[key] || 0) + o.remain
      }
      return acc
    }, {})
    setSummary(summary)

    const q = getSearch('rooms')
    setQs(q || {})
    setInitValue(data)
  }, [updateCount])

  useEffect(() => {
    const tagList = Object.keys(qs).reduce((acc, o) => {
      return (!qs[o]) ? acc : [...acc, {
        key: o,
        value: (o === 'cid') ? campusObj[qs[o]]?.name : (o === 'status') ? statusObj[qs[o]]?.name : (o === 'sex') ? (qs[o] === 'm' ? 'ชาย' : 'หญิง') : qs[o],
        label: {
          cid: 'วิทยาเขต',
          room: 'หมายเลขห้อง',
          sex: 'เพศ',
          status: 'สถานะ'
        }[o]
      }]
    }, [])
    setTags(tagList)
    const data = search(initValue, qs)
    setValue(data)
  }, [initValue, qs, campusObj])

  const handleClose = (key) => {
    const newQs = {
      ...qs,
      [key]: null
    }
    setQs(newQs)
    setSearch('rooms', newQs)
    searchForm.setFieldsValue(newQs)
  }

  const handleDelete = async (row) => {
    try {
      await db.collection('/rooms').doc(row.id).delete()
      setUpdateCount(updateCount + 1)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const download = async (data) => {
    const list = data.map((o) => ({
      'วิทยาเขต': campusObj[o?.cid]?.name ?? '',
      'หมายเลขห้อง': o?.room ?? '',
      'เพศ': (!o?.sex) ? '' : (o.sex === 'm') ? 'ชาย' : 'หญิง',
      'จำนวนเตียง': o?.total ?? '',
      'จำนวนเตียงที่ว่าง': o?.remain ?? '',
      'สถานะ': statusObj[o?.status]?.name ?? ''
    }))
    try {
      await genCsv('room_list', list)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const columns = [
    {
      title: 'วิทยาเขต',
      dataIndex: 'cid',
      width: 120,
      render: data => campusObj[data]?.name ?? ''
    },
    {
      title: 'หมายเลขห้อง',
      dataIndex: 'room',
      width: 100,
      align: 'center'
    },
    {
      title: 'เพศ',
      dataIndex: 'sex',
      width: 100,
      align: 'center',
      render: data => (data === 'm') ? 'ชาย' : 'หญิง'
    },
    {
      title: 'จำนวนเตียง',
      dataIndex: 'total',
      width: 100,
      align: 'center'
    },
    {
      title: 'จำนวนเตียงที่ว่าง',
      dataIndex: 'remain',
      width: 100,
      align: 'center'
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      width: 120,
      align: 'center',
      render: data => (
        <span style={{ color: statusObj[data].color }}>{statusObj[data].name}</span>
      )
    },
    {
      title: 'เครื่องมือ',
      dataIndex: 'Operation',
      width: 175,
      render: (_, row) => (
        <div>
          <Button
            type='link'
            style={{ marginRight: '8px' }}
            icon={<EyeOutlined style={{ fontSize: 18 }} />}
            onClick={() => {
              navigate(`/admin/rooms/${row.id}/view`)
            }}
          ></Button>
          {(isAllowed(role, 'edit', 'rooms')) && (
            <Button
              type='link'
              style={{ marginRight: '8px' }}
              icon={<SettingOutlined style={{ fontSize: 18 }} />}
              onClick={() => {
                navigate(`/admin/rooms/${row.id}/edit`)
              }}
            ></Button>
          )}
          {(isAllowed(role, 'delete', 'rooms')) && (
            <Popconfirm
              title='Are you sure to delete this room?'
              onConfirm={() => handleDelete(row)}
              okText='Yes'
              cancelText='No'
            >
              <Button
                type='link'
                style={{ marginRight: '8px' }}
                danger
                icon={<DeleteOutlined style={{ fontSize: 18 }} />}
              ></Button>
            </Popconfirm>
          )}
        </div>
      )
    }
  ]

  if (loading) return <Skeleton></Skeleton>
  return (
    <Card style={{ minHeight: '100%' }}>
      <Row>
        <Col flex='auto'>
          <div style={{ fontSize: 24 }}>
            หอพัก
          </div>
          <div style={{ marginTop: 12 }}>
            {tags.map((o) => (
              <Tag
                key={o.key}
                closable
                onClose={() => handleClose(o.key)}
              >
                {o.label}: {o.value}
              </Tag>
            ))}
          </div>
        </Col>
        <Col flex='300px'>
          <div style={{ float: 'right', fontSize: 14 }}>
            {(isAllowed(role, 'create', 'rooms')) && (
              <div>
                <Button
                  type='primary'
                  icon={<PlusOutlined style={{ fontSize: 14 }} />}
                  onClick={() => {
                    navigate('/admin/rooms/create')
                  }}
                >
                  เพิ่มหอพัก
                </Button>
              </div>
            )}
            <div style={{ float: 'right', margin: '8px 0 16px 0' }}>
              <Button
                icon={<SearchOutlined />}
                style={{ margin: '0 8px' }}
                onClick={() => {
                  setQsVisible(true)
                }}
              ></Button>
              <Button
                icon={<PrinterOutlined />}
                onClick={() => {
                  download(value)
                }}
              ></Button>
            </div>
          </div>
        </Col>
      </Row >
      <Table
        bordered
        rowKey='id'
        style={{ overflowX: 'auto' }}
        columns={columns}
        dataSource={value}
        pagination={{
          total: value.length,
          current: getPageNo('rooms'),
          onChange(page) {
            onPageChange('rooms', page)
          }
        }}
      />
      {(Object.keys(summary).map((o, i) => (
        <span key={i}>
          {campusObj[o.split('-')[0]]?.name ?? ''} {(o.split('-')[1] === 'm' ? 'ชาย' : 'หญิง')} : ว่าง {summary[o]} เตียง<br />
        </span>
      )))}
      <Modal
        forceRender
        open={qsVisible}
        title={
          <div style={{ padding: '16px 24px', fontWeight: 'bold', fontSize: '18px', lineHeight: '23px', backgroundColor: '#228B90', color: '#F6F6F6', borderRadius: '5px 5px 0px 0px' }}>
            ค้นหา
          </div>
        }
        width='50%'
        closable={false}
        footer={
          <Row justify='center'>
            <Space align='center'>
              <Col>
                <Button
                  type='secondary'
                  size='medium'
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    const empty = {
                      cid: null,
                      room: null,
                      sex: null,
                      status: null
                    }
                    setQs(empty)
                    setSearch('rooms', empty)
                    searchForm.setFieldsValue(empty)
                  }}
                >
                  Clear Data
                </Button>
              </Col>
              <Col>
                <Button
                  size='medium'
                  style={{ color: '#FFF', backgroundColor: '#EE7930' }} icon={<CloseOutlined />}
                  onClick={() => {
                    setQsVisible(false)
                  }}
                >
                  ยกเลิก
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  size='medium'
                  icon={<CheckOutlined />}
                  onClick={() => {
                    const data = searchForm.getFieldsValue()
                    setQs(data)
                    setSearch('rooms', data)
                    setQsVisible(false)
                  }}
                >
                  ยืนยัน
                </Button>
              </Col>
            </Space>
          </Row>
        }
      >
        <AntdForm
          layout='vertical'
          initialValues={qs}
          style={{ margin: '8px 32px' }}
          form={searchForm}
          name='searchForm'
        >
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <AntdForm.Item name='cid' label='วิทยาเขต' >
                <Select placeholder='เลือก' >
                  {((Object.keys(campusObj)).map((o) => (
                    <Select.Option key={o} value={o}>{campusObj[o].name}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
            <Col span={12}>
              <AntdForm.Item name='room' label='หมายเลขห้อง' >
                <Input placeholder='หมายเลขห้อง' />
              </AntdForm.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <AntdForm.Item name='sex' label='เพศ' >
                <Select placeholder='เลือก' >
                  <Select.Option value='m'>ชาย</Select.Option>
                  <Select.Option value='f'>หญิง</Select.Option>
                </Select>
              </AntdForm.Item>
            </Col>
            <Col span={12}>
              <AntdForm.Item name='status' label='สถานะ' >
                <Select placeholder='เลือก' >
                  {((Object.keys(statusObj)).map((o) => (
                    <Select.Option key={o} value={o}>{statusObj[o].name}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
          </Row>
        </AntdForm>
      </Modal>
    </Card >
  )
}

export default List
