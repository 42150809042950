import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Form as AntdForm, Row, Col, Card, Button, Table, Modal, Popover, Space, Input, Select, Tag, Skeleton } from 'antd'
import { EyeOutlined, DownloadOutlined, CloseOutlined, CheckOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'
import { dbTodayjs, offsetMonth } from '../../utils/date'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { DatePicker } from '../../components/DateTime'
import { genCsv } from '../../utils/csv'
import { search } from '../../utils/search'
import { formatNumber, dateTH } from '../../utils/tools'
import { statusObj, payStatusObj, typeObj } from '../../const/fund'
import { nameTitleObj } from '../../const/users'
import { recommenderObj } from '../../const/student'
import { isAllowed } from '../../route/roles'

const Form = () => {
  const navigate = useNavigate()
  const { db, role } = useAuth()
  const { cid } = useParams()
  const { getLenders, getCompanies, getStudents, getSearch, setSearch } = useLayoutContext()

  const [iniValue, setIniValue] = useState()
  const [initPayment, setInitPayment] = useState([])
  const [payment, setPayment] = useState([])
  const [reportVisible, setReportVisible] = useState(false)
  const [coverVisible, setCoverVisible] = useState(false)
  const [qsVisible, setQsVisible] = useState(false)
  const [qs, setQs] = useState({})
  const [tags, setTags] = useState([])

  const [reportForm] = AntdForm.useForm()
  const [coverForm] = AntdForm.useForm()
  const [searchForm] = AntdForm.useForm()

  const { value } = useAsync(async () => {
    if (!cid) {
      return {}
    }

    const lenderData = await getLenders()
    const lender = lenderData.find((o) => o.id === cid)

    let loan = 0
    let principleremain = 0

    const snap = await db.collection('/funds').where('lid', '==', cid).where('disabled', '==', false).get()
    const fundData = snap.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    setIniValue(fundData)

    const payments = fundData.map((o) => {
      const floan = (isNaN(o?.loan)) ? 0 : o.loan
      const fprincipleremain = (!o?.payments?.length) ? 0 : (isNaN(o.payments[o.payments.length - 1]?.principleremain)) ? 0 : o.payments[o.payments.length - 1].principleremain
      const finterestaccum = (!o?.payments?.length) ? 0 : (isNaN(o.payments[o.payments.length - 1]?.interestaccum)) ? 0 : o.payments[o.payments.length - 1].interestaccum
      const startdate = dbTodayjs(o?.startdate)
      const commitdate = offsetMonth(startdate, o?.period)
      const paymentdate = (!o?.payments?.length) ? null : dbTodayjs(o.payments[o.payments.length - 1]?.date)

      loan += floan
      principleremain += fprincipleremain
      return {
        id: o.id,
        index: typeObj[o.type].n,
        type: o.type,
        paystatus: o.paystatus,
        status: o.status,
        name: `${o?.firstname ?? ''} ${o?.lastname ?? ''}`,
        firstname: o?.firstname ?? '',
        lastname: o?.lastname ?? '',
        loan: floan,
        principleremain: fprincipleremain,
        interestaccum: finterestaccum,
        paymentdate: paymentdate,
        commitdate: commitdate
      }
    })

    const q = getSearch('creditorsview')
    setQs(q || {})
    setInitPayment(payments)

    return {
      lender: lender,
      loan: loan,
      principleremain: principleremain,
      payments: payments
    }
  }, [cid])

  useEffect(() => {
    const tagList = Object.keys(qs).reduce((acc, o) => {
      return (!qs[o]) ? acc : [...acc, {
        key: o,
        value: (o === 'paystatus') ? payStatusObj[qs[o]]?.name : (o === 'status') ? statusObj[qs[o]]?.name : qs[o],
        label: {
          firstname: 'ชื่อ',
          lastname: 'นามสกุล',
          paystatus: 'สถานะการชำระเงิน',
          status: 'สถานะ'
        }[o]
      }]
    }, [])
    setTags(tagList)
    const data = search(initPayment, qs)
    setPayment(data)
  }, [initPayment, qs])

  const handleClose = (key) => {
    const newQs = {
      ...qs,
      [key]: null
    }
    setQs(newQs)
    setSearch('creditorsview', newQs)
    searchForm.setFieldsValue(newQs)
  }

  const downloadReport = async (data) => {
    const isQuarter = isNaN(data.month)
    data = {
      ...data,
      bbbb: dayjs(data.year).format('BBBB'),
      yyyy: dayjs(data.year).format('YYYY'),
      month: (!isQuarter) ? data.month : (data.month === 'q1') ? '3' : (data.month === 'q2') ? '6' : (data.month === 'q3') ? '9' : '12',
      isQuarter: isQuarter
    }
    try {
      const companyData = await getCompanies()
      const companyObj = companyData.reduce((acc, o) => {
        acc[o.id] = o.name
        return acc
      }, {})

      const studentData = await getStudents()
      const studentObj = studentData.reduce((acc, o) => {
        if (o?.traveldate) {
          acc[o.scn] = { recommend: o.recommend, branchid: o.branchid }
        }
        return acc
      }, {})

      const list = (iniValue || []).reduce((acc1, o) => {
        let transferTotal = 0
        let latestPayDate
        let qtransfer = 0
        let qprinciple = 0
        let qinterest = 0
        let qremain = 0
        return [...acc1, ...(o.payments || []).reduce((acc2, p) => {
          const date = dbTodayjs(p?.date)
          const paydate = dbTodayjs(p?.paydate)
          latestPayDate = (p?.paydate) ? paydate : latestPayDate
          const month = dayjs(date).format('M')
          const year = dayjs(date).format('BBBB')
          const transfer = (isNaN(p?.transfer)) ? 0 : p.transfer
          const principle = (isNaN(p?.principle)) ? 0 : p.principle
          const interest = (isNaN(p?.interest)) ? 0 : p.interest
          const remain = (isNaN(p?.remain)) ? 0 : p.remain
          transferTotal += transfer
          qtransfer = (parseInt(month) % 3 === 1) ? transfer : (qtransfer + transfer)
          qprinciple = (parseInt(month) % 3 === 1) ? principle : (qprinciple + principle)
          qinterest = (parseInt(month) % 3 === 1) ? interest : (qinterest + interest)
          qremain = (parseInt(month) % 3 === 1) ? remain : (qremain + remain)
          return [...acc2, {
            type: o.type,
            status: o.status,
            month: month,
            year: year,
            company: companyObj[o.companyid],
            title: nameTitleObj[o.title]?.nameTh,
            firstname: o?.firstname,
            lastname: o?.lastname,
            recommend: recommenderObj[studentObj[o?.scn]?.recommend]?.name,
            loan: (isNaN(o?.loan)) ? 0 : o.loan,
            principleremain: (isNaN(p?.principleremain)) ? 0 : p.principleremain,
            interestaccum: (isNaN(p?.interestaccum)) ? 0 : p.interestaccum,
            transferTotal: transferTotal,
            date: dateTH(date?.$d, 'short'),
            installment: (isNaN(o?.installment)) ? 0 : o.installment,
            transfer: transfer,
            principle: principle,
            interest: interest,
            remain: remain,
            qtransfer: qtransfer,
            qprinciple: qprinciple,
            qinterest: qinterest,
            qremain: qremain,
            paydate: dateTH(paydate?.$d, 'short'),
            latestPayDate: dateTH(latestPayDate?.$d, 'short')
          }]
        }, [])]
      }, [])
      let sum = {
        loan: 0,
        principleremain: 0,
        interestaccum: 0,
        transferTotal: 0,
        transfer: 0,
        principle: 0,
        interest: 0,
        remain: 0
      }
      let result = list.reduce((acc, o) => {
        const filter = (
          (data.type.indexOf(o.type) !== -1) &&
          ((data.status === 'all') || (data.status === o.status)) &&
          (data.month === o.month) &&
          (data.bbbb === o.year)
        )
        if (filter) {
          o.transfer = (data.isQuarter) ? o.qtransfer : o.transfer
          o.principle = (data.isQuarter) ? o.qprinciple : o.principle
          o.interest = (data.isQuarter) ? o.qinterest : o.interest
          o.remain = (data.isQuarter) ? o.qremain : o.remain
          sum = {
            loan: sum.loan + o.loan,
            principleremain: sum.principleremain + o.principleremain,
            interestaccum: sum.interestaccum + o.interestaccum,
            transferTotal: sum.transferTotal + o.transferTotal,
            transfer: sum.transfer + o.transfer,
            principle: sum.principle + o.principle,
            interest: sum.interest + o.interest,
            remain: sum.remain + o.remain
          }
        }
        return (filter) ? [...acc, {
          'ลำดับที่': acc.length + 1,
          'บริษัท': o?.company ?? '',
          'Title': o?.title ?? '',
          'ชื่อ': o?.firstname ?? '',
          'นามสกุล': o?.lastname ?? '',
          'ผู้แนะนำ': o?.recommend ?? '',
          'วงเงินกู้': o?.loan ?? '',
          'ยอดหนี้': o?.principleremain ?? '',
          'ดอกเบี้ยค้าง': o?.interestaccum ?? '',
          'เงินโอนสะสม': o?.transferTotal ?? '',
          'วันครบ': o?.date ?? '',
          'เงื่อนไข': o?.installment ?? '',
          'ชำระจริง': o?.transfer ?? '',
          'ชำระเงินต้น': o?.principle ?? '',
          'ชำระดอกเบี้ย': o?.interest ?? '',
          'วันที่ชำระ': o?.paydate ?? '',
          'วันที่ชำระล่าสุด': o?.latestPayDate ?? '',
          'คืนญาติ': o?.remain ?? ''
        }] : acc
      }, [])
      result.push({
        'ลำดับที่': 'รวม',
        'บริษัท': '',
        'Title': '',
        'ชื่อ': '',
        'นามสกุล': '',
        'ผู้แนะนำ': '',
        'วงเงินกู้': sum.loan,
        'ยอดหนี้': sum.principleremain,
        'ดอกเบี้ยค้าง': sum.interestaccum,
        'เงินโอนสะสม': sum.transferTotal,
        'วันครบ': '',
        'เงื่อนไข': '',
        'ชำระจริง': sum.transfer,
        'ชำระเงินต้น': sum.principle,
        'ชำระดอกเบี้ย': sum.interest,
        'วันที่ชำระ': '',
        'วันที่ชำระล่าสุด': '',
        'คืนญาติ': sum.remain
      })
      const reportDate = (isQuarter) ? `ประจำไตรมาส ${Math.floor(parseInt(data.month) / 3)} ${parseInt(data.bbbb)}` : `ประจำเดือน${(new Date(Date.UTC(data.yyyy, parseInt(data.month) - 1, 2, 0, 0, 0))).toLocaleDateString('th-TH', { year: 'numeric', month: 'long' })}`
      const title = `รายงานลูกหนี้กองทุน AIU (${value?.lender?.name ?? ''}) ${reportDate}`
      await genCsv('report', result, title)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const downloadCover = async (data) => {
    const isQuarter = isNaN(data.month)
    data = {
      bbbb: dayjs(data.year).format('BBBB'),
      yyyy: dayjs(data.year).format('YYYY'),
      month: (!isQuarter) ? data.month : (data.month === 'q1') ? '3' : (data.month === 'q2') ? '6' : (data.month === 'q3') ? '9' : '12',
      isQuarter: isQuarter
    }
    try {
      const list = (iniValue || []).reduce((acc1, o) => {
        let transferTotal = 0
        let qtransfer = 0
        let qprinciple = 0
        let qinterest = 0
        let qremain = 0
        let qclose = false
        return [...acc1, ...(o.payments || []).reduce((acc2, p) => {
          const date = dbTodayjs(p?.date)
          const month = dayjs(date).format('M')
          const year = dayjs(date).format('BBBB')
          const transfer = (isNaN(p?.transfer)) ? 0 : p.transfer
          const principle = (isNaN(p?.principle)) ? 0 : p.principle
          const interest = (isNaN(p?.interest)) ? 0 : p.interest
          const remain = (isNaN(p?.remain)) ? 0 : p.remain
          transferTotal += transfer
          qtransfer = (parseInt(month) % 3 === 1) ? transfer : (qtransfer + transfer)
          qprinciple = (parseInt(month) % 3 === 1) ? principle : (qprinciple + principle)
          qinterest = (parseInt(month) % 3 === 1) ? interest : (qinterest + interest)
          qremain = (parseInt(month) % 3 === 1) ? remain : (qremain + remain)
          qclose = (!isNaN(o?.principleremain) && o.principleremain === 0) ? true : qclose
          return [...acc2, {
            type: o.type,
            status: o.status,
            close: (!isNaN(o?.principleremain) && o.principleremain === 0),
            month: month,
            year: year,
            loan: (isNaN(o?.loan)) ? 0 : o.loan,
            principleremain: (isNaN(p?.principleremain)) ? 0 : p.principleremain,
            interestaccum: (isNaN(p?.interestaccum)) ? 0 : p.interestaccum,
            transferTotal: transferTotal,
            transfer: transfer,
            principle: principle,
            interest: interest,
            remain: remain,
            qtransfer: qtransfer,
            qprinciple: qprinciple,
            qinterest: qinterest,
            qremain: qremain,
            qclose: qclose
          }]
        }, [])]
      }, [])

      let sum = {
        1: { n: '1', type: 'ลูกหนี้ประเภทที่ 1 (ลูกหนี้ปกติ)' },
        2: { n: '2', type: 'ลูกหนี้ประเภทที่ 2 (กลับก่อนสัญญา)' },
        3: { n: '3', type: 'ลูกหนี้ประเภทที่ 1 (ส่งฟ้อง)' },
        4: { n: '4', type: 'ลูกหนี้ปิดบัญชีเดือนนี้' },
        5: { n: 'รวม', type: '' }
      }

      list.forEach((o) => {
        if ((data.month === o.month) && (data.bbbb === o.year)) {
          o.transfer = (data.isQuarter) ? o.qtransfer : o.transfer
          o.principle = (data.isQuarter) ? o.qprinciple : o.principle
          o.interest = (data.isQuarter) ? o.qinterest : o.interest
          o.remain = (data.isQuarter) ? o.qremain : o.remain
          o.close = (data.isQuarter) ? o.qclose : o.close

          const key = (o.close) ? 4 : (['normal', 'special'].indexOf(o.type) !== -1) ? 1 : (o.type === 'early') ? 2 : 3

          sum[key].loan = (sum[key]?.loan ?? 0) + o.loan
          sum[5].loan = (sum[5]?.loan ?? 0) + o.loan
          sum[key].principleremain = (sum[key]?.principleremain ?? 0) + o.principleremain
          sum[5].principleremain = (sum[5]?.principleremain ?? 0) + o.principleremain
          sum[key].interestaccum = (sum[key]?.interestaccum ?? 0) + o.interestaccum
          sum[5].interestaccum = (sum[5]?.interestaccum ?? 0) + o.interestaccum
          sum[key].transferTotal = (sum[key]?.transferTotal ?? 0) + o.transferTotal
          sum[5].transferTotal = (sum[5]?.transferTotal ?? 0) + o.transferTotal
          sum[key].transfer = (sum[key]?.transfer ?? 0) + o.transfer
          sum[5].transfer = (sum[5]?.transfer ?? 0) + o.transfer
          sum[key].principle = (sum[key]?.principle ?? 0) + o.principle
          sum[5].principle = (sum[5]?.principle ?? 0) + o.principle
          sum[key].interest = (sum[key]?.interest ?? 0) + o.interest
          sum[5].interest = (sum[5]?.interest ?? 0) + o.interest
          sum[key].remain = (sum[key]?.remain ?? 0) + o.remain
          sum[5].remain = (sum[5]?.remain ?? 0) + o.remain
        }
      })

      const result = Object.keys(sum).map((o) => ({
        'ลำดับที่': sum[o].n,
        'ประเภทลูกหนี้': sum[o].type,
        'วงเงินกู้': sum[o]?.loan ?? '',
        'ยอดหนี้': sum[o]?.principleremain ?? '',
        'ดอกเบี้ยค้าง': sum[o]?.interestaccum ?? '',
        'เงินโอนสะสม': sum[o]?.transferTotal ?? '',
        'ชำระจริง': sum[o]?.transfer ?? '',
        'ชำระเงินต้น': sum[o]?.principle ?? '',
        'ชำระดอกเบี้ย': sum[o]?.interest ?? '',
        'วันที่ชำระ': '',
        'วันที่ชำระล่าสุด': '',
        'คืนญาติ': sum[o]?.remain ?? ''
      }))
      const reportDate = (isQuarter) ? `ประจำไตรมาส ${Math.floor(parseInt(data.month) / 3)} ${parseInt(data.bbbb)}` : `ประจำเดือน${(new Date(Date.UTC(data.yyyy, parseInt(data.month) - 1, 2, 0, 0, 0))).toLocaleDateString('th-TH', { year: 'numeric', month: 'long' })}`
      const title = `รายงานลูกหนี้กองทุน AIU (${value?.lender?.name ?? ''}) ${reportDate} (สรุป)`
      await genCsv('cover', result, title)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const columns = [
    {
      title: 'ชื่อผู้กู้',
      dataIndex: 'name',
      width: 150
    },
    {
      title: 'กองทุน',
      dataIndex: 'type',
      width: 150,
      render: data => typeObj[data]?.name ?? ''
    },
    {
      title: 'วงเงินกู้',
      dataIndex: 'loan',
      width: 120,
      render: data => formatNumber(data, 2)
    },
    {
      title: 'เงินต้นคงเหลือ',
      dataIndex: 'principleremain',
      width: 120,
      render: data => formatNumber(data, 2)
    },
    {
      title: 'ดอกเบี้ยคงค้าง',
      dataIndex: 'interestaccum',
      width: 120,
      render: data => formatNumber(data, 2)
    },
    {
      title: 'วันที่ครบ',
      dataIndex: 'commitdate',
      width: 120,
      render: data => (
        <span>{(data) ? dayjs(data).format('DD/MM/BBBB') : ''}</span>
      )
    },
    {
      title: 'วันที่ชำระล่าสุด',
      dataIndex: 'paymentdate',
      width: 120,
      render: data => (
        <span>{(data) ? dayjs(data).format('DD/MM/BBBB') : ''}</span>
      )
    },
    {
      title: 'สถานะการชำระเงิน',
      dataIndex: 'paystatus',
      width: 150,
      render: data => (
        <span style={{ color: payStatusObj[data]?.color ?? '' }}>{payStatusObj[data]?.name ?? ''}</span>
      )
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      width: 150,
      render: data => (
        <span style={{ color: statusObj[data]?.color ?? '' }}>{statusObj[data]?.name ?? ''}</span>
      )
    },
    {
      title: 'เครื่องมือ',
      dataIndex: 'Operation',
      width: 150,
      render: (_, row) => (
        <div>
          {(isAllowed(role, 'view', 'funds', row.type)) && (
            <Button
              type='link'
              style={{ marginRight: 16 }}
              icon={<EyeOutlined style={{ fontSize: 24 }} />}
              onClick={() => {
                navigate(`/admin/funds/${row.type}/${row.id}/view`)
              }}
            ></Button>
          )}
        </div>
      )
    }
  ]

  if (!value) return <Skeleton></Skeleton>
  return (
    <div>
      <Card
        style={{ marginTop: 16 }}
        bodyStyle={{ padding: '32px 80px' }}
      >
        <Row style={{ marginBottom: 24 }}>
          <Col flex='auto'>
            <div style={{ fontSize: 24 }}>
              รายละเอียดเจ้าหนี้
            </div>
          </Col>
          <Col flex='300px'>
            <div style={{ float: 'right', fontSize: 14 }}>
              <Popover
                content={
                  <div style={{ minWidth: 100 }}>
                    <Row><Button
                      block
                      onClick={() => setReportVisible(true)}
                    >รายงานลูกหนี้กองทุน</Button></Row>
                    <Row><Button
                      block
                      onClick={() => setCoverVisible(true)}
                    >ใบปะหน้า</Button></Row>
                  </div>
                }
                trigger='hover'
              >
                <Button
                  type='primary'
                  icon={<DownloadOutlined style={{ fontSize: 14 }} />}
                >
                  ดาวน์โหลด
                </Button>
              </Popover>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={5} className='aiu-title'>ชื่อผู้ให้กู้</Col>
          <Col span={7} className='aiu-content'>{value?.lender?.name ?? ''}</Col>
          <Col span={5} className='aiu-title'>เบอร์ติดต่อ</Col>
          <Col span={7} className='aiu-content'>{value?.lender?.tel ?? ''}</Col>
        </Row>
        <Row>
          <Col span={5} className='aiu-title'>วงเงินกู้</Col>
          <Col span={7} className='aiu-content'>{formatNumber(value?.loan)}</Col>
          <Col span={5} className='aiu-title'>ยอดหนี้</Col>
          <Col span={7} className='aiu-content'>{formatNumber(value?.principleremain)}</Col>
        </Row>
        <Row>
          <Col flex='auto'>
            <div style={{ marginTop: 48, marginBottom: 24, fontSize: 24 }}>
              รายละเอียดการชำระ
            </div>
            <div style={{ marginTop: 12, marginBottom: 12 }}>
              {tags.map((o) => (
                <Tag
                  key={o.key}
                  closable
                  onClose={() => handleClose(o.key)}
                >
                  {o.label}: {o.value}
                </Tag>
              ))}
            </div>
          </Col>
          <Col flex='300px'>
            <div style={{ float: 'right', margin: '48px 0 24px 0', fontSize: 14 }}>
              <Button
                icon={<SearchOutlined />}
                style={{ margin: '0 8px' }}
                onClick={() => {
                  setQsVisible(true)
                }}
              ></Button>
            </div>
          </Col>
        </Row >
        <Table
          bordered
          rowKey='id'
          style={{ overflowX: 'auto' }}
          columns={columns}
          dataSource={payment.sort((x, y) => x.index - y.index)}
        />
      </Card>
      <Modal
        forceRender
        open={reportVisible}
        title={
          <div style={{ padding: '16px 24px', fontWeight: 'bold', fontSize: '18px', lineHeight: '23px', backgroundColor: '#228B90', color: '#F6F6F6', borderRadius: '5px 5px 0px 0px' }}>
            ดาวน์โหลดลูกหนี้กองทุน
          </div>
        }
        width='50%'
        closable={false}
        footer={
          <Row justify='center'>
            <Space align='center'>
              <Col>
                <Button
                  type='secondary'
                  size='medium'
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    const empty = {
                      type: [],
                      status: null,
                      month: null,
                      year: null
                    }
                    reportForm.setFieldsValue(empty)
                  }}
                >
                  Clear Data
                </Button>
              </Col>
              <Col>
                <Button
                  size='medium'
                  style={{ color: '#FFF', backgroundColor: '#EE7930' }} icon={<CloseOutlined />}
                  onClick={() => {
                    setReportVisible(false)
                  }}
                >
                  ยกเลิก
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  size='medium'
                  icon={<CheckOutlined />}
                  onClick={() => {
                    const data = reportForm.getFieldsValue()
                    downloadReport(data)
                    setReportVisible(false)
                  }}
                >
                  ยืนยัน
                </Button>
              </Col>
            </Space>
          </Row>
        }
      >
        <AntdForm
          layout='vertical'
          style={{ margin: '8px 32px' }}
          form={reportForm}
          name='reportForm'
        >
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <AntdForm.Item name='type' label='ประเภทกองทุน' rules={[{ required: true, message: 'กรุณาเลือกประเภทกองทุนค่ะ' }]}>
                <Select placeholder='เลือก' mode='multiple' >
                  {((Object.keys(typeObj)).map((o) => (
                    <Select.Option key={o} value={o}>{typeObj[o].name}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
            <Col span={12}>
              <AntdForm.Item name='status' label='สถานะ' rules={[{ required: true, message: 'กรุณาเลือกสถานะค่ะ' }]}>
                <Select placeholder='เลือก' >
                  <Select.Option key='all' value='all'>All</Select.Option>
                  {((Object.keys(statusObj)).map((o) => (
                    <Select.Option key={o} value={o}>{statusObj[o].name}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <AntdForm.Item name='month' label='เดือนที่ต้องการ' rules={[{ required: true, message: 'กรุณาเลือกเดือนที่ต้องการค่ะ' }]}>
                <Select placeholder='เลือก' >
                  {([...Array(12).keys()]).map((o) => (
                    <Select.Option key={`${o + 1}`} value={`${o + 1}`}>{`${o + 1}`}</Select.Option>
                  ))}
                  <Select.Option key='q1' value='q1'>Q1</Select.Option>
                  <Select.Option key='q2' value='q2'>Q2</Select.Option>
                  <Select.Option key='q3' value='q3'>Q3</Select.Option>
                  <Select.Option key='q4' value='q4'>Q4</Select.Option>
                </Select>
              </AntdForm.Item>
            </Col>
            <Col span={12}>
              <AntdForm.Item name='year' label='ปีที่ต้องการ' rules={[{ required: true, message: 'กรุณาเลือกปีที่ต้องการค่ะ' }]}>
                <DatePicker placeholder='เลือก' picker='year' format='BBBB' />
              </AntdForm.Item>
            </Col>
          </Row>
        </AntdForm>
      </Modal>
      <Modal
        forceRender
        open={coverVisible}
        title={
          <div style={{ padding: '16px 24px', fontWeight: 'bold', fontSize: '18px', lineHeight: '23px', backgroundColor: '#228B90', color: '#F6F6F6', borderRadius: '5px 5px 0px 0px' }}>
            ดาวน์โหลดใบปะหน้า
          </div>
        }
        width='50%'
        closable={false}
        footer={
          <Row justify='center'>
            <Space align='center'>
              <Col>
                <Button
                  type='secondary'
                  size='medium'
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    const empty = {
                      month: null,
                      year: null
                    }
                    coverForm.setFieldsValue(empty)
                  }}
                >
                  Clear Data
                </Button>
              </Col>
              <Col>
                <Button
                  size='medium'
                  style={{ color: '#FFF', backgroundColor: '#EE7930' }} icon={<CloseOutlined />}
                  onClick={() => {
                    setCoverVisible(false)
                  }}
                >
                  ยกเลิก
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  size='medium'
                  icon={<CheckOutlined />}
                  onClick={() => {
                    const data = coverForm.getFieldsValue()
                    downloadCover(data)
                    setCoverVisible(false)
                  }}
                >
                  ยืนยัน
                </Button>
              </Col>
            </Space>
          </Row>
        }
      >
        <AntdForm
          layout='vertical'
          style={{ margin: '8px 32px' }}
          form={coverForm}
          name='coverForm'
        >
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <AntdForm.Item name='month' label='เดือนที่ต้องการ' rules={[{ required: true, message: 'กรุณาเลือกเดือนที่ต้องการค่ะ' }]}>
                <Select placeholder='เลือก' >
                  {([...Array(12).keys()]).map((o) => (
                    <Select.Option key={`${o + 1}`} value={`${o + 1}`}>{`${o + 1}`}</Select.Option>
                  ))}
                  <Select.Option key='q1' value='q1'>Q1</Select.Option>
                  <Select.Option key='q2' value='q2'>Q2</Select.Option>
                  <Select.Option key='q3' value='q3'>Q3</Select.Option>
                  <Select.Option key='q4' value='q4'>Q4</Select.Option>
                </Select>
              </AntdForm.Item>
            </Col>
            <Col span={12}>
              <AntdForm.Item name='year' label='ปีที่ต้องการ' rules={[{ required: true, message: 'กรุณาเลือกปีที่ต้องการค่ะ' }]}>
                <DatePicker placeholder='เลือก' picker='year' format='BBBB' />
              </AntdForm.Item>
            </Col>
          </Row>
        </AntdForm>
      </Modal>
      <Modal
        forceRender
        open={qsVisible}
        title={
          <div style={{ padding: '16px 24px', fontWeight: 'bold', fontSize: '18px', lineHeight: '23px', backgroundColor: '#228B90', color: '#F6F6F6', borderRadius: '5px 5px 0px 0px' }}>
            ค้นหา
          </div>
        }
        width='50%'
        closable={false}
        footer={
          <Row justify='center'>
            <Space align='center'>
              <Col>
                <Button
                  type='secondary'
                  size='medium'
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    const empty = {
                      firstname: null,
                      lastname: null,
                      paystatus: null,
                      status: null
                    }
                    setQs(empty)
                    setSearch('creditorsview', empty)
                    searchForm.setFieldsValue(empty)
                  }}
                >
                  Clear Data
                </Button>
              </Col>
              <Col>
                <Button
                  size='medium'
                  style={{ color: '#FFF', backgroundColor: '#EE7930' }} icon={<CloseOutlined />}
                  onClick={() => {
                    setQsVisible(false)
                  }}
                >
                  ยกเลิก
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  size='medium'
                  icon={<CheckOutlined />}
                  onClick={() => {
                    const data = searchForm.getFieldsValue()
                    setQs(data)
                    setSearch('creditorsview', data)
                    setQsVisible(false)
                  }}
                >
                  ยืนยัน
                </Button>
              </Col>
            </Space>
          </Row>
        }
      >
        <AntdForm
          layout='vertical'
          initialValues={qs}
          style={{ margin: '8px 32px' }}
          form={searchForm}
          name='searchForm'
        >
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <AntdForm.Item name='firstname' label='ชื่อ' >
                <Input placeholder='ชื่อ' />
              </AntdForm.Item>
            </Col>
            <Col span={12}>
              <AntdForm.Item name='lastname' label='นามสกุล' >
                <Input placeholder='นามสกุล' />
              </AntdForm.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <AntdForm.Item name='paystatus' label='สถานะการชำระเงิน' >
                <Select placeholder='เลือก' >
                  {((Object.keys(payStatusObj)).map((o) => (
                    <Select.Option key={o} value={o}>{payStatusObj[o].name}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
            <Col span={12}>
              <AntdForm.Item name='status' label='สถานะ' >
                <Select placeholder='เลือก' >
                  {((Object.keys(statusObj)).map((o) => (
                    <Select.Option key={o} value={o}>{statusObj[o].name}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
          </Row>
        </AntdForm>
      </Modal>
    </div>
  )
}

export default Form
