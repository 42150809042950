import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Form as AntdForm, Row, Col, Card, Button, Input, Skeleton } from 'antd'
import { SaveOutlined, CloseOutlined } from '@ant-design/icons'

import { useAuth } from '../../../contexts/AuthContextProvider'
import { isAllowed } from '../../../route/roles'

const Form = () => {
  const navigate = useNavigate()
  const { db, role } = useAuth()
  const { cid } = useParams()
  const [loading, setLoading] = useState(false)

  const readOnly = !isAllowed(role, 'edit', 'masters', 'campuses')

  const { value } = useAsync(async () => {
    if (!cid) {
      return {}
    }

    const snap = await db.collection('/campuses').doc(cid).get()
    return {
      ...snap.data(),
      id: snap.id
    }
  }, [cid])

  const handleSubmit = async (formData) => {
    try {
      setLoading(true)
      let doc = db.collection('/campuses').doc()
      if (cid) {
        doc = db.collection('/campuses').doc(cid)
      }
      await doc.set(formData, {
        merge: true
      })
      setLoading(false)
      navigate('/admin/masters/campuses')
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  if (!value) return <Skeleton></Skeleton>
  return (
    <Card style={{ padding: 48, minHeight: '100%' }}>
      <div style={{ marginBottom: 16, fontSize: 24 }}>
        {(!cid) ? 'สร้าง' : ''}วิทยาเขต
      </div>
      <AntdForm
        layout='vertical'
        onFinish={handleSubmit}
        initialValues={value}
      >
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='name' label='ชื่อวิทยาเขต' rules={[{ required: true, message: 'กรุณาใส่ชื่อวิทยาเขตด้วยค่ะ' }]}>
              <Input placeholder='ชื่อ' disabled={readOnly} />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
          <Col span={8}></Col>
        </Row>
        <div>
          {(!readOnly) && (
            <Button
              loading={loading}
              style={{ width: 168, height: 40, marginTop: 20, marginRight: 20 }}
              htmlType='submit'
              type='primary'
            ><SaveOutlined style={{ fontSize: 16 }} />บันทึก</Button>
          )}
          <Button
            loading={loading}
            style={{ height: 40, marginTop: 20 }}
            onClick={() => {
              navigate(-1)
            }}
          ><CloseOutlined />ปิด</Button>
        </div>
      </AntdForm>
    </Card>
  )
}

export default Form
