import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { useAsync } from 'react-use'

import { Card, Col, Row, Button, Image, Tag } from 'antd'
import { DownOutlined, RightOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'
import { dbTodayjs } from '../../../utils/date'

import { useAuth } from '../../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../../contexts/LayoutContextProvider'
import { applyJobObj, jobObj, statusObj, levelObj, courseObj, healthStatusObj } from '../../../const/student'
import { methodObj } from '../../../const/jpstudent'
import { paymentObj } from '../../../const/fee'
import { nameTitleObj } from '../../../const/users'
import { isAllowed } from '../../../route/roles'

import NoProfile from '../../../assets/images/user.png'

const StudentInfoCard = ({ uid, isFull = false }) => {
  const navigate = useNavigate()
  const { db, role } = useAuth()
  const { getCampuses, getCoops, getCompanies, getBranches } = useLayoutContext()

  const [collapse, setCollapse] = useState(true)

  const { value } = useAsync(async () => {
    const snap = await db.collection('/users').doc(uid).get()
    const data = snap.data()
    const roomSnap = await db.collection('/rooms').doc(data.rid).get()
    const roomData = roomSnap.data()
    const lenderSnap = await db.collection('/lenders').doc(data.lid).get()
    const lenderData = lenderSnap.data()
    const branchData = await getBranches()
    const branch = (data.branchid) ? branchData.find((o) => o.id === data.branchid) : null
    const campusData = await getCampuses()
    const campus = (roomData) ? campusData.find((o) => o.id === roomData.cid) : null
    const room = (campus) ? `${campus?.name ?? ''} - ${roomData?.room ?? ''}` : ''
    const coopData = await getCoops()
    const coop = (data?.coopid) ? coopData.find((o) => o.id === data.coopid) : null
    const companyData = await getCompanies()
    const company = (data?.companyid) ? companyData.find((o) => o.id === data.companyid) : null
    const job = (!data?.jobstatus) ? '' : (data.jobstatus !== 'pass') ? 'no' : 'yes'
    const startDate = (data?.startdate) ? dbTodayjs(data.startdate).set('hour', 0) : null
    const status = (data?.status !== 'learning') ? data.status : (!startDate || dayjs().isBefore(startDate, 'day')) ? 'interest' : 'learning'

    let infos = [
      {
        titleL: 'ที่มา',
        contentL: branch?.name ?? '',
        titleR: 'หลักสูตร',
        contentR: courseObj[data?.course]?.name ?? ''
      },
      {
        titleL: 'รหัสนักเรียน',
        contentL: data?.scn ?? '',
        titleR: 'คำนำหน้า',
        contentR: nameTitleObj[data.title]?.nameTh ?? ''
      },
      {
        titleL: 'ชื่อ - สกุล',
        contentL: `${data?.firstname ?? ''} ${data?.lastname ?? ''}`,
        titleR: 'ชื่อเล่น',
        contentR: data?.nickname ?? ''
      },
      {
        titleL: 'ชื่อ - สกุล(ENG)',
        contentL: `${data?.firstnameen ?? ''} ${data?.lastnameen ?? ''}`,
        titleR: 'ชื่อเล่น(ENG)',
        contentR: data?.nicknameen ?? ''
      },
      {
        titleL: 'ชื่อ - สกุล(JP)',
        contentL: `${data?.firstnamejp ?? ''} ${data?.lastnamejp ?? ''}`,
        titleR: 'ชื่อเล่น(JP)',
        contentR: data?.nicknamejp ?? ''
      },
      {
        titleL: 'ตรวจสุขภาพ',
        contentL: (data.healthcheck) ? `${dayjs(dbTodayjs(data.healthcheck)).format('DD / MM / BBBB')} ${(!data.healthstatus) ? '' : `(${healthStatusObj[data?.healthstatus]?.name ?? ''})`}` : 'ยังไม่ตรวจ',
        titleR: 'ประชุมผู้ปกครอง',
        contentR: (data.parentmeeting) ? dayjs(dbTodayjs(data.parentmeeting)).format('DD / MM / BBBB') : 'ยังไม่ประชุม',
      },
      {
        titleL: 'วันที่เข้าเรียน',
        contentL: (data?.startdate) ? dayjs(dbTodayjs(data.startdate)).format('DD / MM / BBBB') : '',
        titleR: 'สถานะ',
        contentR: statusObj[status]?.name ?? '',
        colorR: statusObj[status]?.color
      },
      {
        titleL: 'ค่าลงทะเบียนเรียน',
        contentL: paymentObj[data?.payment]?.name ?? '',
        colorL: paymentObj[data?.payment]?.color ?? '',
        titleR: 'อนุญาตสัมภาษณ์งาน',
        contentR: applyJobObj[data?.applyjob]?.name ?? '',
        colorR: applyJobObj[data?.applyjob]?.color
      },
      {
        titleL: 'ระดับภาษา',
        contentL: levelObj[data?.lev]?.name ?? '',
        colorL: '',
        titleR: 'สถานะการได้งาน',
        contentR: jobObj[job]?.name ?? '',
        colorR: jobObj[job]?.color
      },
      {
        titleL: 'หอพัก',
        contentL: room ?? '',
        titleR: 'กำหนดการเดินทาง',
        contentR: (data?.traveldate) ? dayjs(dbTodayjs(data.traveldate)).format('DD / MM / BBBB') : ''
      },
      {
        titleL: 'สหกรณ์',
        contentL: coop?.name ?? '',
        titleR: 'บริษัท',
        contentR: company?.name ?? ''
      }
    ]

    infos = (!isFull) ? infos : [...infos, {
      titleL: 'ประเภทการชำระค่าเรียน',
      contentL: methodObj[data?.paymethod]?.name ?? '',
      titleR: 'ผู้ให้กู้',
      contentR: lenderData?.name ?? ''
    }]

    return {
      ...data,
      id: snap.id,
      infos: infos
    }
  }, [uid])

  return (
    <Card
      title='ข้อมูลนักเรียน'
      headStyle={{ fontSize: '20px', lineHeight: '20px', backgroundColor: '#228B90', color: '#F6F6F6', borderRadius: '10px 10px 0 0' }}
      bodyStyle={{ display: (collapse) ? 'block' : 'none', padding: (collapse) ? '32px 120px' : 0, boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)' }}
      extra={(collapse) ? <DownOutlined style={{ color: '#F6F6F6' }} onClick={() => setCollapse(false)} /> : <RightOutlined style={{ color: '#F6F6F6' }} onClick={() => setCollapse(true)} />}
    >
      <div style={{ marginBottom: 24, fontSize: 20 }}>
        ข้อมูลนักเรียน
      </div>
      <Row gutter={48}>
        <Col span={8} style={{ textAlign: 'center' }}>
          <Image
            src={(value?.imgurl) ? value.imgurl : NoProfile}
            width='50%'
            height='auto'
            preview={false}
          />
          {(isAllowed(role, 'edit', 'students')) && (
            <Button
              type='primary'
              style={{ height: 56, marginTop: 16 }}
              block
              onClick={() => {
                navigate(`/admin/students/${uid}/status`)
              }}
            >ปรับสถานะนักเรียน</Button>
          )}
        </Col>
        <Col span={16}>
          {((value?.infos ?? []).map((o, i) => (
            <Row key={i}>
              <Col span={6} className='aiu-title'>{o.titleL}</Col>
              <Col span={6} className='aiu-content'>
                {(o.colorL) ? (
                  <Tag color={o.colorL}>
                    {o.contentL}
                  </Tag>
                ) : (
                  <>{o.contentL}</>
                )}
              </Col>
              {(o.titleR) && (
                <>
                  <Col span={6} className='aiu-title'>{o.titleR}</Col>
                  <Col span={6} className='aiu-content'>
                    {(o.colorR) ? (
                      <Tag color={o.colorR}>
                        {o.contentR}
                      </Tag>
                    ) : (
                      <>{o.contentR}</>
                    )}
                  </Col>
                </>
              )}
            </Row>
          )))}
        </Col>
      </Row>
    </Card>
  )
}

export default StudentInfoCard
