import { mkConfig, generateCsv, download } from 'export-to-csv'

const genCsv = async (fileName, data, title) => {
  const csvConfig = mkConfig({
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: !!title,
    title: title || '',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename: fileName
  })
  try {
    const csv = generateCsv(csvConfig)(data)
    download(csvConfig)(csv)
    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}

export { genCsv }