import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/storage'
import firebaseConfig from '../configs/firebase'

// TODO: Use a configuration object
firebase.initializeApp(firebaseConfig)

const auth = firebase.auth
const db = firebase.firestore()
const functions = firebase.app().functions('asia-southeast1')
const storage = firebase.storage()

// eslint-disable-next-line no-restricted-globals
if (process.env.REACT_APP_USE_EMULATOR) {
  auth().useEmulator('http://localhost:9099/', { disableWarnings: true })
  db.useEmulator('localhost', 8080)
  functions.useEmulator('localhost', 5001)
  storage.useEmulator('localhost', 9199)
}

export default firebase
export { auth, db, functions, storage }