import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'

import { Form, Button, Input, message } from 'antd'
import { UserOutlined } from '@ant-design/icons'

import { useAuth } from '../../contexts/AuthContextProvider'

const ForgetPassword = () => {
  const navigate = useNavigate()
  const { auth } = useAuth()
  const [loading, setLoading] = useState(false)

  const handleChange = async ({ email }) => {
    try {
      setLoading(true)
      const formatEmail = email.trim().toLowerCase()
      await auth().sendPasswordResetEmail(formatEmail)
      setLoading(false)
      message.success('Successfully')
      navigate('/login')
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  return (
    <Form layout='vertical' onFinish={handleChange}>
      <Form.Item name='email' rules={[{ required: true, message: 'Please input your Email!' }]}>
        <Input prefix={<UserOutlined />} placeholder='Email' />
      </Form.Item>
      <Form.Item>
        <Button type='primary' className='input-auth' htmlType='submit' style={{ width: '100%' }} loading={loading}>
          <span style={{ fontSize: '16px', fontFamily: 'Roboto' }}>ลืมรหัสผ่าน</span>
        </Button>
      </Form.Item>
      <div style={{ textAlign: 'center', fontSize: '12px', fontFamily: 'Prompt' }}>
        <Link to='/login' style={{ textDecoration: 'underline' }}>เข้าสู่ระบบ</Link>
      </div>
    </Form>
  )
}

export default ForgetPassword
