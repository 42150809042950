const nameTitleObj = {
  mr: { name: 'Mr.', nameTh: 'นาย' },
  miss: { name: 'Miss', nameTh: 'นางสาว' },
  mrs: { name: 'Mrs.', nameTh: 'นาง' }
}

const statusObj = {
  active: { name: 'เปิดการใช้งาน', color: '#228B90' },
  inActive: { name: 'ปิดการใช้งาน', color: '#D35C3B' },
  waiting: { name: 'รอยืนยัน', color: '#D35C3B' }
}

export {
  nameTitleObj,
  statusObj
}
