const statusObj = {
  open: { name: 'กำลังเรียน' },
  close: { name: 'ปิดการเรียน' }
}

const allowObj = {
  no: { name: 'ยังไม่อนุมัติ', color: '#D35C3B' },
  yes: { name: 'อนุมัติแล้ว', color: '#228B90' }
}

const evaStatusObj = {
  no: { name: 'ไม่ผ่าน', color: 'error' },
  yes: { name: 'ผ่าน', color: 'success' }
}

const behaviorLevelObj = {
  no: { name: 'ไม่มีความผิด', color: 'success' },
  little: { name: 'ผิดเล็กน้อย', color: 'processing' },
  watch: { name: 'เฝ้าระวัง', color: 'warning' },
  serious: { name: 'ร้ายแรง', color: 'error' }
}

export {
  statusObj,
  allowObj,
  evaStatusObj,
  behaviorLevelObj
}
