import React, { createContext, useContext, useState } from 'react'

import { useAuth } from './AuthContextProvider'
import { nameTitleObj } from '../const/users'

const LayoutContext = createContext({})

const useLayoutContext = () => {
  const context = useContext(LayoutContext)
  if (!context) {
    throw new Error('Cannot use LayoutContext outside LayoutContextProvider!')
  }
  return context
}

const LayoutContextProvider = ({ children }) => {
  const { db } = useAuth()
  const [qs, setQs] = useState({})
  const [paginate, setPaginate] = useState({})
  const [x, setX] = useState({})

  const getSearch = (key) => {
    return qs[key]
  }

  const setSearch = (key, value) => {
    setQs({
      ...qs,
      [key]: value
    })
  }

  const getPageNo = (key) => {
    return paginate[key]
  }

  const onPageChange = (key, page) => {
    setPaginate({
      ...paginate,
      [key]: page
    })
  }

  const getData = (key, isCache) => {
    if (!isCache) {
      return null
    }

    const d = (new Date()).getHours()
    if (x?.d === d) {
      return x[key]
    }
    return null
  }

  const setData = (key, value, isCache) => {
    if (isCache) {
      const d = (new Date()).getHours()
      if (x?.d === d) {
        setX({
          ...x,
          [key]: value
        })
      } else {
        setX({
          d: d,
          [key]: value
        })
      }
    }
  }

  // Students
  const getStudents = async (isCache = false) => {
    try {
      let studentData = getData('students', isCache)
      if (!studentData) {
        const snap = await db.collection('/users').where('role', '==', 'student').where('disabled', '==', false).get()
        studentData = snap.docs.map(doc => {
          const data = doc.data()
          return {
            ...data,
            id: doc.id,
            key: doc.id
          }
        })
        setData('students', studentData, isCache)
      }
      return Promise.resolve(studentData)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  // Teachers
  const getTeachers = async (isCache = false) => {
    try {
      let teacherData = getData('teachers', isCache)
      if (!teacherData) {
        const snap = await db.collection('/users').where('role', 'in', ['headteacher', 'teacher', 'headadmin', 'admin']).where('disabled', '==', false).where('emailVerified', '==', true).get()
        teacherData = snap.docs.map(doc => {
          const data = doc.data()
          return {
            id: doc.id,
            key: doc.id,
            name: `${data.firstname} ${data.lastname}`,
            title: nameTitleObj[data.title]?.nameTh ?? ''
          }
        })
        setData('teachers', teacherData, isCache)
      }
      return Promise.resolve(teacherData)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  // Users
  const getUsers = async (isCache = false) => {
    try {
      let userData = getData('users', isCache)
      if (!userData) {
        const snap = await db.collection('/users').where('disabled', '==', false).where('emailVerified', '==', true).get()
        userData = snap.docs.map(doc => {
          const data = doc.data()
          return {
            id: doc.id,
            key: doc.id,
            name: `${data.firstname} ${data.lastname}`,
            title: nameTitleObj[data.title]?.nameTh ?? ''
          }
        })
        setData('users', userData, isCache)
      }
      return Promise.resolve(userData)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  // Classes
  const getClasses = async (isCache = false) => {
    try {
      let classData = getData('classes', isCache)
      if (!classData) {
        const snap = await db.collection('/classes').get()
        classData = snap.docs.map(doc => {
          return {
            ...doc.data(),
            id: doc.id,
            key: doc.id
          }
        })
        setData('classes', classData, isCache)
      }
      return Promise.resolve(classData)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  // Jobs
  const getJobs = async (isCache = false) => {
    try {
      let jobData = getData('jobs', isCache)
      if (!jobData) {
        const snap = await db.collection('/jobs').get()
        jobData = snap.docs.map(doc => {
          return {
            ...doc.data(),
            id: doc.id,
            key: doc.id
          }
        })
        setData('jobs', jobData, isCache)
      }
      return Promise.resolve(jobData)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  // Branches
  const getBranches = async (isCache = true) => {
    try {
      let branchData = getData('branches', isCache)
      if (!branchData) {
        const snap = await db.collection('/branches').get()
        branchData = snap.docs.map(doc => {
          const data = doc.data()
          return {
            id: doc.id,
            key: doc.id,
            name: data.name
          }
        })
        setData('branches', branchData, isCache)
      }
      return Promise.resolve(branchData)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  // Campus
  const getCampuses = async (isCache = true) => {
    try {
      let campusData = getData('campuses', isCache)
      if (!campusData) {
        const snap = await db.collection('/campuses').get()
        campusData = snap.docs.map(doc => {
          const data = doc.data()
          return {
            id: doc.id,
            key: doc.id,
            name: data.name
          }
        })
        setData('campuses', campusData, isCache)
      }
      return Promise.resolve(campusData)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  // Coops
  const getCoops = async (isCache = true) => {
    try {
      let coopData = getData('coops', isCache)
      if (!coopData) {
        const snap = await db.collection('/coops').get()
        coopData = snap.docs.map(doc => {
          const data = doc.data()
          return {
            ...data,
            id: doc.id,
            key: doc.id
          }
        })
        setData('coops', coopData, isCache)
      }
      return Promise.resolve(coopData)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  // Companies
  const getCompanies = async (isCache = true) => {
    try {
      let companyData = getData('companies', isCache)
      if (!companyData) {
        const snap = await db.collection('/companies').get()
        companyData = snap.docs.map(doc => {
          const data = doc.data()
          return {
            ...data,
            id: doc.id,
            key: doc.id
          }
        })
        setData('companies', companyData, isCache)
      }
      return Promise.resolve(companyData)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  // Subjects
  const getSubjects = async (isCache = false) => {
    try {
      let subjectData = getData('subjects', isCache)
      if (!subjectData) {
        const snap = await db.collection('/subjects').get()
        subjectData = snap.docs.map(doc => {
          const data = doc.data()
          return {
            id: doc.id,
            key: doc.id,
            name: data.name
          }
        })
        setData('subjects', subjectData, isCache)
      }
      return Promise.resolve(subjectData)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  // Lenders
  const getLenders = async (isCache = false) => {
    try {
      let lenderData = getData('lenders', isCache)
      if (!lenderData) {
        const snap = await db.collection('/lenders').get()
        lenderData = snap.docs.map(doc => {
          const data = doc.data()
          return {
            id: doc.id,
            key: doc.id,
            name: data.name,
            tel: data.tel
          }
        })
        setData('lenders', lenderData, isCache)
      }
      return Promise.resolve(lenderData)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const contextValue = {
    getSearch,
    setSearch,
    getPageNo,
    onPageChange,
    getData,
    setData,
    getStudents,
    getTeachers,
    getUsers,
    getClasses,
    getJobs,
    getBranches,
    getCampuses,
    getCoops,
    getCompanies,
    getSubjects,
    getLenders
  }
  return (
    <LayoutContext.Provider value={contextValue}>
      {children}
    </LayoutContext.Provider>
  )
}

export default LayoutContextProvider
export {
  useLayoutContext
}
