const statusObj = {
  ajapan: { name: 'อยู่ญี่ปุ่น', color: '#D35C3B' },
  bthai: { name: 'กลับไทย - ครบสัญญา', color: '#228B90' },
  cthai: { name: 'กลับไทย - ก่อนกำหนด', color: '#D35C3B' },
  escape: { name: 'หลบหนีสัญญา', color: '#D35C3B' },
  lawsuit: { name: 'มีคดีความ', color: '#D35C3B' }
}

const roundObj = {
  1: { name: 'หมายเลข 1' },
  2: { name: 'หมายเลข 3' },
  s: { name: 'ทักษะเฉพาะ' }
}

const methodObj = {
  cash: { name: 'เงินสด' },
  loan: { name: 'กู้กองทุน' }
}

export {
  statusObj,
  roundObj,
  methodObj
}
