import React from 'react'
import { useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Skeleton } from 'antd'

import { useAuth } from '../../contexts/AuthContextProvider'

import StudentInfoCard from '../StudentPage/Components/info'

const View = () => {
  const { uid } = useParams()
  const { db } = useAuth()

  const { value } = useAsync(async () => {
    if (!uid) {
      return {}
    }

    const snap = await db.collection('/users').doc(uid).get()
    return {
      ...snap.data(),
      id: snap.id
    }
  }, [uid])

  if (!value) return <Skeleton></Skeleton>
  return (
    <div>
      <StudentInfoCard uid={uid} />
    </div>
  )
}

export default View
