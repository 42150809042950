import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Form as AntdForm, Row, Col, Card, Button, Select, Image, Upload, Skeleton } from 'antd'
import { EditOutlined, SaveOutlined, CloseOutlined } from '@ant-design/icons'

import { DatePicker } from '../../components/DateTime'
import { dayjsToDB, dbTodayjs } from '../../utils/date'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { applyObj, applyJobObj, recommenderObj, levelObj, healthStatusObj } from '../../const/student'
import { paymentObj } from '../../const/fee'
import { pickBy } from '../../utils/tools'

import NoProfile from '../../assets/images/user.png'

const Form = () => {
  const navigate = useNavigate()
  const { db, storage, role } = useAuth()
  const { getBranches, getCoops, getCompanies } = useLayoutContext()
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [branches, setBranches] = useState([])
  const [coops, setCoops] = useState([])
  const [companies, setCompanies] = useState([])
  const [updateCount, setUpdateCount] = useState(0)

  const uid = id

  const { value } = useAsync(async () => {
    const branchData = await getBranches()
    setBranches(branchData)

    const coopData = await getCoops()
    setCoops(coopData)

    const companyData = await getCompanies()
    setCompanies(companyData)

    const snap = await db.collection('/users').doc(uid).get()
    const data = snap.data()
    return {
      ...data,
      id: snap.id,
      healthcheck: dbTodayjs(data?.healthcheck),
      parentmeeting: dbTodayjs(data?.parentmeeting),
      startdate: dbTodayjs(data?.startdate),
      traveldate: dbTodayjs(data?.traveldate)
    }
  }, [uid, updateCount])

  const handleSubmit = async (formData) => {
    try {
      setLoading(true)
      const data = pickBy(formData)
      await db.collection('/users').doc(uid).set({
        ...data,
        healthcheck: dayjsToDB(data?.healthcheck),
        parentmeeting: dayjsToDB(data?.parentmeeting),
        startdate: dayjsToDB(data?.startdate),
        traveldate: dayjsToDB(data?.traveldate)
      }, {
        merge: true
      })
      setLoading(false)
      navigate(`/admin/students/${uid}/view`)
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  const customRequestFile = async ({ onError, onSuccess, file }) => {
    try {
      const ref = storage.ref('profiles').child(`${file.name}-${file.uid}`)
      await ref.put(file, {
        contentType: file.type
      })
      let url = await ref.getDownloadURL()
      url = url.split('&token')[0]

      await db.collection('/users').doc(uid).set({
        imgurl: url
      }, {
        merge: true
      })
      setUpdateCount(updateCount + 1)
      onSuccess(null, url)
    } catch (e) {
      onError(e)
    }
  }

  if (!value) return <Skeleton></Skeleton>
  return (
    <Card style={{ padding: 48, minHeight: '100%' }}>
      <div style={{ marginBottom: 16, fontSize: 24 }}>
        ปรับสถานะนักเรียน
      </div>
      <AntdForm
        layout='vertical'
        onFinish={handleSubmit}
        initialValues={value}
      >
        <Row gutter={[24, 32]}>
          <Col span={8}></Col>
          <Col span={8} style={{ margin: '32px 0 80px' }}>
            <div style={{ position: 'relative', maxWidth: 250, margin: 'auto', textAlign: 'center' }}>
              <Image
                src={(value.imgurl) ? value.imgurl : NoProfile}
                width='100%'
                height='auto'
                preview={false}
                style={{ borderRadius: 10, border: '5px solid #BFBFBF' }}
              />
              <Upload
                accept='.webp, .png, .jpg, .jpeg'
                showUploadList={false}
                customRequest={customRequestFile}
              >
                <EditOutlined style={{ position: 'absolute', right: -16, bottom: -2, padding: 8, color: '#BFBFBF', backgroundColor: '#FFF', fontSize: 36, borderRadius: '50%', border: '5px solid #BFBFBF', cursor: 'pointer' }} />
              </Upload>
            </div>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='applystatus' label='สถานะการสมัคร' >
              <Select placeholder='เลือก' >
                {((Object.keys(applyObj)).map((o) => (
                  <Select.Option key={o} value={o}>{applyObj[o]?.name}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='healthcheck' label='ตรวจสุขภาพ' >
              <DatePicker placeholder='เลือก' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='healthstatus' label='สถานะตรวจสุขภาพ' >
              <Select placeholder='เลือก' >
                {((Object.keys(healthStatusObj)).map((o) => (
                  <Select.Option key={o} value={o}>{healthStatusObj[o]?.name}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='parentmeeting' label='สถานะประชุมผู้ปกครอง' >
              <DatePicker placeholder='เลือก' />
            </AntdForm.Item>
          </Col>
          <Col span={16}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='startdate' label='วันที่เข้าเรียน' >
              <DatePicker placeholder='เลือก' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='applyjob' label='สถานะอนุญาตสัมภาษณ์งาน' >
              <Select placeholder='เลือก' >
                {((Object.keys(applyJobObj)).map((o) => (
                  <Select.Option key={o} value={o}>{applyJobObj[o].name}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='lev' label='ระดับภาษา' >
              <Select placeholder='เลือก' >
                {((Object.keys(levelObj)).map((o) => (
                  <Select.Option key={o} value={o}>{levelObj[o].name}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='coopid' label='สหกรณ์' >
              <Select placeholder='เลือก' disabled >
                {(coops.map((o) => (
                  <Select.Option key={o.id} value={o.id}>{o.name}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='companyid' label='บริษัท' >
              <Select placeholder='เลือก' disabled >
                {(companies.map((o) => (
                  <Select.Option key={o.id} value={o.id}>{o.name}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='traveldate' label='วันที่เดินทาง' >
              <DatePicker placeholder='เลือก' disabled />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='branchid' label='สำนักงานที่รับผิดชอบ' >
              <Select
                placeholder='เลือก'
                disabled={['superadmin', 'headadmin', 'admin'].indexOf(role) === -1}
              >
                {(branches.map((o) => (
                  <Select.Option key={o.id} value={o.id}>{o.name}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='recommend' label='ผู้แนะนำ' >
              <Select placeholder='เลือก' disabled >
                {((Object.keys(recommenderObj)).map((o) => (
                  <Select.Option key={o} value={o}>{recommenderObj[o].name}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='payment' label='สถานะค่าลงทะเบียนเรียน' >
              <Select placeholder='เลือก' disabled >
                {((Object.keys(paymentObj)).map((o) => (
                  <Select.Option key={o} value={o}>{paymentObj[o]?.name}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
        </Row>
        <div>
          <Button
            loading={loading}
            style={{ width: 168, height: 40, marginTop: 20 }}
            htmlType='submit'
            type='primary'
          ><SaveOutlined style={{ fontSize: 16 }} />บันทึก</Button>
          <Button
            loading={loading}
            style={{ height: 40, marginTop: 20, marginLeft: 20 }}
            onClick={() => {
              navigate(-1)
            }}
          ><CloseOutlined />ปิด</Button>
        </div>
      </AntdForm>
    </Card>
  )
}

export default Form
