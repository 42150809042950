import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Form as AntdForm, Row, Col, Card, Button, Input, Upload, Skeleton } from 'antd'
import { SaveOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons'

import { DatePicker } from '../../components/DateTime'
import { dayjsToDB, dbTodayjs } from '../../utils/date'
import InputNumberNew from '../../components/InputNumberNew'

import { useAuth } from '../../contexts/AuthContextProvider'
import { pickBy } from '../../utils/tools'

const Form = () => {
  const navigate = useNavigate()
  const { db, storage } = useAuth()
  const { uid, pid } = useParams()

  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState([])

  const { value } = useAsync(async () => {
    if (!pid) {
      return {}
    }

    const snap = await db.collection('/fees').doc(pid).get()
    const data = snap.data()
    setFiles(data.files)
    return {
      ...data,
      id: snap.id,
      date: dbTodayjs(data?.date)
    }
  }, [pid])

  const handleSubmit = async (formData) => {
    try {
      setLoading(true)
      formData = {
        ...formData,
        date: dayjsToDB(formData?.date),
        uid: uid,
        file: null,
        files: files
      }
      const data = pickBy(formData)
      if (pid) {
        await db.collection('/fees').doc(pid).set(data, {
          merge: true
        })
      } else {
        await db.collection('/fees').add(data)
      }
      setLoading(false)
      navigate(`/admin/fees/${uid}/view`)
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  const customRequestFile = async ({ onError, _, file }) => {
    try {
      const ref = storage.ref('fees').child(`${file.name}-${file.uid}`)
      await ref.put(file, {
        contentType: file.type
      })

      const url = await ref.getDownloadURL()

      const data = {
        uid: file.uid,
        name: file.name,
        type: file.type,
        lastModified: file.lastModified,
        url: url
      }

      setFiles([...files, data])
    } catch (e) {
      onError(e)
    }
  }

  if (!value) return <Skeleton></Skeleton>
  return (
    <Card
      style={{ marginTop: 16 }}
      bodyStyle={{ padding: '32px 80px' }}
    >
      <div style={{ margin: '16px 0', fontSize: 24 }}>
        สร้างรายการชำระเงิน
      </div>
      <AntdForm
        layout='vertical'
        onFinish={handleSubmit}
        initialValues={value}
      >
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='date' label='วันที่ชำระ' >
              <DatePicker placeholder='เลือก' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='amount' label='จำนวนที่ชำระ' >
              <InputNumberNew placeholder='จำนวนที่ชำระ' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={16}>
            <AntdForm.Item name='remark' label='หมายเหตุ' >
              <Input.TextArea placeholder='หมายเหตุ' autoSize={{ minRows: 1 }} />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='file' label='อัปโหลด' >
              <Upload
                accept='.webp, .png, .jpg, .jpeg, .xls, .xlsx, .pdf'
                fileList={files}
                customRequest={customRequestFile}
                onRemove={(file) => {
                  const remainFiles = files.filter((o) => o.uid !== file.uid)
                  setFiles(remainFiles)
                }}
              >
                <Button icon={<UploadOutlined />}>อัปโหลด</Button>
              </Upload>
            </AntdForm.Item>
          </Col>
          <Col span={16}></Col>
        </Row>
        <div>
          <Button
            loading={loading}
            style={{ width: 168, height: 40, marginTop: 20 }}
            htmlType='submit'
            type='primary'
          ><SaveOutlined style={{ fontSize: 16 }} />บันทึก</Button>
          <Button
            loading={loading}
            style={{ height: 40, marginTop: 20, marginLeft: 20 }}
            onClick={() => {
              navigate(-1)
            }}
          ><CloseOutlined />ปิด</Button>
        </div>
      </AntdForm>
    </Card>
  )
}

export default Form
