import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Row, Col, Card, Button, Table, Image, Popconfirm, Skeleton } from 'antd'
import { PlusOutlined, DownloadOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { genPdf } from '../../utils/pdf'
import { toDataURL } from '../../utils/tools'
import { isAllowed } from '../../route/roles'

import NoProfile from '../../assets/images/user.png'

const View = () => {
  const navigate = useNavigate()
  const { db, functions, role } = useAuth()
  const { getCampuses, getCoops, getCompanies } = useLayoutContext()
  const { rid } = useParams()

  const [updateCount, setUpdateCount] = useState(0)
  const [coop, setCoop] = useState()
  const [company, setCompany] = useState()
  const [students, setStudents] = useState([])
  const [studentList, setStudentList] = useState()

  const { value } = useAsync(async () => {
    if (!rid) {
      return {}
    }

    const coopData = await getCoops()
    const coopObj = coopData.reduce((acc, o) => {
      acc[o.id] = o
      return acc
    }, {})
    setCoop(coopObj)

    const companyData = await getCompanies()
    const companyObj = companyData.reduce((acc, o) => {
      acc[o.id] = o
      return acc
    }, {})
    setCompany(companyObj)

    const campusData = await getCampuses()
    const campusObj = campusData.reduce((acc, o) => {
      acc[o.id] = o
      return acc
    }, {})

    const studentSnap = await db.collection('/users').where('role', '==', 'student').where('rid', '==', rid).get()
    const studentData = studentSnap.docs.map(doc => {
      return {
        ...doc.data(),
        id: doc.id,
        key: doc.id
      }
    })
    setStudents(studentData)

    const snap = await db.collection('/rooms').doc(rid).get()
    const data = snap.data()
    return {
      ...data,
      id: snap.id,
      campus: campusObj[data.cid]?.name ?? ''
    }
  }, [rid, updateCount])

  useEffect(() => {
    const list = students
    list.forEach((o, i) => {
      toDataURL(o?.imgurl).then(data => {
        list[i]['image'] = data || NoProfile
      })
    })
    setStudentList(list)
  }, [students])

  const handleDelete = async (row) => {
    try {
      const updateRoomStudent = functions.httpsCallable('updateRoomStudent-updateRoomStudent')
      await updateRoomStudent({
        id: rid,
        total: value.total,
        students: students.reduce((acc, o) => {
          return (o.id !== row.id) ? [...acc, o.id] : acc
        }, [])
      })
      setUpdateCount(updateCount + 1)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const download = async (data) => {
    const docObj = {
      pageOrientation: 'landscape',
      content: [
        {
          table: {
            headerRows: 1,
            widths: [30, 120, '*', 100, 150, 150],
            body: [
              [{ text: `ห้อง ${data.room}`, colSpan: 6, fontSize: 24, bold: true }, {}, {}, {}, {}, {}],
              [
                {
                  text: 'ลำดับ',
                  bold: true
                },
                {
                  text: 'รูปถ่าย',
                  bold: true
                },
                {
                  text: 'ชื่อ-นามสกุล',
                  bold: true
                },
                {
                  text: 'ชื่อเล่น',
                  bold: true
                },
                {
                  text: 'บริษัท',
                  bold: true
                },
                {
                  text: 'สหกรณ์',
                  bold: true
                }
              ],
              ...studentList.map((o, i) => ([
                {
                  text: i + 1
                },
                {
                  image: o.image,
                  width: 47,
                  margin: [1, 1]
                },
                {
                  text: `${o?.firstname ?? ''} ${o?.lastname ?? ''}`,
                  alignment: 'left'
                },
                {
                  text: o?.nickname ?? ''
                },
                {
                  text: company[o?.companyid]?.name ?? '-',
                  alignment: 'left'
                },
                {
                  text: coop[o?.coopid]?.name ?? '-',
                  alignment: 'left'
                }
              ]))
            ]
          },
          layout: {
            fillColor: (rowIndex, node, columnIndex) => {
              return (rowIndex === 0) ? '#EB984E' : (rowIndex === 1) ? '#F5CBA7' : null
            }
          }
        }
      ],
      defaultStyle: {
        fontSize: 16,
        font: 'THSarabunNew', // Roboto
        alignment: 'center'
      }
    }
    try {
      await genPdf(docObj, `Room_${data.room}`)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const columns = [
    {
      title: 'รูปถ่าย',
      dataIndex: 'imgurl',
      width: 100,
      render: data => (
        <Image
          src={(data) ? data : NoProfile}
          width='100px'
          height='auto'
          preview={false}
        />
      )
    },
    {
      title: 'ชื่อ',
      dataIndex: 'firstname'
    },
    {
      title: 'นามสกุล',
      dataIndex: 'lastname'
    },
    {
      title: 'ชื่อเล่น',
      dataIndex: 'nickname'
    },
    {
      title: 'บริษัท',
      dataIndex: 'companyid',
      render: data => company[data]?.name ?? ''
    },
    {
      title: 'สหกรณ์',
      dataIndex: 'coopid',
      render: data => coop[data]?.name ?? ''
    },
    {
      title: 'เครื่องมือ',
      dataIndex: 'Operation',
      width: 150,
      render: (_, row) => (
        <>
          {(isAllowed(role, 'edit', 'rooms')) && (
            <Popconfirm
              title={`Are you sure to remove ${row?.nickname ?? 'this student'}?`}
              onConfirm={() => handleDelete(row)}
              okText='Yes'
              cancelText='No'
            >
              <Button
                type='link'
                style={{ marginRight: '8px' }}
                danger
                icon={<DeleteOutlined style={{ fontSize: 18 }} />}
              ></Button>
            </Popconfirm>
          )}
        </>
      )
    }
  ]

  if (!value) return <Skeleton></Skeleton>
  return (
    <div>
      <Card
        style={{ marginTop: 16 }}
        bodyStyle={{ padding: '32px 80px' }}
      >
        <Row style={{ marginBottom: 24 }}>
          <Col flex='auto'>
            <div style={{ fontSize: 24 }}>
              หอพัก
            </div>
          </Col>
          <Col flex='300px'>
            <div style={{ float: 'right', fontSize: 14 }}>
              {(isAllowed(role, 'edit', 'rooms')) && (
                <Button
                  style={{ marginRight: 12, color: '#FFFFFF', backgroundColor: '#EE7930' }}
                  icon={<EditOutlined style={{ fontSize: 14 }} />}
                  onClick={() => {
                    navigate(`/admin/rooms/${rid}/edit`)
                  }}
                >
                  แก้ไข
                </Button>
              )}
              <Button
                type='primary'
                icon={<DownloadOutlined style={{ fontSize: 14 }} />}
                onClick={() => download(value)}
              >
                ดาวน์โหลด
              </Button>
            </div>
          </Col>
        </Row >
        <Row>
          <Col span={3} className='aiu-title'>วิทยาเขต</Col>
          <Col span={5} className='aiu-content'>{value.campus}</Col>
          <Col span={3} className='aiu-title'>หมายเลขห้อง</Col>
          <Col span={5} className='aiu-content'>{value.room}</Col>
          <Col span={3} className='aiu-title'>จำนวนเตียง</Col>
          <Col span={5} className='aiu-content'>{value.total}</Col>
        </Row>
        <Row style={{ margin: '48px 0 24px' }}>
          <Col flex='auto'></Col>
          <Col flex='300px'>
            {(isAllowed(role, 'edit', 'rooms')) && (
              <div style={{ float: 'right', fontSize: 14 }}>
                <Button
                  type='primary'
                  disabled={value.remain === 0}
                  icon={<PlusOutlined style={{ fontSize: 14 }} />}
                  onClick={() => {
                    navigate(`/admin/rooms/${rid}/students`)
                  }}
                >
                  เพิ่มนักเรียน
                </Button>
              </div>
            )}
          </Col>
        </Row >
        <Table
          bordered
          rowKey='id'
          style={{ overflowX: 'auto' }}
          columns={columns}
          dataSource={students}
        />
      </Card>
    </div>
  )
}

export default View
