const recommenderObj = {
  online: { name: 'ออนไลน์' },
  walkin: { name: 'สมัครตรง' },
  recommend: { name: 'มีผู้แนะนำ' },
  seminar: { name: 'สัมมนาแนะแนว' }
}

const levelObj = {
  p: { name: 'พื้นฐาน' },
  n1: { name: 'N1' },
  n2: { name: 'N2' },
  n3: { name: 'N3' },
  n4: { name: 'N4' },
  n5: { name: 'N5' }
}

const applyObj = {
  yes: { name: 'สมัครเรียน' },
  no: { name: 'ถอนตัว' }
}

const applyJobObj = {
  no: { name: 'ไม่อนุญาต', color: '#D35C3B' },
  yes: { name: 'อนุญาต', color: '#228B90' }
}

const jobObj = {
  no: { name: 'ยังไม่ได้งาน', color: '#D35C3B' },
  yes: { name: 'ได้งานแล้ว', color: '#228B90' }
}

const healthCheckObj = {
  no: { name: 'ยังไม่ตรวจ', color: '#D35C3B' },
  yes: { name: 'ตรวจแล้ว', color: '#228B90' }
}

const statusObj = {
  interest: { name: 'สมัครเรียน', color: '#228B90' },
  notInterest: { name: 'ถอนตัว', color: '#D35C3B' },
  learning: { name: 'กำลังเรียน', color: '#228B90' },
  suspension: { name: 'พักการเรียน', color: '#D35C3B' },
  resign: { name: 'ลาออก', color: '#D35C3B' },
  ajapan: { name: 'อยู่ญี่ปุ่น', color: '#228B90' },
  bthai: { name: 'กลับไทย - ครบสัญญา', color: '#228B90' },
  cthai: { name: 'กลับไทย - ก่อนกำหนด', color: '#D35C3B' },
  escape: { name: 'หลบหนีสัญญา', color: '#D35C3B' },
  lawsuit: { name: 'มีคดีความ', color: '#D35C3B' }
}

const courseObj = {
  industry: { name: 'อุตสาหกรรม' },
  care: { name: 'การดูแลผู้สูงอายุ' }
}

const learningSystemObj = {
  norm: { name: 'ปกติ' },
  bi: { name: 'ทวิภาคี' }
}

const degreeObj = {
  jhs: { name: 'ม.3' },
  shs: { name: 'ม.6' },
  nfe: { name: 'เทียบเท่า ม.6 (กศน., เปรียญธรรม 6 ประโยค)' },
  vc: { name: 'ปวช.' },
  dip: { name: 'ปวส.' },
  bd: { name: 'ป.ตรี' }
}

const relationshipObj = {
  single: { name: 'โสด', nameJp: '未婚' },
  umarriage: { name: 'แต่งงานไม่จดทะเบียน', nameJp: '事実婚' },
  marriage: { name: 'แต่งงานจดทะเบียน', nameJp: '既婚' },
  divorce: { name: 'หย่าร้าง', nameJp: '離婚' },
  baby: { name: 'มีลูก', nameJp: '' },
}

const bloodObj = {
  A: { name: 'A' },
  B: { name: 'B' },
  O: { name: 'O' },
  AB: { name: 'AB' }
}

const relationObj = {
  father: { name: 'บิดา', nameJp: '父' },
  mother: { name: 'มารดา', nameJp: '母' },
  spouse: { name: 'คู่สมรส', nameJp: '配偶者' },
  elderbrother: { name: 'พี่ชาย', nameJp: '兄' },
  eldersister: { name: 'พี่สาว', nameJp: '姉' },
  youngerbrother: { name: 'น้องชาย', nameJp: '弟' },
  youngersister: { name: 'น้องสาว', nameJp: '妹' },
  son: { name: 'ลูกชาย', nameJp: '息子' },
  daughter: { name: 'ลูกสาว', nameJp: '娘' },
  stepfather: { name: 'พ่อเลี้ยง', nameJp: '継父' },
  stepmother: { name: 'แม่เลี้ยง', nameJp: '継母' },
  fatherinlaw: { name: 'พ่อบุญธรรม', nameJp: '養父' },
  motherinlaw: { name: 'แม่บุญธรรม', nameJp: '養母' },
  uncle: { name: 'ลุง', nameJp: '叔父' },
  aunt: { name: 'ป้า', nameJp: '叔母' },
  muncle: { name: 'น้า', nameJp: '叔父' },
  funcle: { name: 'อา', nameJp: '叔母' },
  fgrandpa: { name: 'ปู่', nameJp: '祖父' },
  fgrandma: { name: 'ย่า', nameJp: '祖母' },
  mgrandpa: { name: 'ตา', nameJp: '祖父' },
  mgrandma: { name: 'ยาย', nameJp: '祖母' }
}

const relationJpObj = {
  father: { nameJp: '父' },
  mother: { nameJp: '母' },
  spouse: { nameJp: '配偶者' },
  elderbrother: { nameJp: '兄' },
  eldersister: { nameJp: '姉' },
  youngerbrother: { nameJp: '弟' },
  youngersister: { nameJp: '妹' },
  son: { nameJp: '息子' },
  daughter: { nameJp: '娘' },
  stepfather: { nameJp: '継父' },
  stepmother: { nameJp: '継母' },
  fatherinlaw: { nameJp: '養父' },
  motherinlaw: { nameJp: '養母' },
  uncle: { nameJp: '叔父' },
  aunt: { nameJp: '叔母' },
  grandpa: { nameJp: '祖父' },
  grandma: { nameJp: '祖母' }
}

const jobTypeObj = {
  full: { name: 'ทำงานเต็มเวลา' },
  part: { name: 'พาร์ทไทม์' },
  vcintern: { name: 'ฝึกงานปวช.' },
  dipintern: { name: 'ฝึกงานปวส.' },
  bdintern: { name: 'ฝึกงาน ป.ตรี' },
  break: { name: 'ช่วงปิดเทอม' },
  other: { name: 'อื่นๆ' }
}

const parentMeetingObj = {
  yes: { name: 'ประชุมแล้ว' },
  no: { name: 'ยังไม่ประชุม' }
}

const DocumentTypes = [
  { value: 'ผลตรวจสุขภาพ' },
  { value: 'วุฒิการศึกษา' },
  { value: 'ทะเบียนบ้าน' },
  { value: 'หนังสือเดินทาง' },
  { value: 'บัตรประชาชน' }
]

const militaryObj = {
  completed: { name: 'เกณฑ์ทหารแล้ว' },
  postponed: { name: 'ผ่อนผัน' },
  na: { name: 'ยังไม่ถึงเกณฑ์' },
  rotc: { name: 'รด.' },
  w: { name: 'ยกเว้น(เพศหญิง)' }
}

const colorBlindObj = {
  yes: { name: 'ตาบอดสี' },
  no: { name: 'ตาไม่บอดสี' }
}

const healthStatusObj = {
  yes: { name: 'ผ่าน' },
  no: { name: 'ไม่ผ่าน' }
}

export {
  recommenderObj,
  learningSystemObj,
  levelObj,
  applyObj,
  applyJobObj,
  jobObj,
  healthCheckObj,
  statusObj,
  courseObj,
  degreeObj,
  relationshipObj,
  relationJpObj,
  bloodObj,
  relationObj,
  jobTypeObj,
  parentMeetingObj,
  DocumentTypes,
  militaryObj,
  colorBlindObj,
  healthStatusObj
}
