const statusObj = {
  incomplete: { name: 'ยังไม่สมบูรณ์', color: '#D35C3B' },
  complete: { name: 'สมบูรณ์', color: '#228B90' }
}

const typeObj = {
  trainee: { name: 'Invoice ผู้ฝึกงาน' },
  skill: { name: 'Invoice ทักษะเฉพาะ' }
}

export {
  statusObj,
  typeObj
}
