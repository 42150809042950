const roleObj = {
  superadmin: { name: 'Super Admin' },
  student: { name: 'นักเรียน' },
  counselor: { name: 'แนะแนว, สาขา' },
  headteacher: { name: 'หัวหน้าครู' },
  teacher: { name: 'ครู' },
  headrecruiter: { name: 'หัวหน้าจัดหางาน' },
  recruiter: { name: 'จัดหางาน' },
  headadmin: { name: 'หัวหน้าธุรการ' },
  admin: { name: 'ธุรการ' },
  headfund: { name: 'หัวหน้ากองทุน' },
  fund: { name: 'กองทุน' }
}

export {
  roleObj
}
