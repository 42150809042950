import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Form as AntdForm, Card, Row, Col, Table, Modal, Input, Button, Tag, Space, Skeleton } from 'antd'
import { SearchOutlined, EyeOutlined, DeleteOutlined, CheckOutlined, CloseOutlined, PrinterOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'
import { dbTodayjs } from '../../utils/date'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { genCsv } from '../../utils/csv'
import { search } from '../../utils/search'

const List = () => {
  const navigate = useNavigate()
  const { db } = useAuth()
  const { getPageNo, onPageChange, getSearch, setSearch } = useLayoutContext()

  const [initValue, setInitValue] = useState([])
  const [value, setValue] = useState([])
  const [qsVisible, setQsVisible] = useState(false)
  const [qs, setQs] = useState({})
  const [tags, setTags] = useState([])

  const [searchForm] = AntdForm.useForm()

  const { loading } = useAsync(async () => {
    const snap = await db.collection('/coops').get()
    const data = snap.docs.map(doc => {
      const d = doc.data()
      return {
        ...d,
        id: doc.id,
        latestdate: dbTodayjs(d?.latestdate)
      }
    })

    const q = getSearch('invoices')
    setQs(q || {})
    setInitValue(data)
  }, [])

  useEffect(() => {
    const tagList = Object.keys(qs).reduce((acc, o) => {
      return (!qs[o]) ? acc : [...acc, {
        key: o,
        value: qs[o],
        label: {
          name: 'ชื่อสหกรณ์'
        }[o]
      }]
    }, [])
    setTags(tagList)
    const data = search(initValue, qs)
    setValue(data)
  }, [initValue, qs])

  const handleClose = (key) => {
    const newQs = {
      ...qs,
      [key]: null
    }
    setQs(newQs)
    setSearch('invoices', newQs)
    searchForm.setFieldsValue(newQs)
  }

  const download = async (data) => {
    const list = data.map((o) => ({
      'สหกรณ์': o?.name ?? '',
      'วันที่สร้างล่าสุด': (o?.latestdate) ? dayjs(o.latestdate).format('DD/MM/YYYY') : '',
      'Invoice ล่าสุด': o?.latestinv ?? ''
    }))
    try {
      await genCsv('invoice_list', list)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const columns = [
    {
      title: 'สหกรณ์',
      dataIndex: 'name',
      width: 150
    },
    {
      title: 'วันที่สร้างล่าสุด',
      dataIndex: 'latestdate',
      width: 150,
      align: 'center',
      render: data => (
        <span>{(data) ? dayjs(data).format('DD/MM/BBBB') : ''}</span>
      )
    },
    {
      title: 'Invoice ล่าสุด',
      dataIndex: 'latestinv',
      width: 150,
      align: 'center'
    },
    {
      title: 'เครื่องมือ',
      dataIndex: 'Operation',
      width: 100,
      render: (_, row) => (
        <div>
          <Button
            type='link'
            style={{ marginRight: '8px' }}
            icon={<EyeOutlined style={{ fontSize: 18 }} />}
            onClick={() => {
              navigate(`/admin/invoices/${row.id}/view`)
            }}
          ></Button>
        </div>
      )
    }
  ]

  if (loading) return <Skeleton></Skeleton>
  return (
    <Card style={{ minHeight: '100%' }}>
      <Row>
        <Col flex='auto'>
          <div style={{ fontSize: 24 }}>
            เรียกเก็บเงินจากญี่ปุ่น
          </div>
          <div style={{ marginTop: 12 }}>
            {tags.map((o) => (
              <Tag
                key={o.key}
                closable
                onClose={() => handleClose(o.key)}
              >
                {o.label}: {o.value}
              </Tag>
            ))}
          </div>
        </Col>
        <Col flex='300px'>
          <div style={{ float: 'right', margin: '0 0 60px 0', fontSize: 14 }}>
            <Button
              icon={<SearchOutlined />}
              style={{ margin: '0 8px' }}
              onClick={() => {
                setQsVisible(true)
              }}
            ></Button>
            <Button
              icon={<PrinterOutlined />}
              onClick={() => {
                download(value)
              }}
            ></Button>
          </div>
        </Col>
      </Row >
      <Table
        bordered
        rowKey='id'
        style={{ overflowX: 'auto' }}
        columns={columns}
        dataSource={value}
        pagination={{
          total: value.length,
          current: getPageNo('invoices'),
          onChange(page) {
            onPageChange('invoices', page)
          }
        }}
      />
      <Modal
        forceRender
        open={qsVisible}
        title={
          <div style={{ padding: '16px 24px', fontWeight: 'bold', fontSize: '18px', lineHeight: '23px', backgroundColor: '#228B90', color: '#F6F6F6', borderRadius: '5px 5px 0px 0px' }}>
            ค้นหา
          </div>
        }
        width='50%'
        closable={false}
        footer={
          <Row justify='center'>
            <Space align='center'>
              <Col>
                <Button
                  type='secondary'
                  size='medium'
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    const empty = {
                      name: null
                    }
                    setQs(empty)
                    setSearch('invoices', empty)
                    searchForm.setFieldsValue(empty)
                  }}
                >
                  Clear Data
                </Button>
              </Col>
              <Col>
                <Button
                  size='medium'
                  style={{ color: '#FFF', backgroundColor: '#EE7930' }} icon={<CloseOutlined />}
                  onClick={() => {
                    setQsVisible(false)
                  }}
                >
                  ยกเลิก
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  size='medium'
                  icon={<CheckOutlined />}
                  onClick={() => {
                    const data = searchForm.getFieldsValue()
                    setQs(data)
                    setSearch('invoices', data)
                    setQsVisible(false)
                  }}
                >
                  ยืนยัน
                </Button>
              </Col>
            </Space>
          </Row>
        }
      >
        <AntdForm
          layout='vertical'
          initialValues={qs}
          style={{ margin: '8px 32px' }}
          form={searchForm}
          name='searchForm'
        >
          <Row gutter={[24, 24]}>
            <Col span={6}></Col>
            <Col span={12}>
              <AntdForm.Item name='name' label='ชื่อสหกรณ์' >
                <Input placeholder='ชื่อสหกรณ์' />
              </AntdForm.Item>
            </Col>
            <Col span={6}></Col>
          </Row>
        </AntdForm>
      </Modal>
    </Card >
  )
}

export default List
