import html2canvas from 'html2canvas'
import pdfMake from 'pdfmake/build/pdfmake'

import pdfFonts from '../assets/pdfMakeFonts/vfs_fonts'

pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf'
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  },
  NotoSansJP: {
    normal: 'https://cdn.jsdelivr.net/npm/noto-sans-japanese@1.0.0/fonts/NotoSansJP-DemiLight.woff',
    bold: 'https://cdn.jsdelivr.net/npm/noto-sans-japanese@1.0.0/fonts/NotoSansJP-Black.woff'
  }
}

const genPdf = async (docObj, fileName = 'name') => {
  try {
    // await pdfMake.createPdf(docObj).download(fileName)
    await pdfMake.createPdf(docObj).open()
    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}

const html2pdf = async (className, docName, width = 540, height = 775) => {
  try {
    const printArea = document.querySelector(className)
    const canvas = await html2canvas(printArea, { backgroundColor: null })

    const clientWidth = printArea.clientWidth
    let clientHeight = printArea.clientHeight
    const sX = 0
    let sY = 0
    const sWidth = clientWidth
    const sHeight = clientWidth / width * height
    const dX = 0
    const dY = 0
    const dWidth = width
    const dHeight = height
    let canvases = []

    while (clientHeight > 0) {
      const onePageCanvas = document.createElement('canvas')
      onePageCanvas.setAttribute('width', sWidth)
      onePageCanvas.setAttribute('height', sHeight)
      const ctx = onePageCanvas.getContext('2d')
      ctx.drawImage(canvas, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight)

      canvases.push(onePageCanvas.toDataURL('image/png', 1.0))

      clientHeight -= sHeight
      sY += sHeight
    }

    const docObj = {
      pageOrientation: 'portrait',
      content: [
        {
          stack: canvases.map((o) => ({
            image: o
          }))
        }
      ]
    }
    await genPdf(docObj, docName)
    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error)
  }
}

export {
  genPdf,
  html2pdf
}