import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Row, Col, Card, Button, Table, Tag, Skeleton } from 'antd'
import { PlusOutlined, DownloadOutlined, FileDoneOutlined, SearchOutlined, EditOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'
import { dbTodayjs } from '../../utils/date'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { genPdf } from '../../utils/pdf'
import { difference, toDataURL } from '../../utils/tools'
import { nameTitleObj } from '../../const/users'
import { studentJobStatusObj } from '../../const/jobs'
import { relationshipObj, relationJpObj } from '../../const/student'
import { isAllowed } from '../../route/roles'

import NoProfile from '../../assets/images/user.png'

const Form = () => {
  const navigate = useNavigate()
  const { db, role } = useAuth()
  const { getStudents, getCoops, getCompanies } = useLayoutContext()
  const { jid } = useParams()

  const [selectedStudents, setSelectedStudents] = useState([])
  const [studentObj, setStudentObj] = useState()
  const [students, setStudents] = useState()
  const [coopObj, setCoopObj] = useState([])
  const [companyObj, setCompanyObj] = useState([])

  const { value } = useAsync(async () => {
    const coops = await getCoops(true)
    const coopData = coops.reduce((acc, o) => {
      acc[o.id] = o
      return acc
    }, {})
    setCoopObj(coopData)

    const companies = await getCompanies(true)
    const companyData = companies.reduce((acc, o) => {
      acc[o.id] = o
      return acc
    }, {})
    setCompanyObj(companyData)

    if (!jid) {
      return {}
    }

    const sjSnap = await db.collection('/studentjobs').where('jid', '==', jid).where('disabled', '==', false).get()
    const sjObj = sjSnap.docs.reduce((acc, o) => {
      acc[o.data().uid] = { id: o.id, ...o.data() }
      return acc
    }, {})
    const sj = Object.keys(sjObj)

    let studentData = await getStudents()
    studentData = studentData.reduce((acc, o) => {
      return (sj.indexOf(o.id) !== -1) ? [...acc, {
        ...o,
        uid: o.id,
        sid: sjObj[o.id].id,
        scn: o?.scn ?? '',
        jobstatus: sjObj[o.id]?.jobstatus
      }] : acc
    }, [])
    setStudents(studentData)

    const snap = await db.collection('/jobs').doc(jid).get()
    const data = snap.data()
    return {
      ...data,
      id: snap.id,
      interviewdate: dbTodayjs(data?.interviewdate),
      interviewtime: dbTodayjs(data?.interviewtime),
      students: studentData
    }
  }, [jid])

  const selectedData = async (keys, rows) => {
    const rowObj = rows.reduce((acc, o) => {
      acc[o.key] = o
      return acc
    }, {})
    const diff = difference(keys, selectedStudents)
    setSelectedStudents(keys)
    try {
      for await (const v of diff) {
        const snap = await db.collection('/studentinfo').doc(v).get()
        const imageData = await toDataURL(rowObj[v]?.imgurl)
        setStudentObj({
          ...studentObj,
          [v]: {
            ...rowObj[v],
            ...snap.data(),
            image: imageData || NoProfile
          }
        })
      }
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const download = async () => {
    try {
      const students = selectedStudents.reduce((acc, o, i) => {
        const index = Math.floor(i / 5)
        if (!acc[index]) {
          acc[index] = []
        }
        acc[index].push({
          s: o,
          n: i + 1
        })
        return acc
      }, [])
      const docObj = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [10, 10, 10, 10],
        content: [
          ...students.reduce((all, s, i) => {
            return [...all, {
              columns: [
                {
                  width: '*',
                  marginTop: 24,
                  stack: [
                    {
                      text: '技能実習生候補者名簿',
                      alignment: 'center',
                      bold: true,
                      fontSize: 13
                    },
                    {
                      text: '(NAME LIST OF TECHNICAL INTERN TRAINEES)',
                      alignment: 'center',
                      bold: true,
                      fontSize: 13
                    }
                  ]
                },
                {
                  width: 170,
                  table: {
                    headerRows: 1,
                    widths: [70, 95],
                    body: [
                      [
                        {
                          text: '選考日：',
                          alignment: 'right',
                          fontSize: 11
                        },
                        {
                          text: (value.interviewdate) ? dayjs(value.interviewdate).format('YYYY/MM/DD') : '',
                          alignment: 'left',
                          fontSize: 11
                        }
                      ],
                      [
                        {
                          text: '入国予定：',
                          alignment: 'right',
                          fontSize: 11
                        },
                        {
                          text: value?.gotojapandate ?? '',
                          alignment: 'left',
                          fontSize: 14,
                          font: 'THSarabunNew'
                        }
                      ],
                      [
                        {
                          text: '選考会場所：',
                          alignment: 'right',
                          fontSize: 11
                        },
                        {
                          text: value?.interviewplace ?? '',
                          alignment: 'left',
                          fontSize: 14,
                          font: 'THSarabunNew'
                        }
                      ],
                      [
                        {
                          text: '採用人数：',
                          alignment: 'right',
                          fontSize: 11
                        },
                        {
                          text: `${value?.no ?? 0} 名`,
                          alignment: 'left',
                          fontSize: 11
                        }
                      ],
                    ]
                  },
                  layout: 'noBorders'
                },
                {
                  width: 330,
                  table: {
                    headerRows: 1,
                    widths: [125, 200],
                    body: [
                      [
                        {
                          text: '実習実施機関（日本）：',
                          alignment: 'right',
                          fontSize: 11
                        },
                        {
                          text: companyObj[value?.companyid]?.name ?? '',
                          alignment: 'left',
                          fontSize: 11
                        }
                      ],
                      [
                        {
                          text: '監理団体（日本）：',
                          alignment: 'right',
                          fontSize: 11
                        },
                        {
                          text: coopObj[value?.coopid]?.name ?? '',
                          alignment: 'left',
                          fontSize: 11
                        }
                      ],
                      [
                        {
                          text: '送出し機関（タイ）：',
                          alignment: 'right',
                          fontSize: 11
                        },
                        {
                          text: value?.agency ?? '',
                          alignment: 'left',
                          fontSize: 11
                        }
                      ]
                    ]
                  },
                  layout: 'noBorders'
                },
              ],
            }, {
              table: {
                headerRows: 1,
                widths: [15, 60, '*', 60, 15, 15, 15, 60, 30, 30, 30, 30, 15, 15, 15, 15, 25, 35, 20, 60],
                body: [
                  [{ text: 'No.', rowSpan: 4 }, { text: '写  真', rowSpan: 4 }, {}, { text: '生年月日', rowSpan: 2 }, { text: '性 別', rowSpan: 4 }, { text: '婚　姻', rowSpan: 4 }, { text: '子の有無', rowSpan: 4 }, { text: '出身地', rowSpan: 4 }, { text: '入学予定日', rowSpan: 2 }, { text: '健康診断日', rowSpan: 2 }, { text: '裸眼視力 左｜右', colSpan: 2 }, {}, { text: '血液型', rowSpan: 4 }, { text: '利き手', rowSpan: 4 }, { text: '刺青', rowSpan: 4 }, { text: '徴兵', rowSpan: 4 }, { text: '家族構成', colSpan: 2, rowSpan: 2 }, {}, { text: '訪日 親族 の 有無', rowSpan: 4 }, { text: '備考', rowSpan: 4 }],
                  [{}, {}, { text: 'タイ語表記' }, {}, {}, {}, {}, {}, {}, {}, { text: '眼鏡・コンタクト着用有無', colSpan: 2, rowSpan: 2, marginLeft: 6 }, {}, {}, {}, {}, {}, {}, {}, {}, {}],
                  [{}, {}, { text: 'フ リ ガ ナ' }, { text: '年 齢', rowSpan: 2 }, {}, {}, {}, {}, { text: '身 長（ｃｍ)', rowSpan: 2 }, { text: '体 重（ｋｇ)', rowSpan: 2 }, {}, {}, {}, {}, {}, {}, { text: '続柄', rowSpan: 2, marginLeft: 8 }, { text: '職業', rowSpan: 2, marginLeft: 16 }, {}, {}],
                  [{}, {}, { text: 'ニ ッ ク ネ ー ム' }, {}, {}, {}, {}, {}, {}, {}, { text: '矯正視力　左｜右', colSpan: 2 }, {}, {}, {}, {}, {}, {}, {}, {}, {}],
                  ...s.reduce((acc, o) => {
                    const d = studentObj[o.s]
                    d.birthdate = dbTodayjs(d?.birthdate)
                    d.healthcheck = dbTodayjs(d?.healthcheck)
                    const birthdate = (!d.birthdate) ? '' : `${dayjs(d.birthdate).format('YYYY')}年${dayjs(d.birthdate).format('M')}月${dayjs(d.birthdate).format('D')}日`
                    const healthcheck = (!d?.healthcheck) ? '' : `${dayjs(d.healthcheck).format('YYYY/MM')}`
                    const age = (!d.birthdate) ? '' : dayjs().diff(d.birthdate, 'year')
                    const sex = (d.title === 'mr') ? '男' : '女'
                    const hasChild = (d.relationship === 'baby') ? '有' : '無'
                    const eyewear = (!d?.eyewear?.length) ? '' : (d.eyewear.length === 2) ? '眼鏡・コンタクトレンズ着用' : (d.eyewear.indexOf('glasses') !== -1) ? '眼鏡着用' : 'コンタクトレンズ着用'
                    const hand = (!d?.hand) ? '' : (d.hand === 'left') ? '左' : '右'
                    const colorblind = (!d?.colorblind) ? '' : (d.colorblind === 'yes') ? '有' : '無'
                    const military = (!d?.military) ? '' : (d.military === 'completed') ? '済' : (d.military === 'rotc') ? '予科練済' : (d.military === 'postponed') ? '要延期申請' : ''
                    const familyObj = d.family.reduce((acc2, p, j) => {
                      acc2[j] = {
                        relationjp: relationJpObj[p?.relationjp]?.nameJp ?? '',
                        occupationjp: p?.occupationjp ?? ''
                      }
                      return acc2
                    }, {})
                    const relativeinjapan = (!d?.relativeinjapan) ? '' : (d.relativeinjapan === 'yes') ? '有' : '無'
                    const remark = `${(d?.targetjp) ? d.targetjp + ', ' : ''}${(d?.hobbyjp) ? d.hobbyjp + ', ' : ''}${(d?.strengthjp) ? d.strengthjp + ', ' : ''}${(d?.weaknessjp) ? d.weaknessjp + ', ' : ''}${(d?.talentjp) ? d.talentjp : ''}`
                    return [
                      ...acc,
                      [{ text: o.n, rowSpan: 4 }, { image: d.image, width: 50, rowSpan: 4 }, { text: `${d?.lastnameen ?? ''} ${d?.firstnameen ?? ' '}` }, { text: birthdate, rowSpan: 2 }, { text: sex, rowSpan: 4 }, { text: relationshipObj[d?.relationship]?.nameJp ?? '', rowSpan: 4 }, { text: hasChild, rowSpan: 4 }, { text: d?.provincejp ?? '', rowSpan: 4 }, { text: '入学済', rowSpan: 2 }, { text: healthcheck, rowSpan: 2 }, { text: d?.eyeleftdetail ?? '' }, { text: d?.eyerightdetail ?? '' }, { text: d?.blood ?? '', rowSpan: 4 }, { text: hand, rowSpan: 4 }, { text: colorblind, rowSpan: 4 }, { text: military, rowSpan: 4 }, { text: familyObj[0]?.relationjp ?? '' }, { text: familyObj[0]?.occupationjp ?? '' }, { text: relativeinjapan, rowSpan: 4 }, { text: remark, rowSpan: 4 }],
                      [{}, {}, { text: `${d?.lastname ?? ''} ${d?.firstname ?? ' '}`, font: 'THSarabunNew', fontSize: 12 }, {}, {}, {}, {}, {}, {}, {}, { text: eyewear, colSpan: 2, rowSpan: 2, marginLeft: 6 }, {}, {}, {}, {}, {}, { text: familyObj[1]?.relationjp ?? '' }, { text: familyObj[1]?.occupationjp ?? '' }, {}, {}],
                      [{}, {}, { text: `${d?.lastnamejp ?? ''} ${d?.firstnamejp ?? ' '}` }, { text: `${age} 歳`, rowSpan: 2 }, {}, {}, {}, {}, { text: `${d?.height ?? ''} cm`, rowSpan: 2 }, { text: `${d?.weight ?? ''} kg`, rowSpan: 2 }, {}, {}, {}, {}, {}, {}, { text: familyObj[2]?.relationjp ?? '' }, { text: familyObj[2]?.occupationjp ?? '' }, {}, {}],
                      [{}, {}, { text: d?.nicknamejp ?? ' ' }, {}, {}, {}, {}, {}, {}, {}, { text: (d?.glassesleft || d?.contactlensleft || '') }, { text: (d?.glassesright || d?.contactlensright || '') }, {}, {}, {}, {}, { text: familyObj[3]?.relationjp ?? '' }, { text: familyObj[3]?.occupationjp ?? '' }, {}, {}]
                    ]
                  }, [])
                ]
              },
              layout: {
                fillColor: (rowIndex, node, columnIndex) => {
                  return (rowIndex < 4) ? '#f6ddcc' : null
                }
              }
            },
            (i + 1 < students.length) ? { text: '', pageBreak: 'after' } : {}
            ]
          }, [])
        ],
        defaultStyle: {
          fontSize: 8,
          alignment: 'center',
          font: 'NotoSansJP' // Roboto | THSarabunNew | NotoSansJP
        }
      }
      await genPdf(docObj, 'Job')
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const columns = [
    {
      title: 'รหัสนักเรียน',
      dataIndex: 'scn',
      width: 110
    },
    {
      title: 'คำนำหน้า',
      dataIndex: 'title',
      width: 100,
      render: data => nameTitleObj[data]?.nameTh ?? ''
    },
    {
      title: 'ชื่อ',
      dataIndex: 'firstname'
    },
    {
      title: 'นามสกุล',
      dataIndex: 'lastname'
    },
    {
      title: 'สถานะ',
      dataIndex: 'jobstatus',
      width: 120,
      align: 'center',
      render: data => (
        <Tag color={studentJobStatusObj[data]?.color ?? 'error'}>
          {studentJobStatusObj[data]?.name ?? 'ยังไม่สัมภาษณ์'}
        </Tag>
      )
    },
    {
      title: 'เครื่องมือ',
      dataIndex: 'Operation',
      width: 150,
      render: (_, row) => (
        <div>
          {(isAllowed(role, 'edit', 'jobs')) && (
            <Button
              type='link'
              style={{ marginRight: 16 }}
              icon={<FileDoneOutlined style={{ fontSize: 24 }} />}
              onClick={() => {
                navigate(`/admin/jobs/${jid}/student/${row.sid}/interview`)
              }}
            ></Button>
          )}
          <Button
            type='link'
            style={{ marginRight: 16 }}
            icon={<SearchOutlined style={{ fontSize: 24 }} />}
            onClick={() => {
              navigate(`/admin/jobs/${jid}/student/${row.uid}/view`)
            }}
          ></Button>
        </div>
      )
    }
  ]

  if (!value) return <Skeleton></Skeleton>
  return (
    <div>
      <Card
        style={{ marginTop: 16 }}
        bodyStyle={{ padding: '32px 80px' }}
      >
        <Row style={{ marginBottom: 24 }}>
          <Col flex='auto'>
            <div style={{ fontSize: 24 }}>
              รายละเอียดงาน
            </div>
          </Col>
          <Col flex='300px'>
            {(isAllowed(role, 'edit', 'jobs')) && (
              <div style={{ float: 'right', fontSize: 14 }}>
                <Button
                  style={{ color: '#FFFFFF', backgroundColor: '#EE7930' }}
                  icon={<EditOutlined style={{ fontSize: 14 }} />}
                  onClick={() => {
                    navigate(`/admin/jobs/${jid}/edit`)
                  }}
                >
                  แก้ไข
                </Button>
              </div>
            )}
          </Col>
        </Row >
        <Row>
          <Col span={4} className='aiu-title'>ชื่อสหกรณ์ (ENG)</Col>
          <Col span={(role !== 'counselor') ? 8 : 20} className='aiu-content'>{coopObj[value?.coopid]?.name ?? ''}</Col>
          {(role !== 'counselor') && (
            <>
              <Col span={4} className='aiu-title'>ที่อยู่สหกรณ์</Col>
              <Col span={8} className='aiu-content'>{coopObj[value?.coopid]?.address ?? ''}</Col>
            </>
          )}
        </Row>
        <Row>
          <Col span={4} className='aiu-title'>ชื่อบริษัท (ENG)</Col>
          <Col span={(role !== 'counselor') ? 8 : 20} className='aiu-content'>{companyObj[value?.companyid]?.name ?? ''}</Col>
          {(role !== 'counselor') && (
            <>
              <Col span={4} className='aiu-title'>ที่อยู่บริษัท</Col>
              <Col span={8} className='aiu-content'>{companyObj[value?.companyid]?.address ?? ''}</Col>
            </>
          )}
        </Row>
        <Row>
          <Col span={4} className='aiu-title'>ประเภทธุรกิจ</Col>
          <Col span={20} className='aiu-content'>{value?.bustype ?? ''}</Col>
        </Row>
        <Row>
          <Col span={4} className='aiu-title'>ประเภทงานที่ผู้ฝึกงานปฏิบัติ</Col>
          <Col span={20} className='aiu-content'>{value?.jobtype ?? ''}</Col>
        </Row>
        <Row>
          <Col span={4} className='aiu-title'>ชิ้นงานที่ผู้ฝึกงานผลิต</Col>
          <Col span={20} className='aiu-content'>{value?.product ?? ''}</Col>
        </Row>
        <Row>
          <Col span={4} className='aiu-title'>ชั่วโมงทำงานล่วงเวลา (เฉลี่ยต่อเดือน)</Col>
          <Col span={8} className='aiu-content'>{value?.ot ?? ''}</Col>
          <Col span={4} className='aiu-title'>รุ่นพี่คนไทยในญี่ปุ่น</Col>
          <Col span={8} className='aiu-content'>{value?.senior ?? ''}</Col>
        </Row>
        <Row>
          <Col span={4} className='aiu-title'>วันที่สัมภาษณ์งาน</Col>
          <Col span={8} className='aiu-content'>{(value.interviewdate) ? dayjs(value.interviewdate).format('D MMM BBBB') : ''}</Col>
          <Col span={4} className='aiu-title'>เวลาที่สัมภาษณ์งาน</Col>
          <Col span={8} className='aiu-content'>{(value.interviewtime) ? dayjs(value.interviewtime).format('H:mm') : ''}</Col>
        </Row>
        <Row>
          <Col span={4} className='aiu-title'>สถานที่สัมภาษณ์งาน</Col>
          <Col span={8} className='aiu-content'>{value?.interviewplace ?? ''}</Col>
          <Col span={4} className='aiu-title'>วันที่เดินทางเข้าญี่ปุ่น</Col>
          <Col span={8} className='aiu-content'>{value?.gotojapandate ?? ''}</Col>
        </Row>
        <Row>
          <Col span={4} className='aiu-title'>จำนวนที่รับ</Col>
          <Col span={8} className='aiu-content'>{value?.no ?? ''}</Col>
          <Col span={4} className='aiu-title'>คุณสมบัติ</Col>
          <Col span={8} className='aiu-content'>{value?.character ?? ''}</Col>
        </Row>
        <Row>
          <Col span={4} className='aiu-title'>เพศ</Col>
          <Col span={8} className='aiu-content'>{value?.sex ?? ''}</Col>
          <Col span={4} className='aiu-title'>ลักษณะนิสัยที่ต้องการ</Col>
          <Col span={8} className='aiu-content'>{value?.habit ?? ''}</Col>
        </Row>
        <Row>
          <Col span={4} className='aiu-title'>อายุ</Col>
          <Col span={8} className='aiu-content'>{value?.age ?? ''}</Col>
          <Col span={4} className='aiu-title'>คุณสมบัติที่ไม่เหมาะสม</Col>
          <Col span={8} className='aiu-content'>{value?.inappropriate ?? ''}</Col>
        </Row>
        <Row>
          <Col span={4} className='aiu-title'>การทดสอบก่อนวันสัมภาษณ์งาน</Col>
          <Col span={20} className='aiu-content'>{value?.preexam ?? ''}</Col>
        </Row>
        <Row>
          <Col span={4} className='aiu-title'>การทดสอบในวันสัมภาษณ์งาน</Col>
          <Col span={20} className='aiu-content'>{value?.exam ?? ''}</Col>
        </Row>
        <Row>
          <Col span={4} className='aiu-title'>การใช้ Projector</Col>
          <Col span={8} className='aiu-content'>{value?.projector ?? ''}</Col>
          <Col span={4} className='aiu-title'>การฝึกเชื่อมก่อนเดินทาง</Col>
          <Col span={8} className='aiu-content'>{value?.welding ?? ''}</Col>
        </Row>
        <Row>
          <Col span={4} className='aiu-title'>เงื่อนไขหรือหมายเหตุอื่นๆ</Col>
          <Col span={20} className='aiu-content'>{value?.condition ?? ''}</Col>
        </Row>
        <Row>
          <Col span={4} className='aiu-title'>อัปโหลด</Col>
          <Col span={20} className='aiu-content'>{(value?.files ?? []).map((o, i) => (
            <span key={i} style={{ marginRight: 16 }}>
              <a href={o.url} target='_blank' rel='noreferrer'>{o.name}</a>
            </span>
          ))}</Col>
        </Row>
        <Row style={{ margin: '48px 0 24px' }}>
          <Col flex='auto'>
            <div style={{ fontSize: 24 }}>
              รายชื่อนักเรียน
            </div>
          </Col>
          <Col flex='300px'>
            <div style={{ float: 'right', fontSize: 14 }}>
              {(isAllowed(role, 'edit', 'jobs')) && (
                <>
                  <Button
                    type='primary'
                    icon={<DownloadOutlined style={{ fontSize: 14 }} />}
                    onClick={() => download()}
                  >
                    ดาวน์โหลด
                  </Button>
                  <Button
                    type='primary'
                    style={{ marginLeft: 12 }}
                    icon={<PlusOutlined style={{ fontSize: 14 }} />}
                    onClick={() => {
                      navigate(`/admin/jobs/${jid}/students`)
                    }}
                  >
                    เพิ่มนักเรียน
                  </Button>
                </>
              )}
            </div>
          </Col>
        </Row >
        <Table
          bordered
          rowKey='id'
          style={{ overflowX: 'auto' }}
          columns={columns}
          dataSource={students}
          rowSelection={{
            columnWidth: 48,
            hideSelectAll: true,
            onChange: (selectedRowKeys, selectedRows) => selectedData(selectedRowKeys, selectedRows)
          }}
        />
      </Card>
    </div>
  )
}

export default Form
