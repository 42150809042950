import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Button, Form, Input, message } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'

import { useAuth } from '../../contexts/AuthContextProvider'

const AuthAction = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { firebaseApp, functions, auth } = useAuth()
  const [email, setEmail] = useState()
  const [loading, setLoading] = useState(false)

  const mode = searchParams.get('mode')
  const oobCode = searchParams.get('oobCode')

  const { value } = useAsync(async () => {
    const data = await auth().checkActionCode(oobCode)
    if (!data) {
      await message.error('Code is invalid or expired')
      return {}
    }
    setEmail(data?.data?.email ?? '')
    return {}
  }, [oobCode])

  const handleChange = async ({ password }) => {
    if (!email) {
      return Promise.resolve()
    }
    try {
      setLoading(true)
      if (mode === 'resetPassword') {
        await auth().confirmPasswordReset(oobCode, password)
        await message.success('Reset password successfully')
      }
      if (mode === 'verifyEmail') {
        await auth().applyActionCode(oobCode)
        const verifyUser = functions.httpsCallable('verifyUser-verifyUser')
        const { data } = await verifyUser({ email: email })
        await firebaseApp.auth().signInWithEmailAndPassword(email, data.password)
        const currentUser = firebaseApp.auth().currentUser
        await currentUser.updatePassword(password)
        await firebaseApp.auth().signOut()
        await message.success('Comfirmation successfully')
      }
      setLoading(false)
      navigate('/login')
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  const confirmPasswordValidator = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve()
      }
      return Promise.reject('The two passwords that you entered do not match!')
    }
  })

  return (
    <Form
      layout='vertical'
      onFinish={handleChange}
      initialValues={value}
    >
      <Form.Item name='email' label='อีเมล' >
        <Input prefix={<UserOutlined />} placeholder={email || 'Email'} disabled={true} />
      </Form.Item>
      <Form.Item name='password' label='รหัสผ่าน ใหม่' rules={[{ required: true, message: 'Please input your Password!' }]}>
        <Input.Password prefix={<LockOutlined />} placeholder='Password' />
      </Form.Item>
      <Form.Item name='confirmPassword' label='ยืนยันรหัสผ่าน ใหม่' rules={[{ required: true, message: 'Please input your Confirm Password!' }, confirmPasswordValidator]}>
        <Input.Password prefix={<LockOutlined />} placeholder='Password' />
      </Form.Item>
      <Form.Item>
        <Button type='primary' className='input-auth' htmlType='submit' style={{ width: '100%' }} loading={loading}>
          <span style={{ fontSize: '16px', fontFamily: 'Roboto' }}>{(mode === 'resetPassword') ? 'เปลี่ยนรหัสผ่าน' : (mode === 'verifyEmail') ? 'สมัคร' : ''}</span>
        </Button>
      </Form.Item>
    </Form>
  )
}

export default AuthAction
