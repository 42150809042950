import React from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Form as AntdForm, Row, Col, Card, Radio, Input, Skeleton } from 'antd'

import InputNumberNew from '../../components/InputNumberNew'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'

import StudentInfoCard from './Components/info'

const Form = () => {
  const { db, role, userId } = useAuth()
  const { id, sid } = useParams()
  const { getClasses } = useLayoutContext()
  const { pathname } = useLocation()

  const uid = (role === 'student') ? userId : id
  const readOnly = (pathname.indexOf('/view') !== -1)

  const { value } = useAsync(async () => {

    const snap = await db.collection('/studentclasses').doc(sid).get()
    const data = snap.data()

    const evarevSnap = await db.collection('/evarevs').doc(data.eid).get()
    const evarevData = evarevSnap.data()

    const evaresSnap = await db.collection('/evares').doc(data.eid).get()
    const evaresData = evaresSnap.data()

    const classes = await getClasses()
    const classData = classes.find((o) => o.id === data.cid)

    return {
      data: (evarevData?.data ?? []).map((o, i) => ({
        ...o,
        questions: (o?.questions ?? []).map((p, j) => ({
          ...p,
          ans: evaresData?.data[i]?.questions[j]?.ans
        }))
      })),
      hrs: (evaresData?.hrs) ? evaresData.hrs : classData.hrs,
      remark: evaresData?.remark ?? ''
    }
  }, [uid])

  if (!value) return <Skeleton></Skeleton>
  return (
    <div>
      <StudentInfoCard uid={uid} />
      <Card
        style={{ marginTop: 16 }}
        bodyStyle={{ padding: 32 }}
      >
        <AntdForm
          layout='vertical'
          initialValues={value}
        >
          {(value?.data ?? []).map((o, i) => (
            <div key={`e-${i}`}>
              <div style={{ margin: '16px 0', fontSize: 24 }}>
                {value?.data[i]?.name ?? ''}
              </div>
              {(o?.questions ?? []).map((p, j) => (
                <Row key={`q-${i}-${j}`}>
                  <Col flex='325px'>
                    <AntdForm.Item name={['data', i, 'questions', j, 'ans']} label={value?.data[i]?.questions[j]?.name ?? ''}>
                      <Radio.Group disabled={readOnly} >
                        <Radio value='p'>ผ่าน</Radio>
                        <Radio value='n'>ไม่ผ่าน</Radio>
                        <Radio value='t'>ติดตาม</Radio>
                      </Radio.Group>
                    </AntdForm.Item>
                  </Col>
                  <Col flex='auto'>
                    {(value.data[i].questions[j].ans === 't') && (
                      <AntdForm.Item name={['data', i, 'questions', j, 'follow']} style={{ marginTop: 24 }} rules={[{ required: true, message: 'กรุณาใส่การติดตามด้วยค่ะ' }]}>
                        <Input disabled={readOnly} />
                      </AntdForm.Item>
                    )}
                  </Col>
                </Row>
              ))}
            </div>
          ))}
          <AntdForm.Item name='hrs' label='จำนวนชั่วโมง' >
            <InputNumberNew disabled={readOnly} placeholder='จำนวนชั่วโมง' />
          </AntdForm.Item>
          <AntdForm.Item name='remark' label='หมายเหตุ' >
            <Input.TextArea disabled={readOnly} placeholder='หมายเหตุ' autoSize={{ minRows: 1 }} />
          </AntdForm.Item>
        </AntdForm>
      </Card>
    </div>
  )
}

export default Form
