import dayjs from 'dayjs'

const dayjsToDB = (date) => {
  return (date) ? date.$d : null
}

const dbTodayjs = (date) => {
  return (date) ? dayjs(date.toDate()) : null
}

const dayjsToDB2 = (period) => {
  return (period?.length) ? period.map(o => o.$d) : null
}

const dbTodayjs2 = (period) => {
  return (period?.length) ? period.map(o => dayjs(o.toDate())) : null
}

const offsetMonth = (dayjsDate, offset) => {
  if (!(dayjsDate && offset)) {
    return null
  }

  return dayjsDate.add(offset, 'month')
}

export {
  dayjsToDB,
  dbTodayjs,
  dayjsToDB2,
  dbTodayjs2,
  offsetMonth
}