import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useAsync } from 'react-use'

import { Form as AntdForm, Card, Row, Col, Table, Modal, Select, Button, Tag, Space, Skeleton } from 'antd'
import { EyeOutlined, DeleteOutlined, CheckOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { search } from '../../utils/search'
import { formatNumber } from '../../utils/tools'

const List = () => {
  const navigate = useNavigate()
  const { db } = useAuth()
  const { getPageNo, onPageChange, getSearch, setSearch, getLenders } = useLayoutContext()

  const [initValue, setInitValue] = useState([])
  const [value, setValue] = useState([])
  const [qsVisible, setQsVisible] = useState(false)
  const [qs, setQs] = useState({})
  const [tags, setTags] = useState([])

  const [lenderObj, setLenderObj] = useState()

  const [searchForm] = AntdForm.useForm()

  const { loading } = useAsync(async () => {
    const lenders = await getLenders()
    const lenderData = lenders.reduce((acc, o) => {
      acc[o.id] = {
        ...o,
        loan: 0,
        principleremain: 0,
        interestaccum: 0
      }
      return acc
    }, {})
    setLenderObj(lenderData)

    const fundSnap = await db.collection('/funds').where('disabled', '==', false).get()
    fundSnap.docs.forEach(doc => {
      const d = doc.data()
      if (d?.lid && lenderData[d.lid]) {
        lenderData[d.lid].loan += (isNaN(d?.loan)) ? 0 : d.loan
        lenderData[d.lid].principleremain += (!d?.payments?.length) ? 0 : (isNaN(d.payments[d.payments.length - 1]?.principleremain)) ? 0 : d.payments[d.payments.length - 1].principleremain
        lenderData[d.lid].interestaccum += (!d?.payments?.length) ? 0 : (isNaN(d.payments[d.payments.length - 1]?.interestaccum)) ? 0 : d.payments[d.payments.length - 1].interestaccum
      }
    })

    const data = Object.keys(lenderData).map((o) => ({
      ...lenderData[o]
    }))

    const q = getSearch('creditors')
    setQs(q || {})
    setInitValue(data)
  }, [])

  useEffect(() => {
    const tagList = Object.keys(qs).reduce((acc, o) => {
      return (!qs[o]) ? acc : [...acc, {
        key: o,
        value: lenderObj[qs[o]]?.name,
        label: {
          id: 'ผู้ให้กู้'
        }[o]
      }]
    }, [])
    setTags(tagList)
    const data = search(initValue, qs)
    setValue(data)
  }, [initValue, qs, lenderObj])

  const handleClose = (key) => {
    const newQs = {
      ...qs,
      [key]: null
    }
    setQs(newQs)
    setSearch('creditors', newQs)
    searchForm.setFieldsValue(newQs)
  }

  const columns = [
    {
      title: 'ผู้ให้กู้',
      dataIndex: 'name',
      width: 150
    },
    {
      title: 'วงเงินกู้',
      dataIndex: 'loan',
      width: 120,
      render: data => formatNumber(data, 2)
    },
    {
      title: 'เงินต้นคงเหลือ',
      dataIndex: 'principleremain',
      width: 120,
      render: data => formatNumber(data, 2)
    },
    {
      title: 'ดอกเบี้ยคงค้าง',
      dataIndex: 'interestaccum',
      width: 120,
      render: data => formatNumber(data, 2)
    },
    {
      title: 'เบอร์ติดต่อ',
      dataIndex: 'tel',
      width: 120
    },
    {
      title: 'เครื่องมือ',
      dataIndex: 'Operation',
      width: 100,
      render: (_, row) => (
        <div>
          <Button
            type='link'
            style={{ marginRight: '8px' }}
            icon={<EyeOutlined style={{ fontSize: 18 }} />}
            onClick={() => {
              navigate(`/admin/creditors/${row.id}/view`)
            }}
          ></Button>
        </div>
      )
    }
  ]

  if (loading) return <Skeleton></Skeleton>
  return (
    <Card style={{ minHeight: '100%' }}>
      <Row>
        <Col flex='auto'>
          <div style={{ fontSize: 24 }}>
            ยอดผ่อนชำระเจ้าหนี้
          </div>
          <div style={{ marginTop: 12 }}>
            {tags.map((o) => (
              <Tag
                key={o.key}
                closable
                onClose={() => handleClose(o.key)}
              >
                {o.label}: {o.value}
              </Tag>
            ))}
          </div>
        </Col>
        <Col flex='300px'>
          <div style={{ float: 'right', margin: '0 0 60px 0', fontSize: 14 }}>
            <Button
              icon={<SearchOutlined />}
              style={{ margin: '0 8px' }}
              onClick={() => {
                setQsVisible(true)
              }}
            ></Button>
          </div>
        </Col>
      </Row >
      <Table
        bordered
        rowKey='id'
        style={{ overflowX: 'auto' }}
        columns={columns}
        dataSource={value}
        pagination={{
          total: value.length,
          current: getPageNo('creditors'),
          onChange(page) {
            onPageChange('creditors', page)
          }
        }}
      />
      <Modal
        forceRender
        open={qsVisible}
        title={
          <div style={{ padding: '16px 24px', fontWeight: 'bold', fontSize: '18px', lineHeight: '23px', backgroundColor: '#228B90', color: '#F6F6F6', borderRadius: '5px 5px 0px 0px' }}>
            ค้นหา
          </div>
        }
        width='50%'
        closable={false}
        footer={
          <Row justify='center'>
            <Space align='center'>
              <Col>
                <Button
                  type='secondary'
                  size='medium'
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    const empty = {
                      id: null
                    }
                    setQs(empty)
                    setSearch('creditors', empty)
                    searchForm.setFieldsValue(empty)
                  }}
                >
                  Clear Data
                </Button>
              </Col>
              <Col>
                <Button
                  size='medium'
                  style={{ color: '#FFF', backgroundColor: '#EE7930' }} icon={<CloseOutlined />}
                  onClick={() => {
                    setQsVisible(false)
                  }}
                >
                  ยกเลิก
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  size='medium'
                  icon={<CheckOutlined />}
                  onClick={() => {
                    const data = searchForm.getFieldsValue()
                    setQs(data)
                    setSearch('creditors', data)
                    setQsVisible(false)
                  }}
                >
                  ยืนยัน
                </Button>
              </Col>
            </Space>
          </Row>
        }
      >
        <AntdForm
          layout='vertical'
          initialValues={qs}
          style={{ margin: '8px 32px' }}
          form={searchForm}
          name='searchForm'
        >
          <Row gutter={[24, 24]}>
            <Col span={6}></Col>
            <Col span={12}>
              <AntdForm.Item name='id' label='ผู้ให้กู้' >
                <Select placeholder='เลือก' >
                  {((Object.keys(lenderObj)).map((o) => (
                    <Select.Option key={o} value={o}>{lenderObj[o].name}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
            <Col span={6}></Col>
          </Row>
        </AntdForm>
      </Modal>
    </Card >
  )
}

export default List
