const monthTh = ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.']

const MonthLongTh = {
  '1': 'มกราคม',
  '2': 'กุมภาพันธ์',
  '3': 'มีนาคม',
  '4': 'เมษายน',
  '5': 'พฤษภาคม',
  '6': 'มิถุนายน',
  '7': 'กรกฎาคม',
  '8': 'สิงหาคม',
  '9': 'กันยายน',
  '10': 'ตุลาคม',
  '11': 'พฤศจิกายน',
  '12': 'ธันวาคม'
}

export {
  monthTh,
  MonthLongTh
}
