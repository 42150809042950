import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Form as AntdForm, Row, Col, Card, Button, Input, Skeleton } from 'antd'
import { SaveOutlined, CloseOutlined } from '@ant-design/icons'

import TableTransfer from '../../../components/TableTransfer'

import { useAuth } from '../../../contexts/AuthContextProvider'
import { isAllowed } from '../../../route/roles'

const Form = () => {
  const navigate = useNavigate()
  const { firebaseApp, db, role } = useAuth()
  const { eid } = useParams()
  const [loading, setLoading] = useState(false)
  const [questions, setQuestions] = useState()
  const [targetQuestions, setTargetQuestions] = useState([])

  const readOnly = !isAllowed(role, 'edit', 'masters', 'evaluations')

  const { value } = useAsync(async () => {
    const questionsSnap = await db.collection('/questions').orderBy('createddate', 'asc').get()
    const questionsData = questionsSnap.docs.map(doc => {
      return {
        name: doc.data().name,
        key: doc.id,
        id: doc.id
      }
    })
    setQuestions(questionsData)

    if (!eid) {
      return {}
    }

    const snap = await db.collection('/evaluations').doc(eid).get()
    const data = snap.data()
    setTargetQuestions(data?.questions ?? [])
    return {
      ...data,
      id: snap.id
    }
  }, [eid])

  const handleSubmit = async (formData) => {
    try {
      setLoading(true)
      if (eid) {
        await db.collection('/evaluations').doc(eid).set({
          ...formData,
          questions: targetQuestions
        }, {
          merge: true
        })
      } else {
        await db.collection('/evaluations').add({
          ...formData,
          questions: targetQuestions,
          createddate: firebaseApp.firestore.FieldValue.serverTimestamp()
        })
      }
      setLoading(false)
      navigate('/admin/masters/evaluations')
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  const columns = [
    {
      title: 'คำถามประเมิน',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    }
  ]

  if (!value) return <Skeleton></Skeleton>
  return (
    <Card style={{ padding: 48, minHeight: '100%' }}>
      <div style={{ marginBottom: 16, fontSize: 24 }}>
        {(!eid) ? 'สร้าง' : ''}หัวข้อประเมิน
      </div>
      <AntdForm
        layout='vertical'
        onFinish={handleSubmit}
        initialValues={value}
      >
        <Row gutter={[32, 32]}>
          <Col span={12}>
            <AntdForm.Item name='name' label='ชื่อหัวข้อประเมิน' rules={[{ required: true, message: 'กรุณาใส่ชื่อหัวข้อประเมินด้วยค่ะ' }]}>
              <Input placeholder='ชื่อ' disabled={readOnly} />
            </AntdForm.Item>
          </Col>
          <Col span={12}></Col>
        </Row>
        <AntdForm.Item name='questions' label='เลือกคำถามประเมิน' rules={[{ required: true, message: 'กรุณาเลือกคำถามประเมินด้วยค่ะ' }]}>
          <TableTransfer
            titles={['คำถามประเมิน', 'เลือกแล้ว']}
            locale={{ itemUnit: 'คำถาม', itemsUnit: 'คำถาม' }}
            dataSource={questions}
            targetKeys={targetQuestions}
            showSearch={true}
            listStyle={{
              width: '100%',
              minHeight: 300,
            }}
            leftColumns={columns}
            rightColumns={columns}
            disabled={readOnly}
            onChange={(_, direction, moveKeys) => {
              let result = []
              if (direction === 'left') {
                result = targetQuestions.filter((o) => (moveKeys.indexOf(o) === -1))
              } else {
                result = [...targetQuestions, ...moveKeys]
              }
              setTargetQuestions(result)
            }}
            filterOption={(inputValue, item) => (
              item.name.indexOf(inputValue) !== -1
            )}
          />
        </AntdForm.Item>
        <div>
          {(!readOnly) && (
            <Button
              loading={loading}
              style={{ width: 168, height: 40, marginTop: 20, marginRight: 20 }}
              htmlType='submit'
              type='primary'
            ><SaveOutlined style={{ fontSize: 16 }} />บันทึก</Button>
          )}
          <Button
            loading={loading}
            style={{ height: 40, marginTop: 20 }}
            onClick={() => {
              navigate(-1)
            }}
          ><CloseOutlined />ปิด</Button>
        </div>
      </AntdForm>
    </Card>
  )
}

export default Form
