import React, { useState } from 'react'
import { useNavigate } from 'react-router'

import { Form as AntdForm, Row, Col, Card, Button, Input, Modal, message } from 'antd'
import { SaveOutlined, CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

import { useAuth } from '../../contexts/AuthContextProvider'
import { nameTitleObj } from '../../const/users'

const Form = () => {
  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()
  const { functions } = useAuth()
  const [loading, setLoading] = useState(false)

  const [studentForm] = AntdForm.useForm()

  const handleSubmit = async (formData) => {
    try {
      setLoading(true)
      Modal.confirm({
        title: `ยืนยันการเปลี่ยนรหัสนักเรียนจาก ${formData.pscn} เป็น ${formData.scn}`,
        content: <span style={{ color: '#EE7930' }}>**โปรดตรวจสอบความถูกต้อง**</span>,
        icon: <ExclamationCircleOutlined />,
        okText: 'ยืนยัน',
        cancelText: 'ยกเลิก',
        onOk() {
          handleChange(formData)
        },
        onCancel() { }
      })
      setLoading(false)
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  const handleChange = async (formData) => {
    try {
      const updateSCN = functions.httpsCallable('updateSCN-updateSCN')
      const { data } = await updateSCN({
        pscn: formData.pscn,
        scn: formData.scn
      })
      messageApi.open({
        type: 'success',
        content: `เปลี่ยนรหัสนักเรียนของ${nameTitleObj[data.title].nameTh}${data.firstname} ${data.lastname} เป็น ${data.scn} เรียบร้อย`,
      })
      studentForm.setFieldsValue({
        pscn: '',
        scn: ''
      })
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return (
    <Card style={{ padding: 48, minHeight: '100%' }}>
      <div style={{ marginBottom: 16, fontSize: 24 }}>
        เปลี่ยนรหัสนักเรียน
      </div>
      <AntdForm
        layout='vertical'
        onFinish={handleSubmit}
        initialValues={{}}
        form={studentForm}
        name='studentForm'
      >
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='pscn' label='รหัสนักเรียนเก่า' rules={[{ required: true, message: 'กรุณาใส่รหัสนักเรียนเก่าค่ะ' }]}>
              <Input placeholder='รหัสนักเรียนเก่า' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='scn' label='รหัสนักเรียนใหม่' rules={[{ required: true, message: 'กรุณาใส่รหัสนักเรียนเก่าค่ะ' }]}>
              <Input placeholder='รหัสนักเรียนใหม่' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <div>
          <Button
            loading={loading}
            style={{ width: 168, height: 40, marginTop: 20 }}
            htmlType='submit'
            type='primary'
          ><SaveOutlined style={{ fontSize: 16 }} />บันทึก</Button>
          <Button
            loading={loading}
            style={{ height: 40, marginTop: 20, marginLeft: 20 }}
            onClick={() => {
              navigate(-1)
            }}
          ><CloseOutlined />ปิด</Button>
        </div>
      </AntdForm>
      {contextHolder}
    </Card>
  )
}

export default Form
