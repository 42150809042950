import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Form as AntdForm, Row, Col, Card, Button, Input, Select, Skeleton } from 'antd'
import { SaveOutlined, CloseOutlined } from '@ant-design/icons'

import InputNumberNew from '../../components/InputNumberNew'

import { DatePicker } from '../../components/DateTime'
import { dayjsToDB, dbTodayjs } from '../../utils/date'
import TableTransfer from '../../components/TableTransfer'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
// import { levelObj } from '../../const/student'
import { pickBy } from '../../utils/tools'

const Form = () => {
  const navigate = useNavigate()
  const { db, functions } = useAuth()
  const { getCampuses, getSubjects } = useLayoutContext()
  const { cid } = useParams()
  const [loading, setLoading] = useState(false)
  const [campuses, setCampuses] = useState()
  const [teachers, setTeachers] = useState()
  const [students, setStudents] = useState()
  const [subjects, setSubjects] = useState()
  const [targetStudents, setTargetStudents] = useState([])
  const [assessments, setAssessments] = useState()

  const { value } = useAsync(async () => {
    const campusData = await getCampuses()
    setCampuses(campusData)

    const teacherSnap = await db.collection('/users').where('role', 'in', ['headteacher', 'teacher', 'headadmin', 'admin']).where('disabled', '==', false).where('emailVerified', '==', true).get()
    const teacherData = teacherSnap.docs.map(doc => {
      const data = doc.data()
      return {
        id: doc.id,
        key: doc.id,
        name: `${data.firstname} ${data.lastname}`
      }
    })
    setTeachers(teacherData)

    const studentSnap = await db.collection('/users').where('role', '==', 'student').where('disabled', '==', false).where('status', '==', 'learning').get()
    const studentData = studentSnap.docs.map(doc => {
      const data = doc.data()
      return {
        ...data,
        id: doc.id,
        key: doc.id,
        scn: data?.scn ?? ''
      }
    })
    setStudents(studentData)

    const subjectData = await getSubjects()
    setSubjects(subjectData)

    const assessmentSnap = await db.collection('/assessments').orderBy('createddate', 'asc').get()
    const assessmentData = assessmentSnap.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    }))
    setAssessments(assessmentData)

    if (!cid) {
      return {}
    }

    const scSnap = await db.collection('/studentclasses').where('cid', '==', cid).where('disabled', '==', false).get()
    const sc = scSnap.docs.map(doc => doc.data().uid)
    setTargetStudents(sc)

    const snap = await db.collection('/classes').doc(cid).get()
    const data = snap.data()
    return {
      ...data,
      id: snap.id,
      startdate: dbTodayjs(data?.startdate),
      enddate: dbTodayjs(data?.enddate)
    }
  }, [cid])

  const handleSubmit = async (formData) => {
    try {
      setLoading(true)
      const data = pickBy({
        ...formData,
        startdate: dayjsToDB(formData?.startdate),
        enddate: dayjsToDB(formData?.enddate)
      })
      if (cid) {
        const updateClassStudent = functions.httpsCallable('updateClassStudent-updateClassStudent')
        await updateClassStudent({
          id: cid,
          eid: value.eid,
          data: data,
          students: data.students
        })
      } else {
        const createClassStudent = functions.httpsCallable('createClassStudent-createClassStudent')
        await createClassStudent({
          data: data,
          students: data.students
        })
      }
      setLoading(false)
      if (cid) {
        navigate(`/admin/classes/${cid}/view`)
      } else {
        navigate('/admin/classes')
      }
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  const columns = [
    {
      title: 'รหัสนักเรียน',
      dataIndex: 'scn',
      width: 110
    },
    {
      title: 'ชื่อเล่น',
      dataIndex: 'nickname'
    },
    {
      title: 'ชื่อ',
      dataIndex: 'firstname',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.firstname.toLowerCase().localeCompare(b.firstname.toLowerCase())
    },
    {
      title: 'นามสกุล',
      dataIndex: 'lastname'
    }
  ]

  if (!value) return <Skeleton></Skeleton>
  return (
    <Card style={{ padding: 48, minHeight: '100%' }}>
      <div style={{ marginBottom: 16, fontSize: 24 }}>
        {(!cid) ? 'สร้าง' : ''}ห้องเรียน
      </div>
      <AntdForm
        layout='vertical'
        onFinish={handleSubmit}
        initialValues={value}
      >
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='name' label='รายวิชา' rules={[{ required: true, message: 'กรุณาใส่รายวิชาค่ะ' }]}>
              <Input placeholder='รายวิชา' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='subject' label='หมวดวิชา' rules={[{ required: true, message: 'กรุณาเลือกหมวดวิชาค่ะ' }]}>
              <Select
                placeholder='เลือก'
                filterSort={(optionA, optionB) =>
                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
              >
                {((subjects).map((o) => (
                  <Select.Option key={o.id} value={o.id}>{o.name}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='campusid' label='วิทยาเขต' >
              <Select
                placeholder='เลือก'
                filterSort={(optionA, optionB) =>
                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
              >
                {((campuses).map((o) => (
                  <Select.Option key={o.id} value={o.id}>{o.name}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='startdate' label='วันเริ่มต้นรายวิชา' >
              <DatePicker placeholder='เลือก' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='enddate' label='วันสิ้นสุดรายวิชา' >
              <DatePicker placeholder='เลือก' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='hrs' label='จำนวนชั่วโมง' >
              <InputNumberNew placeholder='จำนวนชั่วโมง' />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='teachers' label='ครูผู้สอน' rules={[{ required: true, message: 'กรุณาเลือกครูผู้สอนค่ะ' }]}>
              <Select
                placeholder='เลือก'
                mode='multiple'
                allowClear
                filterOption={(inputValue, option) =>
                  option.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                }
                filterSort={(optionA, optionB) =>
                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
              >
                {((teachers).map((o) => (
                  <Select.Option key={o.id} value={o.id}>{o.name}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='aid' label='แบบประเมิน' rules={[{ required: true, message: 'กรุณาเลือกแบบประเมินค่ะ' }]}>
              <Select
                showSearch
                placeholder='เลือก'
                disabled={!!value?.aid}
                optionFilterProp='children'
                filterOption={(inputValue, option) =>
                  option.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                }
                filterSort={(optionA, optionB) =>
                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
              >
                {((assessments).map((o) => (
                  <Select.Option key={o.id} value={o.id}>{o.name}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <div style={{ marginBottom: 16, fontSize: 24 }}>
          เพิ่มนักเรียน
        </div>
        <AntdForm.Item name='students' >
          <TableTransfer
            titles={['รายชื่อนักเรียน', 'เลือกแล้ว']}
            locale={{ itemUnit: 'คน', itemsUnit: 'คน' }}
            dataSource={students}
            targetKeys={targetStudents}
            showSearch={true}
            listStyle={{
              width: '100%',
              minHeight: 300,
            }}
            leftColumns={columns}
            rightColumns={columns}
            onChange={(v) => {
              setTargetStudents(v)
            }}
            filterOption={(inputValue, item) => (
              item.scn.indexOf(inputValue) !== -1 ||
              ((item.nickname || '').toLowerCase()).indexOf(inputValue.toLowerCase()) !== -1 ||
              ((item.firstname || '').toLowerCase()).indexOf(inputValue.toLowerCase()) !== -1 ||
              ((item.lastname || '').toLowerCase()).indexOf(inputValue.toLowerCase()) !== -1
            )}
          />
        </AntdForm.Item>
        <div>
          <Button
            loading={loading}
            style={{ width: 168, height: 40, marginTop: 20 }}
            htmlType='submit'
            type='primary'
          ><SaveOutlined style={{ fontSize: 16 }} />บันทึก</Button>
          <Button
            loading={loading}
            style={{ height: 40, marginTop: 20, marginLeft: 20 }}
            onClick={() => {
              navigate(-1)
            }}
          ><CloseOutlined />ปิด</Button>
        </div>
      </AntdForm>
    </Card>
  )
}

export default Form
