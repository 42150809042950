import dayjs from 'dayjs'
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'
import generatePicker from 'antd/lib/date-picker/generatePicker'
import 'antd/lib/date-picker/style/index'

// const originFormat = dayjsGenerateConfig.locale.format
// Object.assign(dayjsGenerateConfig.locale, {
//   format: (local, date, format) => {
//     if (/YYYY/.test(format)) {
//       const f = format.replace(/YYYY/g, 'BBBB')
//       return originFormat(local, date, f)
//     }
//     return originFormat(local, date, format)
//   }
// })

Object.assign(dayjsGenerateConfig, {
  getYear: (value) => dayjs(value).year() + 543,
  setYear: (value, year) => dayjs(value).set('year', year - 543)
})

const locale = {
  lang: {
    locale: 'th_TH',
    placeholder: 'Select date',
    rangePlaceholder: ['Start date', 'End date'],
    today: 'Today',
    now: 'Now',
    backToToday: 'Back to today',
    ok: 'OK',
    clear: 'Clear',
    month: 'Month',
    year: 'Year',
    timeSelect: 'Select time',
    dateSelect: 'Select date',
    monthSelect: 'Choose a month',
    yearSelect: 'Choose a year',
    decadeSelect: 'Choose a decade',
    yearFormat: 'BBBB',
    dateFormat: 'DD/MM/BBBB',
    dayFormat: 'D',
    dateTimeFormat: 'DD/MM/BBBB HH:mm:ss',
    weekFormat: 'wo-BBBB',
    monthFormat: 'MMMM',
    monthBeforeYear: true,
    previousMonth: 'Previous month (PageUp)',
    nextMonth: 'Next month (PageDown)',
    previousYear: 'Last year (Control + left)',
    nextYear: 'Next year (Control + right)',
    previousDecade: 'Last decade',
    nextDecade: 'Next decade',
    previousCentury: 'Last century',
    nextCentury: 'Next century'
  },
  timePickerLocale: {
    placeholder: 'Select time'
  },
  dateFormat: 'DD/MM/BBBB',
  dateTimeFormat: 'DD/MM/BBBB HH:mm:ss',
  dayFormat: 'D',
  weekFormat: 'wo-BBBB',
  monthFormat: 'MMMM',
  yearFormat: 'BBBB'
}

const DatePicker = generatePicker(dayjsGenerateConfig)

DatePicker.defaultProps = {
  format: 'DD/MM/BBBB',
  locale: locale
}

DatePicker.RangePicker.defaultProps = {
  format: 'DD/MM/BBBB',
  locale: locale
}

export default DatePicker
