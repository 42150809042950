import React, { useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Form as AntdForm, Row, Col, Card, Button, Input, Select, Upload, Skeleton } from 'antd'
import { SaveOutlined, CloseOutlined, UploadOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons'

import { DatePicker } from '../../components/DateTime'
import { dayjsToDB, dbTodayjs } from '../../utils/date'

import { useAuth } from '../../contexts/AuthContextProvider'
import { behaviorLevelObj } from '../../const/classes'
import { pickBy } from '../../utils/tools'

const Form = () => {
  const navigate = useNavigate()
  const { db, storage, role, userId } = useAuth()
  const { id, bid } = useParams()
  const { pathname } = useLocation()

  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState([])
  const [student, setStudent] = useState()

  const uid = (role === 'student') ? userId : id
  const readOnly = (pathname.indexOf('/view') !== -1)

  const { value } = useAsync(async () => {
    const studentSnap = await db.collection('/users').doc(uid).get()
    const studentData = studentSnap.data()
    setStudent({
      scn: studentData?.scn,
      name: `${studentData?.firstname ?? ''} ${studentData?.lastname ?? ''}`,
      nickname: studentData?.nickname ?? '',
    })

    if (!bid) {
      return {
        behaviors: [{}]
      }
    }

    const snap = await db.collection('/behaviors').doc(bid).get()
    const data = snap.data()
    setFiles(data.files)
    return {
      ...data,
      id: snap.id,
      behaviors: (!data) ? [{}] : data.behaviors.map((o) => ({
        ...o,
        date: dbTodayjs(o?.date)
      }))
    }
  }, [bid])

  const handleSubmit = async (formData) => {
    try {
      setLoading(true)
      formData = {
        ...formData,
        behaviors: formData.behaviors.reduce((acc, o) => {
          return (!o?.behavior) ? acc : [...acc, {
            ...o,
            date: dayjsToDB(o?.date)
          }]
        }, []),
        uid: uid,
        file: null,
        files: files
      }
      const data = pickBy(formData)
      if (bid) {
        await db.collection('/behaviors').doc(bid).set(data, {
          merge: true
        })
      } else {
        await db.collection('/behaviors').add(data)
      }
      setLoading(false)
      navigate(`/admin/students/${uid}/behaviors`)
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  const customRequestFile = async ({ onError, _, file }) => {
    try {
      const ref = storage.ref('behaviors').child(`${file.name}-${file.uid}`)
      await ref.put(file, {
        contentType: file.type
      })

      const url = await ref.getDownloadURL()

      const data = {
        uid: file.uid,
        name: file.name,
        type: file.type,
        lastModified: file.lastModified,
        url: url
      }

      setFiles([...files, data])
    } catch (e) {
      onError(e)
    }
  }

  if (!value) return <Skeleton></Skeleton>
  return (
    <div>
      <Card
        style={{ marginTop: 16 }}
        bodyStyle={{ padding: '32px 80px' }}
      >
        <div style={{ margin: '16px 0', fontSize: 24 }}>
          ข้อมูลนักเรียน
        </div>
        <Row style={{ marginBottom: 24 }}>
          <Col span={3} className='aiu-title'>รหัสนักเรียน</Col>
          <Col span={5} className='aiu-content'>{student?.scn ?? ''}</Col>
          <Col span={3} className='aiu-title'>ชื่อ - สกุล</Col>
          <Col span={5} className='aiu-content'>{student.name}</Col>
          <Col span={3} className='aiu-title'>ชื่อเล่น</Col>
          <Col span={5} className='aiu-content'>{student.nickname}</Col>
        </Row>
        <div style={{ margin: '16px 0', fontSize: 24 }}>
          สร้างรายงานพฤติกรรม
        </div>
        <AntdForm
          layout='vertical'
          onFinish={handleSubmit}
          initialValues={value}
        >
          <AntdForm.List
            name='behaviors'
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <div key={`behavior-${index}`}>
                    <Row gutter={[24, 32]}>
                      <Col span={8}>
                        <AntdForm.Item name={[field.name, 'date']} label='วันที่สร้างรายงาน' >
                          <DatePicker placeholder='เลือก' disabled={readOnly} />
                        </AntdForm.Item>
                      </Col>
                      <Col span={8}>
                        <AntdForm.Item name={[field.name, 'from']} label='ผู้รายงานพฤติกรรม' >
                          <Input placeholder='ผู้รายงานพฤติกรรม' disabled={readOnly} />
                        </AntdForm.Item>
                      </Col>
                      <Col span={8}>
                        <AntdForm.Item name={[field.name, 'lev']} label='ระดับความผิด' >
                          <Select placeholder='เลือก' disabled={readOnly} >
                            {((Object.keys(behaviorLevelObj)).map((o) => (
                              <Select.Option key={o} value={o}>{behaviorLevelObj[o].name}</Select.Option>
                            )))}
                          </Select>
                        </AntdForm.Item>
                      </Col>
                    </Row>
                    <AntdForm.Item name={[field.name, 'behavior']} label='ความประพฤติ' >
                      <Input.TextArea placeholder='ความประพฤติ' autoSize={{ minRows: 1 }} disabled={readOnly} />
                    </AntdForm.Item>
                    <AntdForm.Item name={[field.name, 'remark']} label='หมายเหตุ' >
                      <Input.TextArea placeholder='หมายเหตุ' autoSize={{ minRows: 1 }} disabled={readOnly} />
                    </AntdForm.Item>
                    {(!readOnly) && (
                      <Row gutter={[24, 32]}>
                        <Col span={16}></Col>
                        <Col span={8}>
                          {(index === fields.length - 1) ? (
                            <Button type='primary' onClick={() => add()} icon={<PlusOutlined />} style={{ marginBottom: 8, float: 'right' }}>
                              เพิ่มรายงานพฤติกรรม
                            </Button>
                          ) : (
                            <Button onClick={() => remove(field.name)} icon={<DeleteOutlined />} style={{ marginBottom: 8, float: 'right', color: '#FFF', backgroundColor: '#EE7930', border: '1px solid #EE7930' }}>
                              ลบ
                            </Button>
                          )}
                        </Col>
                      </Row>
                    )}
                  </div>
                ))}
              </>
            )}
          </AntdForm.List>
          <Row gutter={[24, 32]}>
            <Col span={8}>
              <AntdForm.Item name='file' label='อัปโหลด' >
                <Upload
                  disabled={readOnly}
                  accept='.webp, .png, .jpg, .jpeg, .xls, .xlsx, .pdf'
                  fileList={files}
                  customRequest={customRequestFile}
                  onRemove={(file) => {
                    const remainFiles = files.filter((o) => o.uid !== file.uid)
                    setFiles(remainFiles)
                  }}
                >
                  <Button icon={<UploadOutlined />}>อัปโหลด</Button>
                </Upload>
              </AntdForm.Item>
            </Col>
            <Col span={16}></Col>
          </Row>
          {(!readOnly) && (
            <div>
              <Button
                loading={loading}
                style={{ width: 168, height: 40, marginTop: 20 }}
                htmlType='submit'
                type='primary'
              ><SaveOutlined style={{ fontSize: 16 }} />บันทึก</Button>
              <Button
                loading={loading}
                style={{ height: 40, marginTop: 20, marginLeft: 20 }}
                onClick={() => {
                  navigate(-1)
                }}
              ><CloseOutlined />ปิด</Button>
            </div>
          )}
        </AntdForm>
      </Card>
    </div>
  )
}

export default Form
