import React from 'react'

import { InputNumber } from 'antd'

const InputNumberNew = ({ ...restProps }) => (
  <InputNumber
    {...restProps}
    formatter={(v) => new Intl.NumberFormat('en-US').format(v)}
    parser={(v) => v.replace(/\\s?|(,*)/g, '')}
  />
)

export default InputNumberNew