import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Form as AntdForm, Card, Row, Col, Table, Modal, Input, Select, Button, Popconfirm, Tag, Space, Skeleton } from 'antd'
import { PlusOutlined, SearchOutlined, PrinterOutlined, EyeOutlined, DeleteOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { genCsv } from '../../utils/csv'
import { search } from '../../utils/search'
import { statusObj } from '../../const/complain'
import { isAllowed } from '../../route/roles'

const List = () => {
  const navigate = useNavigate()
  const { db, role } = useAuth()
  const { getPageNo, onPageChange, getSearch, setSearch, getCoops, getCompanies } = useLayoutContext()

  const [updateCount, setUpdateCount] = useState(0)
  const [initValue, setInitValue] = useState([])
  const [value, setValue] = useState([])
  const [qsVisible, setQsVisible] = useState(false)
  const [qs, setQs] = useState({})
  const [tags, setTags] = useState([])

  const [coopObj, setCoopObj] = useState()
  const [companyObj, setCompanyObj] = useState()

  const [searchForm] = AntdForm.useForm()

  const { loading } = useAsync(async () => {
    const coops = await getCoops()
    const coopData = coops.reduce((acc, o) => {
      acc[o.id] = o
      return acc
    }, {})
    setCoopObj(coopData)

    const companies = await getCompanies()
    const companyData = companies.reduce((acc, o) => {
      acc[o.id] = o
      return acc
    }, {})
    setCompanyObj(companyData)

    const snap = await db.collection('/complains').get()
    const data = snap.docs.reduce((acc1, doc, i) => {
      const d = doc.data()
      return [...acc1, ...d.complains.reduce((acc2, p, j) => {
        return [...acc2, {
          ...d,
          ...p,
          id: doc.id,
          n: `${i}${j}`
        }]
      }, [])]
    }, [])

    const q = getSearch('complains')
    setQs(q || {})
    setInitValue(data)
  }, [updateCount])

  useEffect(() => {
    const tagList = Object.keys(qs).reduce((acc, o) => {
      return (!qs[o]) ? acc : [...acc, {
        key: o,
        value: (o === 'companyid') ? companyObj[qs[o]]?.name : (o === 'coopid') ? coopObj[qs[o]]?.name : (o === 'status') ? statusObj[qs[o]]?.name : qs[o],
        label: {
          firstname: 'ชื่อ',
          lastname: 'นามสกุล',
          companyid: 'บริษัท',
          coopid: 'สหกรณ์',
          createby: 'สร้างโดย',
          status: 'สถานะ'
        }[o]
      }]
    }, [])
    setTags(tagList)
    const data = search(initValue, qs)
    setValue(data)
  }, [initValue, qs, companyObj, coopObj])

  const handleClose = (key) => {
    const newQs = {
      ...qs,
      [key]: null
    }
    setQs(newQs)
    setSearch('complains', newQs)
    searchForm.setFieldsValue(newQs)
  }

  const handleDelete = async (row) => {
    try {
      await db.collection('/complains').doc(row.id).delete()
      setUpdateCount(updateCount + 1)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const download = async (data) => {
    const list = data.map((o) => ({
      'ชื่อ': o?.firstname ?? '',
      'นามสกุล': o?.lastname ?? '',
      'สหกรณ์': coopObj[o?.coopid]?.name ?? '',
      'บริษัท': companyObj[o?.companyid]?.name ?? '',
      'ผู้สร้าง': o?.createby ?? '',
      'สถานะ': statusObj[o?.status]?.name ?? ''
    }))
    try {
      await genCsv('complain_list', list)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const columns = [
    {
      title: 'ชื่อ',
      dataIndex: 'firstname',
      width: 150
    },
    {
      title: 'นามสกุล',
      dataIndex: 'lastname',
      width: 150
    },
    {
      title: 'สหกรณ์',
      dataIndex: 'coopid',
      width: 150,
      render: data => coopObj[data]?.name ?? ''
    },
    {
      title: 'บริษัท',
      dataIndex: 'companyid',
      width: 150,
      render: data => companyObj[data]?.name ?? ''
    },
    {
      title: 'ผู้สร้าง',
      dataIndex: 'createby',
      width: 150
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      width: 130,
      render: data => (
        <span style={{ color: statusObj[data]?.color ?? '' }}>{statusObj[data]?.name ?? ''}</span>
      )
    },
    {
      title: 'เครื่องมือ',
      dataIndex: 'Operation',
      width: 175,
      render: (_, row) => (
        <div>
          <Button
            type='link'
            style={{ marginRight: '8px' }}
            icon={<EyeOutlined style={{ fontSize: 18 }} />}
            onClick={() => {
              const path = (isAllowed(role, 'edit', 'complains')) ? `/admin/complains/${row.id}/edit` : `/admin/complains/${row.id}/view`
              navigate(path)
            }}
          ></Button>
          {(isAllowed(role, 'delete', 'complains')) && (
            <Popconfirm
              title='Are you sure to delete this complain?'
              onConfirm={() => handleDelete(row)}
              okText='Yes'
              cancelText='No'
            >
              <Button
                type='link'
                style={{ marginRight: '8px' }}
                danger
                icon={<DeleteOutlined style={{ fontSize: 18 }} />}
              ></Button>
            </Popconfirm>
          )}
        </div>
      )
    }
  ]

  if (loading) return <Skeleton></Skeleton>
  return (
    <Card style={{ minHeight: '100%' }}>
      <Row>
        <Col flex='auto'>
          <div style={{ fontSize: 24 }}>
            หน้ารับร้องเรียน
          </div>
          <div style={{ marginTop: 12 }}>
            {tags.map((o) => (
              <Tag
                key={o.key}
                closable
                onClose={() => handleClose(o.key)}
              >
                {o.label}: {o.value}
              </Tag>
            ))}
          </div>
        </Col>
        <Col flex='300px'>
          <div style={{ float: 'right', fontSize: 14 }}>
            {(isAllowed(role, 'create', 'complains')) && (
              <div>
                <Button
                  type='primary'
                  icon={<PlusOutlined style={{ fontSize: 14 }} />}
                  onClick={() => {
                    navigate('/admin/complains/create')
                  }}
                >
                  เพิ่มปัญหา
                </Button>
              </div>
            )}
            <div style={{ float: 'right', margin: '8px 0 16px 0' }}>
              <Button
                icon={<SearchOutlined />}
                style={{ margin: '0 8px' }}
                onClick={() => {
                  setQsVisible(true)
                }}
              ></Button>
              <Button
                icon={<PrinterOutlined />}
                onClick={() => {
                  download(value)
                }}
              ></Button>
            </div>
          </div>
        </Col>
      </Row >
      <Table
        bordered
        rowKey='n'
        style={{ overflowX: 'auto' }}
        columns={columns}
        dataSource={value}
        pagination={{
          total: value.length,
          current: getPageNo('complains'),
          onChange(page) {
            onPageChange('complains', page)
          }
        }}
      />
      <Modal
        forceRender
        open={qsVisible}
        title={
          <div style={{ padding: '16px 24px', fontWeight: 'bold', fontSize: '18px', lineHeight: '23px', backgroundColor: '#228B90', color: '#F6F6F6', borderRadius: '5px 5px 0px 0px' }}>
            ค้นหา
          </div>
        }
        width='50%'
        closable={false}
        footer={
          <Row justify='center'>
            <Space align='center'>
              <Col>
                <Button
                  type='secondary'
                  size='medium'
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    const empty = {
                      firstname: null,
                      lastname: null,
                      companyid: null,
                      coopid: null,
                      createby: null,
                      status: null
                    }
                    setQs(empty)
                    setSearch('complains', empty)
                    searchForm.setFieldsValue(empty)
                  }}
                >
                  Clear Data
                </Button>
              </Col>
              <Col>
                <Button
                  size='medium'
                  style={{ color: '#FFF', backgroundColor: '#EE7930' }} icon={<CloseOutlined />}
                  onClick={() => {
                    setQsVisible(false)
                  }}
                >
                  ยกเลิก
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  size='medium'
                  icon={<CheckOutlined />}
                  onClick={() => {
                    const data = searchForm.getFieldsValue()
                    setQs(data)
                    setSearch('complains', data)
                    setQsVisible(false)
                  }}
                >
                  ยืนยัน
                </Button>
              </Col>
            </Space>
          </Row>
        }
      >
        <AntdForm
          layout='vertical'
          initialValues={qs}
          style={{ margin: '8px 32px' }}
          form={searchForm}
          name='searchForm'
        >
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <AntdForm.Item name='firstname' label='ชื่อ' >
                <Input placeholder='ชื่อ' />
              </AntdForm.Item>
            </Col>
            <Col span={12}>
              <AntdForm.Item name='lastname' label='นามสกุล' >
                <Input placeholder='นามสกุล' />
              </AntdForm.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <AntdForm.Item name='companyid' label='บริษัท' >
                <Select placeholder='เลือก' >
                  {((Object.keys(companyObj)).map((o) => (
                    <Select.Option key={o} value={o}>{companyObj[o].name}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
            <Col span={12}>
              <AntdForm.Item name='coopid' label='สหกรณ์' >
                <Select placeholder='เลือก' >
                  {((Object.keys(coopObj)).map((o) => (
                    <Select.Option key={o} value={o}>{coopObj[o].name}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <AntdForm.Item name='createby' label='ผู้สร้าง' >
                <Input placeholder='ผู้สร้าง' />
              </AntdForm.Item>
            </Col>
            <Col span={12}>
              <AntdForm.Item name='status' label='สถานะ' >
                <Select placeholder='เลือก' >
                  {((Object.keys(statusObj)).map((o) => (
                    <Select.Option key={o} value={o}>{statusObj[o].name}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
          </Row>
        </AntdForm>
      </Modal>
    </Card >
  )
}

export default List
