import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Row, Col, Card, Button, Input, Select, Modal, Table, Popconfirm, Skeleton } from 'antd'
import { PlusOutlined, EyeOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'
import { dbTodayjs, dbTodayjs2 } from '../../utils/date'

import { useAuth } from '../../contexts/AuthContextProvider'
import { formatNumber, pickBy } from '../../utils/tools'
import { statusObj } from '../../const/installment'
import { courseObj } from '../../const/student'
import { isAllowed } from '../../route/roles'

import BillPdf from './Components/BillPdf'

const Form = () => {
  const navigate = useNavigate()
  const { db, functions, role } = useAuth()
  const { iid } = useParams()

  const [updateCount, setUpdateCount] = useState(0)
  const [status, setStatus] = useState()

  const { value } = useAsync(async () => {
    const userSnap = await db.collection('/users').doc(iid).get()
    const userData = userSnap.data()
    setStatus(userData?.installment)

    if (!iid) {
      return {}
    }

    const paymentSnap = await db.collection('/installmentpayments').where('iid', '==', iid).get()
    let payments = paymentSnap.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
      date: dbTodayjs(doc.data()?.date),
      index: doc.data()?.date?.seconds
    }))
    payments = payments.sort((x, y) => x.index - y.index)

    const snap = await db.collection('/installments').doc(iid).get()
    const data = snap.data()
    return {
      ...data,
      id: snap.id,
      scn: data?.scn ?? '',
      startdate: dbTodayjs(data?.startdate),
      resigndate: dbTodayjs(data?.resigndate),
      installmentp: dbTodayjs2(data?.installmentp),
      payments: payments
    }
  }, [iid, updateCount])

  const handleChange = async (value, text) => {
    try {
      let data = {
        id: iid,
        installment: value,
        forgivetext: text
      }
      data = pickBy(data)
      const updateInstallment = functions.httpsCallable('updateInstallment-updateInstallment')
      await updateInstallment(data)
      setStatus(value)
      setUpdateCount(updateCount + 1)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const handleDelete = async (row) => {
    try {
      await db.collection('/installmentpayments').doc(row.id).delete()
      setUpdateCount(updateCount + 1)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const columns = [
    {
      title: 'วันที่ชำระ',
      dataIndex: 'date',
      width: 120,
      align: 'center',
      render: data => (
        <span>{(data) ? dayjs(data).format('DD/MM/BBBB') : ''}</span>
      )
    },
    {
      title: 'จำนวนที่ชำระ',
      dataIndex: 'amount',
      width: 120,
      align: 'center',
      render: data => formatNumber(data, 2)
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'remark',
      width: 200
    },
    {
      title: 'เครื่องมือ',
      dataIndex: 'Operation',
      width: 150,
      render: (_, row) => (
        <div>
          {(isAllowed(role, 'edit', 'installments')) && (
            <Button
              type='link'
              style={{ marginRight: 8 }}
              icon={<EyeOutlined style={{ fontSize: 18 }} />}
              onClick={() => {
                navigate(`/admin/installments/${iid}/payment/${row.id}/edit`)
              }}
            ></Button>
          )}
          <BillPdf uid={iid} value={value} row={row} isRow={true} />
          {(isAllowed(role, 'delete', 'installments')) && (
            <Popconfirm
              title='Are you sure to delete this payment?'
              onConfirm={() => handleDelete(row)}
              okText='Yes'
              cancelText='No'
            >
              <Button
                type='link'
                style={{ marginRight: 8 }}
                danger
                icon={<DeleteOutlined style={{ fontSize: 18 }} />}
              ></Button>
            </Popconfirm>
          )}
        </div>
      )
    }
  ]

  if (!value) return <Skeleton></Skeleton>
  return (
    <div>
      <Card
        style={{ marginTop: 16 }}
        bodyStyle={{ padding: '32px 80px' }}
      >
        <Row style={{ marginBottom: 24 }}>
          <Col flex='auto'>
            <div style={{ fontSize: 24 }}>
              รายละเอียดการผ่อนชำระ
            </div>
          </Col>
          <Col flex='300px'>
            <div style={{ float: 'right', fontSize: 14 }}>
              <BillPdf uid={iid} value={value} isText={true} />
              {(isAllowed(role, 'edit', 'installments')) && (
                <Button
                  style={{ color: '#FFFFFF', backgroundColor: '#EE7930', marginLeft: 8 }}
                  icon={<EditOutlined style={{ fontSize: 14 }} />}
                  onClick={() => {
                    navigate(`/admin/installments/${iid}/edit`)
                  }}
                >
                  แก้ไข
                </Button>
              )}
              {/* <Button
                type='primary'
                icon={<DownloadOutlined style={{ fontSize: 14 }} />}
                onClick={() => download(value)}
              >
                ดาวน์โหลด
              </Button> */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={5} className='aiu-title'>หลักสูตร</Col>
          <Col span={19} className='aiu-content'>{courseObj[value?.course]?.name ?? ''}</Col>
        </Row>
        <Row>
          <Col span={5} className='aiu-title'>รหัสนักเรียน</Col>
          <Col span={7} className='aiu-content'>{value?.scn ?? ''}</Col>
          <Col span={5} className='aiu-title'>ชื่อ-สกุล</Col>
          <Col span={7} className='aiu-content'>{`${value?.firstname ?? ''} ${value?.lastname ?? ''}`}</Col>
        </Row>
        <Row>
          <Col span={5} className='aiu-title'>วันที่เข้าเรียน</Col>
          <Col span={7} className='aiu-content'>{(value.startdate) ? dayjs(value.startdate).format('DD/MM/BBBB') : ''}</Col>
          <Col span={5} className='aiu-title'>วันที่ลาออก</Col>
          <Col span={7} className='aiu-content'>{(value.resigndate) ? dayjs(value.resigndate).format('DD/MM/BBBB') : ''}</Col>
        </Row>
        <Row>
          <Col span={5} className='aiu-title'>จำนวนวันที่เรียน</Col>
          <Col span={19} className='aiu-content'>{(!value.days) ? '' : `${formatNumber(value.days)} วัน`}</Col>
        </Row>
        <Row>
          <Col span={5} className='aiu-title'>จำนวนชั่วโมงเรียน</Col>
          <Col span={7} className='aiu-content'>{(!value?.hrs) ? '' : `${formatNumber(value.hrs)} ชั่วโมง`}</Col>
          <Col span={5} className='aiu-title'>ค่าเรียนต่อชั่วโมง</Col>
          <Col span={7} className='aiu-content'>{(!value?.tuition) ? '' : `${formatNumber(value.tuition, 2)} บาท`}</Col>
        </Row>
        <Row>
          <Col span={5} className='aiu-title'>หมายเหตุ</Col>
          <Col span={19} className='aiu-content'>{value?.remarkfee ?? ''}</Col>
        </Row>
        <Row>
          <Col span={5} className='aiu-title'>ค่าลงทะเบียนเรียน</Col>
          <Col span={7} className='aiu-content'>{(!value?.fee) ? '' : `${formatNumber(value.fee, 2)} บาท`}</Col>
          <Col span={5} className='aiu-title'>ค่าหลักสูตรวิชาชีพ</Col>
          <Col span={7} className='aiu-content'>{(!value?.coursefee) ? '' : `${formatNumber(value.coursefee, 2)} บาท`}</Col>
        </Row>
        <Row>
          <Col span={5} className='aiu-title'>เงินกู้ระหว่างเรียน</Col>
          <Col span={19} className='aiu-content'>{(!value?.loan) ? '' : `${formatNumber(value.loan, 2)} บาท`}</Col>
        </Row>
        <Row>
          <Col span={5} className='aiu-title'>ค่าหอพัก</Col>
          <Col span={7} className='aiu-content'>{(!value?.dorm) ? '' : `${formatNumber(value.dorm, 2)} บาท`}</Col>
          <Col span={5} className='aiu-title'>ค่าหอพัก (ราคารวม)</Col>
          <Col span={7} className='aiu-content'>{(!value?.dormtotal) ? '' : `${formatNumber(value.dormtotal, 2)} บาท`}</Col>
        </Row>
        <Row>
          <Col span={5} className='aiu-title'>ค่าน้ำ</Col>
          <Col span={7} className='aiu-content'>{(!value?.water) ? '' : `${formatNumber(value.water, 2)} บาท`}</Col>
          <Col span={5} className='aiu-title'>ค่าน้ำ (ราคารวม)</Col>
          <Col span={7} className='aiu-content'>{(!value?.watertotal) ? '' : `${formatNumber(value.watertotal, 2)} บาท`}</Col>
        </Row>
        <Row>
          <Col span={5} className='aiu-title'>ค่าไฟ</Col>
          <Col span={7} className='aiu-content'>{(!value?.elec) ? '' : `${formatNumber(value.elec, 2)} บาท`}</Col>
          <Col span={5} className='aiu-title'>ค่าไฟ (ราคารวม)</Col>
          <Col span={7} className='aiu-content'>{(!value?.electotal) ? '' : `${formatNumber(value.electotal, 2)} บาท`}</Col>
        </Row>
        <Row>
          <Col span={5} className='aiu-title'>ค่าเสื้อนักเรียน</Col>
          <Col span={7} className='aiu-content'>{(!value?.shirt) ? '' : `${formatNumber(value.shirt, 2)} บาท`}</Col>
          <Col span={5} className='aiu-title'>ค่าเสื้อนักเรียน (ราคารวม)</Col>
          <Col span={7} className='aiu-content'>{(!value?.shirttotal) ? '' : `${formatNumber(value?.shirttotal, 2)} บาท`}</Col>
        </Row>
        <Row>
          <Col span={5} className='aiu-title'>ค่าอื่นๆ</Col>
          <Col span={7} className='aiu-content'>{(!value?.other) ? '' : `${formatNumber(value.other, 2)} บาท`}</Col>
          <Col span={5} className='aiu-title'>ค่าอื่นๆ (ราคารวม)</Col>
          <Col span={7} className='aiu-content'>{(!value?.othertotal) ? '' : `${formatNumber(value.othertotal, 2)} บาท`}</Col>
        </Row>
        <Row>
          <Col span={5} className='aiu-title'>ค่าใช้จ่ายรวมที่เรียกเก็บ</Col>
          <Col span={7} className='aiu-content'>{(!value?.total) ? '' : `${formatNumber(value.total, 2)} บาท`}</Col>
          <Col span={5} className='aiu-title'>ผ่อนชำระ (งวด)</Col>
          <Col span={7} className='aiu-content'>{(!value?.installmentn) ? '' : `${value.installmentn} งวด`}</Col>
        </Row>
        <Row>
          <Col span={5} className='aiu-title'>จำนวนเงินที่ชำระต่องวด</Col>
          <Col span={7} className='aiu-content'>{(!value?.installmentc) ? '' : `${formatNumber(value.installmentc, 2)} บาท`}</Col>
          <Col span={5} className='aiu-title'>ช่วงเวลาการผ่อนชำระ</Col>
          <Col span={7} className='aiu-content'>{(value?.installmentp?.length) ? `${dayjs(value.installmentp[0]).format('DD/MM/BBBB')} - ${dayjs(value.installmentp[1]).format('DD/MM/BBBB')}` : ''}</Col>
        </Row>
        <Row>
          <Col span={5} className='aiu-title'>หมายเหตุ</Col>
          <Col span={19} className='aiu-content'>{value?.remarkdorm ?? ''}</Col>
        </Row>
        {(status === 'forgive') && (
          <Row>
            <Col span={5} className='aiu-title'>ยกหนี้ให้</Col>
            <Col span={19} className='aiu-content'>{value?.forgivetext ?? ''}</Col>
          </Row>
        )}
        <Row style={{ margin: '48px 0 24px' }}>
          <Col flex='auto'>
            <div style={{ fontSize: 24 }}>
              รายการชำระเงิน
            </div>
          </Col>
          <Col flex='500px'>
            <div style={{ float: 'right', fontSize: 14 }}>
              {(isAllowed(role, 'edit', 'installments')) && (
                <Select
                  placeholder='เลือก'
                  style={{ marginRight: 24, width: 200 }}
                  value={status}
                  onChange={(v) => {
                    let text
                    Modal.confirm({
                      title: `ยืนยันการเปลี่ยนสถานะเป็น${statusObj[v]?.name}`,
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        handleChange(v, text)
                      },
                      onCancel() { },
                      content: (v === 'forgive') && (
                        <>
                          <span>หมายเหตุ</span>
                          <Input.TextArea
                            placeholder='หมายเหตุ'
                            autoSize={{ minRows: 1 }}
                            onChange={(e) => {
                              text = e.target.value
                            }}
                          />
                        </>
                      )
                    })
                  }}
                >
                  {(Object.keys(statusObj).map((o) => (
                    <Select.Option key={o} value={o}>{statusObj[o]?.name}</Select.Option>
                  )))}
                </Select>
              )}
              {(isAllowed(role, 'create', 'installments')) && (
                <Button
                  type='primary'
                  icon={<PlusOutlined style={{ fontSize: 14 }} />}
                  onClick={() => {
                    navigate(`/admin/installments/${iid}/payment/create`)
                  }}
                >
                  สร้างรายการชำระเงิน
                </Button>
              )}
            </div>
          </Col>
        </Row >
        <Table
          bordered
          rowKey='id'
          style={{ overflowX: 'auto' }}
          columns={columns}
          dataSource={value.payments}
        />
      </Card>
    </div>
  )
}

export default Form
