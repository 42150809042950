import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Form as AntdForm, Row, Col, Card, Button, Input, AutoComplete, Select, Checkbox, Upload, Skeleton } from 'antd'
import { SaveOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons'

import { DatePicker } from '../../components/DateTime'
import { dayjsToDB, dbTodayjs } from '../../utils/date'
import InputNumberNew from '../../components/InputNumberNew'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { pickBy } from '../../utils/tools'

import { courseObj } from '../../const/student'

const Form = () => {
  const navigate = useNavigate()
  const { type, fid } = useParams()
  const { db, storage } = useAuth()
  const { getCoops, getLenders } = useLayoutContext()

  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState([])
  const [students, setStudents] = useState()
  const [coopObj, setCoopObj] = useState()
  const [companies, setCompanies] = useState([])
  const [targetCompanies, setTargetCompanies] = useState([])
  const [lenderObj, setLenderObj] = useState()

  const [fundForm] = AntdForm.useForm()

  const { value } = useAsync(async () => {
    const coops = await getCoops()
    const coopData = coops.reduce((acc, o) => {
      acc[o.id] = o
      return acc
    }, {})
    setCoopObj(coopData)

    const companySnap = await db.collection('/companies').get()
    const companyData = companySnap.docs.map(doc => {
      return {
        ...doc.data(),
        id: doc.id,
        key: doc.id
      }
    })
    setCompanies(companyData)

    const lenders = await getLenders()
    const lenderData = lenders.reduce((acc, o) => {
      acc[o.id] = o
      return acc
    }, {})
    setLenderObj(lenderData)

    const studentSnap = await db.collection('/jpstudents').where('isfund', '==', false).get()
    const studentData = studentSnap.docs.map((doc) => {
      const d = doc.data()
      return {
        sid: doc.id,
        value: d.scn,
        title: d.title,
        firstname: d.firstname,
        lastname: d.lastname,
        course: d?.course ?? '',
        coopid: d?.coopid ?? '',
        companyid: d?.companyid ?? '',
        traveldate: dbTodayjs(d?.contractdate)
      }
    })
    setStudents(studentData)

    if (!fid) {
      return {}
    }

    const snap = await db.collection('/funds').doc(fid).get()
    const data = snap.data()
    setFiles(data.files)
    setTargetCompanies(companyData.filter((o) => o.coid === data?.coopid))
    return {
      ...data,
      contractdate: dbTodayjs(data?.contractdate),
      startdate: dbTodayjs(data?.startdate),
      traveldate: dbTodayjs(data?.contractdate)
    }
  }, [fid])

  const handleSubmit = async (formData) => {
    try {
      if (value.disabled) {
        return Promise.resolve()
      }
      setLoading(true)
      formData = {
        ...formData,
        type: type,
        contractdate: dayjsToDB(formData?.contractdate),
        startdate: dayjsToDB(formData?.startdate),
        traveldate: dayjsToDB(formData?.traveldate),
        disabled: (!fid) ? false : null,
        file: null,
        files: files
      }
      const data = pickBy(formData)
      if (fid) {
        await db.collection('/funds').doc(fid).set(data, {
          merge: true
        })
      } else {
        await db.collection('/funds').add(data)
      }
      setLoading(false)
      navigate(`/admin/funds/${type}`)
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  const customRequestFile = async ({ onError, _, file }) => {
    try {
      const ref = storage.ref('funds').child(`${file.name}-${file.uid}`)
      await ref.put(file, {
        contentType: file.type
      })

      const url = await ref.getDownloadURL()

      const data = {
        uid: file.uid,
        name: file.name,
        type: file.type,
        lastModified: file.lastModified,
        url: url
      }

      setFiles([...files, data])
    } catch (e) {
      onError(e)
    }
  }

  if (!value) return <Skeleton></Skeleton>
  return (
    <Card
      style={{ marginTop: 16 }}
      bodyStyle={{ padding: '32px 80px' }}
    >
      <div style={{ margin: '16px 0', fontSize: 24 }}>
        สร้างลูกหนี้
      </div>
      <AntdForm
        layout='vertical'
        onFinish={handleSubmit}
        initialValues={value}
        form={fundForm}
        name='fundForm'
      >
        <AntdForm.Item name='sid' hidden={true}>
          <Input />
        </AntdForm.Item>
        <AntdForm.Item name='traveldate' hidden={true}>
          <DatePicker />
        </AntdForm.Item>
        <AntdForm.Item name='title' hidden={true}>
          <Input />
        </AntdForm.Item>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='scn' label='รหัสนักเรียน' rules={[{ required: true, message: 'กรุณาใส่รหัสนักเรียนค่ะ' }]}>
              <AutoComplete
                options={students}
                placeholder='เลือก'
                filterOption={(inputValue, option) =>
                  option.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                }
                onChange={(v) => {
                  const user = students.find((o) => o.value === v)
                  if (user) {
                    const target = companies.filter((o) => o.coid === user?.coopid)
                    fundForm.setFieldsValue({
                      ...user,
                      scn: user.value
                    })
                    setTargetCompanies(target)
                  }
                }}
              />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='firstname' label='ชื่อ' rules={[{ required: true, message: 'กรุณาใส่ชื่อค่ะ' }]}>
              <Input placeholder='ชื่อ' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='lastname' label='นามสกุล' rules={[{ required: true, message: 'กรุณาใส่นามสกุลค่ะ' }]}>
              <Input placeholder='นามสกุล' />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='course' label='หลักสูตร' rules={[{ required: true, message: 'กรุณาเลือกหลักสูตรค่ะ' }]}>
              <Select placeholder='เลือก' >
                {(Object.keys(courseObj).map((o) => (
                  <Select.Option key={o} value={o}>{courseObj[o]?.name ?? ''}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='coopid' label='สหกรณ์' rules={[{ required: true, message: 'กรุณาเลือกสหกรณ์ค่ะ' }]}>
              <Select
                placeholder='เลือก'
                onChange={(v) => {
                  const target = companies.filter((o) => o.coid === v)
                  fundForm.setFieldsValue({
                    ...fundForm.getFieldsValue(),
                    companyid: null
                  })
                  setTargetCompanies(target)
                }}
              >
                {(Object.keys(coopObj).map((o) => (
                  <Select.Option key={o} value={o}>{coopObj[o]?.name ?? ''}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='companyid' label='บริษัท' rules={[{ required: true, message: 'กรุณาเลือกบริษัทค่ะ' }]}>
              <Select placeholder='เลือก' >
                {(targetCompanies.map((o) => (
                  <Select.Option key={o.id} value={o.id}>{o?.name ?? ''}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='borrower1' label='ผู้กู้ร่วมคนที่ 1' >
              <Input placeholder='ผู้กู้ร่วมคนที่ 1' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='borrower2' label='ผู้กู้ร่วมคนที่ 2' >
              <Input placeholder='ผู้กู้ร่วมคนที่ 2' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='surety1' label='ผู้ค้ำประกันคนที่ 1' >
              <Input placeholder='ผู้ค้ำประกันคนที่ 1' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='surety2' label='ผู้ค้ำประกันคนที่ 2' >
              <Input placeholder='ผู้ค้ำประกันคนที่ 2' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='surety3' label='ผู้ค้ำประกันคนที่ 3' >
              <Input placeholder='ผู้ค้ำประกันคนที่ 3' />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='contractdate' label='วันที่ทำสัญญา' >
              <DatePicker placeholder='เลือก' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='period' label='ระยะเวลาทำสัญญา (เดือน)' >
              <InputNumberNew placeholder='ระยะเวลาทำสัญญา (เดือน)' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='startdate' label='วันที่เริ่มชำระงวดแรก' >
              <DatePicker placeholder='เลือก' />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='loan' label='วงเงินกู้' >
              <InputNumberNew placeholder='วงเงินกู้' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='installment' label='อัตราผ่อนชำระ' >
              <InputNumberNew placeholder='อัตราผ่อนชำระ' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='interest' label='อัตราดอกเบี้ย' >
              <Input placeholder='อัตราดอกเบี้ย' />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='installmentp' label='อัตราผ่อนชำระเงินต้น' >
              <InputNumberNew placeholder='อัตราผ่อนชำระเงินต้น' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='installmenti' label='อัตราผ่อนชำระดอกเบี้ย' >
              <InputNumberNew placeholder='อัตราผ่อนชำระดอกเบี้ย' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='security' label='หลักประกัน' >
              <Checkbox.Group
                style={{ width: '100%' }}
              >
                <Checkbox value='person'>บุคคลค้ำ</Checkbox>
                <Checkbox value='deed'>หลักทรัพย์</Checkbox>
              </Checkbox.Group>
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='lid' label='ผู้ให้กู้' >
              <Select placeholder='เลือก' >
                {(Object.keys(lenderObj).map((o) => (
                  <Select.Option key={o} value={o}>{lenderObj[o]?.name ?? ''}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>

        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='file' label='อัปโหลด' >
              <Upload
                accept='.webp, .png, .jpg, .jpeg, .xls, .xlsx, .pdf'
                fileList={files}
                customRequest={customRequestFile}
                onRemove={(file) => {
                  const remainFiles = files.filter((o) => o.uid !== file.uid)
                  setFiles(remainFiles)
                }}
              >
                <Button icon={<UploadOutlined />}>อัปโหลด</Button>
              </Upload>
            </AntdForm.Item>
          </Col>
          <Col span={16}></Col>
        </Row>
        <div>
          <Button
            loading={loading}
            style={{ width: 168, height: 40, marginTop: 20 }}
            htmlType='submit'
            type='primary'
          ><SaveOutlined style={{ fontSize: 16 }} />บันทึก</Button>
          <Button
            loading={loading}
            style={{ height: 40, marginTop: 20, marginLeft: 20 }}
            onClick={() => {
              navigate(-1)
            }}
          ><CloseOutlined />ปิด</Button>
        </div>
      </AntdForm>
    </Card>
  )
}

export default Form
