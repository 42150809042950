import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Row, Col, Card, Button, Table, Select, Modal, Popconfirm, Skeleton } from 'antd'
import { PlusOutlined, EyeOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'
import { dbTodayjs } from '../../utils/date'

import { useAuth } from '../../contexts/AuthContextProvider'
import { paymentObj } from '../../const/fee'
import { formatNumber } from '../../utils/tools'
import { isAllowed } from '../../route/roles'

const View = () => {
  const navigate = useNavigate()
  const { db, role } = useAuth()
  const { uid } = useParams()

  const [updateCount, setUpdateCount] = useState(0)
  const [payments, setPayments] = useState()
  const [status, setStatus] = useState()

  const { value } = useAsync(async () => {
    if (!uid) {
      return {}
    }

    const feeSnap = await db.collection('/fees').where('uid', '==', uid).get()
    const feeData = feeSnap.docs.map((o) => {
      const d = o.data()
      return {
        ...d,
        id: o.id,
        date: dbTodayjs(d?.date),
        doc: d.files.map((file) => file.name).join(', ')
      }
    })
    setPayments(feeData)

    const snap = await db.collection('/users').doc(uid).get()
    const data = snap.data()
    setStatus(data?.payment)
    return {
      ...data,
      id: snap.id,
      scn: data?.scn ?? ''
    }
  }, [uid, updateCount])

  const handleChange = async (value) => {
    try {
      await db.collection('/users').doc(uid).set({
        payment: value
      }, {
        merge: true
      })
      setStatus(value)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const handleDelete = async (row) => {
    try {
      await db.collection('/fees').doc(row.id).delete()
      setUpdateCount(updateCount + 1)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const columns = [
    {
      title: 'วันที่ชำระ',
      dataIndex: 'date',
      width: 120,
      render: data => (
        <span>{(data) ? dayjs(data).format('D MMM BBBB') : ''}</span>
      )
    },
    {
      title: 'จำนวนที่ชำระ',
      dataIndex: 'amount',
      width: 200,
      align: 'center',
      render: data => formatNumber(data, 2)
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'remark',
      width: 200
    },
    {
      title: 'เอกสารหลักฐาน',
      dataIndex: 'doc',
      width: 150
    },
    {
      title: 'เครื่องมือ',
      dataIndex: 'Operation',
      width: 150,
      render: (_, row) => (
        <>
          {(isAllowed(role, 'edit', 'fees')) && (
            <>
              <Button
                type='link'
                style={{ marginRight: '8px' }}
                icon={<EyeOutlined style={{ fontSize: 18 }} />}
                onClick={() => {
                  navigate(`/admin/fees/${uid}/payment/${row.id}/edit`)
                }}
              ></Button>
              <Popconfirm
                title='Are you sure to remove this payment'
                onConfirm={() => handleDelete(row)}
                okText='Yes'
                cancelText='No'
              >
                <Button
                  type='link'
                  style={{ marginRight: '8px' }}
                  danger
                  icon={<DeleteOutlined style={{ fontSize: 18 }} />}
                ></Button>
              </Popconfirm>
            </>
          )}
        </>
      )
    }
  ]

  if (!value) return <Skeleton></Skeleton>
  return (
    <div>
      <Card
        style={{ marginTop: 16 }}
        bodyStyle={{ padding: '32px 80px' }}
      >
        <Row style={{ marginBottom: 24 }}>
          <Col flex='auto'>
            <div style={{ fontSize: 24 }}>
              ค่าลงทะเบียนเรียน
            </div>
          </Col>
          <Col flex='300px'></Col>
        </Row >
        <Row>
          <Col span={3} className='aiu-title'>รหัสนักเรียน</Col>
          <Col span={5} className='aiu-content'>{value.scn}</Col>
          <Col span={3} className='aiu-title'>ชื่อ</Col>
          <Col span={5} className='aiu-content'>{value.firstname}</Col>
          <Col span={3} className='aiu-title'>นามสกุล</Col>
          <Col span={5} className='aiu-content'>{value.lastname}</Col>
        </Row>
        <Row style={{ margin: '48px 0 24px' }}>
          <Col flex='auto'>
            <div style={{ fontSize: 24 }}>
              รายการชำระเงิน
            </div>
          </Col>
          <Col flex='400px'>
            {(isAllowed(role, 'edit', 'fees')) && (
              <div style={{ float: 'right', fontSize: 14 }}>
                <div>
                  <Select
                    placeholder='เลือก'
                    style={{ marginRight: 24, width: 150 }}
                    value={status}
                    onChange={(v) => {
                      Modal.confirm({
                        title: `ยืนยันการเปลี่ยนสถานะเป็น${paymentObj[v]?.name}`,
                        icon: <ExclamationCircleOutlined />,
                        onOk() {
                          handleChange(v)
                        },
                        onCancel() { }
                      })
                    }}
                  >
                    {(Object.keys(paymentObj).map((o) => (
                      <Select.Option key={o} value={o}>{paymentObj[o]?.name}</Select.Option>
                    )))}
                  </Select>
                  <Button
                    type='primary'
                    disabled={value.remain === 0}
                    icon={<PlusOutlined style={{ fontSize: 14 }} />}
                    onClick={() => {
                      navigate(`/admin/fees/${uid}/payment/create`)
                    }}
                  >
                    สร้างรายการชำระเงิน
                  </Button>
                </div>
              </div>
            )}
          </Col>
        </Row >
        <Table
          bordered
          rowKey='id'
          style={{ overflowX: 'auto' }}
          columns={columns}
          dataSource={payments}
        />
      </Card>
    </div>
  )
}

export default View
