const statusObj = {
  normal: { name: 'เคสปกติ', color: '#228B90' },
  special: { name: 'เคสพิเศษ', color: '#228B90' },
  sue: { name: 'ฟ้องร้อง', color: '#228B90' },
  early: { name: 'กลับก่อนกำหนด', color: '#228B90' }
}

const payStatusObj = {
  pending: { name: 'ค้างชำระ', color: '#D35C3B' },
  paid: { name: 'ปิดบัญชี', color: '#228B90' }
}

const typeObj = {
  normal: { name: 'เคสปกติ', n: 1 },
  special: { name: 'เคสพิเศษ', n: 2 },
  sue: { name: 'ฟ้องร้อง', n: 3 },
  early: { name: 'กลับก่อนกำหนด', n: 4 }
}

const securityObj = {
  person: { name: 'บุคคลค้ำ' },
  deed: { name: 'หลักทรัพย์' }
}

export {
  statusObj,
  payStatusObj,
  typeObj,
  securityObj
}
