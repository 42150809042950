import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { message, Skeleton } from 'antd'

import firebase, { auth, db, functions, storage } from '../utils/firebase'

const AuthContext = React.createContext({
  firebaseApp: firebase,
  auth,
  db,
  functions,
  storage,
  userId: '',
  user: auth().currentUser,
  role: ''
})

const AuthContextProvider = ({
  children
}) => {
  const navigate = useNavigate()
  const tempUser = auth().currentUser
  const [userId, setUserId] = useState()
  const [user, setUser] = useState(tempUser)
  const [role, setRole] = useState('')
  const [loaded, setLoaded] = useState(false)
  const contextValue = {
    firebaseApp: firebase,
    auth,
    db,
    functions,
    storage,
    userId,
    user,
    role
  }

  const getUserData = async (id) => {
    try {
      const snap = await db.collection('/users').doc(id).get()
      const data = {
        ...snap.data(),
        id: snap.id
      }
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  useEffect(() => {
    const staticPage = ['/', '/login', '/confirm-password', '/forget-password', '/reset-password']
    const dynamicPage = ['/register', '/auth/action']
    const prevUrl = window.location.pathname
    const unsubscribe = auth().onAuthStateChanged(async (userAuth) => {
      if (!userAuth?.emailVerified) {
        if (userAuth && !userAuth.emailVerified) {
          message.error('คุณยังไม่ได้ Verify Email')
        }
        if (dynamicPage.indexOf(prevUrl) !== -1) {
          navigate(prevUrl + window.location.search)
        } else if (staticPage.indexOf(prevUrl) === -1) {
          navigate(`/login?continue=${encodeURIComponent(prevUrl)}`)
        } else if (prevUrl === '/') {
          navigate('/login')
        } else {
          navigate(prevUrl)
        }
        setLoaded(true)
        return
      }

      const idToken = await userAuth.getIdTokenResult()
      const data = await getUserData(userAuth.uid)

      setUserId(data.id)
      setUser({ ...data })
      setRole(idToken?.claims?.role ?? '')

      const url = new URL(window.location)
      const params = new URLSearchParams(url.search)
      const redirect = params.get('continue')

      if (redirect) {
        navigate(redirect)
      } else if (staticPage.indexOf(prevUrl) !== -1) {
        switch (idToken.claims.role) {
          case 'superadmin':
            navigate('/admin/students')
            break
          default:
            navigate('/admin/students')
        }
      }
      setLoaded(true)
    })
    return () => {
      unsubscribe()
    }
  }, [navigate, role])

  if (!loaded) return <Skeleton></Skeleton>
  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('Cannot useAuth outside AuthContextProvider!')
  }
  return context
}

export default AuthContextProvider
export {
  useAuth
}
