import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Row, Col, Card, Button, message, Table, Tag, Skeleton } from 'antd'
import { WarningOutlined, FileDoneOutlined, AlertOutlined, EditOutlined, UserOutlined, DownloadOutlined, CloseCircleOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'
import { dbTodayjs } from '../../utils/date'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { nameTitleObj } from '../../const/users'
import { evaStatusObj } from '../../const/classes'
import { jobObj } from '../../const/student'
import { isAllowed } from '../../route/roles'
import { genCsv } from '../../utils/csv'

const Form = () => {
  const navigate = useNavigate()
  const { db, functions, userId, role } = useAuth()
  const { getStudents, getCampuses, getCoops, getCompanies, getSubjects, getJobs } = useLayoutContext()
  const { cid } = useParams()

  const [updateCount, setUpdateCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [students, setStudents] = useState()
  const [teacherObj, setTeacherObj] = useState()
  const [subjectObj, setSubjectObj] = useState()
  const [jobsObj, setJobsObj] = useState()
  const [coopObj, setCoopObj] = useState()
  const [companyObj, setCompanyObj] = useState()
  const [stat, setStat] = useState()

  const { value } = useAsync(async () => {
    const coops = await getCoops()
    const coopData = coops.reduce((acc, o) => {
      acc[o.id] = o
      return acc
    }, {})
    setCoopObj(coopData)

    const companies = await getCompanies()
    const companyData = companies.reduce((acc, o) => {
      acc[o.id] = o
      return acc
    }, {})
    setCompanyObj(companyData)

    if (!cid) {
      return {}
    }

    const campusData = await getCampuses()

    const teacherSnap = await db.collection('/users').where('role', 'in', ['headteacher', 'teacher', 'headadmin', 'admin']).where('disabled', '==', false).where('emailVerified', '==', true).get()
    const teacherDataObj = teacherSnap.docs.reduce((acc, doc) => {
      acc[doc.id] = {
        name: `${doc.data()?.firstname ?? ''} ${doc.data()?.lastname ?? ''}`
      }
      return acc
    }, {})
    setTeacherObj(teacherDataObj)

    const subjects = await getSubjects()
    const subjectData = subjects.reduce((acc, o) => {
      acc[o.id] = o.name
      return acc
    }, [])
    setSubjectObj(subjectData)

    const jobs = await getJobs()
    const jobData = jobs.reduce((acc, o) => {
      acc[o.id] = o
      return acc
    }, [])
    setJobsObj(jobData)

    const scSnap = await db.collection('/studentclasses').where('cid', '==', cid).where('disabled', '==', false).get()
    const scObj = scSnap.docs.reduce((acc, o) => {
      acc[o.data().uid] = { id: o.id, ...o.data() }
      return acc
    }, {})
    const sc = Object.keys(scObj)

    let studentData = await getStudents()
    studentData = studentData.reduce((acc, o) => {
      return (sc.indexOf(o.id) !== -1) ? [...acc, {
        ...o,
        scn: o?.scn ?? '',
        sid: scObj[o.id]?.id,
        evastatus: scObj[o.id]?.evastatus ?? 'no',
        job: (o.jobstatus && o.jobstatus === 'pass') ? 'yes' : 'no',
        t: (o.title === 'mr') ? 'm' : 'w'
      }] : acc
    }, [])
    setStudents(studentData)

    let s = { m: 0, w: 0, ym: 0, yw: 0, nm: 0, nw: 0 }
    studentData.forEach((o) => {
      s['m'] += (o.t === 'm') ? 1 : 0
      s['w'] += (o.t === 'w') ? 1 : 0
      s['ym'] += (o.job === 'yes' && o.t === 'm') ? 1 : 0
      s['yw'] += (o.job === 'yes' && o.t === 'w') ? 1 : 0
      s['nm'] += (o.job === 'no' && o.t === 'm') ? 1 : 0
      s['nw'] += (o.job === 'no' && o.t === 'w') ? 1 : 0
    })
    setStat(s)

    const snap = await db.collection('/classes').doc(cid).get()
    const data = snap.data()
    return {
      ...data,
      id: snap.id,
      startdate: dbTodayjs(data?.startdate),
      enddate: dbTodayjs(data?.enddate),
      campus: (campusData.find((o) => (o.id === data.campusid))?.name ?? ''),
      teachers: (data.teachers.map((o) => (`${teacherDataObj[o]?.name ?? ''}`))).join(', '),
      allowperson: data?.allowperson ?? ''
    }
  }, [cid, updateCount])

  const allowClass = async () => {
    try {
      if (['superadmin', 'headteacher', 'teacher', 'headadmin', 'admin'].indexOf(role) === -1) {
        message.warning('คุณไม่ได้รับอนุญาติให้อนุมัติห้องเรียนค่ะ')
        return Promise.resolve()
      }
      setLoading(true)
      const actionClassApi = functions.httpsCallable('actionClass-actionClass')
      await actionClassApi({
        type: 'allow',
        cid: cid,
        allowperson: `${teacherObj[userId]?.name ?? ''}`
      })
      message.success('อนุมัติห้องเรียนเรียบร้อยค่ะ')
      setUpdateCount(updateCount + 1)
      setLoading(false)
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  const closeClass = async () => {
    try {
      if (['superadmin', 'headteacher', 'teacher', 'headadmin', 'admin'].indexOf(role) === -1) {
        message.warning('คุณไม่ได้รับอนุญาติให้ปิดการเรียนค่ะ')
        return Promise.resolve()
      }
      setLoading(true)
      const actionClassApi = functions.httpsCallable('actionClass-actionClass')
      await actionClassApi({
        type: 'close',
        cid: cid
      })
      message.success('ปิดการเรียนเรียบร้อยค่ะ')
      setUpdateCount(updateCount + 1)
      setLoading(false)
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  const download = async () => {
    const list = students.map((o) => ({
      'รหัสนักเรียน': o?.scn ?? '',
      'คำนำหน้า': nameTitleObj[o?.title]?.nameTh ?? '',
      'ชื่อ': o?.firstname ?? '',
      'นามสกุล': o?.lastname ?? '',
      'บริษัท': companyObj[o?.companyid]?.name ?? '',
      'สหกรณ์': coopObj[o?.coopid]?.name ?? '',
      'กำหนดการเดินทาง': (o?.traveldate) ? dayjs(dbTodayjs(o?.traveldate)).format('D MMM BBBB') : (o?.jid) ? jobsObj[o.jid]?.gotojapandate : '',
      'สถานะการได้งาน': jobObj[o?.job]?.name ?? 'ไม่ผ่าน',
      'สถานะการประเมิน': evaStatusObj[o?.evastatus]?.name ?? 'ไม่ผ่าน'
    }))
    try {
      const title = `รายวิชา,"${value.name}",หมวดวิชา,"${subjectObj[value?.subject] ?? ''}",วิทยาเขต,"${value.campus}"
วันเริ่มต้นรายวิชา,"${(value.startdate) ? dayjs(value.startdate).format('D MMM BBBB') : ''}",วันสิ้นสุดรายวิชา,"${(value.enddate) ? dayjs(value.enddate).format('D MMM BBBB') : ''}",จำนวนชั่วโมง,${value.hrs}
ครูผู้สอน,"${value.teachers}"
ผู้อนุมัติ,"${value.allowperson}"
นักเรียนชาย,${stat.m},นักเรียนหญิง,${stat.w},จำนวนนักเรียนทั้งหมด,${stat.m + stat.w}
นักเรียนชายได้งาน,${stat.ym},นักเรียนหญิงได้งาน,${stat.yw},รวมนักเรียนที่ได้งาน,${stat.ym + stat.yw}
นักเรียนชายยังไม่ได้งาน,${stat.nm},นักเรียนหญิงยังไม่ได้งาน,${stat.nw},รวมนักเรียนที่ยังไม่ได้งาน,${stat.nm + stat.nw}`
      await genCsv('class', list, title)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const columns = [
    {
      title: 'รหัสนักเรียน',
      dataIndex: 'scn',
      width: 120
    },
    {
      title: 'คำนำหน้า',
      dataIndex: 'title',
      align: 'center',
      width: 100,
      render: data => (nameTitleObj[data]?.nameTh ?? '')
    },
    {
      title: 'ชื่อ',
      dataIndex: 'firstname'
    },
    {
      title: 'นามสกุล',
      dataIndex: 'lastname'
    },
    {
      title: 'บริษัท',
      dataIndex: 'companyid',
      width: 120,
      render: data => companyObj[data]?.name ?? ''
    },
    {
      title: 'สหกรณ์',
      dataIndex: 'coopid',
      width: 120,
      render: data => coopObj[data]?.name ?? ''
    },
    {
      title: 'กำหนดการเดินทาง',
      dataIndex: 'traveldate',
      width: 150,
      render: (_, row) => (row?.traveldate) ? dayjs(dbTodayjs(row.traveldate)).format('D MMM BBBB') : (row?.jid) ? jobsObj[row.jid]?.gotojapandate : ''
    },
    {
      title: 'สถานะการได้งาน',
      dataIndex: 'job',
      width: 150,
      render: data => (
        <Tag color={jobObj[data]?.color ?? 'error'}>
          {jobObj[data]?.name ?? 'ไม่ผ่าน'}
        </Tag>
      )
    },
    {
      title: 'สถานะการประเมิน',
      dataIndex: 'evastatus',
      width: 150,
      render: data => (
        <Tag color={evaStatusObj[data]?.color ?? 'error'}>
          {evaStatusObj[data]?.name ?? 'ไม่ผ่าน'}
        </Tag>
      )
    },
    {
      title: 'เครื่องมือ',
      dataIndex: 'Operation',
      width: 300,
      render: (_, row) => (
        <div>
          {(isAllowed(role, 'edit', 'classes') && !(value.status && value.status === 'close')) && (
            <>
              <Button
                type='link'
                style={{ marginRight: 8 }}
                icon={<FileDoneOutlined style={{ fontSize: 24 }} />}
                disabled={value.status === 'close'}
                onClick={() => {
                  navigate(`/admin/classes/${cid}/student/${row.sid}/evaluate`)
                }}
              ></Button>
              <Button
                type='link'
                style={{ marginRight: 8 }}
                icon={<AlertOutlined style={{ fontSize: 24 }} />}
                disabled={value.status === 'close'}
                onClick={() => {
                  navigate(`/admin/students/${row.id}/behaviors`)
                }}
              ></Button>
              <Button
                type='link'
                icon={<UserOutlined style={{ fontSize: 24 }} />}
                onClick={() => {
                  navigate(`/admin/students/${row.id}/view`)
                }}
              ></Button>
            </>
          )}
        </div>
      )
    }
  ]

  if (!value) return <Skeleton></Skeleton>
  return (
    <div>
      <Card
        style={{ marginTop: 16 }}
        bodyStyle={{ padding: '32px 80px' }}
      >
        <Row style={{ marginBottom: 24 }}>
          <Col flex='auto'>
            <div style={{ fontSize: 24 }}>
              ห้องเรียน
            </div>
          </Col>
          <Col flex='300px'>
            <div style={{ float: 'right', fontSize: 14 }}>
              {(isAllowed(role, 'edit', 'classes')) && (
                <>
                  {(!(value.status && value.status === 'close') && (value.allow && value.allow === 'yes')) && (
                    <Button
                      loading={loading}
                      icon={<CloseCircleOutlined style={{ fontSize: 14 }} />}
                      style={{ marginRight: 8 }}
                      type='primary'
                      danger
                      onClick={() => closeClass()}
                    >
                      ปิดการเรียน
                    </Button>
                  )}
                  {!(value.allow && value.allow === 'yes') && (
                    <Button
                      loading={loading}
                      style={{ marginRight: 8, color: '#F6F6F6', backgroundColor: '#D35C3B', border: '1px solid #D35C3B' }}
                      icon={<WarningOutlined style={{ fontSize: 14 }} />}
                      onClick={() => allowClass()}
                    >
                      อนุมัติห้องเรียน
                    </Button>
                  )}
                  <Button
                    loading={loading}
                    type='primary'
                    icon={<DownloadOutlined style={{ fontSize: 14 }} />}
                    onClick={() => download()}
                  >
                    ดาวน์โหลด
                  </Button>
                </>
              )}
            </div>
          </Col>
        </Row >
        <Row>
          <Col span={3} className='aiu-title'>รายวิชา</Col>
          <Col span={5} className='aiu-content'>{value.name}</Col>
          <Col span={3} className='aiu-title'>หมวดวิชา</Col>
          <Col span={5} className='aiu-content'>{subjectObj[value?.subject] ?? ''}</Col>
          <Col span={3} className='aiu-title'>วิทยาเขต</Col>
          <Col span={5} className='aiu-content'>{value.campus}</Col>
        </Row>
        <Row>
          <Col span={3} className='aiu-title'>วันเริ่มต้นรายวิชา</Col>
          <Col span={5} className='aiu-content'>{(value.startdate) ? dayjs(value.startdate).format('D MMM BBBB') : ''}</Col>
          <Col span={3} className='aiu-title'>วันสิ้นสุดรายวิชา</Col>
          <Col span={5} className='aiu-content'>{(value.enddate) ? dayjs(value.enddate).format('D MMM BBBB') : ''}</Col>
          <Col span={3} className='aiu-title'>จำนวนชั่วโมง</Col>
          <Col span={5} className='aiu-content'>{value.hrs}</Col>
        </Row>
        <Row>
          <Col span={3} className='aiu-title'>ครูผู้สอน</Col>
          <Col span={21} className='aiu-content'>{value.teachers}</Col>
        </Row>
        <Row>
          <Col span={3} className='aiu-title'>ผู้อนุมัติ</Col>
          <Col span={21} className='aiu-content'>{value.allowperson}</Col>
        </Row>
        <Row>
          <Col span={3} className='aiu-title'>นักเรียนชาย</Col>
          <Col span={5} className='aiu-content'>{stat.m} คน</Col>
          <Col span={3} className='aiu-title'>นักเรียนหญิง</Col>
          <Col span={5} className='aiu-content'>{stat.w} คน</Col>
          <Col span={3} className='aiu-title'>จำนวนนักเรียนทั้งหมด</Col>
          <Col span={5} className='aiu-content'>{stat.m + stat.w} คน</Col>
        </Row>
        <Row>
          <Col span={3} className='aiu-title'>นักเรียนชายได้งาน</Col>
          <Col span={5} className='aiu-content'>{stat.ym} คน</Col>
          <Col span={3} className='aiu-title'>นักเรียนหญิงได้งาน</Col>
          <Col span={5} className='aiu-content'>{stat.yw} คน</Col>
          <Col span={3} className='aiu-title'>รวมนักเรียนที่ได้งาน</Col>
          <Col span={5} className='aiu-content'>{stat.ym + stat.yw} คน</Col>
        </Row>
        <Row>
          <Col span={3} className='aiu-title'>นักเรียนชายยังไม่ได้งาน</Col>
          <Col span={5} className='aiu-content'>{stat.nm} คน</Col>
          <Col span={3} className='aiu-title'>นักเรียนหญิงยังไม่ได้งาน</Col>
          <Col span={5} className='aiu-content'>{stat.nw} คน</Col>
          <Col span={3} className='aiu-title'>รวมนักเรียนที่ยังไม่ได้งาน</Col>
          <Col span={5} className='aiu-content'>{stat.nm + stat.nw} คน</Col>
        </Row>
        <div style={{ margin: '32px 0 24px', fontSize: 24 }}>
          รายชื่อนักเรียน
        </div>
        <Table
          bordered
          rowKey='id'
          style={{ overflowX: 'auto' }}
          columns={columns}
          dataSource={students}
        />
        {(isAllowed(role, 'edit', 'classes') && !(value.status && value.status === 'close')) && (
          <div style={{ marginTop: 32 }}>
            <Button
              style={{ color: '#F6F6F6', backgroundColor: '#EE7930', border: '1px solid #EE7930' }}
              icon={<EditOutlined style={{ fontSize: 14 }} />}
              disabled={value.status === 'close'}
              onClick={() => {
                navigate(`/admin/classes/${cid}/edit`)
              }}
            >
              แก้ไข
            </Button>
          </div>
        )}
      </Card>
    </div>
  )
}

export default Form
