import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Menu } from 'antd'
import { ReadOutlined, TeamOutlined, WalletOutlined, SolutionOutlined, HomeOutlined, SearchOutlined, FileAddOutlined, UserOutlined } from '@ant-design/icons'

import { useAuth } from '../../contexts/AuthContextProvider'
import { roleObj, menuObj } from '../../route/roles'

import './Sidebar.less'

const Sidebar = () => {
  let location = useLocation()
  const navigate = useNavigate()
  const { role } = useAuth()

  const getItem = (label, key, icon, children, role, roles, order, type) => {
    if (roles && roles.indexOf(role) === -1) {
      return null
    }
    return { key, icon, children, label, type, order }
  }

  const onClick = (e) => {
    if (!e?.key) {
      return
    }
    navigate(e.key)
  }

  if (['/admin', '/admin/'].indexOf(location.pathname) !== -1) {
    location.pathname = '/admin/classes'
  }

  const items = [
    // getItem('นักเรียน', '/admin/student', <TeamOutlined />, null, role, roleObj['view']['student']),
    getItem('ห้องเรียน', '/admin/classes', <ReadOutlined />, null, role, roleObj['view']['classes'], menuObj[role]['classes']),
    getItem('นักเรียน', '/admin/students', <TeamOutlined />, null, role, roleObj['view']['students'], menuObj[role]['students']),
    getItem('ค่าลงทะเบียนเรียน', '/admin/fees', <WalletOutlined />, null, role, roleObj['view']['fees'], menuObj[role]['fees']),
    getItem('จัดหางาน', '/admin/jobs', <SearchOutlined />, null, role, roleObj['view']['jobs'], menuObj[role]['jobs']),
    getItem('หอพัก', '/admin/rooms', <HomeOutlined />, null, role, roleObj['view']['rooms'], menuObj[role]['rooms']),
    getItem('กองทุน', null, <SolutionOutlined />, [
      getItem('กองทุน', '/admin/funds/normal', null, null, role, roleObj['view']['funds']['normal']),
      getItem('เคสพิเศษ', '/admin/funds/special', null, null, role, roleObj['view']['funds']['special']),
      getItem('ฟ้องร้อง', '/admin/funds/sue', null, null, role, roleObj['view']['funds']['sue']),
      getItem('กลับก่อนกำหนด', '/admin/funds/early', null, null, role, roleObj['view']['funds']['early']),
    ], role, roleObj['view']['funds']['all'], menuObj[role]['funds']),
    getItem('เพิ่มเติมข้อมูล', null, <FileAddOutlined />, [
      getItem('เจ้าหนี้กองทุน', '/admin/masters/lenders', null, null, role, roleObj['view']['masters']['lenders']),
      getItem('สหกรณ์', '/admin/masters/coops', null, null, role, roleObj['view']['masters']['coops']),
      getItem('บริษัท', '/admin/masters/companies', null, null, role, roleObj['view']['masters']['companies']),
      getItem('วิทยาเขต', '/admin/masters/campuses', null, null, role, roleObj['view']['masters']['campuses']),
      getItem('สาขา', '/admin/masters/branches', null, null, role, roleObj['view']['masters']['branches']),
      getItem('หมวดวิชา', '/admin/masters/subjects', null, null, role, roleObj['view']['masters']['subjects']),
      getItem('แบบประเมิน', '/admin/masters/assessments', null, null, role, roleObj['view']['masters']['assessments']),
      getItem('หัวข้อประเมิน', '/admin/masters/evaluations', null, null, role, roleObj['view']['masters']['evaluations']),
      getItem('คำถามประเมิน', '/admin/masters/questions', null, null, role, roleObj['view']['masters']['questions']),
    ], role, roleObj['view']['masters']['master'], menuObj[role]['masters']),
    getItem('ผู้ใช้งาน', '/admin/users', <UserOutlined />, null, role, roleObj['view']['users'], menuObj[role]['users']),
    getItem('นักเรียนไปญี่ปุ่น', '/admin/jpstudents', <TeamOutlined />, null, role, roleObj['view']['jpstudents'], menuObj[role]['jpstudents']),
    getItem('เรียกเก็บเงินจากญี่ปุ่น', '/admin/invoices', <TeamOutlined />, null, role, roleObj['view']['invoices'], menuObj[role]['invoices']),
    getItem('ผ่อนชำระค่าเรียน', '/admin/installments', <TeamOutlined />, null, role, roleObj['view']['installments'], menuObj[role]['installments']),
    getItem('ยอดผ่อนชำระเจ้าหนี้', '/admin/creditors', <TeamOutlined />, null, role, roleObj['view']['creditors'], menuObj[role]['creditors']),
    getItem('หน้ารับร้องเรียน', '/admin/complains', <TeamOutlined />, null, role, roleObj['view']['complains'], menuObj[role]['complains'])
  ].filter((o => o)).sort((x, y) => x.order - y.order)

  return (
    <div className='sidebar'>
      <Menu
        theme='dark'
        mode='inline'
        style={{ backgroundColor: '#228B90', color: '#D2D2D2' }}
        defaultSelectedKeys={[location.pathname]}
        onClick={onClick}
        items={items}
      />
    </div>
  )
}

export default Sidebar
