import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Row, Col, Card, Button, Badge, Modal, Skeleton } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import { useAuth } from '../../contexts/AuthContextProvider'
import { isAllowed } from '../../route/roles'

import StudentInfoCard from './Components/info'

const Form = () => {
  const navigate = useNavigate()
  const { db, functions, role } = useAuth()
  const { id } = useParams()

  const [status, setStatus] = useState()

  const uid = id

  const { value } = useAsync(async () => {
    if (!uid) {
      return {}
    }

    const snap = await db.collection('/users').doc(uid).get()
    const data = snap.data()
    setStatus(data.status)
    return {
      ...data,
      id: snap.id
    }
  }, [uid])

  const suspension = async (uid) => {
    try {
      await functions.httpsCallable('suspension-suspension')({ uid: uid })
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const resign = async (uid) => {
    try {
      await functions.httpsCallable('resign-resign')({ uid: uid })
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  if (!value) return <Skeleton></Skeleton>
  return (
    <div className='student'>
      <StudentInfoCard uid={uid} isFull={true} />
      <Card
        style={{ marginTop: 16 }}
        bodyStyle={{ padding: '32px 120px' }}
      >
        <Row gutter={48}>
          <Col span={8} style={{ textAlign: 'center' }}>
            <Badge.Ribbon text='ยังไม่สมบูรณ์' color='#F5222D' style={{ display: (value?.iscompletedth) ? 'none' : 'block' }}>
              <Button
                disabled={!isAllowed(role, 'view', 'infoTh')}
                type='primary'
                style={{ height: 56, marginTop: 16 }}
                block
                onClick={() => {
                  const path = (isAllowed(role, 'edit', 'infoTh')) ? `/admin/students/${uid}/info-th/edit` : `/admin/students/${uid}/info-th/view`
                  navigate(path)
                }}
              >ประวัตินักเรียน (ไทย)</Button>
            </Badge.Ribbon>
          </Col>
          <Col span={8} style={{ textAlign: 'center' }}>
            <Badge.Ribbon text='ยังไม่สมบูรณ์' color='#F5222D' style={{ display: (value?.iscompletedjp) ? 'none' : 'block' }}>
              <Button
                disabled={!isAllowed(role, 'view', 'infoJp')}
                type='primary'
                style={{ height: 56, marginTop: 16 }}
                block
                onClick={() => {
                  const path = (isAllowed(role, 'edit', 'infoJp')) ? `/admin/students/${uid}/info-jp/edit` : `/admin/students/${uid}/info-jp/view`
                  navigate(path)
                }}
              >ประวัตินักเรียน (ญี่ปุ่น)</Button>
            </Badge.Ribbon>
          </Col>
          <Col span={8} style={{ textAlign: 'center' }}>
            <Badge.Ribbon text='ยังไม่สมบูรณ์' color='#F5222D' style={{ display: (value?.docstatus === 'yes') ? 'none' : 'block' }}>
              <Button
                disabled={!isAllowed(role, 'view', 'documents')}
                type='primary'
                style={{ height: 56, marginTop: 16 }}
                block
                onClick={() => {
                  navigate(`/admin/students/${uid}/documents`)
                }}
              >เอกสาร</Button>
            </Badge.Ribbon>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col span={8} style={{ textAlign: 'center' }}>
            <Button
              disabled={!isAllowed(role, 'view', 'studentBehaviors')}
              type='primary'
              style={{ height: 56, marginTop: 16 }}
              block
              onClick={() => {
                navigate(`/admin/students/${uid}/behaviors`)
              }}
            >พฤติกรรม</Button>
          </Col>
          <Col span={8} style={{ textAlign: 'center' }}>
            <Button
              disabled={!isAllowed(role, 'view', 'studentClasses')}
              type='primary'
              style={{ height: 56, marginTop: 16 }}
              block
              onClick={() => {
                navigate(`/admin/students/${uid}/classes`)
              }}
            >ห้องเรียน</Button>
          </Col>
          <Col span={8} style={{ textAlign: 'center' }}>
            <Button
              disabled={!isAllowed(role, 'view', 'studentFunds')}
              type='primary'
              style={{ height: 56, marginTop: 16 }}
              block
              onClick={() => {
                navigate(`/admin/students/${uid}/funds`)
              }}
            >กองทุนกู้ยืม</Button>
          </Col>
        </Row>
        <Row gutter={48}>
          <Col span={8} style={{ textAlign: 'center' }}>
            <Button
              disabled={!isAllowed(role, 'view', 'studentInterviews')}
              type='primary'
              style={{ height: 56, marginTop: 16 }}
              block
              onClick={() => {
                navigate(`/admin/students/${uid}/interviews`)
              }}
            >สัมภาษณ์งาน</Button>
          </Col>
          <Col span={8} style={{ textAlign: 'center' }}>
            {(isAllowed(role, 'edit', 'students')) && (
              <Button
                type='primary'
                style={{ height: 56, marginTop: 16, backgroundColor: '#EE7930', border: '1px solid #EE7930' }}
                block
                disabled={['resign'].indexOf(status) !== -1}
                onClick={() => {
                  Modal.confirm({
                    title: `${(status === 'suspension') ? 'ยกเลิก' : 'ยืนยันสถานะ'}การพักการเรียนของ ${value?.firstname ?? ''} ${value?.lastname ?? ''}`,
                    icon: <ExclamationCircleOutlined />,
                    onOk() {
                      const s = (status === 'suspension') ? (value.prevstatus || value.status) : 'suspension'
                      setStatus(s)
                      suspension(uid)
                    },
                    onCancel() { }
                  })
                }}
              >{(status === 'suspension') ? 'ยกเลิก' : ''}พักการเรียน</Button>
            )}
          </Col>
          <Col span={8} style={{ textAlign: 'center' }}>
            {(isAllowed(role, 'edit', 'students')) && (
              <Button
                type='primary'
                style={{ height: 56, marginTop: 16, backgroundColor: '#D35C3B', border: '1px solid #D35C3B' }}
                block
                disabled={status === 'resign'}
                onClick={() => {
                  Modal.confirm({
                    title: `ยืนยันสถานะการลาออกของ ${value?.firstname ?? ''} ${value?.lastname ?? ''}`,
                    icon: <ExclamationCircleOutlined />,
                    onOk() {
                      setStatus('resign')
                      resign(uid)
                    },
                    onCancel() { }
                  })
                }}
              >ลาออก</Button>
            )}
          </Col>
        </Row>
      </Card>
    </div>
  )
}

export default Form
