import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Card, Table, Button, Skeleton } from 'antd'
import { EyeOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'
import { dbTodayjs } from '../../utils/date'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'

import StudentInfoCard from './Components/info'

const Form = () => {
  const navigate = useNavigate()
  const { db, role, userId } = useAuth()
  const { id } = useParams()
  const { getClasses, getSubjects } = useLayoutContext()

  const [subjectObj, setSubjectObj] = useState()

  const uid = (role === 'student') ? userId : id

  const { value } = useAsync(async () => {
    const classes = await getClasses()
    const classObj = classes.reduce((acc, o) => {
      acc[o.id] = o
      return acc
    }, {})

    const teacherSnap = await db.collection('/users').where('role', 'in', ['headteacher', 'teacher', 'headadmin', 'admin']).where('disabled', '==', false).where('emailVerified', '==', true).get()
    const teacherObj = teacherSnap.docs.reduce((acc, doc) => {
      acc[doc.id] = `${doc.data()?.firstname ?? ''} ${doc.data()?.lastname ?? ''}`
      return acc
    }, {})

    const subjects = await getSubjects()
    const subjectData = subjects.reduce((acc, o) => {
      acc[o.id] = o.name
      return acc
    }, [])
    setSubjectObj(subjectData)

    const snap = await db.collection('/studentclasses').where('uid', '==', uid).get()
    return snap.docs.map((doc, i) => {
      const data = doc.data()
      return {
        id: doc.id,
        name: classObj[data.cid]?.name ?? '',
        subject: classObj[data.cid]?.subject ?? '',
        hrs: classObj[data.cid]?.hrs ?? '',
        createddate: dbTodayjs(classObj[data.cid]?.createddate),
        enddate: dbTodayjs(classObj[data.cid]?.enddate),
        teachers: (classObj[data.cid]?.teachers || []).map((o) => `${teacherObj[o] ?? ''}`).join(', ')
      }
    })
  }, [uid])

  const columns = [
    {
      title: 'รายวิชา',
      dataIndex: 'name'
    },
    {
      title: 'หมวดวิชา',
      dataIndex: 'subject',
      render: data => (
        <span>{subjectObj[data] ?? ''}</span>
      )
    },
    {
      title: 'วันเริ่มต้นรายวิชา',
      dataIndex: 'createddate',
      render: data => (
        <span>{(data) ? dayjs(data).format('D MMM BBBB') : ''}</span>
      )
    },
    {
      title: 'วันสิ้นสุดรายวิชา',
      dataIndex: 'enddate',
      render: data => (
        <span>{(data) ? dayjs(data).format('D MMM BBBB') : ''}</span>
      )
    },
    {
      title: 'จำนวนชั่วโมง',
      dataIndex: 'hrs',
      align: 'center'
    },
    {
      title: 'ครูผู้สอน',
      dataIndex: 'teachers',
      align: 'center'
    },
    {
      title: 'เครื่องมือ',
      dataIndex: 'Operation',
      width: 100,
      render: (_, row) => (
        <div>
          <Button
            type='link'
            style={{ marginRight: '8px' }}
            icon={<EyeOutlined style={{ fontSize: 18 }} />}
            onClick={() => {
              const path = (role === 'student') ? `/admin/student/classes/${row.id}/view` : `/admin/students/${uid}/classes/${row.id}/view`
              navigate(path)
            }}
          ></Button>
        </div>
      )
    }
  ]

  if (!value) return <Skeleton></Skeleton>
  return (
    <div>
      <StudentInfoCard uid={uid} />
      <Card
        style={{ marginTop: 16 }}
        bodyStyle={{ padding: 32 }}
      >
        <div style={{ marginBottom: 24, fontSize: 24 }}>
          ห้องเรียน
        </div>
        <Table
          bordered
          rowKey='id'
          style={{ overflowX: 'auto' }}
          columns={columns}
          dataSource={value}
        />
      </Card>
    </div>
  )
}

export default Form
