import React from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Card, Table, Button, Skeleton } from 'antd'
import { EyeOutlined } from '@ant-design/icons'

import { formatNumber } from '../../utils/tools'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'

import StudentInfoCard from './Components/info'

const Form = () => {
  const navigate = useNavigate()
  const { db, role, userId } = useAuth()
  const { getLenders } = useLayoutContext()
  const { id } = useParams()

  const uid = (role === 'student') ? userId : id

  const { value } = useAsync(async () => {
    const lenders = await getLenders()
    const lenderData = lenders.reduce((acc, o) => {
      acc[o.id] = o.name
      return acc
    }, {})

    const studentSnap = await db.collection('/users').doc(uid).get()
    const studentData = studentSnap.data()

    const snap = await db.collection('/funds').where('scn', '==', studentData.scn).where('disabled', '==', false).get()
    return snap.docs.map((doc) => {
      const data = doc.data()
      return {
        ...data,
        id: doc.id,
        lender: lenderData[data.lid] ?? ''
      }
    })
  }, [uid])

  const columns = [
    {
      title: 'ผู้ให้กู้',
      dataIndex: 'lender',
      align: 'center'
    },
    {
      title: 'ระยะเวลาทำสัญญา',
      dataIndex: 'period',
      align: 'center',
      render: data => (data) ? `${data} เดือน` : ''
    },
    {
      title: 'วงเงินกู้',
      dataIndex: 'loan',
      align: 'center',
      render: data => formatNumber(data, 0)
    },
    {
      title: 'อัตราผ่อนชำระ',
      dataIndex: 'installment',
      align: 'center',
      render: data => formatNumber(data, 0)
    },
    {
      title: 'อัตราดอกเบี้ย',
      dataIndex: 'interest',
      align: 'center',
      render: data => (data) ? `${formatNumber(data, 2)}%` : ''
    },
    {
      title: 'ยดอคงเหลือ',
      dataIndex: 'payments',
      align: 'center',
      render: data => (data?.length) ? formatNumber(data[data.length - 1]?.principleremain, 0) : ''
    },
    {
      title: 'เครื่องมือ',
      dataIndex: 'Operation',
      width: 150,
      render: (_, row) => (
        <div>
          <Button
            type='link'
            style={{ marginRight: '8px' }}
            icon={<EyeOutlined style={{ fontSize: 18 }} />}
            onClick={() => {
              const path = (role === 'student') ? `/admin/student/funds/${row.id}/view` : `/admin/students/${uid}/funds/${row.id}/view`
              navigate(path)
            }}
          ></Button>
        </div>
      )
    }
  ]

  if (!value) return <Skeleton></Skeleton>
  return (
    <div className='student'>
      <StudentInfoCard uid={uid} />
      <Card
        style={{ marginTop: 16 }}
        bodyStyle={{ padding: 32 }}
      >
        <div style={{ fontSize: 24 }}>
          กองทุน
        </div>
        <Table
          bordered
          rowKey='id'
          style={{ overflowX: 'auto' }}
          columns={columns}
          dataSource={value}
        />
      </Card>
    </div>
  )
}

export default Form
