import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Form as AntdForm, Row, Col, Card, Button, Input, Select, Skeleton } from 'antd'
import { SaveOutlined, CloseOutlined } from '@ant-design/icons'

import { DatePicker } from '../../components/DateTime'
import { dayjsToDB2, dbTodayjs2 } from '../../utils/date'
import InputNumberNew from '../../components/InputNumberNew'

import { useAuth } from '../../contexts/AuthContextProvider'
import { relationObj, relationJpObj, jobTypeObj } from '../../const/student'
import { pickBy } from '../../utils/tools'
import { isAllowed } from '../../route/roles'

const Form = () => {
  const navigate = useNavigate()
  // const { db, role, userId } = useAuth()
  const { db, role } = useAuth()
  const { id } = useParams()

  const [loading, setLoading] = useState(false)

  // const uid = (role === 'student') ? userId : id
  const uid = id
  const readOnly = !isAllowed(role, 'edit', 'infoJp')

  const { value } = useAsync(async () => {
    const userSnap = await db.collection('/users').doc(uid).get()
    const userData = userSnap.data()
    const snap = await db.collection('/studentinfo').doc(uid).get()
    const data = snap.data()
    return {
      ...data,
      ...userData,
      family: (data?.family?.length) ? data.family : [{}],
      jobs: (data?.jobs?.length) ? data.jobs.map((o) => ({ ...o, period: dbTodayjs2(o?.period) })) : [{}],
      jhsperiod: dbTodayjs2(data?.jhsperiod),
      shsperiod: dbTodayjs2(data?.shsperiod),
      dipperiod: dbTodayjs2(data?.dipperiod),
      bdperiod: dbTodayjs2(data?.bdperiod)
    }
  }, [uid])

  const handleSubmit = async (formData) => {
    try {
      setLoading(true)
      const checkInput = Object.keys(formData).find((o) => ((formData[o] === null) || (formData[o] === '')))
      const userData = {
        firstnamejp: formData.firstnamejp,
        lastnamejp: formData.lastnamejp,
        nicknamejp: formData.nicknamejp,
        iscompletedjp: !checkInput
      }
      const formatData = pickBy(userData)
      const isDiff = Object.keys(formatData).find((o) => formatData[o] !== value[o])
      if (isDiff) {
        await db.collection('/users').doc(uid).set(formatData, {
          merge: true
        })
      }
      const data = pickBy(formData)
      await db.collection('/studentinfo').doc(uid).set({
        ...data,
        family: data.family.map((o) => pickBy(o)),
        jobs: data.jobs.map((o) => {
          const obj = pickBy(o)
          return {
            ...obj,
            period: dayjsToDB2(o?.period)
          }
        }),
        jhsperiod: dayjsToDB2(data?.jhsperiod),
        shsperiod: dayjsToDB2(data?.shsperiod),
        dipperiod: dayjsToDB2(data?.dipperiod),
        bdperiod: dayjsToDB2(data?.bdperiod)
      }, {
        merge: true
      })
      setLoading(false)
      navigate(`/admin/students/${uid}/view`)
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  if (!value) return <Skeleton></Skeleton>
  return (
    <Card style={{ padding: 48, minHeight: '100%' }}>
      <AntdForm
        layout='vertical'
        onFinish={handleSubmit}
        initialValues={value}
      >
        <div style={{ marginBottom: 16, fontSize: 24 }}>
          ข้อมูลนักเรียน
        </div>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='firstnamejp' label='ชื่อจริง (ญี่ปุ่น)' >
              <Input disabled={readOnly} placeholder={`${value?.firstname ?? ''}`} />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='lastnamejp' label='นามสกุล (ญี่ปุ่น)' >
              <Input disabled={readOnly} placeholder={`${value?.lastname ?? ''}`} />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='nicknamejp' label='ชื่อเล่น (ญี่ปุ่น)' >
              <Input disabled={readOnly} placeholder={`${value?.nickname ?? ''}`} />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='provincejp' label='จังหวัดที่อยู่ปัจจุบัน' >
              <Input disabled={readOnly} placeholder={`${value?.province ?? ''}`} />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
          <Col span={8}></Col>
        </Row>
        <div style={{ marginBottom: 16, fontSize: 24 }}>
          สมาชิกในครอบครัว
        </div>
        <AntdForm.List
          name='family'
        >
          {(fields) => (
            <>
              {fields.map((field, index) => (
                <div key={`family-${index}`}>
                  <Row gutter={[24, 32]}>
                    <Col span={8}>
                      <AntdForm.Item name={[field.name, 'namejp']} label='ชื่อ - นามสกุล' >
                        <Input disabled={readOnly} placeholder={`${value?.family[index]?.name ?? ''}`} />
                      </AntdForm.Item>
                    </Col>
                    <Col span={8}>
                      <AntdForm.Item name={[field.name, 'relationjp']} label='ความสัมพันธ์' >
                        <Select disabled={readOnly} placeholder={`${relationObj[value?.family[index]?.relation]?.name ?? ''}`} >
                          {((Object.keys(relationJpObj)).map((o) => (
                            <Select.Option key={o} value={o}>{relationJpObj[o]?.nameJp}</Select.Option>
                          )))}
                        </Select>
                      </AntdForm.Item>
                    </Col>
                    <Col span={8}>
                      <AntdForm.Item name={[field.name, 'agejp']} label='อายุ' >
                        <InputNumberNew disabled={readOnly} placeholder={`${value?.family[index]?.age ?? ''}`} />
                      </AntdForm.Item>
                    </Col>
                  </Row>
                  <Row gutter={[24, 32]}>
                    <Col span={8}>
                      <AntdForm.Item name={[field.name, 'occupationjp']} label='อาชีพ' >
                        <Input disabled={readOnly} placeholder={`${value?.family[index]?.occupation ?? ''}`} />
                      </AntdForm.Item>
                    </Col>
                    <Col span={8}>
                      <AntdForm.Item name={[field.name, 'positionjp']} label='ตำแหน่ง' >
                        <Input disabled={readOnly} placeholder={`${value?.family[index]?.position ?? ''}`} />
                      </AntdForm.Item>
                    </Col>
                    <Col span={8}>
                      <AntdForm.Item name={[field.name, 'companyjp']} label='สถานที่ทำงาน / ชื่อบริษัท' >
                        <Input disabled={readOnly} placeholder={`${value?.family[index]?.company ?? ''}`} />
                      </AntdForm.Item>
                    </Col>
                  </Row>
                  <Row gutter={[24, 32]}>
                    <Col span={8}>
                      <AntdForm.Item name={[field.name, 'provincejp']} label='จังหวัด' >
                        <Input disabled={readOnly} placeholder={`${value?.family[index]?.province ?? ''}`} />
                      </AntdForm.Item>
                    </Col>
                    <Col span={8}></Col>
                    <Col span={8}></Col>
                  </Row>
                </div>
              ))}
            </>
          )}
        </AntdForm.List>
        <div style={{ marginBottom: 16, fontSize: 24 }}>
          ประวัติการศึกษา
        </div>
        <div style={{ marginBottom: 8, fontSize: 16 }}>
          ระดับมัธยม
        </div>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='jhsnamejp' label='ชื่อสถานศึกษา' >
              <Input disabled={readOnly} placeholder={`${value?.jhsname ?? ''}`} />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='jhsmajorjp' label='สาขา/แผนก' >
              <Input disabled={readOnly} placeholder={`${value?.jhsmajor ?? ''}`} />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='jhsperiod' label='เข้าศึกษา - จบการศึกษา' >
              <DatePicker.RangePicker disabled={readOnly} />
            </AntdForm.Item>
          </Col>
        </Row>
        <div style={{ marginBottom: 8, fontSize: 16 }}>
          ระดับมัธยมปลาย/ปวช.
        </div>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='shsnamejp' label='ชื่อสถานศึกษา' >
              <Input disabled={readOnly} placeholder={`${value?.shsname ?? ''}`} />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='shsmajorjp' label='สาขา/แผนก' >
              <Input disabled={readOnly} placeholder={`${value?.shsmajor ?? ''}`} />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='shsperiod' label='เข้าศึกษา - จบการศึกษา' >
              <DatePicker.RangePicker disabled={readOnly} />
            </AntdForm.Item>
          </Col>
        </Row>
        <div style={{ marginBottom: 8, fontSize: 16 }}>
          ระดับอนุปริญญา/ปวส./ทวิภาคี
        </div>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='dipnamejp' label='ชื่อสถานศึกษา' >
              <Input disabled={readOnly} placeholder={`${value?.dipname ?? ''}`} />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='dipmajorjp' label='สาขา/แผนก' >
              <Input disabled={readOnly} placeholder={`${value?.dipmajor ?? ''}`} />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='dipperiod' label='เข้าศึกษา - จบการศึกษา' >
              <DatePicker.RangePicker disabled={readOnly} />
            </AntdForm.Item>
          </Col>
        </Row>
        <div style={{ marginBottom: 8, fontSize: 16 }}>
          ระดับปริญญาตรี
        </div>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='bdnamejp' label='ชื่อสถานศึกษา' >
              <Input disabled={readOnly} placeholder={`${value?.bdname ?? ''}`} />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='bdmajorjp' label='สาขา/แผนก' >
              <Input disabled={readOnly} placeholder={`${value?.bdmajor ?? ''}`} />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='bdperiod' label='เข้าศึกษา - จบการศึกษา' >
              <DatePicker.RangePicker disabled={readOnly} />
            </AntdForm.Item>
          </Col>
        </Row>
        <div style={{ marginBottom: 16, fontSize: 24 }}>
          ประวัติการทำงาน
        </div>
        <AntdForm.List
          name='jobs'
        >
          {(fields) => (
            <>
              {fields.map((field, index) => (
                <div key={`jobs-${index}`}>
                  <Row gutter={[24, 32]}>
                    <Col span={8}>
                      <AntdForm.Item name={[field.name, 'companyjp']} label='ชื่อบริษัท' >
                        <Input disabled={readOnly} placeholder={`${value?.jobs[index]?.company ?? ''}`} />
                      </AntdForm.Item>
                    </Col>
                    <Col span={8}>
                      <AntdForm.Item name={[field.name, 'companytypejp']} label='ประเภทกิจการ' >
                        <Input disabled={readOnly} placeholder={`${value?.jobs[index]?.companytype ?? ''}`} />
                      </AntdForm.Item>
                    </Col>
                    <Col span={8}>
                      <AntdForm.Item name={[field.name, 'jdjp']} label='ลักษณะงานที่ทำ' >
                        <Input disabled={readOnly} placeholder={`${value?.jobs[index]?.jd ?? ''}`} />
                      </AntdForm.Item>
                    </Col>
                  </Row>
                  <Row gutter={[24, 32]}>
                    <Col span={8}>
                      <AntdForm.Item name={[field.name, 'jobtypejp']} label='ประเภทงาน' >
                        <Select disabled={readOnly} placeholder={`${jobTypeObj[value?.jobs[index]?.jobtype]?.name ?? ''}`} >
                          {((Object.keys(jobTypeObj)).map((o) => (
                            <Select.Option key={o} value={o}>{jobTypeObj[o]?.name}</Select.Option>
                          )))}
                        </Select>
                      </AntdForm.Item>
                    </Col>
                    <Col span={8}>
                      <AntdForm.Item name={[field.name, 'provincejp']} label='จังหวัด' >
                        <Input disabled={readOnly} placeholder={`${value?.jobs[index]?.province ?? ''}`} />
                      </AntdForm.Item>
                    </Col>
                    <Col span={8}>
                      <AntdForm.Item name={[field.name, 'salaryjp']} label='เงินเดือน' >
                        <InputNumberNew disabled={readOnly} placeholder={`${value?.jobs[index]?.salary ?? ''}`} />
                      </AntdForm.Item>
                    </Col>
                  </Row>
                  <Row gutter={[24, 32]}>
                    <Col span={8}>
                      <AntdForm.Item name={[field.name, 'period']} label='เริ่มงาน - ลาออก' >
                        <DatePicker.RangePicker disabled={readOnly} />
                      </AntdForm.Item>
                    </Col>
                    <Col span={8}></Col>
                    <Col span={8}></Col>
                  </Row>
                </div>
              ))}
            </>
          )}
        </AntdForm.List>
        <div style={{ marginBottom: 16, fontSize: 24 }}>
          คำถามแสดงความคิดเห็น
        </div>
        <AntdForm.Item name='targetjp' label='เป้าหมายชีวิต' >
          <Input disabled={readOnly} placeholder={`${value?.target ?? ''}`} />
        </AntdForm.Item>
        <AntdForm.Item name='hobbyjp' label='งานอดิเรก' >
          <Input disabled={readOnly} placeholder={`${value?.hobby ?? ''}`} />
        </AntdForm.Item>
        <AntdForm.Item name='strengthjp' label='ข้อดี' >
          <Input disabled={readOnly} placeholder={`${value?.strength ?? ''}`} />
        </AntdForm.Item>
        <AntdForm.Item name='weaknessjp' label='ข้อเสีย' >
          <Input disabled={readOnly} placeholder={`${value?.weakness ?? ''}`} />
        </AntdForm.Item>
        <AntdForm.Item name='talentjp' label='ความสามารถพิเศษ' >
          <Input disabled={readOnly} placeholder={`${value?.talent ?? ''}`} />
        </AntdForm.Item>
        {(!readOnly) && (
          <div>
            <Button
              loading={loading}
              style={{ width: 168, height: 40, marginTop: 20 }}
              htmlType='submit'
              type='primary'
            ><SaveOutlined style={{ fontSize: 16 }} />บันทึก</Button>
            <Button
              loading={loading}
              style={{ height: 40, marginTop: 20, marginLeft: 20 }}
              onClick={() => {
                navigate(-1)
              }}
            ><CloseOutlined />ปิด</Button>
          </div>
        )}
      </AntdForm>
    </Card>
  )
}

export default Form
