import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Form as AntdForm, Row, Col, Card, Button, Input, Skeleton } from 'antd'
import { SaveOutlined, CloseOutlined } from '@ant-design/icons'

import TableTransfer from '../../../components/TableTransfer'

import { useAuth } from '../../../contexts/AuthContextProvider'
import { isAllowed } from '../../../route/roles'

const Form = () => {
  const navigate = useNavigate()
  const { firebaseApp, db, role } = useAuth()
  const { aid } = useParams()
  const [loading, setLoading] = useState(false)
  const [evaluations, setEvaluations] = useState()
  const [targetEvaluations, setTargetEvaluations] = useState([])

  const readOnly = !isAllowed(role, 'edit', 'masters', 'assessments')

  const { value } = useAsync(async () => {
    const evaluationSnap = await db.collection('/evaluations').orderBy('createddate', 'asc').get()
    const evaluationData = evaluationSnap.docs.map(doc => {
      return {
        name: doc.data().name,
        key: doc.id,
        id: doc.id
      }
    })
    setEvaluations(evaluationData)

    if (!aid) {
      return {}
    }

    const snap = await db.collection('/assessments').doc(aid).get()
    const data = snap.data()
    setTargetEvaluations(data?.evaluations ?? [])
    return {
      ...data,
      id: snap.id
    }
  }, [aid])

  const handleSubmit = async (formData) => {
    try {
      setLoading(true)
      if (aid) {
        await db.collection('/assessments').doc(aid).set({
          ...formData,
          evaluations: targetEvaluations
        }, {
          merge: true
        })
      } else {
        await db.collection('/assessments').add({
          ...formData,
          evaluations: targetEvaluations,
          createddate: firebaseApp.firestore.FieldValue.serverTimestamp()
        })
      }
      setLoading(false)
      navigate('/admin/masters/assessments')
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  const columns = [
    {
      title: 'หัวข้อประเมิน',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    }
  ]

  if (!value) return <Skeleton></Skeleton>
  return (
    <Card style={{ padding: 48, minHeight: '100%' }}>
      <div style={{ marginBottom: 16, fontSize: 24 }}>
        {(!aid) ? 'สร้าง' : ''}แบบประเมิน
      </div>
      <AntdForm
        layout='vertical'
        onFinish={handleSubmit}
        initialValues={value}
      >
        <Row gutter={[32, 32]}>
          <Col span={12}>
            <AntdForm.Item name='name' label='ชื่อแบบประเมิน' rules={[{ required: true, message: 'กรุณาใส่ชื่อแบบประเมินด้วยค่ะ' }]}>
              <Input placeholder='ชื่อ' disabled={readOnly} />
            </AntdForm.Item>
          </Col>
          <Col span={12}></Col>
        </Row>
        <AntdForm.Item name='evaluations' label='เลือกหัวข้อประเมิน' rules={[{ required: true, message: 'กรุณาเลือกหัวข้อประเมินด้วยค่ะ' }]}>
          <TableTransfer
            titles={['หัวข้อประเมิน', 'เลือกแล้ว']}
            locale={{ itemUnit: 'หัวข้อประเมิน', itemsUnit: 'หัวข้อประเมิน' }}
            dataSource={evaluations}
            targetKeys={targetEvaluations}
            showSearch={true}
            listStyle={{
              width: '100%',
              minHeight: 300,
            }}
            leftColumns={columns}
            rightColumns={columns}
            disabled={readOnly}
            onChange={(_, direction, moveKeys) => {
              let result = []
              if (direction === 'left') {
                result = targetEvaluations.filter((o) => (moveKeys.indexOf(o) === -1))
              } else {
                result = [...targetEvaluations, ...moveKeys]
              }
              setTargetEvaluations(result)
            }}
            filterOption={(inputValue, item) => (
              item.name.indexOf(inputValue) !== -1
            )}
          />
        </AntdForm.Item>
        <div>
          {(!readOnly) && (
            <Button
              loading={loading}
              style={{ width: 168, height: 40, marginTop: 20, marginRight: 20 }}
              htmlType='submit'
              type='primary'
            ><SaveOutlined style={{ fontSize: 16 }} />บันทึก</Button>
          )}
          <Button
            loading={loading}
            style={{ height: 40, marginTop: 20 }}
            onClick={() => {
              navigate(-1)
            }}
          ><CloseOutlined />ปิด</Button>
        </div>
      </AntdForm>
    </Card>
  )
}

export default Form
