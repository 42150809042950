import React, { useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Form as AntdForm, Row, Col, Card, Button, Input, AutoComplete, Select, Upload, Skeleton } from 'antd'
import { SaveOutlined, CloseOutlined, UploadOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons'

import { DatePicker } from '../../components/DateTime'
import { dayjsToDB, dbTodayjs } from '../../utils/date'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { statusObj } from '../../const/complain'
import { pickBy } from '../../utils/tools'

const Form = () => {
  const navigate = useNavigate()
  const { cid } = useParams()
  const { db, storage } = useAuth()
  const { pathname } = useLocation()
  const { getCoops, getCompanies } = useLayoutContext()

  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState([])
  const [students, setStudents] = useState()
  const [coopObj, setCoopObj] = useState()
  const [companyObj, setCompanyObj] = useState()

  const readOnly = (pathname.indexOf('/view') !== -1)

  const [complainForm] = AntdForm.useForm()

  const { value } = useAsync(async () => {
    const coops = await getCoops()
    const coopData = coops.reduce((acc, o) => {
      acc[o.id] = o
      return acc
    }, {})
    setCoopObj(coopData)

    const companies = await getCompanies()
    const companyData = companies.reduce((acc, o) => {
      acc[o.id] = o
      return acc
    }, {})
    setCompanyObj(companyData)

    const studentSnap = await db.collection('/users').where('role', '==', 'student').where('status', '==', 'ajapan').where('disabled', '==', false).get()
    const studentData = studentSnap.docs.map((doc) => {
      const d = doc.data()
      return {
        id: doc.id,
        value: d?.scn ?? '',
        firstname: d.firstname,
        lastname: d.lastname,
        coopid: d?.coopid ?? '',
        companyid: d?.companyid ?? ''
      }
    })
    setStudents(studentData)

    if (!cid) {
      return {
        complains: [{}]
      }
    }

    const snap = await db.collection('/complains').doc(cid).get()
    const data = snap.data()
    setFiles(data.files)
    return {
      ...data,
      id: snap.id,
      date: dbTodayjs(data?.date),
      complains: (!data?.complains?.length) ? [{}] : data.complains
    }
  }, [cid])

  const handleSubmit = async (formData) => {
    try {
      setLoading(true)
      formData = {
        ...formData,
        date: dayjsToDB(formData?.date),
        complains: formData.complains.filter((o) => o?.detail),
        file: null,
        files: files
      }
      const data = pickBy(formData)
      if (cid) {
        await db.collection('/complains').doc(cid).set(data, {
          merge: true
        })
      } else {
        await db.collection('/complains').add(data)
      }
      setLoading(false)
      navigate('/admin/complains')
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  const customRequestFile = async ({ onError, _, file }) => {
    try {
      const ref = storage.ref('complains').child(`${file.name}-${file.uid}`)
      await ref.put(file, {
        contentType: file.type
      })

      const url = await ref.getDownloadURL()

      const data = {
        uid: file.uid,
        name: file.name,
        type: file.type,
        lastModified: file.lastModified,
        url: url
      }

      setFiles([...files, data])
    } catch (e) {
      onError(e)
    }
  }

  if (!value) return <Skeleton></Skeleton>
  return (
    <Card
      style={{ marginTop: 16 }}
      bodyStyle={{ padding: '32px 80px' }}
    >
      <div style={{ margin: '16px 0', fontSize: 24 }}>
        ปัญหานักเรียนที่เกิดในญี่ปุ่น
      </div>
      <AntdForm
        layout='vertical'
        onFinish={handleSubmit}
        initialValues={value}
        form={complainForm}
        name='complainForm'
      >
        <AntdForm.Item name='id' hidden={true}>
          <Input />
        </AntdForm.Item>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='scn' label='รหัสนักเรียน' rules={[{ required: true, message: 'กรุณาใส่รหัสนักเรียนค่ะ' }]}>
              <AutoComplete
                disabled={readOnly}
                options={students}
                placeholder='เลือก'
                filterOption={(inputValue, option) =>
                  option.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                }
                onChange={(v) => {
                  const user = students.find((o) => o.value === v)
                  if (user) {
                    complainForm.setFieldsValue({
                      ...user,
                      scn: user.value
                    })
                  }
                }}
              />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='firstname' label='ชื่อ' rules={[{ required: true, message: 'กรุณาใส่ชื่อค่ะ' }]}>
              <Input disabled={readOnly} placeholder='ชื่อ' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='lastname' label='นามสกุล' rules={[{ required: true, message: 'กรุณาใส่นามสกุลค่ะ' }]}>
              <Input disabled={readOnly} placeholder='นามสกุล' />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='companyid' label='บริษัท' rules={[{ required: true, message: 'กรุณาเลือกบริษัทค่ะ' }]}>
              <Select disabled placeholder='เลือก'>
                {(Object.keys(companyObj).map((o) => (
                  <Select.Option key={o} value={o}>{companyObj[o]?.name ?? ''}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='coopid' label='สหกรณ์' rules={[{ required: true, message: 'กรุณาเลือกสหกรณ์ค่ะ' }]}>
              <Select disabled placeholder='เลือก' >
                {(Object.keys(coopObj).map((o) => (
                  <Select.Option key={o} value={o}>{coopObj[o]?.name ?? ''}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='date' label='วันที่สร้าง' >
              <DatePicker disabled={readOnly} placeholder='เลือก' />
            </AntdForm.Item>
          </Col>
        </Row>
        <AntdForm.List
          name='complains'
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <div key={`complain-${index}`}>
                  <Row gutter={[24, 32]}>
                    <Col span={8}>
                      <AntdForm.Item name={[field.name, 'createby']} label='ผู้สร้าง' rules={[{ required: true, message: 'กรุณาใส่ชื่อคนสร้างค่ะ' }]}>
                        <Input disabled={readOnly} placeholder='ผู้สร้าง' />
                      </AntdForm.Item>
                    </Col>
                    <Col span={8}></Col>
                    <Col span={8}></Col>
                  </Row>
                  <AntdForm.Item name={[field.name, 'detail']} label='รายละเอียด' >
                    <Input.TextArea disabled={readOnly} placeholder='รายละเอียด' autoSize={{ minRows: 1 }} />
                  </AntdForm.Item>
                  <Row gutter={[24, 32]}>
                    <Col span={16}></Col>
                    <Col span={8}>
                      {(index === fields.length - 1) ? (
                        <Button type='primary' onClick={() => add()} icon={<PlusOutlined />} style={{ marginBottom: 8, float: 'right' }}>
                          เพิ่มรายละเอียด
                        </Button>
                      ) : (
                        <Button onClick={() => remove(field.name)} icon={<DeleteOutlined />} style={{ marginBottom: 8, float: 'right', color: '#FFF', backgroundColor: '#EE7930', border: '1px solid #EE7930' }}>
                          ลบ
                        </Button>
                      )}
                    </Col>
                  </Row>
                </div>
              ))}
            </>
          )}
        </AntdForm.List>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='file' label='อัปโหลด' >
              <Upload
                disabled={readOnly}
                accept='.webp, .png, .jpg, .jpeg, .xls, .xlsx, .pdf'
                fileList={files}
                customRequest={customRequestFile}
                onRemove={(file) => {
                  const remainFiles = files.filter((o) => o.uid !== file.uid)
                  setFiles(remainFiles)
                }}
              >
                <Button icon={<UploadOutlined />}>อัปโหลด</Button>
              </Upload>
            </AntdForm.Item>
          </Col>
          <Col span={16}></Col>
        </Row>
        {(cid) && (
          <Row gutter={[24, 32]}>
            <Col span={8}>
              <AntdForm.Item name='status' label='สถานะ' rules={[{ required: true, message: 'กรุณาเลือกสถานะค่ะ' }]}>
                <Select disabled={readOnly} placeholder='เลือก' >
                  {(Object.keys(statusObj).map((o) => (
                    <Select.Option key={o} value={o}>{statusObj[o]?.name ?? ''}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
            <Col span={8}></Col>
            <Col span={8}></Col>
          </Row>
        )}
        {(!readOnly) && (
          <div>
            <Button
              loading={loading}
              style={{ width: 168, height: 40, marginTop: 20 }}
              htmlType='submit'
              type='primary'
            ><SaveOutlined style={{ fontSize: 16 }} />บันทึก</Button>
            <Button
              loading={loading}
              style={{ height: 40, marginTop: 20, marginLeft: 20 }}
              onClick={() => {
                navigate(-1)
              }}
            ><CloseOutlined />ปิด</Button>
          </div>
        )}
      </AntdForm>
    </Card>
  )
}

export default Form
