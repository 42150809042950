const paymentObj = {
  payment: { name: 'จ่ายแล้ว', color: '#228B90' },
  partial: { name: 'จ่ายบางส่วน', color: '#D35C3B' },
  none: { name: 'ยังไม่จ่าย', color: '#D35C3B' },
  return: { name: 'คืนเงิน', color: '#D35C3B' }
}

export {
  paymentObj
}
