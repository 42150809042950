import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { useAsync } from 'react-use'

import { Form as AntdForm, Row, Col, Card, Button, AutoComplete, Input, Skeleton } from 'antd'
import { SaveOutlined, CloseOutlined } from '@ant-design/icons'

import { useAuth } from '../../contexts/AuthContextProvider'

const Form = () => {
  const navigate = useNavigate()
  const { db } = useAuth()

  const [loading, setLoading] = useState(false)
  const [students, setStudents] = useState()

  const [feeForm] = AntdForm.useForm()

  const { value } = useAsync(async () => {
    const snap = await db.collection('/users').where('role', '==', 'student').where('disabled', '==', false).get()
    const data = snap.docs.reduce((acc, o) => {
      const doc = o.data()
      return (doc?.payment) ? acc : [...acc, {
        id: o.id,
        value: doc?.scn ?? '',
        firstname: doc.firstname,
        lastname: doc.lastname
      }]
    }, [])
    setStudents(data)

    return {}
  })

  const handleSubmit = async (formData) => {
    try {
      setLoading(true)
      await db.collection('/users').doc(formData.id).set({
        payment: 'none'
      }, {
        merge: true
      })
      setLoading(false)
      navigate('/admin/fees')
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  if (!value) return <Skeleton></Skeleton>
  return (
    <Card style={{ padding: 48, minHeight: '100%' }}>
      <div style={{ marginBottom: 16, fontSize: 24 }}>
        สร้างค่าลงทะเบียนเรียน
      </div>
      <AntdForm
        layout='vertical'
        onFinish={handleSubmit}
        initialValues={value}
        form={feeForm}
        name='feeForm'
      >
        <AntdForm.Item name='id' hidden={true}>
          <Input />
        </AntdForm.Item>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='scn' label='รหัสนักเรียน' rules={[{ required: true, message: 'กรุณาใส่รหัสนักเรียนค่ะ' }]}>
              <AutoComplete
                options={students}
                placeholder='เลือก'
                filterOption={(inputValue, option) =>
                  option.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                }
                onChange={(v) => {
                  const user = students.find((o) => o.value === v)
                  if (user) {
                    feeForm.setFieldsValue({
                      ...user,
                      scn: user.value
                    })
                  }
                }}
              />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='firstname' label='ชื่อ' rules={[{ required: true, message: 'กรุณาใส่ชื่อค่ะ' }]}>
              <Input placeholder='ชื่อ' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='lastname' label='นามสกุล' rules={[{ required: true, message: 'กรุณาใส่นามสกุลค่ะ' }]}>
              <Input placeholder='นามสกุล' />
            </AntdForm.Item>
          </Col>
        </Row>
        <div>
          <Button
            loading={loading}
            style={{ width: 168, height: 40, marginTop: 20 }}
            htmlType='submit'
            type='primary'
          ><SaveOutlined style={{ fontSize: 16 }} />บันทึก</Button>
          <Button
            loading={loading}
            style={{ height: 40, marginTop: 20, marginLeft: 20 }}
            onClick={() => {
              navigate(-1)
            }}
          ><CloseOutlined />ปิด</Button>
        </div>
      </AntdForm>
    </Card>
  )
}

export default Form
