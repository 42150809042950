import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Form as AntdForm, Row, Col, Card, Button, Input, Select, Upload, Skeleton } from 'antd'
import { SaveOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons'

import { DatePicker, TimePicker } from '../../components/DateTime'
import { dayjsToDB, dbTodayjs } from '../../utils/date'
import InputNumberNew from '../../components/InputNumberNew'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { pickBy } from '../../utils/tools'

const Form = () => {
  const navigate = useNavigate()
  const { firebaseApp, db, storage, userId } = useAuth()
  const { getCoops, getCompanies } = useLayoutContext()
  const { jid } = useParams()
  const [loading, setLoading] = useState(false)
  const [coops, setCoops] = useState([])
  const [companies, setCompanies] = useState([])
  const [targetCompanies, setTargetCompanies] = useState([])
  const [files, setFiles] = useState([])

  const [jobForm] = AntdForm.useForm()

  const { value } = useAsync(async () => {
    const coopData = await getCoops(true)
    setCoops(coopData)

    const companyData = await getCompanies(true)
    setCompanies(companyData)

    if (!jid) {
      return {}
    }

    const snap = await db.collection('/jobs').doc(jid).get()
    const data = snap.data()
    setFiles(data.files || [])
    setTargetCompanies(companyData.filter((o) => o.coid === data?.coopid))
    const coop = coopData.find((o) => o.id === data.coopid)
    const company = companyData.find((o) => o.id === data.companyid)
    return {
      ...data,
      id: snap.id,
      interviewdate: dbTodayjs(data?.interviewdate),
      interviewtime: dbTodayjs(data?.interviewtime),
      coopaddress: coop?.address ?? '',
      companyaddress: company?.address ?? '',
      bustype: company?.type ?? ''
    }
  }, [jid])

  const handleSubmit = async (formData) => {
    try {
      setLoading(true)
      formData = {
        ...formData,
        createddate: (!jid) ? firebaseApp.firestore.FieldValue.serverTimestamp() : null,
        creator: (!jid) ? userId : null,
        interviewdate: dayjsToDB(formData?.interviewdate),
        interviewtime: dayjsToDB(formData?.interviewtime),
        file: null,
        files: files
      }
      const data = pickBy(formData)
      if (jid) {
        await db.collection('/jobs').doc(jid).set(data, {
          merge: true
        })
        setLoading(false)
        navigate(`/admin/jobs/${jid}/view`)
      } else {
        await db.collection('/jobs').add(data)
        setLoading(false)
        navigate('/admin/jobs')
      }
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  const customRequestFile = async ({ onError, _, file }) => {
    try {
      const ref = storage.ref('jobs').child(`${file.name}-${file.uid}`)
      await ref.put(file, {
        contentType: file.type
      })

      const url = await ref.getDownloadURL()

      const data = {
        uid: file.uid,
        name: file.name,
        type: file.type,
        lastModified: file.lastModified,
        url: url
      }

      setFiles([...files, data])
    } catch (e) {
      onError(e)
    }
  }

  if (!value) return <Skeleton></Skeleton>
  return (
    <Card style={{ padding: 48, minHeight: '100%' }}>
      <div style={{ marginBottom: 16, fontSize: 24 }}>
        {(!jid) ? 'สร้าง' : ''}งาน
      </div>
      <AntdForm
        layout='vertical'
        onFinish={handleSubmit}
        initialValues={value}
        form={jobForm}
        name='jobForm'
      >
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='coopid' label='สหกรณ์ (อังกฤษ)' rules={[{ required: true, message: 'กรุณาลือกสหกรณ์ค่ะ' }]}>
              <Select
                placeholder='เลือก'
                onChange={(v) => {
                  const target = companies.filter((o) => o.coid === v)
                  const coop = coops.find((o) => o.id === v)
                  jobForm.setFieldsValue({
                    ...jobForm.getFieldsValue(),
                    coopaddress: coop?.address ?? '',
                    companyid: null,
                    companyaddress: '',
                    bustype: ''
                  })
                  setTargetCompanies(target)
                }}
              >
                {((coops).map((o) => (
                  <Select.Option key={o.id} value={o.id}>{o.name}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='coopaddress' label='ที่อยู่ (สหกรณ์)' >
              <Input placeholder='ที่อยู่ (สหกรณ์)' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='companyid' label='บริษัท (อังกฤษ)' rules={[{ required: true, message: 'กรุณาเลือกบริษัทค่ะ' }]}>
              <Select
                placeholder='เลือก'
                onChange={(v) => {
                  const company = companies.find((o) => o.id === v)
                  jobForm.setFieldsValue({
                    ...jobForm.getFieldsValue(),
                    companyaddress: company?.address ?? '',
                    bustype: company?.type ?? ''
                  })
                }}
              >
                {((targetCompanies).map((o) => (
                  <Select.Option key={o.id} value={o.id}>{o.name}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='companyaddress' label='ที่อยู่ (บริษัท)' >
              <Input placeholder='ที่อยู่ (บริษัท)' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='agency' label='ชื่อบริษัทจัดหางาน (ไทย)' rules={[{ required: true, message: 'กรุณาใส่ชื่อบริษัทจัดหางานค่ะ' }]}>
              <Input placeholder='ชื่อบริษัทจัดหางาน (ไทย)' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={16}>
            <AntdForm.Item name='bustype' label='ประเภทธุรกิจ' >
              <Input placeholder='ประเภทธุรกิจ' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={16}>
            <AntdForm.Item name='jobtype' label='ประเภทงานที่ผู้ฝึกงานปฏิบัติ' >
              <Input placeholder='ประเภทงานที่ผู้ฝึกงานปฏิบัติ' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={16}>
            <AntdForm.Item name='product' label='ชิ้นงานที่ผู้ฝึกงานผลิต' >
              <Input placeholder='ชิ้นงานที่ผู้ฝึกงานผลิต' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='ot' label='เฉลี่ยชั่งโมงล่วงเวลาต่อเดือน (ถ้ามี)' >
              <Input placeholder='เฉลี่ยชั่งโมงล่วงเวลาต่อเดือน (ถ้ามี)' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='senior' label='รุ่นพี่ที่เป็นคนไทยอยู่ที่ญี่ปุ่น' >
              <Input placeholder='รุ่นพี่ที่เป็นคนไทยอยู่ที่ญี่ปุ่น' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='interviewdate' label='วันที่สัมภาษณ์งาน' >
              <DatePicker placeholder='เลือก' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='interviewtime' label='เวลาที่สัมภาษณ์งาน' >
              <TimePicker format='HH:mm' placeholder='เลือก' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='interviewplace' label='สถานที่สัมภาษณ์งาน' >
              <Input placeholder='สถานที่สัมภาษณ์งาน' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='gotojapandate' label='วันที่เดินทางเข้าญี่ปุ่น' >
              <Input placeholder='วันที่เดินทางเข้าญี่ปุ่น' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='no' label='จำนวนที่รับ' >
              <InputNumberNew placeholder='จำนวนที่รับ' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='character' label='คุณสมบัติ' >
              <Input placeholder='คุณสมบัติ' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='sex' label='เพศ' >
              <Input placeholder='เพศ' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='habit' label='นิสัย' >
              <Input placeholder='นิสัย' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='age' label='อายุ' >
              <Input placeholder='อายุ' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='inappropriate' label='ไม่เหมาะสม' >
              <Input placeholder='ไม่เหมาะสม' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={16}>
            <AntdForm.Item name='preexam' label='ข้อสอบที่ให้สอบก่อนวันสัมภาษณ์งาน' >
              <Input placeholder='ข้อสอบที่ให้สอบก่อนวันสัมภาษณ์งาน' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={16}>
            <AntdForm.Item name='exam' label='ข้อสอบที่ให้สอบในวันสัมภาษณ์งาน' >
              <Input placeholder='ข้อสอบที่ให้สอบในวันสัมภาษณ์งาน' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='projector' label='การใช้ Projector' >
              <Input placeholder='การใช้ Projector' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='welding' label='การฝึกเชื่อมก่อนเดินทาง' >
              <Input placeholder='การฝึกเชื่อมก่อนเดินทาง' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={16}>
            <AntdForm.Item name='condition' label='เงื่อนไข หรือหมายเหตุอื่นๆ' >
              <Input placeholder='เงื่อนไข หรือหมายเหตุอื่นๆ' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={16}>
            <AntdForm.Item name='subsidiary' label='บริษัทลูกในประเทศไทย' >
              <Input placeholder='บริษัทลูกในประเทศไทย' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={16}>
            <AntdForm.Item name='file' label='อัปโหลด'>
              <Upload
                accept='.webp, .png, .jpg, .jpeg, .xls, .xlsx, .pdf'
                fileList={files}
                customRequest={customRequestFile}
                onRemove={(file) => {
                  const remainFiles = files.filter((o) => o.uid !== file.uid)
                  setFiles(remainFiles)
                }}
              >
                <Button icon={<UploadOutlined />}>อัปโหลด</Button>
              </Upload>
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <div>
          <Button
            loading={loading}
            style={{ width: 168, height: 40, marginTop: 20 }}
            htmlType='submit'
            type='primary'
          ><SaveOutlined style={{ fontSize: 16 }} />บันทึก</Button>
          <Button
            loading={loading}
            style={{ height: 40, marginTop: 20, marginLeft: 20 }}
            onClick={() => {
              navigate(-1)
            }}
          ><CloseOutlined />ปิด</Button>
        </div>
      </AntdForm>
    </Card>
  )
}

export default Form
