import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Form as AntdForm, Modal, Card, Row, Col, Table, Input, Select, Button, Tag, Space, Skeleton } from 'antd'
import { EyeOutlined, SearchOutlined, CloseOutlined, DeleteOutlined, CheckOutlined } from '@ant-design/icons'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { search } from '../../utils/search'
import { statusObj } from '../../const/installment'
import { courseObj } from '../../const/student'

import BillPdf from './Components/BillPdf'

const List = () => {
  const navigate = useNavigate()
  const { db } = useAuth()
  const { getPageNo, onPageChange, getSearch, setSearch } = useLayoutContext()

  const [initValue, setInitValue] = useState([])
  const [value, setValue] = useState([])
  const [qsVisible, setQsVisible] = useState(false)
  const [qs, setQs] = useState({})
  const [tags, setTags] = useState([])

  const [searchForm] = AntdForm.useForm()

  const { loading } = useAsync(async () => {
    const snap = await db.collection('/users').where('status', '==', 'resign').where('installment', 'in', ['ongoing', 'forgive', 'complete']).get()
    let data = snap.docs.map(doc => {
      const d = doc.data()
      return {
        ...d,
        id: doc.id,
        scn: d?.scn ?? ''
      }
    })
    data = data.sort((x, y) => x.installment.localeCompare(y.installment))

    const q = getSearch('installments')
    setQs(q || {})
    setInitValue(data)
  }, [])

  useEffect(() => {
    const tagList = Object.keys(qs).reduce((acc, o) => {
      return (!qs[o]) ? acc : [...acc, {
        key: o,
        value: (o === 'installment') ? statusObj[qs[o]]?.name : (o === 'course') ? courseObj[qs[o]]?.name : qs[o],
        label: {
          scn: 'รหัสนักเรียน',
          firstname: 'ชื่อ',
          lastname: 'นามสกุล',
          course: 'หลักสูตร',
          installment: 'สถานะ'
        }[o]
      }]
    }, [])
    setTags(tagList)
    const data = search(initValue, qs)
    setValue(data)
  }, [initValue, qs])

  const handleClose = (key) => {
    const newQs = {
      ...qs,
      [key]: null
    }
    setQs(newQs)
    setSearch('installments', newQs)
    searchForm.setFieldsValue(newQs)
  }

  const columns = [
    {
      title: 'รหัสนักเรียน',
      dataIndex: 'scn',
      width: 120
    },
    {
      title: 'ชื่อ',
      dataIndex: 'firstname',
      width: 150
    },
    {
      title: 'นามสกุล',
      dataIndex: 'lastname',
      width: 150
    },
    {
      title: 'หลักสูตร',
      dataIndex: 'course',
      width: 100,
      render: data => (
        <span>{courseObj[data]?.name ?? ''}</span>
      )
    },
    {
      title: 'สถานะ',
      dataIndex: 'installment',
      width: 180,
      render: data => (
        <span style={{ color: statusObj[data]?.color ?? '' }}>{statusObj[data]?.name ?? ''}</span>
      )
    },
    {
      title: 'เครื่องมือ',
      dataIndex: 'Operation',
      width: 120,
      render: (_, row) => (
        <div>
          {(row.hasinstallment) ? (
            <>
              <Button
                type='link'
                style={{ marginRight: 8 }}
                icon={<EyeOutlined style={{ fontSize: 18 }} />}
                onClick={() => {
                  navigate(`/admin/installments/${row.id}/view`)
                }}
              ></Button>
              <BillPdf uid={row.id} />
            </>
          ) : (
            <Button
              type='link'
              style={{ marginRight: 8 }}
              icon={<EyeOutlined style={{ fontSize: 18 }} />}
              onClick={() => {
                navigate(`/admin/installments/${row.id}/create`)
              }}
            ></Button>
          )}
        </div>
      )
    }
  ]

  if (loading) return <Skeleton></Skeleton>
  return (
    <Card style={{ minHeight: '100%' }}>
      <Row>
        <Col flex='auto'>
          <div style={{ fontSize: 24 }}>
            ผ่อนชำระค่าเรียนเมื่อเด็กลาออก
          </div>
          <div style={{ marginTop: 12 }}>
            {tags.map((o) => (
              <Tag
                key={o.key}
                closable
                onClose={() => handleClose(o.key)}
              >
                {o.label}: {o.value}
              </Tag>
            ))}
          </div>
        </Col>
        <Col flex='300px'>
          <div style={{ float: 'right', margin: '48px 0 24px 0', fontSize: 14 }}>
            <Button
              icon={<SearchOutlined />}
              style={{ margin: '0 8px' }}
              onClick={() => {
                setQsVisible(true)
              }}
            ></Button>
          </div>
        </Col>
      </Row>
      <Table
        bordered
        rowKey='id'
        style={{ overflowX: 'auto' }}
        columns={columns}
        dataSource={value}
        pagination={{
          total: value.length,
          current: getPageNo('installments'),
          onChange(page) {
            onPageChange('installments', page)
          }
        }}
      />
      <Modal
        forceRender
        open={qsVisible}
        title={
          <div style={{ padding: '16px 24px', fontWeight: 'bold', fontSize: '18px', lineHeight: '23px', backgroundColor: '#228B90', color: '#F6F6F6', borderRadius: '5px 5px 0px 0px' }}>
            ค้นหา
          </div>
        }
        width='50%'
        closable={false}
        footer={
          <Row justify='center'>
            <Space align='center'>
              <Col>
                <Button
                  type='secondary'
                  size='medium'
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    const empty = {
                      scn: null,
                      firstname: null,
                      lastname: null,
                      installment: null
                    }
                    setQs(empty)
                    setSearch('installments', empty)
                    searchForm.setFieldsValue(empty)
                  }}
                >
                  Clear Data
                </Button>
              </Col>
              <Col>
                <Button
                  size='medium'
                  style={{ color: '#FFF', backgroundColor: '#EE7930' }} icon={<CloseOutlined />}
                  onClick={() => {
                    setQsVisible(false)
                  }}
                >
                  ยกเลิก
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  size='medium'
                  icon={<CheckOutlined />}
                  onClick={() => {
                    const data = searchForm.getFieldsValue()
                    setQs(data)
                    setSearch('installments', data)
                    setQsVisible(false)
                  }}
                >
                  ยืนยัน
                </Button>
              </Col>
            </Space>
          </Row>
        }
      >
        <AntdForm
          layout='vertical'
          initialValues={qs}
          style={{ margin: '8px 32px' }}
          form={searchForm}
          name='searchForm'
        >
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <AntdForm.Item name='scn' label='รหัสนักเรียน' >
                <Input placeholder='รหัสนักเรียน' />
              </AntdForm.Item>
            </Col>
            <Col span={12}>
              <AntdForm.Item name='installment' label='สถานะ' >
                <Select placeholder='เลือก' >
                  {((Object.keys(statusObj)).map((o) => (
                    <Select.Option key={o} value={o}>{statusObj[o].name}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <AntdForm.Item name='firstname' label='ชื่อ' >
                <Input placeholder='ชื่อ' />
              </AntdForm.Item>
            </Col>
            <Col span={12}>
              <AntdForm.Item name='lastname' label='นามสกุล' >
                <Input placeholder='นามสกุล' />
              </AntdForm.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <AntdForm.Item name='course' label='หลักสูตร' >
                <Select placeholder='เลือก' >
                  {((Object.keys(courseObj)).map((o) => (
                    <Select.Option key={o} value={o}>{courseObj[o].name}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
            <Col span={12}></Col>
          </Row>
        </AntdForm>
      </Modal>
    </Card >
  )
}

export default List
