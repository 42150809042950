import React from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Card, Table, Button, Tag, Skeleton } from 'antd'
import { EyeOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'
import { dbTodayjs } from '../../utils/date'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { studentJobStatusObj } from '../../const/jobs'
import { isAllowed } from '../../route/roles'

import StudentInfoCard from './Components/info'

const Form = () => {
  const navigate = useNavigate()
  const { db, role, userId } = useAuth()
  const { id } = useParams()
  const { getCoops, getCompanies, getJobs } = useLayoutContext()

  const uid = (role === 'student') ? userId : id

  const { value } = useAsync(async () => {
    const coopData = await getCoops()
    const coopObj = coopData.reduce((acc, o) => {
      acc[o.id] = o.name
      return acc
    }, {})

    const companyData = await getCompanies()
    const companyObj = companyData.reduce((acc, o) => {
      acc[o.id] = o.name
      return acc
    }, {})

    const jobData = await getJobs()
    const jobObj = jobData.reduce((acc, o) => {
      acc[o.id] = o
      return acc
    }, {})

    const snap = await db.collection('/studentjobs').where('uid', '==', uid).get()
    const result = snap.docs.map((doc, i) => {
      const data = doc.data()
      const jobData = jobObj[data.jid]
      return {
        ...data,
        bustype: jobData?.bustype ?? '',
        coop: coopObj[jobData?.coopid] ?? '',
        company: companyObj[jobData?.companyid] ?? '',
        id: doc.id,
        date: dbTodayjs(data?.date)
      }
    })
    return result.sort((x, y) => x.jobstatus.localeCompare(y.jobstatus))
  }, [uid])

  const columns = [
    {
      title: 'ประเภทธุรกิจ',
      dataIndex: 'bustype'
    },
    {
      title: 'สหกรณ์',
      dataIndex: 'coop'
    },
    {
      title: 'บริษัท',
      dataIndex: 'company'
    },
    {
      title: 'วันที่สร้าง',
      dataIndex: 'date',
      render: data => (
        <span>{(data) ? dayjs(data).format('D MMM BBBB') : ''}</span>
      )
    },
    {
      title: 'สถานะ',
      dataIndex: 'jobstatus',
      width: 120,
      align: 'center',
      render: data => (
        <Tag color={studentJobStatusObj[data]?.color ?? 'error'}>
          {studentJobStatusObj[data]?.name ?? 'ยังไม่สัมภาษณ์'}
        </Tag>
      )
    },
    {
      title: 'เครื่องมือ',
      dataIndex: 'Operation',
      width: 150,
      render: (_, row) => (
        <div>
          <Button
            type='link'
            style={{ marginRight: '8px' }}
            icon={<EyeOutlined style={{ fontSize: 18 }} />}
            onClick={() => {
              const path = (role === 'student') ? `/admin/student/interviews/${row.id}/view` : (isAllowed(role, 'edit', 'studentInterviews')) ? `/admin/students/${uid}/interviews/${row.id}/edit` : `/admin/students/${uid}/interviews/${row.id}/view`
              navigate(path)
            }}
          ></Button>
        </div>
      )
    }
  ]

  if (!value) return <Skeleton></Skeleton>
  return (
    <div className='student'>
      <StudentInfoCard uid={uid} />
      <Card
        style={{ marginTop: 16 }}
        bodyStyle={{ padding: 32 }}
      >
        <div style={{ fontSize: 24 }}>
          สัมภาษณ์งาน
        </div>
        <Table
          bordered
          rowKey='id'
          style={{ overflowX: 'auto' }}
          columns={columns}
          dataSource={value}
        />
      </Card>
    </div>
  )
}

export default Form
