import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Card, Row, Col, Table, Modal, Button, Popconfirm, message, Space, Skeleton } from 'antd'
import { PlusOutlined, EyeOutlined, DeleteOutlined, QrcodeOutlined, SaveOutlined, CopyOutlined } from '@ant-design/icons'

import QRCode from 'qrcode'

import { useAuth } from '../../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../../contexts/LayoutContextProvider'
import { hostName } from '../../../configs/host'
import { isAllowed } from '../../../route/roles'

const List = () => {
  const navigate = useNavigate()
  const { db, role } = useAuth()
  const { getPageNo, onPageChange } = useLayoutContext()

  const [updateCount, setUpdateCount] = useState(0)
  const [modalVisible, setModalVisible] = useState(false)
  const [modalData, setModalData] = useState()

  const { value, loading } = useAsync(async () => {
    const snap = await db.collection('/branches').get()
    return snap.docs.map(doc => {
      return {
        ...doc.data(),
        id: doc.id
      }
    })
  }, [updateCount])

  const handleDelete = async (row) => {
    try {
      await db.collection('/branches').doc(row.id).delete()
      setUpdateCount(updateCount + 1)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const columns = [
    {
      title: 'สาขา',
      dataIndex: 'name'
    },
    {
      title: 'เครื่องมือ',
      dataIndex: 'Operation',
      width: 150,
      render: (_, row) => (
        <div>
          <Button
            type='link'
            style={{ marginRight: '8px' }}
            icon={<EyeOutlined style={{ fontSize: 18 }} />}
            onClick={() => {
              navigate(`/admin/masters/branches/${row.id}/edit`)
            }}
          ></Button>
          <Button
            type='link'
            style={{ marginRight: '8px' }}
            icon={<QrcodeOutlined style={{ fontSize: 18 }} />}
            onClick={async () => {
              const data = {
                name: row.name,
                url: `${hostName}/register?branch=${row.id}`
              }
              const canvas = document.getElementById('canvas')
              await QRCode.toCanvas(canvas, data.url, { width: 400 })
              setModalData(data)
              setModalVisible(true)
            }}
          ></Button>
          {(isAllowed(role, 'delete', 'masters', 'branches')) && (
            <Popconfirm
              title={`Are you sure to delete ${row.name} branch?`}
              onConfirm={() => handleDelete(row)}
              okText='Yes'
              cancelText='No'
            >
              <Button
                type='link'
                danger
                icon={<DeleteOutlined style={{ fontSize: 18 }} />}
              ></Button>
            </Popconfirm>
          )}
        </div>
      )
    }
  ]

  if (loading) return <Skeleton></Skeleton>
  return (
    <Card style={{ minHeight: '100%' }}>
      <Row>
        <Col flex='auto'>
          <div style={{ fontSize: 24 }}>
            สาขา
          </div>
        </Col>
        <Col flex='300px'>
          <div style={{ float: 'right', fontSize: 14 }}>
            {(isAllowed(role, 'create', 'masters', 'branches')) && (
              <div>
                <Button
                  type='primary'
                  icon={<PlusOutlined style={{ fontSize: 14 }} />}
                  onClick={() => {
                    navigate('/admin/masters/branches/create')
                  }}
                >
                  เพิ่มสาขา
                </Button>
              </div>
            )}
            <div style={{ float: 'right', margin: '8px 0 16px 0' }}>
              <div style={{ margin: '24px 0' }}></div>
            </div>
          </div>
        </Col>
      </Row >
      <Table
        bordered
        rowKey='id'
        style={{ overflowX: 'auto' }}
        columns={columns}
        dataSource={value}
        pagination={{
          total: value.length,
          current: getPageNo('branches'),
          onChange(page) {
            onPageChange('branches', page)
          }
        }}
      />
      <Modal
        forceRender
        open={modalVisible}
        title={
          <div style={{ padding: '16px 24px', fontWeight: 'bold', fontSize: '18px', lineHeight: '23px', backgroundColor: '#228B90', color: '#F6F6F6', borderRadius: '5px 5px 0px 0px' }}>
            QR Code สำหรับการลงทะเบียนสาขา {modalData?.name ?? ''}
          </div>
        }
        width='50%'
        closable={false}
        footer={
          <Row justify='center'>
            <Space align='center'>
              <Col>
                <Button
                  size='medium'
                  style={{ color: '#FFF', backgroundColor: '#EE7930' }} icon={<CopyOutlined />}
                  onClick={async () => {
                    const url = document.getElementById('qrcode').innerHTML
                    await navigator.clipboard.writeText(url)
                    message.success('Copy URL เรียบร้อยแล้ว')
                    setModalVisible(false)
                  }}
                >
                  Copy URL
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  size='medium'
                  icon={<SaveOutlined />}
                  onClick={() => {
                    const link = document.createElement('a')
                    link.download = `qrcode_${modalData?.name ?? ''}.png`
                    link.href = document.getElementById('canvas').toDataURL()
                    link.click()
                    setModalVisible(false)
                  }}
                >
                  Copy QR Code Image
                </Button>
              </Col>
            </Space>
          </Row>
        }
      >
        <div style={{ textAlign: 'center' }}>
          <canvas id='canvas'></canvas>
          <div id='qrcode' style={{ color: '#228B90' }}>{modalData?.url ?? ''}</div>
        </div>
      </Modal>
    </Card >
  )
}

export default List
