import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Form as AntdForm, Row, Col, Card, Button, Radio, Input, Tag, Skeleton } from 'antd'
import { SaveOutlined, CloseOutlined } from '@ant-design/icons'

import InputNumberNew from '../../components/InputNumberNew'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { nameTitleObj } from '../../const/users'
import { evaStatusObj } from '../../const/classes'

const Form = () => {
  const navigate = useNavigate()
  const { db } = useAuth()
  const { cid, sid } = useParams()
  const { getClasses } = useLayoutContext()

  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState()

  useAsync(async () => {
    if (!sid) {
      return
    }

    const classes = await getClasses()

    const snap = await db.collection('/studentclasses').doc(sid).get()
    const data = snap.data()

    const evarevSnap = await db.collection('/evarevs').doc(data.eid).get()
    const evarevData = evarevSnap.data()
    const evaresSnap = await db.collection('/evares').doc(sid).get()
    const evaresData = evaresSnap.data()

    const studentSnap = await db.collection('/users').doc(data.uid).get()
    const studentData = studentSnap.data()
    const classData = classes.find((o) => o.id === data.cid)

    setValue({
      data: (evarevData?.data ?? []).map((o, i) => ({
        ...o,
        questions: (o?.questions ?? []).map((p, j) => ({
          ...p,
          ans: evaresData?.data[i]?.questions[j]?.ans ?? 'n',
          follow: evaresData?.data[i]?.questions[j]?.follow ?? ''
        }))
      })),
      scn: studentData?.scn,
      name: `${nameTitleObj[studentData.title]?.nameTh ?? ''} ${studentData?.firstname ?? ''} ${studentData?.lastname ?? ''}`,
      status: (data.evastatus) ? data.evastatus : 'no',
      hrs: (evaresData?.hrs) ? evaresData.hrs : classData.hrs,
      remark: evaresData?.remark ?? ''
    })
    return
  }, [sid])

  const handleSubmit = async (formData) => {
    try {
      setLoading(true)
      const notPass = formData.data.find((o) => (o?.questions ?? []).find((p) => p?.ans !== 'p'))
      await db.collection('/evares').doc(sid).set({
        ...formData,
        sid: sid,
        cid: cid
      }, {
        merge: true
      })
      if (!notPass) {
        await db.collection('/studentclasses').doc(sid).set({
          evastatus: 'yes'
        }, {
          merge: true
        })
      }
      setLoading(false)
      navigate(`/admin/classes/${cid}/view`)
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  if (!value) return <Skeleton></Skeleton>
  return (
    <div>
      <Card
        style={{ marginTop: 16 }}
        bodyStyle={{ padding: '32px 80px' }}
      >
        <Row style={{ marginBottom: 24 }}>
          <Col flex='auto'>
            <div style={{ fontSize: 24 }}>
              ข้อมูลนักเรียน
            </div>
          </Col>
          <Col flex='300px'></Col>
        </Row >
        <Row style={{ marginBottom: 24 }}>
          <Col span={3} className='aiu-title'>รหัสนักเรียน</Col>
          <Col span={5} className='aiu-content'>{value?.scn ?? ''}</Col>
          <Col span={3} className='aiu-title'>ชื่อ</Col>
          <Col span={5} className='aiu-content'>{value.name}</Col>
          <Col span={3} className='aiu-title'>สถานะ</Col>
          <Col span={5} className='aiu-content'>
            <Tag color={evaStatusObj[value.status].color}>
              {evaStatusObj[value.status].name}
            </Tag>
          </Col>
        </Row>
        <AntdForm
          layout='vertical'
          onFinish={handleSubmit}
          initialValues={value}
        >
          {(value?.data ?? []).map((o, i) => (
            <div key={`e-${i}`}>
              <div style={{ margin: '16px 0', fontSize: 24 }}>
                {value?.data[i]?.name ?? ''}
              </div>
              {(o?.questions ?? []).map((p, j) => (
                <Row key={`q-${i}-${j}`}>
                  <Col flex='325px'>
                    <AntdForm.Item name={['data', i, 'questions', j, 'ans']} label={value?.data[i]?.questions[j]?.name ?? ''}>
                      <Radio.Group
                        onChange={(e) => {
                          let _value = value
                          _value.data[i].questions[j].ans = e.target.value
                          setValue({ ..._value })
                        }}
                      >
                        <Radio value='p'>ผ่าน</Radio>
                        <Radio value='n'>ไม่ผ่าน</Radio>
                        <Radio value='t'>ติดตาม</Radio>
                      </Radio.Group>
                    </AntdForm.Item>
                  </Col>
                  <Col flex='auto'>
                    {(value.data[i].questions[j].ans === 't') && (
                      <AntdForm.Item name={['data', i, 'questions', j, 'follow']} style={{ marginTop: 24 }} rules={[{ required: true, message: 'กรุณาใส่การติดตามด้วยค่ะ' }]}>
                        <Input />
                      </AntdForm.Item>
                    )}
                  </Col>
                </Row>
              ))}
            </div>
          ))}
          <AntdForm.Item name='hrs' label='จำนวนชั่วโมง' >
            <InputNumberNew placeholder='จำนวนชั่วโมง' />
          </AntdForm.Item>
          <AntdForm.Item name='remark' label='หมายเหตุ' >
            <Input.TextArea placeholder='หมายเหตุ' autoSize={{ minRows: 1 }} />
          </AntdForm.Item>
          <div>
            <Button
              loading={loading}
              style={{ width: 168, height: 40, marginTop: 20 }}
              htmlType='submit'
              type='primary'
            ><SaveOutlined style={{ fontSize: 16 }} />บันทึก</Button>
            <Button
              loading={loading}
              style={{ height: 40, marginTop: 20, marginLeft: 20 }}
              onClick={() => {
                navigate(-1)
              }}
            ><CloseOutlined />ปิด</Button>
          </div>
        </AntdForm>
      </Card>
    </div>
  )
}

export default Form
