import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Form as AntdForm, Row, Col, Card, Button, Select, Input, Skeleton } from 'antd'
import { SaveOutlined, CloseOutlined } from '@ant-design/icons'

import InputNumberNew from '../../components/InputNumberNew'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { pickBy } from '../../utils/tools'

const Form = () => {
  const navigate = useNavigate()
  const { db } = useAuth()
  const { getCampuses } = useLayoutContext()
  const { rid } = useParams()

  const [loading, setLoading] = useState(false)
  const [campuses, setCampuses] = useState()

  const { value } = useAsync(async () => {
    const campusData = await getCampuses()
    setCampuses(campusData)

    if (!rid) {
      return {}
    }

    const snap = await db.collection('/rooms').doc(rid).get()
    return {
      ...snap.data(),
      id: snap.id
    }
  }, [rid])

  const handleSubmit = async (formData) => {
    try {
      setLoading(true)
      const data = pickBy(formData)
      if (rid) {
        await db.collection('/rooms').doc(rid).set(data, {
          merge: true
        })
      } else {
        await db.collection('/rooms').add(data)
      }
      setLoading(false)
      navigate('/admin/rooms')
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  if (!value) return <Skeleton></Skeleton>
  return (
    <Card style={{ padding: 48, minHeight: '100%' }}>
      <div style={{ marginBottom: 16, fontSize: 24 }}>
        {(!rid) ? 'สร้าง' : ''}หอพัก
      </div>
      <AntdForm
        layout='vertical'
        onFinish={handleSubmit}
        initialValues={value}
      >
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='cid' label='วิทยาเขต' rules={[{ required: true, message: 'กรุณาเลือกวิทยาเขตค่ะ' }]}>
              <Select placeholder='เลือก' >
                {((campuses).map((o) => (
                  <Select.Option key={o.id} value={o.id}>{o.name}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='room' label='หมายเลขห้อง' rules={[{ required: true, message: 'กรุณาใส่หมายเลขห้องค่ะ' }]}>
              <Input placeholder='หมายเลขห้อง' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='total' label='จำนวนเตียง' rules={[{ required: true, message: 'กรุณาใส่จำนวนเตียงค่ะ' }]}>
              <InputNumberNew placeholder='จำนวนเตียง' />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='sex' label='เพศ'>
              <Select placeholder='เลือก' >
                <Select.Option value='m'>ชาย</Select.Option>
                <Select.Option value='f'>หญิง</Select.Option>
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
          <Col span={8}></Col>
        </Row>
        <div>
          <Button
            loading={loading}
            style={{ width: 168, height: 40, marginTop: 20 }}
            htmlType='submit'
            type='primary'
          ><SaveOutlined style={{ fontSize: 16 }} />บันทึก</Button>
          <Button
            loading={loading}
            style={{ height: 40, marginTop: 20, marginLeft: 20 }}
            onClick={() => {
              navigate(-1)
            }}
          ><CloseOutlined />ปิด</Button>
        </div>
      </AntdForm>
    </Card>
  )
}

export default Form
