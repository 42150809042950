import React, { useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Form as AntdForm, Row, Col, Card, Button, AutoComplete, Input, Upload, Skeleton } from 'antd'
import { SaveOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons'

import { DatePicker } from '../../components/DateTime'
import { dayjsToDB, dbTodayjs } from '../../utils/date'

import { useAuth } from '../../contexts/AuthContextProvider'
import { DocumentTypes } from '../../const/student'
import { pickBy } from '../../utils/tools'

const Form = () => {
  const navigate = useNavigate()
  const { db, storage, role, userId } = useAuth()
  const { id, did } = useParams()
  const { pathname } = useLocation()

  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState([])

  const uid = (role === 'student') ? userId : id
  const readOnly = (pathname.indexOf('/view') !== -1)

  const { value } = useAsync(async () => {
    if (!did) {
      return {
        uid: uid
      }
    }

    const snap = await db.collection('/documents').doc(did).get()
    const data = snap.data()
    setFiles(data.files || [])
    return {
      ...data,
      id: snap.id,
      uid: uid,
      date: dbTodayjs(data?.date),
    }
  }, [did])

  const handleSubmit = async (formData) => {
    try {
      setLoading(true)
      formData = {
        ...formData,
        date: dayjsToDB(formData?.date),
        file: null,
        files: files
      }
      const data = pickBy(formData)
      let doc = db.collection('/documents').doc()
      if (did) {
        doc = db.collection('/documents').doc(did)
      }
      await doc.set(data, {
        merge: true
      })
      setLoading(false)
      navigate(`/admin/students/${uid}/documents`)
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  const customRequestFile = async ({ onError, _, file }) => {
    try {
      const ref = storage.ref('documents').child(`${file.name}-${file.uid}`)
      await ref.put(file, {
        contentType: file.type
      })

      const url = await ref.getDownloadURL()

      const data = {
        uid: file.uid,
        name: file.name,
        type: file.type,
        lastModified: file.lastModified,
        url: url
      }

      setFiles([...files, data])
    } catch (e) {
      onError(e)
    }
  }

  if (!value) return <Skeleton></Skeleton>
  return (
    <Card style={{ padding: 48, minHeight: '100%' }}>
      <div style={{ marginBottom: 16, fontSize: 24 }}>
        {(did) ? '' : 'สร้าง'}เอกสาร
      </div>
      <AntdForm
        layout='vertical'
        onFinish={handleSubmit}
        initialValues={value}
      >
        <AntdForm.Item name='uid' >
          <Input hidden={true} />
        </AntdForm.Item>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='date' label='วันที่อัปโหลด' >
              <DatePicker disabled={readOnly} placeholder='เลือก' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='name' label='ชื่อเอกสาร' >
              <AutoComplete
                disabled={readOnly}
                options={DocumentTypes}
                placeholder='เลือก'
                filterOption={(inputValue, option) =>
                  option.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                }
              />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='file' label='อัปโหลด' rules={[{ required: true, message: 'Please upload at least 1 file!' }]}>
              <Upload
                disabled={readOnly}
                accept='.webp, .png, .jpg, .jpeg, .xls, .xlsx, .pdf'
                fileList={files}
                customRequest={customRequestFile}
                onRemove={(file) => {
                  const remainFiles = files.filter((o) => o.uid !== file.uid)
                  setFiles(remainFiles)
                }}
              >
                <Button icon={<UploadOutlined />}>อัปโหลด</Button>
              </Upload>
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
          <Col span={8}></Col>
        </Row>
        {(!readOnly) && (
          <div>
            <Button
              loading={loading}
              style={{ width: 168, height: 40, marginTop: 20 }}
              htmlType='submit'
              type='primary'
            ><SaveOutlined style={{ fontSize: 16 }} />บันทึก</Button>
            <Button
              loading={loading}
              style={{ height: 40, marginTop: 20, marginLeft: 20 }}
              onClick={() => {
                navigate(-1)
              }}
            ><CloseOutlined />ปิด</Button>
          </div>
        )}
      </AntdForm>
    </Card>
  )
}

export default Form
