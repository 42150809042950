import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Form as AntdForm, Card, Row, Col, Button, Input, Select, Skeleton } from 'antd'
import { SaveOutlined, CloseOutlined } from '@ant-design/icons'

import { useAuth } from '../../contexts/AuthContextProvider'
import { studentJobStatusObj } from '../../const/jobs'
import { pickBy } from '../../utils/tools'

const Form = () => {
  const navigate = useNavigate()
  const { db, firebaseApp } = useAuth()
  const { jid, sid } = useParams()
  const [loading, setLoading] = useState(false)

  const { value } = useAsync(async () => {
    if (!sid) {
      return {}
    }

    const snap = await db.collection('/studentjobs').doc(sid).get()
    const data = snap.data()
    const studentSnap = await db.collection('/users').doc(data.uid).get()
    const studentData = studentSnap.data()
    return {
      ...data,
      scn: studentData?.scn ?? '',
      name: `${studentData?.firstname ?? ''} ${studentData?.lastname ?? ''}`,
      nickname: studentData?.nickname ?? ''
    }
  }, [sid])

  const handleSubmit = async (formData) => {
    try {
      setLoading(true)
      let data = pickBy(formData)
      if (value.jobstatus !== 'pass' && formData.jobstatus === 'pass') {
        data.updateddate = firebaseApp.firestore.FieldValue.serverTimestamp()
      }
      if (sid) {
        await db.collection('/studentjobs').doc(sid).set(data, {
          merge: true
        })
      } else {
        await db.collection('/studentjobs').add(data)
      }
      setLoading(false)
      navigate(`/admin/jobs/${jid}/view`)
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  if (!value) return <Skeleton></Skeleton>
  return (
    <div>
      <Card style={{ padding: 48, minHeight: '100%' }}>
        <div style={{ marginBottom: 16, fontSize: 24 }}>
          ผลการสัมภาษณ์งาน
        </div>
        <Row style={{ marginBottom: 24 }}>
          <Col span={3} className='aiu-title'>รหัสนักเรียน</Col>
          <Col span={5} className='aiu-content'>{value.scn}</Col>
          <Col span={3} className='aiu-title'>ชื่อ - สกุล</Col>
          <Col span={5} className='aiu-content'>{value.name}</Col>
          <Col span={3} className='aiu-title'>ชื่อเล่น</Col>
          <Col span={5} className='aiu-content'>{value.nickname}</Col>
        </Row>
        <AntdForm
          layout='vertical'
          onFinish={handleSubmit}
          initialValues={value}
        >
          <Row gutter={[24, 32]}>
            <Col span={8}>
              <AntdForm.Item name='jobstatus' label='ผลการสัมภาษณ์งาน' >
                <Select placeholder='เลือก' >
                  {((Object.keys(studentJobStatusObj)).map((o) => (
                    <Select.Option key={o} value={o}>{studentJobStatusObj[o].name}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
            <Col span={8}></Col>
            <Col span={8}></Col>
          </Row>
          <AntdForm.Item name='comment' label='ความคิดเห็น' >
            <Input.TextArea placeholder='ความคิดเห็น' autoSize={{ minRows: 1 }} />
          </AntdForm.Item>
          <AntdForm.Item name='remark' label='หมายเหตุ' >
            <Input.TextArea placeholder='หมายเหตุ' autoSize={{ minRows: 1 }} />
          </AntdForm.Item>
          <div>
            <Button
              loading={loading}
              style={{ width: 168, height: 40, marginTop: 20 }}
              htmlType='submit'
              type='primary'
            ><SaveOutlined style={{ fontSize: 16 }} />บันทึก</Button>
            <Button
              loading={loading}
              style={{ height: 40, marginTop: 20, marginLeft: 20 }}
              onClick={() => {
                navigate(-1)
              }}
            ><CloseOutlined />ปิด</Button>
          </div>
        </AntdForm>
      </Card>
    </div>
  )
}

export default Form
