import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Row, Col, Card, Table, Select, Button, Modal, Popconfirm, Skeleton } from 'antd'
import { PlusOutlined, EyeOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'
import { dbTodayjs } from '../../utils/date'

import { useAuth } from '../../contexts/AuthContextProvider'
import { DocumentTypes } from '../../const/student'
import { isAllowed } from '../../route/roles'

import StudentInfoCard from './Components/info'

const Form = () => {
  const navigate = useNavigate()
  const { db, role, userId } = useAuth()
  const { id } = useParams()

  const [updateCount, setUpdateCount] = useState(0)
  const [docstatus, setDocstatus] = useState()
  const [enableChange, setEnableChange] = useState(false)

  const uid = (role === 'student') ? userId : id

  const { value } = useAsync(async () => {
    const studentSnap = await db.collection('/users').doc(uid).get()
    const studentData = studentSnap.data()
    setDocstatus(studentData?.docstatus ?? 'no')
    const snap = await db.collection('/documents').where('uid', '==', uid).get()
    let docs = []
    let result = snap.docs.map((doc, i) => {
      const data = doc.data()
      docs.push(data.name)
      return {
        ...data,
        id: doc.id,
        n: i + 1,
        date: dbTodayjs(data?.date),
        i: data?.date?.seconds
      }
    })
    result = result.sort((x, y) => x.i - y.i)
    const remainDoc = DocumentTypes.find((o) => docs.indexOf(o.value) === -1)
    setEnableChange(!remainDoc)
    return result
  }, [uid, updateCount])

  const handleChange = async (value) => {
    try {
      await db.collection('/users').doc(uid).set({
        docstatus: value
      }, {
        merge: true
      })
      setDocstatus(value)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const handleDelete = async (row) => {
    try {
      await db.collection('/documents').doc(row.id).delete()
      setUpdateCount(updateCount + 1)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const columns = [
    {
      title: 'เอกสารที่',
      dataIndex: 'n',
      width: 100,
      render: (data, row, index) => index + 1
    },
    {
      title: 'วันที่อัปโหลด',
      dataIndex: 'date',
      width: 200,
      render: data => (
        <span>{(data) ? dayjs(data).format('D MMM BBBB') : ''}</span>
      )
    },
    {
      title: 'ชื่อเอกสาร',
      dataIndex: 'name'
    },
    {
      title: 'เครื่องมือ',
      dataIndex: 'Operation',
      width: 150,
      render: (_, row) => (
        <div>
          <Button
            type='link'
            style={{ marginRight: '8px' }}
            icon={<EyeOutlined style={{ fontSize: 18 }} />}
            onClick={() => {
              const path = (role === 'student') ? `/admin/student/documents/${row.id}/view` : (isAllowed(role, 'edit', 'documents')) ? `/admin/students/${uid}/documents/${row.id}/edit` : `/admin/students/${uid}/documents/${row.id}/view`
              navigate(path)
            }}
          ></Button>
          {(isAllowed(role, 'delete', 'documents')) && (
            <Popconfirm
              title={`Are you sure to delete ${row.name} document?`}
              onConfirm={() => handleDelete(row)}
              okText='Yes'
              cancelText='No'
            >
              <Button
                type='link'
                style={{ marginRight: '8px' }}
                danger
                icon={<DeleteOutlined style={{ fontSize: 18 }} />}
              ></Button>
            </Popconfirm>
          )}
        </div>
      )
    }
  ]

  if (!value) return <Skeleton></Skeleton>
  return (
    <div>
      <StudentInfoCard uid={uid} />
      <Card
        style={{ marginTop: 16 }}
        bodyStyle={{ padding: 32 }}
      >
        <Row style={{ marginBottom: 24 }}>
          <Col flex='auto'>
            <div style={{ fontSize: 24 }}>
              เอกสาร
            </div>
          </Col>
          <Col flex='300px'>
            <div style={{ float: 'right', fontSize: 14 }}>
              <div>
                {(isAllowed(role, 'edit', 'documents')) && (
                  <Select
                    placeholder='เลือก'
                    style={{ marginRight: 24, width: 150 }}
                    value={docstatus}
                    onChange={(v) => {
                      Modal.confirm({
                        title: `ยืนยันการเปลี่ยนสถานะเป็น${(v === 'no') ? 'เอกสารไม่ครบ' : 'เอกสารครบ'}`,
                        icon: <ExclamationCircleOutlined />,
                        onOk() {
                          handleChange(v)
                        },
                        onCancel() { }
                      })
                    }}
                    disabled={!enableChange}
                  >
                    <Select.Option value='no'>เอกสารไม่ครบ</Select.Option>
                    <Select.Option value='yes'>เอกสารครบ</Select.Option>
                  </Select>
                )}
                {(isAllowed(role, 'create', 'documents')) && (
                  <Button
                    type='primary'
                    icon={<PlusOutlined style={{ fontSize: 14 }} />}
                    onClick={() => {
                      navigate(`/admin/students/${uid}/documents/create`)
                    }}
                  >
                    เอกสาร
                  </Button>
                )}
              </div>
            </div>
          </Col>
        </Row >
        <Table
          bordered
          rowKey='id'
          style={{ overflowX: 'auto' }}
          columns={columns}
          dataSource={value}
        />
      </Card>
    </div>
  )
}

export default Form
