import React, { useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Form as AntdForm, Row, Col, Card, Button, Input, Select, Skeleton } from 'antd'
import { EditOutlined, CloseOutlined } from '@ant-design/icons'

import { useAuth } from '../../contexts/AuthContextProvider'
import { pickBy } from '../../utils/tools'
import { studentJobStatusObj } from '../../const/jobs'

const Form = () => {
  const navigate = useNavigate()
  const { db, role, userId } = useAuth()
  const { id, sid } = useParams()
  const { pathname } = useLocation()

  const [loading, setLoading] = useState(false)
  const [student, setStudent] = useState()

  const uid = (role === 'student') ? userId : id
  const readOnly = (pathname.indexOf('/view') !== -1)

  const { value } = useAsync(async () => {
    if (!sid) {
      return {}
    }

    const studentSnap = await db.collection('/users').doc(uid).get()
    const studentData = studentSnap.data()
    setStudent({
      scn: studentData?.scn,
      name: `${studentData?.firstname ?? ''} ${studentData?.lastname ?? ''}`,
      nickname: studentData?.nickname ?? '',
    })

    const snap = await db.collection('/studentjobs').doc(sid).get()
    return {
      ...snap.data(),
      id: snap.id
    }
  }, [sid])

  const handleSubmit = async (formData) => {
    try {
      setLoading(true)
      const data = pickBy(formData)
      if (sid) {
        await db.collection('/studentjobs').doc(sid).set(data, {
          merge: true
        })
      }
      setLoading(false)
      navigate(`/admin/students/${uid}/interviews`)
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  if (!value) return <Skeleton></Skeleton>
  return (
    <div>
      <Card
        style={{ marginTop: 16 }}
        bodyStyle={{ padding: '32px 80px' }}
      >
        <div style={{ margin: '16px 0', fontSize: 24 }}>
          ผลการสัมภาษณ์งาน
        </div>
        <Row style={{ margin: '32px 0' }}>
          <Col span={3} className='aiu-title'>รหัสนักเรียน</Col>
          <Col span={5} className='aiu-content'>{student?.scn ?? ''}</Col>
          <Col span={3} className='aiu-title'>ชื่อ</Col>
          <Col span={5} className='aiu-content'>{student.name}</Col>
          <Col span={3} className='aiu-title'>ชื่อเล่น</Col>
          <Col span={5} className='aiu-content'>{student.nickname}</Col>
        </Row>
        <AntdForm
          layout='vertical'
          onFinish={handleSubmit}
          initialValues={value}
        >
          <Row gutter={[24, 32]}>
            <Col span={8}>
              <AntdForm.Item name='jobstatus' label='สถานะ' >
                <Select disabled={readOnly} placeholder='เลือก' >
                  {((Object.keys(studentJobStatusObj)).map((o) => (
                    <Select.Option key={o} value={o}>{studentJobStatusObj[o].name}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
            <Col span={8}></Col>
            <Col span={8}></Col>
          </Row>
          <AntdForm.Item name='comment' label='ความคิดเห็น' >
            <Input.TextArea disabled={readOnly} placeholder='ความคิดเห็น' autoSize={{ minRows: 1 }} />
          </AntdForm.Item>
          <AntdForm.Item name='remark' label='หมายเหตุ' >
            <Input.TextArea disabled={readOnly} placeholder='หมายเหตุ' autoSize={{ minRows: 1 }} />
          </AntdForm.Item>
          {(!readOnly) && (
            <div>
              <Button
                loading={loading}
                style={{ width: 168, height: 40, marginTop: 20, color: '#FFFFFF', backgroundColor: '#EE7930' }}
                htmlType='submit'
              ><EditOutlined style={{ fontSize: 16 }} />แก้ไข</Button>
              <Button
                loading={loading}
                style={{ height: 40, marginTop: 20, marginLeft: 20 }}
                onClick={() => {
                  navigate(-1)
                }}
              ><CloseOutlined />ปิด</Button>
            </div>
          )}
        </AntdForm>
      </Card>
    </div>
  )
}

export default Form
