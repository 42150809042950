const firebaseConfig = {
  development: {
    apiKey: 'AIzaSyDPYt4YpYLSYdOwMjGbls5QGyJCSqQTjR4',
    authDomain: 'aluwebsite-develop.firebaseapp.com',
    projectId: 'aluwebsite-develop',
    storageBucket: 'aluwebsite-develop.appspot.com',
    messagingSenderId: '343701406629',
    appId: '1:343701406629:web:e223e300fb7173d1781e4c'
  },
  staging: {
    apiKey: 'AIzaSyDPYt4YpYLSYdOwMjGbls5QGyJCSqQTjR4',
    authDomain: 'aluwebsite-develop.firebaseapp.com',
    projectId: 'aluwebsite-develop',
    storageBucket: 'aluwebsite-develop.appspot.com',
    messagingSenderId: '343701406629',
    appId: '1:343701406629:web:e223e300fb7173d1781e4c'
  },
  production: {
    apiKey: "AIzaSyDjufYqmyE0ZXo-zpXYudhmb-RYeoP-pBw",
    authDomain: "aluwebsite.firebaseapp.com",
    projectId: "aluwebsite",
    storageBucket: "aluwebsite.appspot.com",
    messagingSenderId: "674264818787",
    appId: "1:674264818787:web:2ae70fa42fb12029f54456"
  }
}[process.env.REACT_APP_ENV]

export default firebaseConfig;