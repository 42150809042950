import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Form as AntdForm, Row, Col, Card, Button, Input, Select, Skeleton } from 'antd'
import { SaveOutlined, CloseOutlined } from '@ant-design/icons'

import { DatePicker } from '../../components/DateTime'
import { dayjsToDB, dbTodayjs, dayjsToDB2, dbTodayjs2 } from '../../utils/date'
import InputNumberNew from '../../components/InputNumberNew'

import { useAuth } from '../../contexts/AuthContextProvider'
import { courseObj } from '../../const/student'
import { pickBy } from '../../utils/tools'

const Form = () => {
  const navigate = useNavigate()
  const { iid } = useParams()
  const { db } = useAuth()

  const [loading, setLoading] = useState(false)
  const [hasInstallment, setHasInstallment] = useState(false)

  const { value } = useAsync(async () => {
    const studentSnap = await db.collection('/users').doc(iid).get()
    const studentData = studentSnap.data()

    const snap = await db.collection('/installments').doc(iid).get()
    const data = snap.data()
    if (!data) {
      return {
        ...studentData,
        scn: studentData?.scn ?? '',
        startdate: dbTodayjs(studentData?.startdate),
        resigndate: dbTodayjs(studentData?.resigndate),
        installmentp: dbTodayjs2(studentData?.installmentp)
      }
    }

    setHasInstallment(studentData?.hasinstallment ?? false)
    return {
      ...data,
      scn: studentData?.scn ?? '',
      title: studentData.title,
      firstname: studentData.firstname,
      lastname: studentData.lastname,
      course: studentData.course,
      startdate: dbTodayjs(data?.startdate),
      resigndate: dbTodayjs(data?.resigndate),
      installmentp: dbTodayjs2(data?.installmentp)
    }
  })

  const handleSubmit = async (formData) => {
    try {
      setLoading(true)
      formData = {
        ...formData,
        startdate: dayjsToDB(formData?.startdate),
        resigndate: dayjsToDB(formData?.resigndate),
        installmentp: dayjsToDB2(formData?.installmentp)
      }
      const data = pickBy(formData)
      if (!hasInstallment) {
        await db.collection('/users').doc(iid).set({
          hasinstallment: true
        }, {
          merge: true
        })
      }
      await db.collection('/installments').doc(iid).set(data, {
        merge: true
      })
      setLoading(false)
      navigate('/admin/installments')
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  if (!value) return <Skeleton></Skeleton>
  return (
    <Card style={{ padding: 48, minHeight: '100%' }}>
      <div style={{ marginBottom: 16, fontSize: 24 }}>
        การผ่อนชำระค่าเรียนเมื่อเด็กลาออก
      </div>
      <AntdForm
        layout='vertical'
        onFinish={handleSubmit}
        initialValues={value}
      >
        <AntdForm.Item name='title' hidden={true}>
          <Input />
        </AntdForm.Item>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='scn' label='รหัสนักเรียน' rules={[{ required: true, message: 'กรุณาใส่รหัสนักเรียนค่ะ' }]}>
              <Input placeholder='รหัสนักเรียน' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='firstname' label='ชื่อ' rules={[{ required: true, message: 'กรุณาใส่ชื่อค่ะ' }]}>
              <Input placeholder='ชื่อ' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='lastname' label='นามสกุล' rules={[{ required: true, message: 'กรุณาใส่นามสกุลค่ะ' }]}>
              <Input placeholder='นามสกุล' />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='course' label='หลักสูตร' >
              <Select placeholder='เลือก' >
                {((Object.keys(courseObj)).map((o) => (
                  <Select.Option key={o} value={o}>{courseObj[o]?.name}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
          <Col span={8}></Col>
        </Row>
        <div style={{ marginBottom: 16, fontSize: 24 }}>
          รายละเอียดค่าเรียน
        </div>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='startdate' label='วันที่เข้าเรียน' >
              <DatePicker placeholder='เลือก' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='resigndate' label='วันที่ออก' >
              <DatePicker placeholder='เลือก' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='days' label='จำนวนวันที่เรียน' >
              <InputNumberNew placeholder='จำนวนวันที่เรียน' />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='hrs' label='จำนวนชั่วโมงเรียน' >
              <InputNumberNew placeholder='จำนวนชั่วโมงเรียน' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='tuition' label='ค่าเรียนต่อชั่วโมง' >
              <InputNumberNew placeholder='ค่าเรียนต่อชั่วโมง' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='fee' label='ค่าเรียน 5,000' >
              <InputNumberNew placeholder='ค่าเรียน 5,000' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='coursefee' label='ค่าหลักสูตรวิชาชีพ' >
              <InputNumberNew placeholder='ค่าหลักสูตรวิชาชีพ' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='loan' label='เงินกู้ระหว่างเรียน' >
              <InputNumberNew placeholder='เงินกู้ระหว่างเรียน' />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='remarkfee' label='หมายเหตุ' >
              <Input.TextArea autoSize={{ minRows: 1 }} placeholder='หมายเหตุ' />
            </AntdForm.Item>
          </Col>
          <Col span={16}></Col>
        </Row>
        <div style={{ marginBottom: 16, fontSize: 24 }}>
          รายละเอียดค่าหอพัก
        </div>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='dorm' label='ค่าหอพัก' >
              <InputNumberNew placeholder='ค่าหอพัก' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='dormtotal' label='ค่าหอพัก (ราคารวม)' >
              <InputNumberNew placeholder='ค่าหอพัก (ราคารวม)' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='water' label='ค่าน้ำ' >
              <InputNumberNew placeholder='ค่าน้ำ' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='watertotal' label='ค่าน้ำ (ราคารวม)' >
              <InputNumberNew placeholder='ค่าน้ำ (ราคารวม)' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='elec' label='ค่าไฟ' >
              <InputNumberNew placeholder='ค่าไฟ' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='electotal' label='ค่าไฟ (ราคารวม)' >
              <InputNumberNew placeholder='ค่าไฟ (ราคารวม)' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='shirt' label='ค่าเสื้อนักเรียน (ตัวละ)' >
              <InputNumberNew placeholder='ค่าเสื้อนักเรียน (ตัวละ)' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='shirttotal' label='ค่าเสื้อนักเรียน (ราคารวม)' >
              <InputNumberNew placeholder='ค่าเสื้อนักเรียน (ราคารวม)' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='other' label='ค่าอื่นๆ' >
              <InputNumberNew placeholder='ค่าอื่นๆ' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='othertotal' label='ค่าอื่นๆ (ราคารวม)' >
              <InputNumberNew placeholder='ค่าอื่นๆ (ราคารวม)' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='total' label='ค่าใช้จ่ายรวมที่เรียกเก็บ' >
              <InputNumberNew placeholder='ค่าใช้จ่ายรวมที่เรียกเก็บ' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='installmentn' label='ผ่อนชำระ (งวด)' >
              <InputNumberNew placeholder='ผ่อนชำระ (งวด)' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='installmentc' label='ผ่อนงวดละ (บาท)' >
              <InputNumberNew placeholder='ผ่อนงวดละ (บาท)' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='installmentp' label='ช่วงเวลาการผ่อนชำระ' >
              <DatePicker.RangePicker />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='remarkdorm' label='หมายเหตุ' >
              <Input.TextArea autoSize={{ minRows: 1 }} placeholder='หมายเหตุ' />
            </AntdForm.Item>
          </Col>
          <Col span={16}></Col>
        </Row>
        <div>
          <Button
            loading={loading}
            style={{ width: 168, height: 40, marginTop: 20 }}
            htmlType='submit'
            type='primary'
          ><SaveOutlined style={{ fontSize: 16 }} />บันทึก</Button>
          <Button
            loading={loading}
            style={{ height: 40, marginTop: 20, marginLeft: 20 }}
            onClick={() => {
              navigate(-1)
            }}
          ><CloseOutlined />ปิด</Button>
        </div>
      </AntdForm>
    </Card>
  )
}

export default Form
