import React, { useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router'
import { useAsync } from 'react-use'

import { Form as AntdForm, Row, Col, Card, Button, Input, Skeleton } from 'antd'
import { SaveOutlined, CloseOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons'

import { DatePicker } from '../../components/DateTime'
import { dayjsToDB, dbTodayjs } from '../../utils/date'
import InputNumberNew from '../../components/InputNumberNew'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { pickBy, monthDiff } from '../../utils/tools'


const Form = () => {
  const navigate = useNavigate()
  const { cid, iid, type } = useParams()
  const location = useLocation()
  const { db } = useAuth()
  const { getCompanies } = useLayoutContext()

  const [loading, setLoading] = useState(false)

  const [invoiceForm] = AntdForm.useForm()

  const isCopy = (location.pathname).indexOf('/copy') !== -1

  const { value } = useAsync(async () => {
    const coopSnap = await db.collection('/coops').doc(cid).get()
    const coopData = {
      coop: coopSnap.data()?.name ?? '',
      cooptel: coopSnap.data()?.tel ?? '',
      coopfax: coopSnap.data()?.fax ?? '',
      coopaddress: coopSnap.data()?.address ?? ''
    }

    if (!iid || isCopy) {
      const companyData = await getCompanies()
      const companyObj = companyData.reduce((acc, o) => {
        acc[o.id] = o
        return acc
      }, {})

      const studentSnap = await db.collection('/jpstudents').where('coopid', '==', cid).where('status', '==', 'ajapan').get()
      const today = new Date()
      let companies = {}
      let sumy1 = 0
      let sumy2 = 0
      let sumy3 = 0
      let sumy4 = 0
      let sumy5 = 0
      studentSnap.docs.forEach((o) => {
        const d = o.data()
        if (!d?.traveldate) {
          return
        }
        const traveldate = dbTodayjs(d?.traveldate)
        const traveldateJs = d?.traveldate?.toDate()
        const date = `${traveldateJs.getFullYear()}${traveldateJs.getMonth() + 1}${traveldateJs.getDate()}`
        const key = `${d.companyid}-${date}`
        const noTime = monthDiff(traveldateJs, today)
        const round = parseInt(d.round)
        const n = (companies[key]?.n) ? companies[key]?.n + 1 : 1
        const y1 = (round === 1 && (noTime <= 12)) ? n : null
        const y2 = (round === 1 && (noTime > 12 && noTime <= 24)) ? n : null
        const y3 = (round === 1 && (noTime > 24)) ? n : null
        const y4 = (round === 2 && (noTime <= 12)) ? n : null
        const y5 = (round === 2 && (noTime > 12)) ? n : null
        sumy1 += y1
        sumy2 += y2
        sumy3 += y3
        sumy4 += y4
        sumy5 += y5
        companies[key] = {
          key: key,
          cid: d.companyid,
          company: companyObj[d.companyid]?.name,
          traveldate: traveldate,
          round: round,
          n: n,
          date: date,
          noTime: noTime,
          y1: y1,
          y2: y2,
          y3: y3,
          y4: y4,
          y5: y5
        }
      })
      let companyList = Object.keys(companies).map((o) => (companies[o]))
      companyList = companyList.sort((x, y) => x.key.localeCompare(y.key))

      if (!iid) {
        return {
          ...coopData,
          list: (companyList?.length) ? companyList : [{}],
          sumy1: sumy1,
          sumy2: sumy2,
          sumy3: sumy3,
          sumy4: sumy4,
          sumy5: sumy5,
          sum: sumy1 + sumy2 + sumy3 + sumy4 + sumy5,
          total: 0
        }
      }

      const snap = await db.collection('/invoices').doc(iid).get()
      const data = snap.data()
      return {
        ...data,
        ...coopData,
        list: (companyList?.length) ? companyList : [{}],
        sumy1: sumy1,
        sumy2: sumy2,
        sumy3: sumy3,
        sumy4: sumy4,
        sumy5: sumy5,
        sum: sumy1 + sumy2 + sumy3 + sumy4 + sumy5,
        total: 0,
        no: '',
        date: null,
        paymentdate: null,
        feemonth: null
      }
    }

    const snap = await db.collection('/invoices').doc(iid).get()
    const data = snap.data()
    return {
      ...data,
      ...coopData,
      id: snap.id,
      date: dbTodayjs(data?.date),
      paymentdate: dbTodayjs(data?.paymentdate),
      feemonth: dbTodayjs(data?.feemonth),
      list: data.list.map((o) => ({
        ...o,
        traveldate: dbTodayjs(o?.traveldate)
      }))
    }
  }, [iid])

  const handleSubmit = async (formData) => {
    try {
      setLoading(true)
      formData = {
        ...formData,
        cid: cid,
        type: type,
        date: dayjsToDB(formData?.date),
        paymentdate: dayjsToDB(formData?.paymentdate),
        feemonth: dayjsToDB(formData?.feemonth),
        list: formData.list.map((o) => pickBy({
          ...o,
          traveldate: dayjsToDB(o?.traveldate)
        }))
      }
      const data = pickBy(formData)
      if (!iid || isCopy) {
        await db.collection('/invoices').add(data)
      } else {
        await db.collection('/invoices').doc(iid).set(data, {
          merge: true
        })
      }
      await db.collection('/coops').doc(cid).set({
        latestdate: data.date,
        latestinv: formData.no
      }, {
        merge: true
      })

      setLoading(false)
      navigate(`/admin/invoices/${cid}/view`)
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  if (!value) return <Skeleton></Skeleton>
  return (
    <Card style={{ padding: 48, minHeight: '100%' }}>
      <div style={{ marginBottom: 16, fontSize: 24 }}>
        ข้อมูลเอกสาร
      </div>
      <AntdForm
        layout='vertical'
        onFinish={handleSubmit}
        initialValues={value}
        form={invoiceForm}
        name='invoiceForm'
      >
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='no' label='Invoice No.' rules={[{ required: true, message: 'กรุณาใส่ Invoice No. ค่ะ' }]}>
              <Input placeholder='Invoice No.' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='date' label='วัน/เดือน/ปี' rules={[{ required: true, message: 'กรุณาเลือกวัน/เดือน/ปีค่ะ' }]}>
              <DatePicker placeholder='เลือก' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='paymentdate' label='วันที่เรียกเก็บเงิน' rules={[{ required: true, message: 'กรุณาเลือกวันที่เรียกเก็บเงินค่ะ' }]}>
              <DatePicker placeholder='เลือก' />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='feemonth' label='ค่าดูแล (เดือน)' rules={[{ required: true, message: 'กรุณาเลือกค่าดูแลค่ะ' }]}>
              <DatePicker format='BBBB/MM' picker='month' placeholder='เลือก' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
          <Col span={8}></Col>
        </Row>
        <div style={{ marginBottom: 16, fontSize: 24 }}>
          ข้อมูลบริษัทผู้ส่ง
        </div>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='sender' label='ชื่อบริษัทผู้ส่ง' rules={[{ required: true, message: 'กรุณาใส่ชื่อบริษัทผู้ส่งค่ะ' }]}>
              <Input placeholder='ชื่อบริษัทผู้ส่ง' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='sendertax' label='TAX ID' rules={[{ required: true, message: 'กรุณาใส่ TAX ID ค่ะ' }]}>
              <Input placeholder='TAX ID' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='sendertel' label='Tel.' >
              <Input placeholder='Tel.' />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='senderfax' label='Fax' >
              <Input placeholder='Fax' />
            </AntdForm.Item>
          </Col>
          <Col span={16}>
            <AntdForm.Item name='senderaddress' label='Address' >
              <Input placeholder='Address' />
            </AntdForm.Item>
          </Col>
        </Row>
        <div style={{ marginBottom: 16, fontSize: 24 }}>
          ข้อมูลสหกรณ์
        </div>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='coop' label='ชื่อสหกรณ์' rules={[{ required: true, message: 'กรุณาใส่ชื่อสหกรณ์ค่ะ' }]}>
              <Input placeholder='ชื่อสหกรณ์' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='cooptel' label='Tel.' >
              <Input placeholder='Tel.' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='coopfax' label='Fax' >
              <Input placeholder='Fax' />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={16}>
            <AntdForm.Item name='coopaddress' label='Address' >
              <Input placeholder='Address' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <div style={{ marginBottom: 16, fontSize: 24 }}>
          วิธีเรียกเก็บเงิน
        </div>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='accountname' label='Account Name' rules={[{ required: true, message: 'กรุณาใส่ Account Name ค่ะ' }]}>
              <Input placeholder='Account Name' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='bank' label='Bank Name' rules={[{ required: true, message: 'กรุณาใส่ Bank Name ค่ะ' }]}>
              <Input placeholder='Bank Name' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='bankaddress' label='Bank Address' rules={[{ required: true, message: 'กรุณาใส่ Bank Address ค่ะ' }]}>
              <Input placeholder='Bank Address' />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='branch' label='Branch' rules={[{ required: true, message: 'กรุณาใส่ Branch ค่ะ' }]}>
              <Input placeholder='Branch' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='accountno' label='Account No.' rules={[{ required: true, message: 'กรุณาใส่ Account No. ค่ะ' }]}>
              <Input placeholder='Account No.' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='swift' label='Swift Code' rules={[{ required: true, message: 'กรุณาใส่ Swift Code ค่ะ' }]}>
              <Input placeholder='Swift Code' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        {(!!value?.list) && (
          <Row gutter={[8, 8]} style={{ marginTop: 24, textAlign: 'center' }}>
            <Col span={5} >
              COMPANY NAME
            </Col>
            <Col span={10}>
              NUMBER OF TECHNICAL INTERN TRAINEE
              <Row gutter={[0, 8]} style={{ borderTop: '1px solid black' }}>
                <Col flex={2}>
                  1st year
                </Col>
                <Col flex={2}>
                  2nd year
                </Col>
                <Col flex={2}>
                  3rd year
                </Col>
                <Col flex={2}>
                  4th year
                </Col>
                <Col flex={2}>
                  5th year
                </Col>
              </Row>
            </Col>
            <Col span={3}>
              ENTRY DATE
            </Col>
            <Col span={2}>
              NUMBER OF TIMES
            </Col>
            <Col span={3}>
              SUBTOTAL
            </Col>
            <Col span={1}></Col>
          </Row>
        )}
        <AntdForm.List
          name='list'
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Row key={index} gutter={8}>
                  <Col span={5}>
                    <AntdForm.Item name={[field.name, 'cid']} hidden={true}>
                      <Input />
                    </AntdForm.Item>
                    <AntdForm.Item name={[field.name, 'company']} >
                      <Input />
                    </AntdForm.Item>
                  </Col>
                  <Col span={2}>
                    <AntdForm.Item name={[field.name, 'y1']} >
                      <InputNumberNew
                        onChange={(v) => {
                          let _value = invoiceForm.getFieldsValue()
                          _value.list[index]['y1'] = v
                          _value.sumy1 = _value.list.reduce((acc, o) => acc + o['y1'], 0)
                          _value.sum = _value.sumy1 + _value.sumy2 + _value.sumy3 + _value.sumy4 + _value.sumy5
                          invoiceForm.setFieldsValue({
                            ..._value
                          })
                        }}
                      />
                    </AntdForm.Item>
                  </Col>
                  <Col span={2}>
                    <AntdForm.Item name={[field.name, 'y2']} >
                      <InputNumberNew
                        onChange={(v) => {
                          let _value = invoiceForm.getFieldsValue()
                          _value.list[index]['y2'] = v
                          _value.sumy2 = _value.list.reduce((acc, o) => acc + o['y2'], 0)
                          _value.sum = _value.sumy1 + _value.sumy2 + _value.sumy3 + _value.sumy4 + _value.sumy5
                          invoiceForm.setFieldsValue({
                            ..._value
                          })
                        }}
                      />
                    </AntdForm.Item>
                  </Col>
                  <Col span={2}>
                    <AntdForm.Item name={[field.name, 'y3']} >
                      <InputNumberNew
                        onChange={(v) => {
                          let _value = invoiceForm.getFieldsValue()
                          _value.list[index]['y3'] = v
                          _value.sumy3 = _value.list.reduce((acc, o) => acc + o['y3'], 0)
                          _value.sum = _value.sumy1 + _value.sumy2 + _value.sumy3 + _value.sumy4 + _value.sumy5
                          invoiceForm.setFieldsValue({
                            ..._value
                          })
                        }}
                      />
                    </AntdForm.Item>
                  </Col>
                  <Col span={2}>
                    <AntdForm.Item name={[field.name, 'y4']} >
                      <InputNumberNew
                        onChange={(v) => {
                          let _value = invoiceForm.getFieldsValue()
                          _value.list[index]['y4'] = v
                          _value.sumy4 = _value.list.reduce((acc, o) => acc + o['y4'], 0)
                          _value.sum = _value.sumy1 + _value.sumy2 + _value.sumy3 + _value.sumy4 + _value.sumy5
                          invoiceForm.setFieldsValue({
                            ..._value
                          })
                        }}
                      />
                    </AntdForm.Item>
                  </Col>
                  <Col span={2}>
                    <AntdForm.Item name={[field.name, 'y5']} >
                      <InputNumberNew
                        onChange={(v) => {
                          let _value = invoiceForm.getFieldsValue()
                          _value.list[index]['y5'] = v
                          _value.sumy5 = _value.list.reduce((acc, o) => acc + o['y5'], 0)
                          _value.sum = _value.sumy1 + _value.sumy2 + _value.sumy3 + _value.sumy4 + _value.sumy5
                          invoiceForm.setFieldsValue({
                            ..._value
                          })
                        }}
                      />
                    </AntdForm.Item>
                  </Col>
                  <Col span={3}>
                    <AntdForm.Item name={[field.name, 'traveldate']} >
                      <DatePicker />
                    </AntdForm.Item>
                  </Col>
                  <Col span={2}>
                    <AntdForm.Item name={[field.name, 'noTime']} >
                      <InputNumberNew />
                    </AntdForm.Item>
                  </Col>
                  <Col span={3}>
                    <AntdForm.Item name={[field.name, 'total']} >
                      <InputNumberNew
                        onChange={(v) => {
                          let _value = invoiceForm.getFieldsValue()
                          _value.list[index]['total'] = v
                          _value.total = _value.list.reduce((acc, o) => acc + o['total'], 0)
                          invoiceForm.setFieldsValue({
                            ..._value
                          })
                        }}
                      />
                    </AntdForm.Item>
                  </Col>
                  <Col span={1}>
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={() => remove(field.name)}
                    >
                    </Button>
                  </Col>
                </Row>
              ))}
              <Button
                icon={<PlusOutlined />}
                style={{ marginBottom: 16 }}
                onClick={() => add()}
              >
                เพิ่มแถว
              </Button>
            </>
          )}
        </AntdForm.List>
        <Row gutter={[16, 8]}>
          <Col span={5}>
            <Input value='Total' />
          </Col>
          <Col span={2}>
            <AntdForm.Item name='sumy1' >
              <InputNumberNew />
            </AntdForm.Item>
          </Col>
          <Col span={2}>
            <AntdForm.Item name='sumy2' >
              <InputNumberNew />
            </AntdForm.Item>
          </Col>
          <Col span={2}>
            <AntdForm.Item name='sumy3' >
              <InputNumberNew />
            </AntdForm.Item>
          </Col>
          <Col span={2}>
            <AntdForm.Item name='sumy4' >
              <InputNumberNew />
            </AntdForm.Item>
          </Col>
          <Col span={2}>
            <AntdForm.Item name='sumy5' >
              <InputNumberNew />
            </AntdForm.Item>
          </Col>
          <Col span={3}>
            <AntdForm.Item name='sum' >
              <InputNumberNew />
            </AntdForm.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={3}>
            <AntdForm.Item name='total' >
              <InputNumberNew />
            </AntdForm.Item>
          </Col>
          <Col span={1}></Col>
        </Row>
        <div>
          <Button
            loading={loading}
            style={{ width: 168, height: 40, marginTop: 20 }}
            htmlType='submit'
            type='primary'
          ><SaveOutlined style={{ fontSize: 16 }} />บันทึก</Button>
          <Button
            loading={loading}
            style={{ height: 40, marginTop: 20, marginLeft: 20 }}
            onClick={() => {
              navigate(-1)
            }}
          ><CloseOutlined />ปิด</Button>
        </div>
      </AntdForm>
    </Card >
  )
}

export default Form
