const statusObj = {
  open: { name: 'กำลังหานักเรียน' },
  close: { name: 'ปิดออร์เดอร์' }
}

const studentJobStatusObj = {
  cancel: { name: 'ยกเลิก', color: 'error' },
  no: { name: 'ยังไม่สัมภาษณ์', color: 'error' },
  notpass: { name: 'ไม่ผ่าน', color: 'error' },
  reserve: { name: 'ตัวสำรอง', color: 'error' },
  pass: { name: 'ผ่าน', color: 'success' }
}

export {
  statusObj,
  studentJobStatusObj
}
