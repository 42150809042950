import React, { useState, useEffect } from 'react'

import { Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'

import { genPdf } from '../../../utils/pdf'
import { nameTitleObj } from '../../../const/users'
import { courseObj, degreeObj, learningSystemObj, relationshipObj, bloodObj, relationObj, jobTypeObj } from '../../../const/student'
import { toDataURL } from '../../../utils/tools'

import logoImg from '../../../assets/images/logo.png'
import NoProfile from '../../../assets/images/user.png'

const InfoPdf = ({ value }) => {
  const [logo, setLogo] = useState()
  const [photo, setPhoto] = useState()

  useEffect(() => {
    toDataURL(logoImg).then(data => {
      setLogo(data)
    })
    toDataURL(value?.imgurl).then(data => {
      setPhoto(data || NoProfile)
    })
  }, [value])

  const print = async () => {
    const docSetting = {
      headerFontSize: 24,
      titleFontSize: 16,
      contentFontSize: 14,
      titleMargin: [0, 16, 0, 0],
      labelWidth: 200,
      contentMargin: [0, 0, 0, 0]
    }

    const contents = [
      {
        title: ''
      },
      {
        label: 'รหัสนักเรียน',
        detail: value?.scn ?? ''
      },
      {
        label: 'หลักสูตรที่สนใจ',
        detail: courseObj[value?.course]?.name ?? ''
      },
      {
        label: 'ระบบการเรียน',
        detail: learningSystemObj[value?.learningsystem]?.name ?? ''
      },
      {
        label: 'วุฒิการศึกษา',
        detail: degreeObj[value?.degree]?.name ?? ''
      },
      {
        label: 'สถานศึกษา',
        detail: value?.school ?? ''
      },
      {
        label: 'หมายเหตุ',
        detail: value?.remark ?? ''
      },
      {
        label: 'ชื่อ-นามสกุล (ภาษาไทย)',
        detail: `${nameTitleObj[value?.title]?.nameTh ?? ''} ${value?.firstname ?? ''} ${value?.lastname ?? ''}`
      },
      {
        label: 'ชื่อเล่น (ภาษาไทย)',
        detail: value?.nickname ?? ''
      },
      {
        label: 'ชื่อ-นามสกุล (อังกฤษ)',
        detail: `${nameTitleObj[value?.title]?.name ?? ''} ${value?.firstnameen ?? ''} ${value?.lastnameen ?? ''}`
      },
      {
        label: 'ชื่อเล่น (อังกฤษ)',
        detail: value?.nicknameen ?? ''
      },
      {
        label: 'วันเดือนปีเกิด',
        detail: (value?.birthdate) ? dayjs(value.birthdate).format('DD / MM / BBBB') : ''
      },
      {
        label: 'อายุ',
        detail: value?.age ?? ''
      },
      {
        label: 'สถานภาพ',
        detail: relationshipObj[value?.relationship]?.name ?? ''
      },
      {
        label: 'ที่อยู่ตามทะเบียนบ้าน',
        detail: value?.addresshome ?? ''
      },
      {
        label: 'ที่อยู่ปัจจุบัน',
        detail: value?.address ?? ''
      },
      {
        label: 'เบอร์ติดต่อ',
        detail: value?.tel ?? ''
      },
      {
        label: 'Facebook',
        detail: value?.facebook ?? ''
      },
      {
        label: 'Line',
        detail: value?.line ?? ''
      },
      {
        label: 'Email',
        detail: value?.email ?? ''
      },
      {
        label: 'เบอร์ติดต่อผู้ปกครอง',
        detail: value?.telparent ?? ''
      },
      {
        label: 'อาศัยอยู่กับ',
        detail: ({
          parent: 'พ่อแม่',
          other: 'อื่นๆ'
        }[value?.livewith] ?? '') + ((value?.livewith !== 'other') ? '' : (!value?.livewitho) ? '' : ' (' + value.livewitho + ')')
      },
      {
        title: 'สัดส่วน'
      },
      {
        label: 'ส่วนสูง',
        detail: (!value?.height) ? '' : `${value.height} ซม.`
      },
      {
        label: 'น้ำหนัก',
        detail: (!value?.weight) ? '' : `${value.weight} กก.`
      },
      {
        label: 'สัดส่วน',
        detail: ({
          normal: 'ปกติ',
          fat: 'อ้วน',
          thin: 'ผอม'
        }[value?.body] ?? '')
      },
      {
        label: 'มือข้างที่ถนัด',
        detail: ({
          left: 'มือซ้าย',
          right: 'มือขวา'
        }[value?.hand] ?? '')
      },
      {
        label: 'กรุ๊ปเลือด',
        detail: bloodObj[value?.blood]?.name ?? ''
      },
      {
        title: 'ร่างกาย/สายตา'
      },
      {
        label: 'สายตา (ตาข้างซ้าย)',
        detail: ({
          normal: 'ปกติ',
          abnormal: 'ไม่ปกติ'
        }[value?.eyeleft] ?? '') + ((value?.eyeleftdetail) ? ' (' + value.eyeleftdetail + ')' : '')
      },
      {
        label: 'สายตา (ตาข้างขวา)',
        detail: ({
          normal: 'ปกติ',
          abnormal: 'ไม่ปกติ'
        }[value?.eyeright] ?? '') + ((value?.eyerightdetail) ? ' (' + value.eyerightdetail + ')' : '')
      },
      {
        label: 'ใส่แว่นตา/คอนแทคเลนส์',
        detail: (value?.eyewear ?? []).map((o) => {
          return {
            glasses: 'แว่นตา',
            contactlens: 'คอนแทคเลนส์'
          }[o]
        }).join(', ') + '\n' + (value?.eyewear ?? []).map((o) => {
          let result = ''
          switch (o) {
            case 'glasses':
              result = `สายตาข้างซ้าย (แว่นตา): ${(value?.glassesleft) ? value.glassesleft : ''}\nสายตาข้างขวา (แว่นตา): ${(value?.glassesright) ? value.glassesright : ''}`
              break;
            case 'contactlens':
              result = `สายตาข้างซ้าย (คอนแทคเลนส์): ${(value?.contactlensleft) ? value.contactlensleft : ''}\nสายตาข้างขวา (คอนแทคเลนส์): ${(value?.contactlensright) ? value.contactlensright : ''}`
              break;
            default:
              result = ''
              break;
          }
          return result
        }).join('\n')
      },
      {
        label: 'ตาบอดสี',
        detail: ({
          yes: 'ตาบอดสี',
          no: 'ตาไม่บอดสี'
        }[value?.colorblind] ?? '')
      },
      {
        label: 'ตาเอียง',
        detail: ({
          yes: 'เอียง',
          no: 'ไม่เอียง'
        }[value?.slantedeye] ?? '')
      },
      {
        label: 'ศัลยกรรม',
        detail: ({
          no: 'ไม่ทำ',
          yes: 'ทำ'
        }[value?.surgery] ?? '') + ((value?.surgery !== 'yes') ? '' : (!value?.surgeryy) ? '' : ' (' + value.surgeryy + ')')
      },
      {
        label: 'รอยสัก',
        detail: ({
          no: 'ไม่มี',
          yes: 'มี'
        }[value?.tattoo] ?? '') + ((value?.tattoo !== 'yes') ? '' : `${(value?.tattoopos) ? '\nตำแหน่งรอยสัก: ' + value.tattoopos : ''}${(value?.tattoosize) ? '\nขนาดรอยสัก: ' + value.tattoosize : ''}`)
      },
      {
        label: 'สถานะทางทหาร',
        detail: ({
          completed: `เกณฑ์ทหารแล้ว ${(value?.militaryc) ? ' ' + value.militaryc : ''}`,
          postponed: `ผ่อนผัน ${(value?.militaryp) ? ' ' + value.militaryp : ''}`,
          na: `ยังไม่ถึงเกณฑ์ ${(value?.militaryn) ? 'จะถึงเกณฑ์ ' + value.militaryn : ''}`,
          rotc: 'รด.',
          w: 'เพศหญิง',
        }[value?.military] ?? '')
      },
      {
        label: 'ประวัติการเจ็บป่วย',
        detail: (value?.illness ?? []).map((o) => {
          let result = ''
          switch (o) {
            case 'surgery':
              result = `เคยผ่าตัด ${(value?.illnesss) ? '(' + value.illnesss + ')' : ''}`
              break;
            case 'accident':
              result = `เคยเกิดอุบัติเหตุหนัก ${(value?.illnessa) ? '(' + value.illnessa + ')' : ''}`
              break;
            case 'bone':
              result = `เคยกระดูกหัก/มีเหล็กดาม ${(value?.illnessb) ? '(' + value.illnessb + ')' : ''}`
              break;
            default:
              result = `อื่นๆ ${(value?.illnesso) ? '(' + value.illnesso + ')' : ''}`
              break;
          }
          return result
        }).join('\n')
      },
      {
        label: 'โรคประจำตัว',
        detail: (value?.disease ?? []).map((o) => {
          let result = ''
          switch (o) {
            case 'no':
              result = 'ไม่มี'
              break;
            case 'anemia':
              result = 'โรคเลือดจาง'
              break;
            case 'hepatitisb':
              result = 'ไวรัสตับอักเสบบี'
              break;
            case 'seizures':
              result = 'ลมชัก'
              break;
            case 'thyroid':
              result = 'ไทรอยด์'
              break;
            case 'asthma':
              result = 'หอบหืด'
              break;
            case 'disease':
              result = 'มีโรค'
              break;
            case 'allergy':
              result = 'ภูมิแพ้'
              break;
            default:
              result = ''
              break;
          }
          return result
        }).join(', ') + (((value?.disease ?? []).indexOf('disease') === -1) ? '' : `\nรายละเอียดโรค: ${value?.diseased ?? ''}`) + (((value?.disease ?? []).indexOf('allergy') === -1) ? '' : `\nแพ้อะไร: ${value?.diseaseaw ?? ''}\nอาการ: ${value?.diseaseah ?? ''}`)
      },
      {
        title: 'รายละเอียดเพิ่มเติม'
      },
      {
        label: 'สูบบุหรี่',
        detail: ({
          no: 'ไม่สูบ',
          regular: 'สูบประจำ',
          sometimes: 'สูบบางครั้ง'
        }[value?.smoking] ?? '') + ((value?.smoking !== 'sometimes') ? '' : (!value?.smokings) ? '' : ' (' + value.smokings + ')')
      },
      {
        label: 'ดื่มเหล้า/ดื่มเบียร์',
        detail: ({
          no: 'ไม่ดื่ม',
          regular: 'ดื่มประจำ',
          sometimes: 'ดื่มบางครั้ง'
        }[value?.drinking] ?? '') + ((value?.drinking !== 'sometimes') ? '' : (!value?.drinkings) ? '' : ' (' + value.drinkings + ')')
      },
      {
        label: 'ใบอนุญาตขับขี่',
        detail: (value?.driver ?? []).map((o) => {
          return {
            no: 'ไม่มี',
            motorcycle: 'รถจักรยานยนต์',
            car: 'รถยนต์'
          }[o]
        }).join(', ')
      },
      {
        label: 'เคยมีพาสปอร์ตหรือไม่',
        detail: ({
          no: 'ไม่มี',
          yes: 'มี'
        }[value?.passport] ?? '') + ((value?.passport !== 'yes') ? '' : (!value?.passporty) ? '' : ' (' + value.passporty + ')')
      },
      {
        label: 'เคยไปญี่ปุ่นหรือไม่',
        detail: ({
          no: 'ไม่เคย',
          yes: 'เคย'
        }[value?.gotojapan] ?? '') + ((value?.gotojapan !== 'yes') ? '' : (!value?.gotojapany) ? '' : ' (' + value.gotojapany + ')')
      },
      {
        label: 'เคยมีคดีความหรือไม่',
        detail: ({
          no: 'ไม่เคย',
          yes: 'เคย'
        }[value?.lawsuit] ?? '') + ((value?.lawsuit !== 'yes') ? '' : (!value?.lawsuity) ? '' : ' (' + value.lawsuity + ')')
      },
      {
        label: 'เคยเปลี่ยนชื่อ-นามสกุลหรือไม่',
        detail: ({
          no: 'ไม่เคย',
          yes: 'เคย'
        }[value?.changename] ?? '') + ((value?.changename !== 'yes') ? '' : ((!value?.changenameyf && !value?.changenameyl) ? '' : '\nชื่อ-นามสกุล ' + (value?.changenameyf ?? '') + ' ' + (value?.changenameyl ?? '')))
      },
      {
        label: 'เคยสมัครงานไปญี่ปุ่นกับบริษัทอื่นหรือไม่',
        detail: ({
          no: 'ไม่เคย',
          yes: 'เคย'
        }[value?.jobinjapan] ?? '') + ((value?.jobinjapan !== 'yes') ? '' : ((!value?.jobinjapanyc) ? '' : '\nชื่อบริษัท: ' + value?.jobinjapanyc) + ((!value?.jobinjapanyw) ? '' : '\nเมื่อไหร่: ' + value?.jobinjapanyw))
      },
      {
        label: 'มีภาระหนี้สินหรือไม่',
        detail: ({
          no: 'ไม่มี',
          yes: 'มี'
        }[value?.debt] ?? '') + ((value?.debt !== 'yes') ? '' : ((!value?.debtyw) ? '' : '\nกู้จาก: ' + value.debtyw) + ((!value?.debtyd) ? '' : '\nกู้มาทำอะไร: ' + value.debtyd) + ((!value?.debtyn) ? '' : '\nจำนวน: ' + value.debtyn + ' บาท'))
      },
      {
        label: 'มีญาติพี่น้อง คนรู้จัก เพื่อน อยู่ที่ญี่ปุ่นหรือไม่',
        detail: ({
          no: 'ไม่มี',
          yes: 'มี'
        }[value?.relativeinjapan] ?? '') + ((value?.relativeinjapan !== 'yes') ? '' : '\n' + [
          `ชื่อ-นามสกุล คนที่อยู่ญี่ปุ่น: ${(value?.relativename ?? '')}`,
          `อายุ: ${(value?.relativeage) ? value.relativeage + ' ปี' : ''}`,
          `เพศ: ${(!value?.relativegender) ? '' : (value.relativegender === 'male') ? 'ชาย' : 'หญิง'}`,
          `ความสัมพันธ์: ${value?.relativerelation ?? ''}`,
          `ที่อยู่ในญี่ปุ่น: ${value?.relativeaddress ?? ''}`,
          `เบอร์ติดต่อ: ${value?.relativetel ?? ''}`,
          `ระยะเวลาที่อยู่ญี่ปุ่น: ${(value?.relativelived) ? value.relativelived + ' ปี' : ''}`
        ].join('\n'))
      },
      {
        title: 'สมาชิกในครอบครัว'
      },
      ...(value?.family ?? []).map((o, i) => {
        return {
          label: `สมาชิกคนที่ ${i + 1}`,
          detail: [
            `ชื่อ - นามสกุล: ${o?.name ?? ''}`,
            `ความสัมพันธ์: ${relationObj[o?.relation]?.name ?? ''}`,
            `อายุ: ${(o?.age) ? o.age + ' ปี' : ''}`,
            `อาชีพ: ${o?.occupation ?? ''}`,
            `ตำแหน่ง: ${o?.position ?? ''}`,
            `สถานที่ทำงาน / ชื่อบริษัท: ${o?.company ?? ''}`,
            `จังหวัด: ${o?.province ?? ''}`
          ].join('\n')
        }
      }),
      {
        label: 'กรุณาอธิบายเพิ่มเติม กรณี บิดา/มารดา หย่าร้างหรือเสียชีวิต',
        detail: (value?.familyremark ?? '')
      },
      {
        title: 'ประวัติการศึกษา'
      },
      {
        label: 'ระดับมัธยม',
        detail: [
          `ชื่อสถานศึกษา: ${value?.jhsname ?? ''}`,
          `สาขา/แผนก: ${value?.jhsmajor ?? ''}`,
          `เข้าศึกษา - จบการศึกษา: ${(value?.jhsperiod?.length) ? (dayjs(value.jhsperiod[0]).format('DD / MM / BBBB') + ' - ' + dayjs(value.jhsperiod[1]).format('DD / MM / BBBB')) : ''}`
        ].join('\n')
      },
      {
        label: 'ระดับมัธยมปลาย/ปวช.',
        detail: [
          `ชื่อสถานศึกษา: ${value?.shsname ?? ''}`,
          `สาขา/แผนก: ${value?.shsmajor ?? ''}`,
          `เข้าศึกษา - จบการศึกษา: ${(value?.shsperiod?.length) ? (dayjs(value.shsperiod[0]).format('DD / MM / BBBB') + ' - ' + dayjs(value.shsperiod[1]).format('DD / MM / BBBB')) : ''}`
        ].join('\n')
      },
      {
        label: 'ระดับอนุปริญญา/ปวส./ทวิภาคี',
        detail: [
          `ชื่อสถานศึกษา: ${value?.dipname ?? ''}`,
          `สาขา/แผนก: ${value?.dipmajor ?? ''}`,
          `เข้าศึกษา - จบการศึกษา: ${(value?.dipperiod?.length) ? (dayjs(value.dipperiod[0]).format('DD / MM / BBBB') + ' - ' + dayjs(value.dipperiod[1]).format('DD / MM / BBBB')) : ''}`
        ].join('\n')
      },
      {
        label: 'ระดับปริญญาตรี',
        detail: [
          `ชื่อสถานศึกษา: ${value?.bdname ?? ''}`,
          `สาขา/แผนก: ${value?.bdmajor ?? ''}`,
          `เข้าศึกษา - จบการศึกษา: ${(value?.bdperiod?.length) ? (dayjs(value.bdperiod[0]).format('DD / MM / BBBB') + ' - ' + dayjs(value.bdperiod[1]).format('DD / MM / BBBB')) : ''}`
        ].join('\n')
      },
      {
        title: 'ประวัติการทำงาน'
      },
      ...(value?.jobs ?? []).map((o, i) => {
        return {
          label: `ประวัติการทำงาน ครั้งที่ ${i + 1}`,
          detail: [
            `ชื่อบริษัท: ${o?.company ?? ''}`,
            `ประเภทกิจการ: ${o?.companytype ?? ''}`,
            `ลักษณะงานที่ทำ: ${o?.jd ?? ''}`,
            `ประเภทงาน: ${jobTypeObj[o?.jobtype]?.name ?? ''}`,
            `จังหวัด: ${o?.province ?? ''}`,
            `เงินเดือน: ${(o?.salary) ? o.salary + ' บาท' : ''}`,
            `เริ่มงาน - ลาออก: ${(o?.period?.length) ? (dayjs(o.period[0]).format('DD / MM / BBBB') + ' - ' + dayjs(o.period[1]).format('DD / MM / BBBB')) : ''}`
          ].join('\n')
        }
      }),
      {
        title: 'คำถามแสดงความคิดเห็น'
      },
      {
        label: 'เป้าหมายชีวิต',
        detail: value?.target ?? ''
      },
      {
        label: 'งานอดิเรก',
        detail: value?.hobby ?? ''
      },
      {
        label: 'ข้อดี',
        detail: value?.strength ?? ''
      },
      {
        label: 'ข้อเสีย',
        detail: value?.weakness ?? ''
      },
      {
        label: 'ความสามารถพิเศษ',
        detail: value?.talent ?? ''
      },
      {
        title: 'สำหรับเจ้าหน้าที่'
      },
      {
        label: 'ความคิดเห็นเพิ่มเติม',
        detail: value?.comment ?? ''
      }
    ]
    try {
      const docObj = {
        pageOrientation: 'portrait',
        content: [
          {
            columns: [
              {
                width: '*',
                stack: [
                  {
                    text: 'ข้อมูลนักเรียน',
                    fontSize: docSetting.headerFontSize,
                    bold: true,
                    margin: [0, 0, 0, 4]
                  },
                  {
                    image: photo,
                    width: 100,
                    margin: [0, 0, 0, 4]
                  }
                ]
              },
              {
                image: logo,
                width: 70,
                margin: [0, 0]
              }
            ]
          },
          ...contents.map((o) => {
            if (o?.title) {
              return {
                text: o?.title ?? '',
                style: 'title'
              }
            } else {
              return {
                columns: [
                  {
                    text: o.label,
                    width: docSetting.labelWidth,
                    bold: true,
                    margin: docSetting.contentMargin
                  },
                  {
                    text: o.detail,
                    width: '*',
                    margin: docSetting.contentMargin
                  }
                ]
              }
            }
          }),
          {
            margin: [0, 48, 0, 0],
            columns: [
              {
                text: '',
                width: '*'
              },
              {
                width: 200,
                alignment: 'center',
                stack: [
                  {
                    text: 'ลงชื่อผู้สัมภาษณ์'
                  },
                  {
                    text: value?.interviewer ?? ''
                  },
                  {
                    text: (value?.infothdate) ? `(${(dayjs(value.infothdate).format('DD / MM / BBBB'))})` : ''
                  }
                ]
              }
            ]
          }
        ],
        styles: {
          title: {
            fontSize: docSetting.titleFontSize,
            bold: true,
            margin: docSetting.titleMargin,
            decoration: 'underline',
            decorationColor: 'black'
          }
        },
        defaultStyle: {
          fontSize: docSetting.contentFontSize,
          font: 'THSarabunNew'
        }
      }
      await genPdf(docObj, `student_info_${value?.scn ?? ''}`)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return (
    <Button
      style={{ width: 168, height: 40, marginTop: 20, marginRight: 20 }}
      type='secondary'
      onClick={() => print()}
    ><DownloadOutlined style={{ fontSize: 16 }} />ดาวน์โหลด</Button>
  )
}

export default InfoPdf
