import React from 'react'
import { Outlet } from 'react-router-dom'
import { Layout, Col, Row, Card, Image } from 'antd'

import ImgBg from '../../assets/images/bg.svg'
import Logo from '../../assets/images/logo.png'
import './AuthLayout.less'

const AuthLayout = () => {
  return (
    <Layout style={{ backgroundImage: `url(${ImgBg})` }} className='auth-layout'>
      <Layout.Content>
        <Row>
          <Col flex='auto'></Col>
          <Col flex='325px'>
            <Card style={{ marginTop: '50%', borderRadius: '10px', backgroundColor: '#ffffff', borderColor: '#30303000' }} >
              <Row>
                <Col flex='auto'></Col>
                <Col flex='108px'>
                  <Image src={Logo} className='logo' height={108} preview={false}></Image>
                </Col>
                <Col flex='auto'></Col>
              </Row>
              <Outlet />
            </Card>
          </Col>
          <Col flex='auto'></Col>
        </Row>
      </Layout.Content>
    </Layout>
  )
}

export default AuthLayout
