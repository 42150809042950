import React from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Row, Col, Card, Table, Button, Tag, Skeleton } from 'antd'
import { PlusOutlined, SettingOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'
import { dbTodayjs } from '../../utils/date'

import { useAuth } from '../../contexts/AuthContextProvider'
import { behaviorLevelObj } from '../../const/classes'
import { isAllowed } from '../../route/roles'

import StudentInfoCard from './Components/info'

const Form = () => {
  const navigate = useNavigate()
  const { db, role, userId } = useAuth()
  const { id } = useParams()

  const uid = (role === 'student') ? userId : id

  const { value } = useAsync(async () => {

    const snap = await db.collection('/behaviors').where('uid', '==', uid).get()
    let i = 0
    const result = snap.docs.reduce((acc1, doc) => {
      const data = doc.data()
      return [...acc1, ...data.behaviors.reduce((acc2, p) => {
        i += 1
        return [...acc2, {
          ...data,
          ...p,
          id: doc.id,
          n: i,
          date: dbTodayjs(p?.date),
          index: p?.date?.seconds
        }]
      }, [])
      ]
    }, [])
    return result.sort((x, y) => x.index - y.index)
  }, [uid])

  const columns = [
    {
      title: 'ครั้งที่',
      dataIndex: 'n',
      width: 50,
      align: 'center'
    },
    {
      title: 'วันที่',
      dataIndex: 'date',
      render: data => (
        <span>{(data) ? dayjs(data).format('D MMM BBBB') : ''}</span>
      )
    },
    {
      title: 'ความประพฤติ',
      dataIndex: 'behavior'
    },
    {
      title: 'ผู้รายงานพฤติกรรม',
      dataIndex: 'from',
      align: 'center'
    },
    {
      title: 'ระดับความผิด',
      dataIndex: 'lev',
      render: data => (
        <Tag color={behaviorLevelObj[data]?.color ?? 'green'}>
          {behaviorLevelObj[data]?.name ?? ''}
        </Tag>
      )
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'remark'
    },
    {
      title: 'เครื่องมือ',
      dataIndex: 'Operation',
      width: 150,
      render: (_, row) => (
        <div>
          <Button
            type='link'
            style={{ marginRight: '8px' }}
            icon={<SettingOutlined style={{ fontSize: 18 }} />}
            onClick={() => {
              const path = (role === 'student') ? `/admin/student/behaviors/${row.id}/view` : (isAllowed(role, 'edit', 'studentBehaviors')) ? `/admin/students/${uid}/behaviors/${row.id}/edit` : `/admin/students/${uid}/behaviors/${row.id}/view`
              navigate(path)
            }}
          ></Button>
        </div>
      )
    }
  ]

  if (!value) return <Skeleton></Skeleton>
  return (
    <div>
      <StudentInfoCard uid={uid} />
      <Card
        style={{ marginTop: 16 }}
        bodyStyle={{ padding: 32 }}
      >
        <Row style={{ marginBottom: 24 }}>
          <Col flex='auto'>
            <div style={{ fontSize: 24 }}>
              พฤติกรรม
            </div>
          </Col>
          <Col flex='300px'>
            {(isAllowed(role, 'create', 'studentBehaviors')) && (
              <div style={{ float: 'right', fontSize: 14 }}>
                <Button
                  type='primary'
                  icon={<PlusOutlined style={{ fontSize: 14 }} />}
                  onClick={() => {
                    navigate(`/admin/students/${uid}/behaviors/create`)
                  }}
                >
                  รายงานพฤติกรรม
                </Button>
              </div>
            )}
          </Col>
        </Row >
        <Table
          bordered
          rowKey='n'
          style={{ overflowX: 'auto' }}
          columns={columns}
          dataSource={value}
        />
      </Card>
    </div>
  )
}

export default Form
