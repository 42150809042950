import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import AuthContextProvider from './contexts/AuthContextProvider'
import './App.less'

import Router from './route'

const App = () => {
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <Router></Router>
      </AuthContextProvider>
    </BrowserRouter>
  )
}

export default App
