const pickBy = (obj) => {
  return Object.keys(obj).filter((k) => obj[k] != null).reduce((a, k) => ({ ...a, [k]: obj[k] }), {})
}

const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const formatNumber = (value, decimal) => {
  if (!value || isNaN(value)) {
    return ''
  }
  return new Intl.NumberFormat('en-US', { maximumFractionDigits: decimal }).format(parseFloat(value))
}

const monthDiff = (d1, d2) => {
  if (!(d1 && d2)) {
    return 0
  }
  let months
  months = (d2.getFullYear() - d1.getFullYear()) * 12
  months -= d1.getMonth()
  months += d2.getMonth()
  return months <= 0 ? 0 : months
}

const dateTH = (date, monthFormat = 'long') => {
  if (!date) {
    return ''
  }
  return date.toLocaleDateString('th-TH', { year: 'numeric', month: monthFormat, day: 'numeric' })
}

const durationTh = (d1, d2) => {
  const diff = monthDiff(d1, d2)
  const y = Math.floor(diff / 12)
  const m = diff % 12
  return `${(y > 0) ? y + ' ปี ' : ''}${m} เดือน`
}

const durationDay = (d1, d2) => {
  let day1 = new Date(d1)
  let day2 = new Date(d2)
  day1.setHours(0, 0, 0)
  day2.setHours(0, 0, 0)
  return Math.round((day2 - day1) / (24 * 60 * 60 * 1000))
}

const difference = (arr1, arr2) => {
  return (arr1 || []).filter((o) => ((arr2 || []).indexOf(o) === -1))
}

const toDataURL = (url) => {
  return new Promise((resolve, reject) => {
    if (!url) return resolve()
    const image = new window.Image()
    image.src = url
    image.setAttribute('crossOrigin', 'anonymous')
    image.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = image.naturalWidth
      canvas.height = image.naturalHeight
      canvas.getContext('2d').drawImage(image, 0, 0)
      resolve(canvas.toDataURL('image/png'))
    }
    image.onerror = () => resolve()
  })
}

export {
  pickBy,
  sleep,
  formatNumber,
  monthDiff,
  dateTH,
  durationTh,
  durationDay,
  difference,
  toDataURL
}