import React, { useState, useEffect } from 'react'

import { Button, Dropdown } from 'antd'
import { PrinterOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'
import { dbTodayjs } from '../../../utils/date'

import { genPdf } from '../../../utils/pdf'
import { formatNumber, toDataURL } from '../../../utils/tools'
import theps from '../../../assets/images/theps.png'

const InvoicePdf = ({ value }) => {
  const [logo, setLogo] = useState()

  useEffect(() => {
    toDataURL(theps).then(data => {
      setLogo(data)
    })
  }, [])

  const getPdf = async (data, type) => {
    data.date = dbTodayjs(data?.date)
    data.paymentdate = dbTodayjs(data?.paymentdate)
    data.feemonth = dbTodayjs(data?.feemonth)
    const feemonth = {
      '1': 'JAN', '2': 'FEB', '3': 'MAR', '4': 'APR', '5': 'MAY', '6': 'JUN', '7': 'JUL', '8': 'AUG', '9': 'SEP', '10': 'OCT', '11': 'NOV', '12': 'DEC'
    }[(dayjs(data?.feemonth).format('M'))] ?? ''
    let company = ''
    const countObj = data.list.reduce((acc, o) => {
      if (o?.company) {
        company = o.company
        acc[company] = 0
      }
      acc[company] += 1
      return acc
    }, {})
    const list = (data.list || []).map((o) => {
      const date = dbTodayjs(o?.traveldate)
      return [
        {
          rowSpan: countObj[o.company],
          text: o?.company ?? '',
          alignment: 'left'
        },
        {
          text: o?.y1 ?? ''
        },
        {
          text: o?.y2 ?? ''
        },
        {
          text: o?.y3 ?? ''
        },
        {
          text: o?.y4 ?? ''
        },
        {
          text: o?.y5 ?? ''
        },
        {
          text: dayjs(date).format('YYYY/MM/DD')
        },
        {
          text: o?.noTime ?? ''
        },
        {
          text: formatNumber(o?.total)
        }
      ]
    })
    try {
      const docObj = {
        pageOrientation: 'portrait',
        pageMargins: [10, 10, 10, 10],
        content: [
          {
            columns: [
              {
                image: logo,
                width: 200
              },
              {
                text: '',
                width: '*'
              },
              {
                text: (type === 'invoice') ? 'INVOICE' : 'RECEIPT',
                width: 250,
                fontSize: 32,
                bold: true,
                color: 'gray',
                margin: [0, 0]
              }
            ],
          },
          {
            columns: [
              {
                width: '*',
                margin: [0, 8, 0, 0],
                stack: [
                  {
                    text: 'THEPS 2018 RECRUITMENT CO.,LTD.',
                    fontSize: 18
                  },
                  {
                    text: '3 SOI NAPALAI 11, SOUTH BANGNA SUB-DISTRICT,',
                    fontSize: 11
                  },
                  {
                    text: 'BANGNA DISTRICT, BANGKOK, 10260, THAILAND',
                    fontSize: 11
                  },
                  {
                    text: 'TAX ID : 0105561022597',
                    fontSize: 11
                  },
                  {
                    text: 'TEL : (+66) 2116-8408    FAX : (+66) 2116-8409',
                    fontSize: 11
                  }
                ]
              },
              {
                width: 250,
                margin: [0, 8, 0, 0],
                table: {
                  headerRows: 1,
                  widths: [150, '*'],
                  body: [
                    [
                      {
                        text: (type === 'invoice') ? 'INVOICE NO.' : 'RECEIPT NO.',
                        alignment: 'center',
                        bold: true,
                        fontSize: 12
                      },
                      {
                        text: 'DATE',
                        alignment: 'center',
                        bold: true,
                        fontSize: 12
                      }
                    ],
                    [
                      {
                        text: data.no,
                        alignment: 'center',
                        fontSize: 12
                      },
                      {
                        text: dayjs(data?.date).format('YYYY/MM/DD'),
                        alignment: 'center',
                        fontSize: 12
                      }
                    ]
                  ]
                },
                layout: {
                  fillColor: (rowIndex, node, columnIndex) => {
                    return (rowIndex === 0) ? '#d7dbdd' : null
                  }
                }
              }
            ],
            columnGap: 20
          },
          {
            width: 250,
            margin: [0, 30, 0, 0],
            table: {
              headerRows: 1,
              widths: [250],
              body: [
                [
                  {
                    text: (type === 'invoice') ? 'BILL TO' : 'RECEIVED FROM',
                    alignment: 'center',
                    bold: true,
                    fontSize: 13
                  }
                ]
              ]
            },
            layout: {
              fillColor: (rowIndex, node, columnIndex) => {
                return (rowIndex === 0) ? '#d7dbdd' : null
              },
              hLineColor: (i, node) => {
                return '#b2babb'
              },
              vLineColor: (i, node) => {
                return '#b2babb'
              }
            }
          },
          {
            text: (type === 'invoice') ? data?.coop ?? '' : 'JP-RISE COOPERATIVE',
            fontSize: 11,
            margin: [0, 4]
          },
          {
            text: (type === 'invoice') ? data?.coopaddress ?? '' : '425 HIRU-CHO, MATSUSAKA-CITY, MIE-PREFECTURE, 515-2105 JAPAN',
            fontSize: 11,
            margin: [0, 4]
          },
          {
            text: (type === 'invoice') ? `TEL: ${data?.cooptel ?? ''}    FAX: ${data?.coopfax ?? ''}` : 'TEL: 0598-56-7438    FAX: 0598-56-6353',
            fontSize: 11,
            margin: [0, 4]
          },
          {
            width: 240,
            margin: [0, 8],
            table: {
              headerRows: 1,
              widths: [120, 120],
              body: [
                [
                  {
                    text: 'AMOUNT',
                    alignment: 'center',
                    fontSize: 12
                  },
                  {
                    text: 'PAYMENT DATE',
                    alignment: 'center',
                    fontSize: 12
                  }
                ],
                [
                  {
                    text: `¥ ${formatNumber(data?.total)}`,
                    alignment: 'center',
                    fontSize: 12
                  },
                  {
                    text: dayjs(data?.paymentdate).format('YYYY/MM/DD'),
                    alignment: 'center',
                    fontSize: 12
                  }
                ]
              ]
            }
          },
          {
            text: [
              {
                text: '[ ADMINISTRATIVE EXPENSES FOR ',
                fontSize: 13
              },
              {
                text: feemonth,
                color: 'red',
                fontSize: 13
              },
              {
                text: ` ${dayjs(data?.feemonth).format('YYYY')}`,
                fontSize: 13
              },
              {
                text: ' ]',
                fontSize: 13
              }
            ]
          },
          {
            margin: [0, 8, 0, 0],
            alignment: 'center',
            table: {
              headerRows: 1,
              widths: [150, 32, 32, 32, 32, 32, 70, 45, '*'],
              body: [
                [
                  {
                    rowSpan: 3,
                    text: 'COMPANY NAME',
                    margin: [0, 24, 0, 0]
                  },
                  {
                    colSpan: 5,
                    text: 'NUMBER OF TOKUTEI GINOU'
                  },
                  {},
                  {},
                  {},
                  {},
                  {
                    rowSpan: 3,
                    text: 'ENTRY DATE',
                    margin: [0, 24, 0, 0]
                  },
                  {
                    rowSpan: 3,
                    text: 'NUMBER OF TIMES',
                    margin: [0, 8, 0, 0]
                  },
                  {
                    rowSpan: 3,
                    text: 'SUBTOTAL',
                    margin: [0, 24, 0, 0]
                  }
                ],
                [
                  {},
                  {
                    text: 'I',
                    fontSize: 9
                  },
                  {
                    text: 'II',
                    colSpan: 2,
                    fontSize: 9
                  },
                  {},
                  {
                    text: 'III',
                    colSpan: 2,
                    fontSize: 9
                  },
                  {},
                  {},
                  {},
                  {}
                ],
                [
                  {},
                  {
                    text: '1st year',
                    alignment: 'right',
                    fontSize: 9
                  },
                  {
                    text: '2nd year',
                    alignment: 'right',
                    fontSize: 9
                  },
                  {
                    text: '3rd year',
                    alignment: 'right',
                    fontSize: 9
                  },
                  {
                    text: '4th year',
                    alignment: 'right',
                    fontSize: 9
                  },
                  {
                    text: '5th year',
                    alignment: 'right',
                    fontSize: 9
                  },
                  {},
                  {},
                  {}
                ],
                ...list,
                [
                  {},
                  {
                    text: data?.sumy1 ?? 0
                  },
                  {
                    text: data?.sumy2 ?? 0
                  },
                  {
                    text: data?.sumy3 ?? 0
                  },
                  {
                    text: data?.sumy4 ?? 0
                  },
                  {
                    text: data?.sumy5 ?? 0
                  },
                  {
                    text: data?.sum ?? 0
                  },
                  {
                    text: 'TOTAL'
                  },
                  {
                    text: `¥ ${formatNumber(data?.total)}`
                  }
                ],
              ]
            },
            layout: {
              fillColor: (rowIndex, node, columnIndex) => {
                return (rowIndex < 3) ? '#d7dbdd' : null
              },
              hLineWidth: (i, node) => {
                return (i === 0 || i === node.table.body.length) ? 2 : 1
              },
              vLineWidth: (i, node) => {
                return (i === 0 || i === node.table.widths.length) ? 2 : 1
              }
            }
          },
          (type === 'invoice') ? {
            text: '＊入国日が入国月の初日でない場合、初月と最終月の管理費を日割り計算してご請求させて頂いております。',
            fontSize: 11,
            margin: [0, 8]
          } : {},
          (type === 'invoice') ? {
            text: 'REMITTANCE DESTINATION',
            bold: true,
            fontSize: 13,
            pageBreak: 'before'
          } : {},
          (type === 'invoice') ? {
            table: {
              headerRows: 1,
              widths: [120, '*'],
              body: [
                [
                  {
                    text: 'ACCOUNT NAME',
                    alignment: 'center',
                    fontSize: 11
                  },
                  {
                    text: data?.accountname ?? '',
                    fontSize: 11
                  }
                ],
                [
                  {
                    text: 'BANK NAME',
                    alignment: 'center',
                    fontSize: 11
                  },
                  {
                    text: `[${data?.bank ?? ''}]\n${data?.bankaddress ?? ''}`,
                    fontSize: 11
                  }
                ],
                [
                  {
                    text: 'BRANCH',
                    alignment: 'center',
                    fontSize: 11
                  },
                  {
                    text: data?.branch ?? '',
                    fontSize: 11
                  }
                ],
                [
                  {
                    text: 'ACCOUNT NO.',
                    alignment: 'center',
                    fontSize: 11
                  },
                  {
                    text: data?.accountno ?? '',
                    fontSize: 11
                  }
                ],
                [
                  {
                    text: 'SWIFT CODE',
                    alignment: 'center',
                    fontSize: 11
                  },
                  {
                    text: data?.swift ?? '',
                    fontSize: 11
                  }
                ],
              ]
            },
            layout: {
              hLineWidth: (i, node) => {
                return (i === 0 || i === node.table.body.length) ? 2 : 1
              },
              vLineWidth: (i, node) => {
                return (i === 0 || i === node.table.widths.length) ? 2 : 1
              }
            }
          } : {},
          (type === 'invoice') ? {} :
            {
              columns: [
                {
                  width: 200,
                  margin: [30, 40, 0, 0],
                  alignment: 'center',
                  stack: [
                    {
                      text: '___________________________'
                    },
                    {
                      text: 'RECEIPT',
                      fontSize: 12
                    }
                  ]
                },
                {
                  text: '',
                  width: '*'
                }
              ]
            }
        ],
        defaultStyle: {
          fontSize: 11,
          font: 'NotoSansJP' // Roboto | THSarabunNew | NotoSansJP
        }
      }
      await genPdf(docObj, `Invoice_${data.no}`)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return (
    <Dropdown
      menu={{
        onClick: (e) => {
          getPdf(value, e.key)
        },
        items: [
          { key: 'invoice', label: 'Invoice' },
          { key: 'receipt', label: 'Receipt' }
        ]
      }}
      placement='bottomRight'
    >
      <Button
        type='link'
        style={{ marginRight: 8 }}
        icon={<PrinterOutlined style={{ fontSize: 18 }} />}
      >
      </Button>
    </Dropdown>
  )
}

export default InvoicePdf
