import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Form as AntdForm, Row, Col, Card, Button, Input, Skeleton } from 'antd'
import { PlusOutlined, SaveOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons'

import { DatePicker } from '../../components/DateTime'
import { dayjsToDB, dbTodayjs } from '../../utils/date'
import InputNumberNew from '../../components/InputNumberNew'

import { useAuth } from '../../contexts/AuthContextProvider'
import { pickBy } from '../../utils/tools'

const Form = () => {
  const navigate = useNavigate()
  const { db } = useAuth()
  const { type, fid } = useParams()

  const [loading, setLoading] = useState(false)

  const [fundForm] = AntdForm.useForm()

  const { value } = useAsync(async () => {
    const snap = await db.collection('/funds').doc(fid).get()
    const data = snap.data()
    return {
      ...data,
      id: snap.id,
      payments: (data?.payments ?? [{
        n: 1,
        principleremain: data.loan,
        interestremain: data.installmenti,
        interestaccum: data.installmenti
      }]).map((o) => ({
        ...o,
        date: dbTodayjs(o?.date),
        paydate: dbTodayjs(o?.paydate)
      }))
    }
  }, [fid])

  const handleSubmit = async (formData) => {
    try {
      if (value.disabled) {
        return Promise.resolve()
      }
      setLoading(true)
      const data = {
        remark: formData?.remark ?? '',
        payments: formData.payments.reduce((acc, o) => {
          return (!o?.date) ? acc : [...acc, pickBy({
            ...o,
            date: dayjsToDB(o?.date),
            paydate: dayjsToDB(o?.paydate)
          })]
        }, [])
      }
      await db.collection('/funds').doc(fid).set(data, {
        merge: true
      })
      setLoading(false)
      navigate(`/admin/funds/${type}/${fid}/view`)
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  const computeFund = (data, loan, index) => {
    return {
      ...data,
      payments: data.payments.reduce((acc, o, i) => {
        const transfer = o?.transfer ?? 0
        const remain = o?.remain ?? 0
        const interestremain = (o?.interestremain ?? 0)
        const paid = (transfer > remain) ? transfer - remain : 0
        const interestcum = interestremain + (acc[i - 1]?.interestaccum ?? 0)
        const interest = (paid > interestcum) ? interestcum : paid
        const interestaccum = interestcum - interest
        const principle = paid - interest
        const principleremain = (acc[i - 1]?.principleremain ?? loan) - principle
        return (i >= index) ? [...acc, {
          ...o,
          paid: paid,
          interest: interest,
          interestaccum: interestaccum,
          principle: principle,
          principleremain: principleremain
        }] : [...acc, o]
      }, [])
    }
  }

  if (!value) return <Skeleton></Skeleton>
  return (
    <div>
      <Card
        style={{ marginTop: 16 }}
        bodyStyle={{ padding: '32px 80px' }}
      >
        <div style={{ marginBottom: 16, fontSize: 24 }}>
          รายการผ่อนชำระ
        </div>
        <AntdForm
          layout='vertical'
          onFinish={handleSubmit}
          initialValues={value}
          form={fundForm}
          name='fundForm'
        >
          {(!!value?.payments?.length) && (
            <Row gutter={[8, 8]} style={{ textAlign: 'center' }}>
              <Col span={1}>
                งวดที่
              </Col>
              <Col span={3}>
                งวดการผ่อน
              </Col>
              <Col span={3}>
                วันที่ชำระเงิน
              </Col>
              <Col span={2}>
                ยอดเงินที่ชำระ
              </Col>
              <Col span={2}>
                รับชำระในงวด (จำนวนเงิน)
              </Col>
              <Col span={2}>
                เงินต้นชำระแล้ว
              </Col>
              <Col span={2}>
                ดอกเบี้ยชำระแล้ว
              </Col>
              <Col span={2}>
                เงินต้นคงเหลือในงวด
              </Col>
              <Col span={2}>
                ดอกเบี้ยคงค้างในงวด
              </Col>
              <Col span={2}>
                ดอกเบี้ยสะสม
              </Col>
              <Col span={2}>
                ยอดเงินคงเหลือ
              </Col>
              <Col span={1}>
                เครื่องมือ
              </Col>
            </Row>
          )}
          <AntdForm.List
            name='payments'
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Row key={index} gutter={8}>
                    <Col span={1}>
                      <AntdForm.Item name={[field.name, 'n']} >
                        <InputNumberNew />
                      </AntdForm.Item>
                    </Col>
                    <Col span={3}>
                      <AntdForm.Item name={[field.name, 'date']} >
                        <DatePicker />
                      </AntdForm.Item>
                    </Col>
                    <Col span={3}>
                      <AntdForm.Item name={[field.name, 'paydate']} >
                        <DatePicker />
                      </AntdForm.Item>
                    </Col>
                    <Col span={2}>
                      <AntdForm.Item name={[field.name, 'transfer']} >
                        <InputNumberNew
                          onChange={(v) => {
                            if (type === 'normal') {
                              let data = fundForm.getFieldsValue()
                              data.payments[index].transfer = v
                              const _value = computeFund(data, value.loan, index)
                              fundForm.setFieldsValue({
                                ..._value
                              })
                            }
                          }}
                        />
                      </AntdForm.Item>
                    </Col>
                    <Col span={2}>
                      <AntdForm.Item name={[field.name, 'paid']} >
                        <InputNumberNew />
                      </AntdForm.Item>
                    </Col>
                    <Col span={2}>
                      <AntdForm.Item name={[field.name, 'principle']} >
                        <InputNumberNew />
                      </AntdForm.Item>
                    </Col>
                    <Col span={2}>
                      <AntdForm.Item name={[field.name, 'interest']} >
                        <InputNumberNew />
                      </AntdForm.Item>
                    </Col>
                    <Col span={2}>
                      <AntdForm.Item name={[field.name, 'principleremain']} >
                        <InputNumberNew />
                      </AntdForm.Item>
                    </Col>
                    <Col span={2}>
                      <AntdForm.Item name={[field.name, 'interestremain']} >
                        <InputNumberNew />
                      </AntdForm.Item>
                    </Col>
                    <Col span={2}>
                      <AntdForm.Item name={[field.name, 'interestaccum']} >
                        <InputNumberNew />
                      </AntdForm.Item>
                    </Col>
                    <Col span={2}>
                      <AntdForm.Item name={[field.name, 'remain']} >
                        <InputNumberNew
                          onChange={(v) => {
                            if (type === 'normal') {
                              let data = fundForm.getFieldsValue()
                              data.payments[index].remain = v
                              const _value = computeFund(data, value.loan, index)
                              fundForm.setFieldsValue({
                                ..._value
                              })
                            }
                          }}
                        />
                      </AntdForm.Item>
                    </Col>
                    <Col span={1} style={{ textAlign: 'center' }}>
                      <Button
                        icon={<DeleteOutlined />}
                        onClick={() => remove(field.name)}
                      >
                      </Button>
                    </Col>
                  </Row>
                ))}
                <Button
                  icon={<PlusOutlined />}
                  style={{ marginBottom: 16 }}
                  onClick={() => {
                    const n = fields.length
                    const data = fundForm.getFieldsValue()
                    let arg = { n: n + 1 }
                    if (type === 'normal') {
                      arg = {
                        n: n + 1,
                        principleremain: data.payments[n - 1].principleremain,
                        interestremain: value.installmenti,
                        interestaccum: data.payments[n - 1].interestaccum + value.installmenti
                      }
                    }
                    add(arg)
                  }}
                >
                  เพิ่มแถว
                </Button>
              </>
            )}
          </AntdForm.List>
          <AntdForm.Item name='remark' label='หมายเหตุ' >
            <Input.TextArea placeholder='หมายเหตุ' autoSize={{ minRows: 1 }} />
          </AntdForm.Item>
          <div>
            <Button
              loading={loading}
              style={{ width: 168, height: 40, marginTop: 20 }}
              htmlType='submit'
              type='primary'
            ><SaveOutlined style={{ fontSize: 16 }} />บันทึก</Button>
            <Button
              loading={loading}
              style={{ height: 40, marginTop: 20, marginLeft: 20 }}
              onClick={() => {
                navigate(-1)
              }}
            ><CloseOutlined />ปิด</Button>
          </div>
        </AntdForm>
      </Card>
    </div>
  )
}

export default Form
