import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Form as AntdForm, Row, Col, Card, Button, Input, Select, Radio, Checkbox, Skeleton } from 'antd'
import { SaveOutlined, CloseOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'

import { DatePicker } from '../../components/DateTime'
import { dayjsToDB, dbTodayjs, dayjsToDB2, dbTodayjs2 } from '../../utils/date'
import InputNumberNew from '../../components/InputNumberNew'

import { useAuth } from '../../contexts/AuthContextProvider'
import { nameTitleObj } from '../../const/users'
import { courseObj, degreeObj, learningSystemObj, relationshipObj, bloodObj, relationObj, jobTypeObj } from '../../const/student'
import { pickBy } from '../../utils/tools'
import { isAllowed } from '../../route/roles'
import InfoPdf from './Components/InfoPdf'

const Form = () => {
  const navigate = useNavigate()
  // const { db, role, userId } = useAuth()
  const { db, role } = useAuth()
  const { id } = useParams()

  const [loading, setLoading] = useState(false)
  const [liveWith, setLiveWith] = useState()
  const [eyeWear, setEyeWear] = useState()
  const [surgery, setSurgery] = useState()
  const [tattoo, setTattoo] = useState()
  const [military, setMilitary] = useState()
  const [illness, setIllness] = useState()
  const [disease, setDisease] = useState()
  const [smoking, setSmoking] = useState()
  const [drinking, setDrinking] = useState()
  const [passport, setPassport] = useState()
  const [gotojapan, setGotojapan] = useState()
  const [lawsuit, setLawsuit] = useState()
  const [changename, setChangename] = useState()
  const [jobinjapan, setJobinjapan] = useState()
  const [debt, setDebt] = useState()
  const [relativeinjapan, setRelativeinjapan] = useState()

  const [infoForm] = AntdForm.useForm()

  // const uid = (role === 'student') ? userId : id
  const uid = id
  const readOnly = !isAllowed(role, 'edit', 'infoTh')

  const { value } = useAsync(async () => {
    const userSnap = await db.collection('/users').doc(uid).get()
    const userData = userSnap.data()
    const snap = await db.collection('/studentinfo').doc(uid).get()
    const data = snap.data()
    setLiveWith(data?.livewith)
    setEyeWear(data?.eyewear)
    setSurgery(data?.surgery)
    setTattoo(data?.tattoo)
    setMilitary(data?.military)
    setIllness(data?.illness)
    setDisease(data?.disease)
    setSmoking(data?.smoking)
    setDrinking(data?.drinking)
    setPassport(data?.passport)
    setGotojapan(data?.gotojapan)
    setLawsuit(data?.lawsuit)
    setChangename(data?.changename)
    setJobinjapan(data?.jobinjapan)
    setDebt(data?.debt)
    setRelativeinjapan(data?.relativeinjapan)
    return {
      ...userData,
      ...data,
      birthdate: dbTodayjs(data?.birthdate),
      infothdate: dbTodayjs(data?.infothdate),
      family: (data?.family?.length) ? data.family : [{}],
      jobs: (data?.jobs?.length) ? data.jobs.map((o) => ({ ...o, period: dbTodayjs2(o?.period) })) : [{}],
      jhsperiod: dbTodayjs2(data?.jhsperiod),
      shsperiod: dbTodayjs2(data?.shsperiod),
      dipperiod: dbTodayjs2(data?.dipperiod),
      bdperiod: dbTodayjs2(data?.bdperiod)
    }
  }, [uid])

  const handleSubmit = async (formData) => {
    try {
      setLoading(true)
      const exceptField = ['eyewear', 'illness', 'familyremark', 'dipname', 'dipmajor', 'dipperiod', 'bdname', 'bdmajor', 'bdperiod']
      const checkInput = Object.keys(formData).find((o) => ((exceptField.indexOf(o) === -1) && ((formData[o] === null) || (formData[o] === ''))))
      const userData = {
        title: formData.title,
        firstname: formData.firstname,
        lastname: formData.lastname,
        nickname: formData.nickname,
        firstnameen: formData.firstnameen,
        lastnameen: formData.lastnameen,
        nicknameen: formData.nicknameen,
        email: formData.email,
        tel: formData.tel,
        course: formData.course,
        learningsystem: formData.learningsystem,
        degree: formData.degree,
        school: formData.school,
        colorblind: formData.colorblind,
        military: formData.military,
        iscompletedth: !checkInput
      }
      const formatData = pickBy(userData)
      const isDiff = Object.keys(formatData).find((o) => formatData[o] !== value[o])
      if (isDiff) {
        await db.collection('/users').doc(uid).set(formatData, {
          merge: true
        })
      }
      let data = pickBy(formData)
      await db.collection('/studentinfo').doc(uid).set({
        ...data,
        birthdate: dayjsToDB(data?.birthdate),
        infothdate: dayjsToDB(data?.infothdate),
        family: data.family.map((o) => pickBy(o)),
        jobs: data.jobs.map((o) => {
          const obj = pickBy(o)
          return {
            ...obj,
            period: dayjsToDB2(o?.period)
          }
        }),
        jhsperiod: dayjsToDB2(data?.jhsperiod),
        shsperiod: dayjsToDB2(data?.shsperiod),
        dipperiod: dayjsToDB2(data?.dipperiod),
        bdperiod: dayjsToDB2(data?.bdperiod)
      }, {
        merge: true
      })
      setLoading(false)
      navigate(`/admin/students/${uid}/view`)
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  if (!value) return <Skeleton></Skeleton>
  return (
    <Card style={{ padding: 48, minHeight: '100%' }}>
      <AntdForm
        layout='vertical'
        onFinish={handleSubmit}
        initialValues={value}
        form={infoForm}
        name='infoForm'
      >
        <div style={{ marginBottom: 16, fontSize: 24 }}>
          ข้อมูลนักเรียน
        </div>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='course' label='หลักสูตรที่สนใจ' >
              <Select disabled={readOnly} placeholder='เลือก' >
                {((Object.keys(courseObj)).map((o) => (
                  <Select.Option key={o} value={o}>{courseObj[o]?.name}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='learningsystem' label='ระบบการเรียน' >
              <Select disabled={readOnly} placeholder='เลือก' >
                {((Object.keys(learningSystemObj)).map((o) => (
                  <Select.Option key={o} value={o}>{learningSystemObj[o]?.name}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='degree' label='วุฒิการศึกษา' >
              <Select disabled={readOnly} placeholder='เลือก' >
                {((Object.keys(degreeObj)).map((o) => (
                  <Select.Option key={o} value={o}>{degreeObj[o]?.name}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='school' label='สถานศึกษา' >
              <Input disabled={readOnly} placeholder='สถานศึกษา' />
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={24}>
            <AntdForm.Item name='remark' label='หมายเหตุ' >
              <Input.TextArea disabled={readOnly} placeholder='หมายเหตุ' autoSize={{ minRows: 1 }} />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='title' label='คำนำหน้า' >
              <Select disabled={readOnly} placeholder='เลือก' >
                {((Object.keys(nameTitleObj)).map((o) => (
                  <Select.Option key={o} value={o}>{nameTitleObj[o]?.nameTh}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
          <Col span={8}></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='firstname' label='ชื่อจริง (ไทย)' >
              <Input disabled={readOnly} placeholder='ชื่อจริง (ไทย)' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='lastname' label='นามสกุล (ไทย)' >
              <Input disabled={readOnly} placeholder='นามสกุล (ไทย)' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='nickname' label='ชื่อเล่น (ไทย)' >
              <Input disabled={readOnly} placeholder='ชื่อเล่น (ไทย)' />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='firstnameen' label='ชื่อจริง (อังกฤษ)' >
              <Input disabled={readOnly} placeholder='ชื่อจริง (อังกฤษ)' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='lastnameen' label='นามสกุล (อังกฤษ)' >
              <Input disabled={readOnly} placeholder='นามสกุล (อังกฤษ)' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='nicknameen' label='ชื่อเล่น (อังกฤษ)' >
              <Input disabled={readOnly} placeholder='ชื่อเล่น (อังกฤษ)' />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='birthdate' label='วันเดือนปีเกิด' >
              <DatePicker
                disabled={readOnly}
                placeholder='วันเดือนปีเกิด'
                onChange={(v) => {
                  infoForm.setFieldsValue({
                    ...infoForm.getFieldsValue(),
                    age: (dayjs()).diff(v, 'year')
                  })
                }}
              />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='age' label='อายุ' >
              <InputNumberNew disabled={readOnly} placeholder='อายุ' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='relationship' label='สถานภาพ' >
              <Select disabled={readOnly} placeholder='เลือก' >
                {((Object.keys(relationshipObj)).map((o) => (
                  <Select.Option key={o} value={o}>{relationshipObj[o]?.name}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={24}>
            <AntdForm.Item name='addresshome' label='ที่อยู่ตามทะเบียนบ้าน' >
              <Input.TextArea disabled={readOnly} placeholder='ที่อยู่ตามทะเบียนบ้าน' autoSize={{ minRows: 1 }} />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={24}>
            <AntdForm.Item name='address' label='ที่อยู่ปัจจุบัน' >
              <Input.TextArea disabled={readOnly} placeholder='ที่อยู่ปัจจุบัน' autoSize={{ minRows: 1 }} />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='tel' label='เบอร์ติดต่อ' >
              <Input disabled={readOnly} placeholder='เบอร์ติดต่อ' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='facebook' label='Facebook' >
              <Input disabled={readOnly} placeholder='Facebook' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='line' label='Line' >
              <Input disabled={readOnly} placeholder='Line' />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='email' label='Email' >
              <Input disabled={readOnly} placeholder='Email' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='telparent' label='เบอร์ติดต่อผู้ปกครอง' >
              <Input disabled={readOnly} placeholder='เบอร์ติดต่อผู้ปกครอง' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='livewith' label='อาศัยอยู่กับ' >
              <Radio.Group disabled={readOnly}
                options={[
                  { value: 'parent', label: 'พ่อแม่' },
                  { value: 'other', label: 'อื่นๆ' }
                ]}
                onChange={(e) => setLiveWith(e.target.value)}
              />
            </AntdForm.Item>
            {(liveWith === 'other') && (
              <AntdForm.Item name='livewitho'>
                <Input disabled={readOnly} />
              </AntdForm.Item>
            )}
          </Col>
        </Row>
        <div style={{ marginBottom: 16, fontSize: 24 }}>
          สัดส่วน
        </div>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='height' label='ส่วนสูง' >
              <Input disabled={readOnly} placeholder='ส่วนสูง' addonAfter='ซม.' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='weight' label='น้ำหนัก' >
              <Input disabled={readOnly} placeholder='น้ำหนัก' addonAfter='กก.' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='body' label='สัดส่วน' >
              <Radio.Group disabled={readOnly}
                options={[
                  { value: 'normal', label: 'ปกติ' },
                  { value: 'fat', label: 'อ้วน' },
                  { value: 'thin', label: 'ผอม' }
                ]}
              />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='hand' label='มือข้างที่ถนัด' >
              <Select disabled={readOnly} placeholder='เลือก' >
                <Select.Option value='left'>มือซ้าย</Select.Option>
                <Select.Option value='right'>มือขวา</Select.Option>
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='blood' label='กรุ๊ปเลือด' >
              <Select disabled={readOnly} placeholder='เลือก' >
                {((Object.keys(bloodObj)).map((o) => (
                  <Select.Option key={o} value={o}>{bloodObj[o]?.name}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}></Col>
        </Row>
        <div style={{ marginBottom: 16, fontSize: 24 }}>
          ร่างกาย/สายตา
        </div>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <Row>
              <Col span={16}>
                <AntdForm.Item name='eyeleft' label='สายตา (ตาข้างซ้าย)' >
                  <Radio.Group disabled={readOnly}
                    options={[
                      { value: 'normal', label: 'ปกติ' },
                      { value: 'abnormal', label: 'ไม่ปกติ' }
                    ]}
                  />
                </AntdForm.Item>
              </Col>
              <Col span={8}>
                <AntdForm.Item name='eyeleftdetail' style={{ marginTop: 28 }}>
                  <InputNumberNew disabled={readOnly} />
                </AntdForm.Item>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row>
              <Col span={16}>
                <AntdForm.Item name='eyeright' label='สายตา (ตาข้างขวา)' >
                  <Radio.Group disabled={readOnly}
                    options={[
                      { value: 'normal', label: 'ปกติ' },
                      { value: 'abnormal', label: 'ไม่ปกติ' }
                    ]}
                  />
                </AntdForm.Item>
              </Col>
              <Col span={8}>
                <AntdForm.Item name='eyerightdetail' style={{ marginTop: 28 }}>
                  <InputNumberNew disabled={readOnly} />
                </AntdForm.Item>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='eyewear' label='ใส่แว่นตา/คอนแทคเลนส์' >
              <Checkbox.Group disabled={readOnly}
                options={[
                  { value: 'glasses', label: 'แว่นตา' },
                  { value: 'contactlens', label: 'คอนแทคเลนส์' }
                ]}
                onChange={(v) => setEyeWear(v)}
              />
            </AntdForm.Item>
          </Col>
        </Row>
        {((eyeWear || []).indexOf('glasses') !== -1) && (
          <Row gutter={[24, 32]}>
            <Col span={8}>
              <AntdForm.Item name='glassesleft' label='สายตาข้างซ้าย (แว่นตา)'>
                <InputNumberNew disabled={readOnly} placeholder='สายตาข้างซ้าย (แว่นตา)' />
              </AntdForm.Item>
            </Col>
            <Col span={8}>
              <AntdForm.Item name='glassesright' label='สายตาข้างขวา (แว่นตา)'>
                <InputNumberNew disabled={readOnly} placeholder='สายตาข้างขวา (แว่นตา)' />
              </AntdForm.Item>
            </Col>
            <Col span={8}></Col>
          </Row>
        )}
        {((eyeWear || []).indexOf('contactlens') !== -1) && (
          <Row gutter={[24, 32]}>
            <Col span={8}>
              <AntdForm.Item name='contactlensleft' label='สายตาข้างซ้าย (คอนแทคเลนส์)'>
                <InputNumberNew disabled={readOnly} placeholder='สายตาข้างซ้าย (คอนแทคเลนส์)' />
              </AntdForm.Item>
            </Col>
            <Col span={8}>
              <AntdForm.Item name='contactlensright' label='สายตาข้างขวา (คอนแทคเลนส์)'>
                <InputNumberNew disabled={readOnly} placeholder='สายตาข้างขวา (คอนแทคเลนส์)' />
              </AntdForm.Item>
            </Col>
            <Col span={8}></Col>
          </Row>
        )}
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='colorblind' label='ตาบอดสี' >
              <Select disabled={readOnly} placeholder='เลือก' >
                <Select.Option value='yes'>ตาบอดสี</Select.Option>
                <Select.Option value='no'>ตาไม่บอดสี</Select.Option>
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='slantedeye' label='ตาเอียง' >
              <Select disabled={readOnly} placeholder='เลือก' >
                <Select.Option value='yes'>เอียง</Select.Option>
                <Select.Option value='no'>ไม่เอียง</Select.Option>
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='surgery' label='ศัลยกรรม' >
              <Radio.Group disabled={readOnly}
                options={[
                  { value: 'no', label: 'ไม่ทำ' },
                  { value: 'yes', label: 'ทำ' }
                ]}
                onChange={(e) => setSurgery(e.target.value)}
              />
            </AntdForm.Item>
            {(surgery === 'yes') && (
              <AntdForm.Item name='surgeryy'>
                <Input disabled={readOnly} />
              </AntdForm.Item>
            )}
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='tattoo' label='รอยสัก' >
              <Select disabled={readOnly}
                placeholder='เลือก'
                onChange={(v) => setTattoo(v)}
              >
                <Select.Option value='no'>ไม่มี</Select.Option>
                <Select.Option value='yes'>มี</Select.Option>
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            {(tattoo === 'yes') && (
              <AntdForm.Item name='tattoopos' label='ตำแหน่งรอยสัก'>
                <Input disabled={readOnly} placeholder='ตำแหน่งรอยสัก' />
              </AntdForm.Item>
            )}
          </Col>
          <Col span={8}>
            {(tattoo === 'yes') && (
              <AntdForm.Item name='tattoosize' label='ขนาดรอยสัก'>
                <Input disabled={readOnly} placeholder='ขนาดรอยสัก' />
              </AntdForm.Item>
            )}
          </Col>
        </Row>
        <AntdForm.Item name='military' label='สถานะทางทหาร' >
          <Radio.Group disabled={readOnly}
            style={{ width: '100%' }}
            onChange={(e) => setMilitary(e.target.value)}
          >
            <Row>
              <Col span={8}>
                <Row>
                  <Col span='auto'>
                    <Radio value='completed'>เกณฑ์ทหารแล้ว</Radio>
                  </Col>
                  <Col span={5}>
                    {(military === 'completed') && (
                      <AntdForm.Item name='militaryc' style={{ marginBottom: 0 }}>
                        <Input disabled={readOnly} placeholder='ปีที่เกณฑ์ทหาร' />
                      </AntdForm.Item>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Row>
                  <Col span='auto'>
                    <Radio value='postponed'>ผ่อนผัน</Radio>
                  </Col>
                  <Col span={5}>
                    {(military === 'postponed') && (
                      <AntdForm.Item name='militaryp' style={{ marginBottom: 0 }}>
                        <Input disabled={readOnly} placeholder='ปีที่ผ่อนผัน' />
                      </AntdForm.Item>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Row>
                  <Col span='auto'>
                    <Radio value='na'>ยังไม่ถึงเกณฑ์ จะถึงเกณฑ์</Radio>
                  </Col>
                  <Col span={5}>
                    {(military === 'na') && (
                      <AntdForm.Item name='militaryn' style={{ marginBottom: 0 }}>
                        <Input disabled={readOnly} placeholder='ปีที่ถึงเกณฑ์' />
                      </AntdForm.Item>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Radio value='rotc'>รด.</Radio>
              </Col>
              <Col span={8}>
                <Radio value='w'>เพศหญิง</Radio>
              </Col>
              <Col span={8}></Col>
            </Row>
          </Radio.Group>
        </AntdForm.Item>
        <AntdForm.Item name='illness' label='ประวัติการเจ็บป่วย' >
          <Checkbox.Group disabled={readOnly}
            style={{ width: '100%' }}
            onChange={(v) => setIllness(v)}
          >
            <Row>
              <Col span={8}>
                <Row>
                  <Col span='auto'>
                    <Checkbox value='surgery'>เคยผ่าตัด</Checkbox>
                  </Col>
                  <Col span={8}>
                    {((illness || []).indexOf('surgery') !== -1) && (
                      <AntdForm.Item name='illnesss' style={{ marginBottom: 0 }}>
                        <Input disabled={readOnly} />
                      </AntdForm.Item>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Row>
                  <Col span='auto'>
                    <Checkbox value='accident'>เคยเกิดอุบัติเหตุหนัก</Checkbox>
                  </Col>
                  <Col span={8}>
                    {((illness || []).indexOf('accident') !== -1) && (
                      <AntdForm.Item name='illnessa' style={{ marginBottom: 0 }}>
                        <Input disabled={readOnly} />
                      </AntdForm.Item>
                    )}
                  </Col>
                  <Col span='auto'></Col>
                </Row>
              </Col>
              <Col span={8}>
                <Row>
                  <Col span='auto'>
                    <Checkbox value='bone'>เคยกระดูกหัก/มีเหล็กดาม</Checkbox>
                  </Col>
                  <Col span={8}>
                    {((illness || []).indexOf('bone') !== -1) && (
                      <AntdForm.Item name='illnessb' style={{ marginBottom: 0 }}>
                        <Input disabled={readOnly} />
                      </AntdForm.Item>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Row>
                  <Col span='auto'>
                    <Checkbox value='other'>อื่นๆ</Checkbox>
                  </Col>
                  <Col span={8}>
                    {((illness || []).indexOf('other') !== -1) && (
                      <AntdForm.Item name='illnesso' style={{ marginBottom: 0 }}>
                        <Input disabled={readOnly} />
                      </AntdForm.Item>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col span={8}></Col>
            </Row>
          </Checkbox.Group>
        </AntdForm.Item>
        <AntdForm.Item name='disease' label='โรคประจำตัว' >
          <Checkbox.Group disabled={readOnly}
            style={{ width: '100%' }}
            onChange={(v) => setDisease(v)}
          >
            <Row>
              <Col span={8}>
                <Checkbox value='no'>ไม่มี</Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value='anemia'>โรคเลือดจาง</Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value='hepatitisb'>ไวรัสตับอักเสบบี</Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value='seizures'>ลมชัก/ลมบ้าหมู</Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value='thyroid'>ไทรอยด์</Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value='asthma'>หอบหืด</Checkbox>
              </Col>
              <Col span={8}>
                <Row>
                  <Col span='auto'>
                    <Checkbox value='disease'>มีโรค</Checkbox>
                  </Col>
                  <Col span={6}>
                    {((disease || []).indexOf('disease') !== -1) && (
                      <AntdForm.Item name='diseased' style={{ marginBottom: 0 }}>
                        <Input disabled={readOnly} />
                      </AntdForm.Item>
                    )}
                  </Col>
                  <Col span='auto'></Col>
                </Row>
              </Col>
              <Col span={16}>
                <Row>
                  <Col span='auto'>
                    <Checkbox value='allergy'>ภูมิแพ้</Checkbox>
                  </Col>
                  {((disease || []).indexOf('allergy') !== -1) && (
                    <>
                      <Col span={8}>
                        <AntdForm.Item name='diseaseaw' style={{ marginBottom: 0 }}>
                          <Input disabled={readOnly} placeholder='แพ้อะไร' />
                        </AntdForm.Item>
                      </Col>
                      <Col span={8}>
                        <AntdForm.Item name='diseaseah' style={{ marginBottom: 0 }}>
                          <Input disabled={readOnly} placeholder='อาการ' />
                        </AntdForm.Item>
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
            </Row>
          </Checkbox.Group>
        </AntdForm.Item>
        <div style={{ marginBottom: 16, fontSize: 24 }}>
          รายละเอียดเพิ่มเติม
        </div>
        <Row gutter={[24, 32]}>
          <Col span='auto'>
            <AntdForm.Item name='smoking' label='สูบบุหรี่' >
              <Radio.Group disabled={readOnly}
                style={{ width: '100%' }}
                onChange={(e) => setSmoking(e.target.value)}
              >
                <Radio value='no'>ไม่สูบ</Radio>
                <Radio value='regular'>สูบประจำ</Radio>
                <Radio value='sometimes'>สูบบางครั้ง</Radio>
              </Radio.Group>
            </AntdForm.Item>
          </Col>
          <Col span={6}>
            {(smoking === 'sometimes') && (
              <AntdForm.Item name='smokings' style={{ marginTop: 28 }}>
                <Input disabled={readOnly} />
              </AntdForm.Item>
            )}
          </Col>
          <Col span='auto'></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span='auto'>
            <AntdForm.Item name='drinking' label='ดื่มเหล้า/ดื่มเบียร์' >
              <Radio.Group disabled={readOnly}
                style={{ width: '100%' }}
                onChange={(e) => setDrinking(e.target.value)}
              >
                <Radio value='no'>ไม่ดื่ม</Radio>
                <Radio value='regular'>ดื่มประจำ</Radio>
                <Radio value='sometimes'>ดื่มบางครั้ง</Radio>
              </Radio.Group>
            </AntdForm.Item>
          </Col>
          <Col span={6}>
            {(drinking === 'sometimes') && (
              <AntdForm.Item name='drinkings' style={{ marginTop: 28 }}>
                <Input disabled={readOnly} />
              </AntdForm.Item>
            )}
          </Col>
          <Col span='auto'></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={24}>
            <AntdForm.Item name='driver' label='ใบอนุญาตขับขี่' >
              <Checkbox.Group disabled={readOnly}
                style={{ width: '100%' }}
              >
                <Checkbox value='no'>ไม่มี</Checkbox>
                <Checkbox value='motorcycle'>รถจักรยานยนต์</Checkbox>
                <Checkbox value='car'>รถยนต์</Checkbox>
              </Checkbox.Group>
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span='auto'>
            <AntdForm.Item name='passport' label='เคยมีพาสปอร์ตหรือไม่' >
              <Radio.Group disabled={readOnly}
                style={{ width: '100%' }}
                onChange={(e) => setPassport(e.target.value)}
              >
                <Radio value='no'>ไม่มี</Radio>
                <Radio value='yes'>มี</Radio>
              </Radio.Group>
            </AntdForm.Item>
          </Col>
          <Col span={6}>
            {(passport === 'yes') && (
              <AntdForm.Item name='passporty' style={{ marginTop: 28 }}>
                <Input disabled={readOnly} placeholder='เพราะอะไร' />
              </AntdForm.Item>
            )}
          </Col>
          <Col span='auto'></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span='auto'>
            <AntdForm.Item name='gotojapan' label='เคยไปญี่ปุ่นหรือไม่' >
              <Radio.Group disabled={readOnly}
                style={{ width: '100%' }}
                onChange={(e) => setGotojapan(e.target.value)}
              >
                <Radio value='no'>ไม่เคย</Radio>
                <Radio value='yes'>เคย</Radio>
              </Radio.Group>
            </AntdForm.Item>
          </Col>
          <Col span={6}>
            {(gotojapan === 'yes') && (
              <AntdForm.Item name='gotojapany' style={{ marginTop: 28 }}>
                <Input disabled={readOnly} placeholder='ไปทำอะไร วีซ่าอะไร' />
              </AntdForm.Item>
            )}
          </Col>
          <Col span='auto'></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span='auto'>
            <AntdForm.Item name='lawsuit' label='เคยมีคดีความหรือไม่' >
              <Radio.Group disabled={readOnly}
                style={{ width: '100%' }}
                onChange={(e) => setLawsuit(e.target.value)}
              >
                <Radio value='no'>ไม่เคย</Radio>
                <Radio value='yes'>เคย</Radio>
              </Radio.Group>
            </AntdForm.Item>
          </Col>
          <Col span={6}>
            {(lawsuit === 'yes') && (
              <AntdForm.Item name='lawsuity' style={{ marginTop: 28 }}>
                <Input disabled={readOnly} placeholder='เรื่องอะไร' />
              </AntdForm.Item>
            )}
          </Col>
          <Col span='auto'></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span='auto'>
            <AntdForm.Item name='changename' label='เคยเปลี่ยนชื่อ-นามสกุลหรือไม่' >
              <Radio.Group disabled={readOnly}
                style={{ width: '100%' }}
                onChange={(e) => setChangename(e.target.value)}
              >
                <Radio value='no'>ไม่เคย</Radio>
                <Radio value='yes'>เคย</Radio>
              </Radio.Group>
            </AntdForm.Item>
          </Col>
          {(changename === 'yes') && (
            <>
              <Col span={5}>
                <AntdForm.Item name='changenameyf' style={{ marginTop: 28 }}>
                  <Input disabled={readOnly} placeholder='ชื่อ' />
                </AntdForm.Item>
              </Col>
              <Col span={5}>
                <AntdForm.Item name='changenameyl' style={{ marginTop: 28 }}>
                  <Input disabled={readOnly} placeholder='นามสกุล' />
                </AntdForm.Item>
              </Col>
            </>
          )}
          <Col span='auto'></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span='auto'>
            <AntdForm.Item name='jobinjapan' label='เคยสมัครงานไปญี่ปุ่นกับบริษัทอื่นหรือไม่' >
              <Radio.Group disabled={readOnly}
                style={{ width: '100%' }}
                onChange={(e) => setJobinjapan(e.target.value)}
              >
                <Radio value='no'>ไม่เคย</Radio>
                <Radio value='yes'>เคย</Radio>
              </Radio.Group>
            </AntdForm.Item>
          </Col>
          {(jobinjapan === 'yes') && (
            <>
              <Col span={5}>
                <AntdForm.Item name='jobinjapanyc' style={{ marginTop: 28 }}>
                  <Input disabled={readOnly} placeholder='ชื่อบริษัท' />
                </AntdForm.Item>
              </Col>
              <Col span={5}>
                <AntdForm.Item name='jobinjapanyw' style={{ marginTop: 28 }}>
                  <Input disabled={readOnly} placeholder='เมื่อไหร่' />
                </AntdForm.Item>
              </Col>
            </>
          )}
          <Col span='auto'></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span='auto'>
            <AntdForm.Item name='debt' label='มีภาระหนี้สินหรือไม่' >
              <Radio.Group disabled={readOnly}
                style={{ width: '100%' }}
                onChange={(e) => setDebt(e.target.value)}
              >
                <Radio value='no'>ไม่มี</Radio>
                <Radio value='yes'>มี</Radio>
              </Radio.Group>
            </AntdForm.Item>
          </Col>
          {(debt === 'yes') && (
            <>
              <Col span={4}>
                <AntdForm.Item name='debtyw' style={{ marginTop: 28 }}>
                  <Input disabled={readOnly} placeholder='กู้จากไหน' />
                </AntdForm.Item>
              </Col>
              <Col span={4}>
                <AntdForm.Item name='debtyd' style={{ marginTop: 28 }}>
                  <Input disabled={readOnly} placeholder='กู้มาทำอะไร' />
                </AntdForm.Item>
              </Col>
              <Col span={4}>
                <AntdForm.Item name='debtyn' style={{ marginTop: 28 }}>
                  <Input disabled={readOnly} placeholder='จำนวน' addonAfter='บาท' />
                </AntdForm.Item>
              </Col>
            </>
          )}
          <Col span='auto'></Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span='auto'>
            <AntdForm.Item name='relativeinjapan' label='มีญาติพี่น้อง คนรู้จัก เพื่อน อยู่ที่ญี่ปุ่นหรือไม่' >
              <Radio.Group disabled={readOnly}
                style={{ width: '100%' }}
                onChange={(e) => setRelativeinjapan(e.target.value)}
              >
                <Radio value='no'>ไม่มี</Radio>
                <Radio value='yes'>มี</Radio>
              </Radio.Group>
            </AntdForm.Item>
          </Col>
          <Col span='auto'></Col>
        </Row>
        {(relativeinjapan === 'yes') && (
          <>
            <Row gutter={[24, 32]}>
              <Col span={8}>
                <AntdForm.Item name='relativename' label='ชื่อ-นามสกุล คนที่อยู่ญี่ปุ่น' >
                  <Input disabled={readOnly} placeholder='ชื่อ-นามสกุล คนที่อยู่ญี่ปุ่น' />
                </AntdForm.Item>
              </Col>
              <Col span={8}>
                <AntdForm.Item name='relativeage' label='อายุ' >
                  <InputNumberNew disabled={readOnly} placeholder='อายุ' />
                </AntdForm.Item>
              </Col>
              <Col span={8}>
                <AntdForm.Item name='relativegender' label='เพศ' >
                  <Select disabled={readOnly} placeholder='เลือก' >
                    <Select.Option value='male'>ชาย</Select.Option>
                    <Select.Option value='female'>หญิง</Select.Option>
                  </Select>
                </AntdForm.Item>
              </Col>
            </Row>
            <Row gutter={[24, 32]}>
              <Col span={8}>
                <AntdForm.Item name='relativerelation' label='ความสัมพันธ์' >
                  <Input disabled={readOnly} placeholder='ความสัมพันธ์' />
                </AntdForm.Item>
              </Col>
              <Col span={16}>
                <AntdForm.Item name='relativeaddress' label='ที่อยู่ในญี่ปุ่น' >
                  <Input.TextArea disabled={readOnly} placeholder='ที่อยู่ในญี่ปุ่น' autoSize={{ minRows: 1 }} />
                </AntdForm.Item>
              </Col>
            </Row>
            <Row gutter={[24, 32]}>
              <Col span={8}>
                <AntdForm.Item name='relativetel' label='เบอร์ติดต่อ' >
                  <Input disabled={readOnly} placeholder='เบอร์ติดต่อ' />
                </AntdForm.Item>
              </Col>
              <Col span={8}>
                <AntdForm.Item name='relativelived' label='ระยะเวลาที่อยู่ญี่ปุ่น' >
                  <InputNumberNew disabled={readOnly} placeholder='ระยะเวลาที่อยู่ญี่ปุ่น' />
                </AntdForm.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
          </>
        )}
        <div style={{ marginBottom: 16, fontSize: 24 }}>
          สมาชิกในครอบครัว
        </div>
        <AntdForm.List
          name='family'
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <div key={`family-${index}`}>
                  <Row gutter={[24, 32]}>
                    <Col span={8}>
                      <AntdForm.Item name={[field.name, 'name']} label='ชื่อ - นามสกุล' >
                        <Input disabled={readOnly} placeholder='ชื่อ - นามสกุล' />
                      </AntdForm.Item>
                    </Col>
                    <Col span={8}>
                      <AntdForm.Item name={[field.name, 'relation']} label='ความสัมพันธ์' >
                        <Select disabled={readOnly} placeholder='เลือก' >
                          {((Object.keys(relationObj)).map((o) => (
                            <Select.Option key={o} value={o}>{relationObj[o]?.name}</Select.Option>
                          )))}
                        </Select>
                      </AntdForm.Item>
                    </Col>
                    <Col span={8}>
                      <AntdForm.Item name={[field.name, 'age']} label='อายุ' >
                        <InputNumberNew disabled={readOnly} placeholder='อายุ' />
                      </AntdForm.Item>
                    </Col>
                  </Row>
                  <Row gutter={[24, 32]}>
                    <Col span={8}>
                      <AntdForm.Item name={[field.name, 'occupation']} label='อาชีพ' >
                        <Input disabled={readOnly} placeholder='อาชีพ' />
                      </AntdForm.Item>
                    </Col>
                    <Col span={8}>
                      <AntdForm.Item name={[field.name, 'position']} label='ตำแหน่ง' >
                        <Input disabled={readOnly} placeholder='ตำแหน่ง' />
                      </AntdForm.Item>
                    </Col>
                    <Col span={8}>
                      <AntdForm.Item name={[field.name, 'company']} label='สถานที่ทำงาน / ชื่อบริษัท' >
                        <Input disabled={readOnly} placeholder='สถานที่ทำงาน / ชื่อบริษัท' />
                      </AntdForm.Item>
                    </Col>
                  </Row>
                  <Row gutter={[24, 32]}>
                    <Col span={8}>
                      <AntdForm.Item name={[field.name, 'province']} label='จังหวัด' >
                        <Input disabled={readOnly} placeholder='จังหวัด' />
                      </AntdForm.Item>
                    </Col>
                    <Col span={8}></Col>
                    <Col span={8}>
                      {(!readOnly) && (
                        <>
                          {(index !== 0) ? (
                            <Button onClick={() => remove(field.name)} icon={<DeleteOutlined />} style={{ marginTop: 30, padding: '0 24px', color: '#FFF', backgroundColor: '#EE7930', border: '1px solid #EE7930' }}>
                              ลบ
                            </Button>
                          ) : (
                            <Button type='primary' onClick={() => add()} icon={<PlusOutlined />} style={{ marginTop: 30, padding: '0 24px' }}>
                              เพิ่มสมาชิกในครอบครัว
                            </Button>
                          )}
                        </>
                      )}
                    </Col>
                  </Row>
                </div>
              ))}
            </>
          )}
        </AntdForm.List>
        <AntdForm.Item name='familyremark' label='กรุณาอธิบายเพิ่มเติม กรณี บิดา/มารดา หย่าร้างหรือเสียชีวิต' >
          <Input.TextArea disabled={readOnly} autoSize={{ minRows: 1 }} />
        </AntdForm.Item>
        <div style={{ marginBottom: 16, fontSize: 24 }}>
          ประวัติการศึกษา
        </div>
        <div style={{ marginBottom: 8, fontSize: 16 }}>
          ระดับมัธยม
        </div>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='jhsname' label='ชื่อสถานศึกษา' >
              <Input disabled={readOnly} placeholder='ชื่อสถานศึกษา' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='jhsmajor' label='สาขา/แผนก' >
              <Input disabled={readOnly} placeholder='สาขา/แผนก' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='jhsperiod' label='เข้าศึกษา - จบการศึกษา' >
              <DatePicker.RangePicker disabled={readOnly} />
            </AntdForm.Item>
          </Col>
        </Row>
        <div style={{ marginBottom: 8, fontSize: 16 }}>
          ระดับมัธยมปลาย/ปวช.
        </div>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='shsname' label='ชื่อสถานศึกษา' >
              <Input disabled={readOnly} placeholder='ชื่อสถานศึกษา' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='shsmajor' label='สาขา/แผนก' >
              <Input disabled={readOnly} placeholder='สาขา/แผนก' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='shsperiod' label='เข้าศึกษา - จบการศึกษา' >
              <DatePicker.RangePicker disabled={readOnly} />
            </AntdForm.Item>
          </Col>
        </Row>
        <div style={{ marginBottom: 8, fontSize: 16 }}>
          ระดับอนุปริญญา/ปวส./ทวิภาคี
        </div>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='dipname' label='ชื่อสถานศึกษา' >
              <Input disabled={readOnly} placeholder='ชื่อสถานศึกษา' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='dipmajor' label='สาขา/แผนก' >
              <Input disabled={readOnly} placeholder='สาขา/แผนก' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='dipperiod' label='เข้าศึกษา - จบการศึกษา' >
              <DatePicker.RangePicker disabled={readOnly} />
            </AntdForm.Item>
          </Col>
        </Row>
        <div style={{ marginBottom: 8, fontSize: 16 }}>
          ระดับปริญญาตรี
        </div>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='bdname' label='ชื่อสถานศึกษา' >
              <Input disabled={readOnly} placeholder='ชื่อสถานศึกษา' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='bdmajor' label='สาขา/แผนก' >
              <Input disabled={readOnly} placeholder='สาขา/แผนก' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='bdperiod' label='เข้าศึกษา - จบการศึกษา' >
              <DatePicker.RangePicker disabled={readOnly} />
            </AntdForm.Item>
          </Col>
        </Row>
        <div style={{ marginBottom: 16, fontSize: 24 }}>
          ประวัติการทำงาน
        </div>
        <AntdForm.List
          name='jobs'
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <div key={`jobs-${index}`}>
                  <Row gutter={[24, 32]}>
                    <Col span={8}>
                      <AntdForm.Item name={[field.name, 'company']} label='ชื่อบริษัท' >
                        <Input disabled={readOnly} placeholder='ชื่อบริษัท' />
                      </AntdForm.Item>
                    </Col>
                    <Col span={8}>
                      <AntdForm.Item name={[field.name, 'companytype']} label='ประเภทกิจการ' >
                        <Input disabled={readOnly} placeholder='ประเภทกิจการ' />
                      </AntdForm.Item>
                    </Col>
                    <Col span={8}>
                      <AntdForm.Item name={[field.name, 'jd']} label='ลักษณะงานที่ทำ' >
                        <Input disabled={readOnly} placeholder='ลักษณะงานที่ทำ' />
                      </AntdForm.Item>
                    </Col>
                  </Row>
                  <Row gutter={[24, 32]}>
                    <Col span={8}>
                      <AntdForm.Item name={[field.name, 'jobtype']} label='ประเภทงาน' >
                        <Select disabled={readOnly} placeholder='เลือก' >
                          {((Object.keys(jobTypeObj)).map((o) => (
                            <Select.Option key={o} value={o}>{jobTypeObj[o]?.name}</Select.Option>
                          )))}
                        </Select>
                      </AntdForm.Item>
                    </Col>
                    <Col span={8}>
                      <AntdForm.Item name={[field.name, 'province']} label='จังหวัด' >
                        <Input disabled={readOnly} placeholder='จังหวัด' />
                      </AntdForm.Item>
                    </Col>
                    <Col span={8}>
                      <AntdForm.Item name={[field.name, 'salary']} label='เงินเดือน' >
                        <InputNumberNew disabled={readOnly} placeholder='เงินเดือน' />
                      </AntdForm.Item>
                    </Col>
                  </Row>
                  <Row gutter={[24, 32]}>
                    <Col span={8}>
                      <AntdForm.Item name={[field.name, 'period']} label='เริ่มงาน - ลาออก' >
                        <DatePicker.RangePicker disabled={readOnly} />
                      </AntdForm.Item>
                    </Col>
                    <Col span={8}></Col>
                    <Col span={8}>
                      {(!readOnly) && (
                        <>
                          {(index !== 0) ? (
                            <Button onClick={() => remove(field.name)} icon={<DeleteOutlined />} style={{ marginTop: 30, padding: '0 24px', color: '#FFF', backgroundColor: '#EE7930', border: '1px solid #EE7930' }}>
                              ลบ
                            </Button>
                          ) : (
                            <Button type='primary' onClick={() => add()} icon={<PlusOutlined />} style={{ marginTop: 30, padding: '0 24px' }}>
                              เพิ่มประวัติการทำงาน
                            </Button>
                          )}
                        </>
                      )}
                    </Col>
                  </Row>
                </div>
              ))}
            </>
          )}
        </AntdForm.List>
        <AntdForm.Item name='target' label='เป้าหมายชีวิต' >
          <Input disabled={readOnly} placeholder='เป้าหมายชีวิต' />
        </AntdForm.Item>
        <AntdForm.Item name='hobby' label='งานอดิเรก' >
          <Input disabled={readOnly} placeholder='งานอดิเรก' />
        </AntdForm.Item>
        <AntdForm.Item name='strength' label='ข้อดี' >
          <Input disabled={readOnly} placeholder='ข้อดี' />
        </AntdForm.Item>
        <AntdForm.Item name='weakness' label='ข้อเสีย' >
          <Input disabled={readOnly} placeholder='ข้อเสีย' />
        </AntdForm.Item>
        <AntdForm.Item name='talent' label='ความสามารถพิเศษ' >
          <Input disabled={readOnly} placeholder='ความสามารถพิเศษ' />
        </AntdForm.Item>
        <div style={{ marginBottom: 16, fontSize: 24 }}>
          สำหรับเจ้าหน้าที่
        </div>
        <AntdForm.Item name='comment' label='ความคิดเห็นเพิ่มเติม' >
          <Input.TextArea disabled={readOnly} placeholder='ความคิดเห็นเพิ่มเติม' autoSize={{ minRows: 1 }} />
        </AntdForm.Item>
        <AntdForm.Item name='interviewer' label='ลงชื่อผู้สัมภาษณ์' >
          <Input disabled={readOnly} placeholder='ลงชื่อผู้สัมภาษณ์' />
        </AntdForm.Item>
        <AntdForm.Item name='infothdate' label='วัน/เดือน/ปี' >
          <DatePicker disabled={readOnly} placeholder='วัน/เดือน/ปี' style={{ width: 300 }} />
        </AntdForm.Item>
        <div>
          {(!readOnly) && (
            <>
              <Button
                loading={loading}
                style={{ width: 168, height: 40, marginTop: 20, marginRight: 20 }}
                htmlType='submit'
                type='primary'
              ><SaveOutlined style={{ fontSize: 16 }} />บันทึก</Button>
              <InfoPdf value={value} />
            </>
          )}
          <Button
            loading={loading}
            style={{ height: 40, marginTop: 20 }}
            onClick={() => {
              navigate(-1)
            }}
          ><CloseOutlined />ปิด</Button>
        </div>
      </AntdForm>
    </Card>
  )
}

export default Form
