import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Form as AntdForm, Row, Col, Card, Button, Input, Select, Modal, Space, Skeleton } from 'antd'
import { EditOutlined, DownloadOutlined, PrinterOutlined, SettingOutlined, ExclamationCircleOutlined, CloseOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'
import { DatePicker } from '../../components/DateTime'
import { dbTodayjs } from '../../utils/date'
import InputNumberNew from '../../components/InputNumberNew'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { nameTitleObj } from '../../const/users'
import { genCsv } from '../../utils/csv'
import { genPdf } from '../../utils/pdf'
import { formatNumber, dateTH, toDataURL } from '../../utils/tools'
import { typeObj, securityObj } from '../../const/fund'
import { monthTh } from '../../const/date'
import { isAllowed } from '../../route/roles'

import logoImg from '../../assets/images/logo.png'

const Form = () => {
  const navigate = useNavigate()
  const { db, functions, role } = useAuth()
  const { type, fid } = useParams()
  const { getCoops, getCompanies, getLenders } = useLayoutContext()

  const [coopObj, setCoopObj] = useState()
  const [companyObj, setCompanyObj] = useState()
  const [lenderObj, setLenderObj] = useState()
  const [fundType, setFundType] = useState()
  const [printData, setPrintData] = useState()
  const [confirmVisible, setConfirmVisible] = useState()

  const [logo, setLogo] = useState()

  const [printForm] = AntdForm.useForm()

  const { value } = useAsync(async () => {
    const coops = await getCoops()
    const coopData = coops.reduce((acc, o) => {
      acc[o.id] = o
      return acc
    }, {})
    setCoopObj(coopData)

    const companies = await getCompanies()
    const companyData = companies.reduce((acc, o) => {
      acc[o.id] = o
      return acc
    }, {})
    setCompanyObj(companyData)

    const lenders = await getLenders()
    const lenderData = lenders.reduce((acc, o) => {
      acc[o.id] = o
      return acc
    }, {})
    setLenderObj(lenderData)

    const snap = await db.collection('/funds').doc(fid).get()
    const data = snap.data()
    setFundType(data.type)
    return {
      ...data,
      id: snap.id,
      contractdate: dbTodayjs(data?.contractdate),
      startdate: dbTodayjs(data?.startdate),
      traveldate: dbTodayjs(data?.traveldate),
      payments: (data?.payments ?? []).map((o) => ({
        ...o,
        date: dbTodayjs(o?.date),
        paydate: dbTodayjs(o?.paydate)
      }))
    }
  }, [fid])

  useEffect(() => {
    toDataURL(logoImg).then(data => {
      setLogo(data)
    })
  }, [])

  const handleChange = async (value) => {
    try {
      const changeStatus = functions.httpsCallable('changeFundStatus-changeFundStatus')
      await changeStatus({
        id: fid,
        type: value
      })
      setFundType(value)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const download = async (data) => {
    const title = `รายละเอียดการผ่อนเงินกู้ กองทุนกู้ยืมเพื่อการศึกษาต่อ ณ ประเทศญี่ปุ่น
${nameTitleObj[data.title]?.nameTh ?? ''}${data?.firstname ?? ''} ${data?.lastname ?? ''} ${(data?.borrower1) ? 'และ ' + data.borrower1 : ''} ${(data?.borrower2) ? 'และ ' + data.borrower2 : ''}
ทำสัญญาลงวันที่ ${dateTH(data?.contractdate?.$d)} วงเงินกู้ ${formatNumber(data?.loan)} บาท ระยะเวลา ${data?.period ?? ''} เดือน ดอกเบี้ย ${data?.interest ?? ''}
****** ${(data.security.find((o) => o === 'deed')) ? '' : 'ไม่'}มีโฉนด ******
ผ่อนเดือนละ ${formatNumber(data.installment)} บาท (เงินต้น ${formatNumber(data?.installmentp)} บาท ดอกเบี้ย ${formatNumber(data?.installmenti)} บาท) เดินทางวันที่ ${dateTH(data?.traveldate?.$d)}
ชำระทุกวันที่ 25 ของเดือน เริ่มงวดแรก ${dateTH(data?.startdate?.$d)} เดินทางไปประเทศญี่ปุ่น บริษัท ${companyObj[data?.companyid]?.name ?? ''} กับ ${coopObj[data?.coopid]?.name ?? ''}`
    data.payments.unshift({ n: 0, principleremain: data.loan })
    const list = data.payments.map((o) => ({
      'งวดที่': o.n,
      'งวดการผ่อน': dateTH(o?.date?.$d, 'short'),
      'ยอดเงินที่ชำระ': o?.transfer ?? '',
      'รับชำระในงวด (จำนวนเงิน)': o?.paid ?? '',
      'เงินต้นชำระแล้ว': o?.principle ?? '',
      'ดอกเบี้ยชำระแล้ว': o?.interest ?? '',
      'เงินต้นคงเหลือในงวด': o?.principleremain ?? '',
      'ดอกเบี้ยคงค้างในงวด': o?.interestremain ?? '',
      'ดอกเบี้ยสะสม': o?.interestaccum ?? '',
      'ยอดเงินคงเหลือ': o?.remain ?? ''
    }))
    try {
      await genCsv(`fund_${data.scn}`, list, title)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const print = async (index, name) => {
    const data = value.payments[index]
    const months = value.payments.reduce((acc, o, i) => {
      if (i !== 0 && i <= index && value.payments[i - 1]?.transfer) {
        acc = []
      }
      return (
        (o?.date) &&
        (i <= index)
      ) ? [...acc, monthTh[o?.date?.$d?.getMonth()]] : acc
    }, [])
    const docObj = {
      pageOrientation: 'portrait',
      content: [
        {
          columns: [
            {
              text: 'ใบแจ้งยอดชำระ',
              width: '*',
              fontSize: 36,
              bold: true,
              margin: [0, 26]
            },
            {
              alignment: 'right',
              image: logo,
              width: 90,
              height: 90
            }
          ]
        },
        {
          columns: [
            {
              text: 'ชื่อ-สกุล',
              width: 140,
              bold: true,
              margin: [0, 8, 0, 0]
            },
            {
              text: `${nameTitleObj[value?.title]?.nameTh ?? ''}${value?.firstname ?? ''} ${value?.lastname ?? ''}`,
              width: '*',
              margin: [0, 8, 0, 0]
            }
          ]
        },
        {
          columns: [
            {
              text: 'บริษัท',
              width: 140,
              bold: true,
              margin: [0, 8, 0, 0]
            },
            {
              text: `${companyObj[value?.companyid]?.name ?? ''}`,
              width: '*',
              margin: [0, 8, 0, 0]
            }
          ]
        },
        {
          columns: [
            {
              text: 'วันที่',
              width: 140,
              bold: true,
              margin: [0, 8, 0, 0]
            },
            {
              text: `${(data?.date) ? dayjs(data.date).format('DD/MM/BBBB') : ''}`,
              width: '*',
              margin: [0, 8, 0, 0]
            }
          ]
        },
        {
          columns: [
            {
              text: 'ยอดชำระ',
              width: 140,
              bold: true,
              margin: [0, 8, 0, 0]
            },
            {
              text: `${formatNumber(data?.transfer) || 0} บาท`,
              width: '*',
              margin: [0, 8, 0, 0]
            }
          ]
        },
        {
          columns: [
            {
              text: 'ชำระดอกเบี้ย',
              width: 140,
              bold: true,
              margin: [0, 8, 0, 0]
            },
            {
              text: `${formatNumber(data?.interest) || 0} บาท (${months.join(' ')})`,
              width: '*',
              margin: [0, 8, 0, 0]
            }
          ]
        },
        {
          columns: [
            {
              text: 'ดอกเบี้ยคงค้าง',
              width: 140,
              bold: true,
              margin: [0, 8, 0, 0]
            },
            {
              text: `${formatNumber(data?.interestaccum) || 0} บาท`,
              width: '*',
              margin: [0, 8, 0, 0]
            }
          ]
        },
        {
          columns: [
            {
              text: 'เงินต้นคงเหลือ',
              width: 140,
              bold: true,
              margin: [0, 8, 0, 0]
            },
            {
              text: `${formatNumber(data?.principleremain) || 0} บาท`,
              width: '*',
              margin: [0, 8, 0, 0]
            }
          ]
        },
        {
          columns: [
            {
              text: 'ผู้แจ้งยอด',
              width: 140,
              bold: true,
              margin: [0, 8, 0, 0]
            },
            {
              text: name,
              width: '*',
              margin: [0, 8, 0, 0]
            }
          ]
        },
      ],
      defaultStyle: {
        fontSize: 24,
        font: 'THSarabunNew' // Roboto
      }
    }
    try {
      await genPdf(docObj, `statement_${value?.payments[index]?.n ?? 0}`)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  if (!value) return <Skeleton></Skeleton>
  return (
    <div>
      <Card
        style={{ marginTop: 16 }}
        bodyStyle={{ padding: '32px 80px' }}
      >
        <Row style={{ marginBottom: 24 }}>
          <Col flex='auto'>
            <div style={{ fontSize: 24 }}>
              รายละเอียดกองทุน
            </div>
          </Col>
          <Col flex='300px'>
            <div style={{ float: 'right', fontSize: 14 }}>
              {(isAllowed(role, 'edit', 'funds', type)) && (
                <Button
                  disabled={value.disabled}
                  style={{ marginRight: 12, color: '#FFFFFF', backgroundColor: '#EE7930' }}
                  icon={<EditOutlined style={{ fontSize: 14 }} />}
                  onClick={() => {
                    navigate(`/admin/funds/${type}/${fid}/edit`)
                  }}
                >
                  แก้ไข
                </Button>
              )}
              <Button
                type='primary'
                icon={<DownloadOutlined style={{ fontSize: 14 }} />}
                onClick={() => download(value)}
              >
                ดาวน์โหลด
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={5} className='aiu-title'>รหัสนักเรียน</Col>
          <Col span={7} className='aiu-content'>{value?.scn ?? ''}</Col>
          <Col span={5} className='aiu-title'>ชื่อ-นามสกุล</Col>
          <Col span={7} className='aiu-content'>{`${value?.firstname ?? ''} ${value?.lastname ?? ''}`}</Col>
        </Row>
        <Row>
          <Col span={5} className='aiu-title'>สหกรณ์</Col>
          <Col span={7} className='aiu-content'>{coopObj[value.coopid]?.name ?? ''}</Col>
          <Col span={5} className='aiu-title'>บริษัท</Col>
          <Col span={7} className='aiu-content'>{companyObj[value.companyid]?.name ?? ''}</Col>
        </Row>
        <Row>
          <Col span={5} className='aiu-title'>ผู้กู้ร่วมคนที่ 1</Col>
          <Col span={7} className='aiu-content'>{value?.borrower1 ?? ''}</Col>
          <Col span={5} className='aiu-title'>ผู้กู้ร่วมคนที่ 2</Col>
          <Col span={7} className='aiu-content'>{value?.borrower2 ?? ''}</Col>
        </Row>
        <Row>
          <Col span={5} className='aiu-title'>ผู้ค้ำประกันคนที่ 1</Col>
          <Col span={7} className='aiu-content'>{value?.surety1 ?? ''}</Col>
          <Col span={5} className='aiu-title'>ผู้ค้ำประกันคนที่ 2</Col>
          <Col span={7} className='aiu-content'>{value?.surety2 ?? ''}</Col>
        </Row>
        <Row>
          <Col span={5} className='aiu-title'>ผู้ค้ำประกันคนที่ 3</Col>
          <Col span={7} className='aiu-content'>{value?.surety3 ?? ''}</Col>
          <Col span={5} className='aiu-title'>วันที่ทำสัญญา</Col>
          <Col span={7} className='aiu-content'>{(value.contractdate) ? dayjs(value.contractdate).format('DD/MM/BBBB') : ''}</Col>
        </Row>
        <Row>
          <Col span={5} className='aiu-title'>ระยะเวลาทำสัญญา (เดือน)</Col>
          <Col span={7} className='aiu-content'>{value?.period ?? ''}</Col>
          <Col span={5} className='aiu-title'>วันที่เริ่มชำระงวดแรก</Col>
          <Col span={7} className='aiu-content'>{(value.startdate) ? dayjs(value.startdate).format('DD/MM/BBBB') : ''}</Col>
        </Row>
        <Row>
          <Col span={5} className='aiu-title'>วงเงินกู้</Col>
          <Col span={7} className='aiu-content'>{formatNumber(value?.loan, 2)} บาท</Col>
          <Col span={5} className='aiu-title'>อัตราผ่อนชำระ</Col>
          <Col span={7} className='aiu-content'>{formatNumber(value?.installment, 2)} บาท</Col>
        </Row>
        <Row>
          <Col span={5} className='aiu-title'>อัตราผ่อนชำระเงินต้น</Col>
          <Col span={7} className='aiu-content'>{formatNumber(value?.installmentp, 2)} บาท</Col>
          <Col span={5} className='aiu-title'>อัตราผ่อนชำระดอกเบี้ย</Col>
          <Col span={7} className='aiu-content'>{formatNumber(value?.installmenti, 2)} บาท</Col>
        </Row>
        <Row>
          <Col span={5} className='aiu-title'>อัตราดอกเบี้ย</Col>
          <Col span={7} className='aiu-content'>{formatNumber(value?.interest, 2)}</Col>
          <Col span={5} className='aiu-title'>หลักประกัน</Col>
          <Col span={7} className='aiu-content'>{(value?.security ?? []).map((o) => (securityObj[o]?.name ?? '')).join(', ')}</Col>
        </Row>
        <Row>
          <Col span={5} className='aiu-title'>ผู้ให้กู้</Col>
          <Col span={7} className='aiu-content'>{lenderObj[value.lid]?.name ?? ''}</Col>
          <Col span={5} className='aiu-title'>วันที่เดินทาง</Col>
          <Col span={7} className='aiu-content'>{(value.traveldate) ? dayjs(value.traveldate).format('DD/MM/BBBB') : ''}</Col>
        </Row>
        <Row>
          <Col span={5} className='aiu-title'>อัปโหลด</Col>
          <Col span={19} className='aiu-content'>{(value?.files ?? []).map((o, i) => (
            <span key={i} style={{ marginRight: 16 }}>
              <a href={o.url} target='_blank' rel='noreferrer'>{o.name}</a>
            </span>
          ))}</Col>
        </Row>
        <Row style={{ margin: '48px 0 24px' }}>
          <Col flex='auto'>
            <div style={{ fontSize: 24 }}>
              รายการชำระเงิน
            </div>
          </Col>
          <Col flex='500px'>
            {(isAllowed(role, 'edit', 'funds', type)) && (
              <div style={{ float: 'right', fontSize: 14 }}>
                <Select
                  disabled={value.disabled}
                  placeholder='เลือก'
                  style={{ marginRight: 24, width: 200 }}
                  value={fundType}
                  onChange={(v) => {
                    Modal.confirm({
                      title: `ยืนยันการเปลี่ยนสถานะเป็น${typeObj[v].name}`,
                      content: <span style={{ color: '#EE7930' }}>**โปรดตรวจสอบความถูกต้อง เพราะจะไม่สามารถเปลี่ยนสถานะกลับได้</span>,
                      icon: <ExclamationCircleOutlined />,
                      okText: 'ยืนยัน',
                      cancelText: 'ยกเลิก',
                      onOk() {
                        handleChange(v)
                      },
                      onCancel() { }
                    })
                  }}
                >
                  {(Object.keys(typeObj).reduce((acc, o) => {
                    return (typeObj[o].n >= typeObj[type].n) ? [...acc, (
                      <Select.Option key={o} value={o}>{typeObj[o].name}</Select.Option>
                    )] : acc
                  }, []))}
                </Select>
                <Button
                  disabled={value.disabled}
                  type='primary'
                  icon={<SettingOutlined style={{ fontSize: 14 }} />}
                  onClick={() => {
                    navigate(`/admin/funds/${type}/${fid}/payment`)
                  }}
                >
                  จัดการการผ่อนชำระ
                </Button>
              </div>
            )}
          </Col>
        </Row >
        <AntdForm
          layout='vertical'
          initialValues={value}
        >
          {(!!value?.payments?.length) && (
            <Row gutter={[8, 8]} style={{ textAlign: 'center' }}>
              <Col span={1}>
                งวดที่
              </Col>
              <Col span={3}>
                งวดการผ่อน
              </Col>
              <Col span={3}>
                วันที่ชำระเงิน
              </Col>
              <Col span={2}>
                ยอดเงินที่ชำระ
              </Col>
              <Col span={2}>
                รับชำระในงวด (จำนวนเงิน)
              </Col>
              <Col span={2}>
                เงินต้นชำระแล้ว
              </Col>
              <Col span={2}>
                ดอกเบี้ยชำระแล้ว
              </Col>
              <Col span={2}>
                เงินต้นคงเหลือในงวด
              </Col>
              <Col span={2}>
                ดอกเบี้ยคงค้างในงวด
              </Col>
              <Col span={2}>
                ดอกเบี้ยสะสม
              </Col>
              <Col span={2}>
                ยอดเงินคงเหลือ
              </Col>
              <Col span={1}>
                เครื่องมือ
              </Col>
            </Row>
          )}
          <AntdForm.List
            name='payments'
          >
            {(fields) => (
              <>
                {fields.map((field, index) => (
                  <Row key={index} gutter={8}>
                    <Col span={1}>
                      <AntdForm.Item name={[field.name, 'n']} >
                        <InputNumberNew disabled={true} />
                      </AntdForm.Item>
                    </Col>
                    <Col span={3}>
                      <AntdForm.Item name={[field.name, 'date']} >
                        <DatePicker disabled={true} />
                      </AntdForm.Item>
                    </Col>
                    <Col span={3}>
                      <AntdForm.Item name={[field.name, 'paydate']} >
                        <DatePicker disabled={true} placeholder='' />
                      </AntdForm.Item>
                    </Col>
                    <Col span={2}>
                      <AntdForm.Item name={[field.name, 'transfer']} >
                        <InputNumberNew disabled={true} />
                      </AntdForm.Item>
                    </Col>
                    <Col span={2}>
                      <AntdForm.Item name={[field.name, 'paid']} >
                        <InputNumberNew disabled={true} />
                      </AntdForm.Item>
                    </Col>
                    <Col span={2}>
                      <AntdForm.Item name={[field.name, 'principle']} >
                        <InputNumberNew disabled={true} />
                      </AntdForm.Item>
                    </Col>
                    <Col span={2}>
                      <AntdForm.Item name={[field.name, 'interest']} >
                        <InputNumberNew disabled={true} />
                      </AntdForm.Item>
                    </Col>
                    <Col span={2}>
                      <AntdForm.Item name={[field.name, 'principleremain']} >
                        <InputNumberNew disabled={true} />
                      </AntdForm.Item>
                    </Col>
                    <Col span={2}>
                      <AntdForm.Item name={[field.name, 'interestremain']} >
                        <InputNumberNew disabled={true} />
                      </AntdForm.Item>
                    </Col>
                    <Col span={2}>
                      <AntdForm.Item name={[field.name, 'interestaccum']} >
                        <InputNumberNew disabled={true} />
                      </AntdForm.Item>
                    </Col>
                    <Col span={2}>
                      <AntdForm.Item name={[field.name, 'remain']} >
                        <InputNumberNew disabled={true} />
                      </AntdForm.Item>
                    </Col>
                    <Col span={1} style={{ textAlign: 'center' }}>
                      <Button
                        icon={<PrinterOutlined />}
                        onClick={() => {
                          setPrintData(index)
                          setConfirmVisible(true)
                        }}
                      >
                      </Button>
                    </Col>
                  </Row>
                ))}
              </>
            )}
          </AntdForm.List>
        </AntdForm>
        <Row>
          <Col span={5} className='aiu-title'>หมายเหตุ</Col>
          <Col span={19} className='aiu-content'>{value?.remark ?? ''}</Col>
        </Row>
      </Card>
      <Modal
        forceRender
        open={confirmVisible}
        title={
          <div style={{ padding: '16px 24px', fontWeight: 'bold', fontSize: '18px', lineHeight: '23px', backgroundColor: '#228B90', color: '#F6F6F6', borderRadius: '5px 5px 0px 0px' }}>
            พิมพ์ใบแจ้งยอดชำระ
          </div>
        }
        width='50%'
        closable={false}
        footer={
          <Row justify='center'>
            <Space align='center'>
              <Col>
                <Button
                  type='primary'
                  size='large'
                  icon={<PrinterOutlined />}
                  onClick={() => {
                    printForm.submit()
                    const data = printForm.getFieldsValue()
                    if (data?.name) {
                      setConfirmVisible(false)
                      print(printData, data.name)
                    }
                  }}
                >
                  พิมพ์
                </Button>
              </Col>
              <Col>
                <Button
                  size='large'
                  style={{ color: '#FFF', backgroundColor: '#EE7930' }} icon={<CloseOutlined />}
                  onClick={() => {
                    setConfirmVisible(false)
                  }}
                >
                  ยกเลิก
                </Button>
              </Col>
            </Space>
          </Row>
        }
      >
        <AntdForm
          layout='vertical'
          style={{ margin: '8px 32px' }}
          form={printForm}
          name='printForm'
        >
          <Row gutter={[24, 24]}>
            <Col span={6}></Col>
            <Col span={12}>
              <AntdForm.Item name='name' label='ชื่อผู้แจ้งยอด' rules={[{ required: true, message: 'กรุณาใส่ชื่อผู้แจ้งยอดค่ะ' }]}>
                <Input placeholder='ชื่อ' />
              </AntdForm.Item>
            </Col>
            <Col span={6}></Col>
          </Row>
        </AntdForm>
      </Modal>
    </div>
  )
}

export default Form
