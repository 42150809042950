import React, { useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Form as AntdForm, Row, Col, Card, Button, AutoComplete, Input, Select, Skeleton } from 'antd'
import { SaveOutlined, CloseOutlined } from '@ant-design/icons'

import { DatePicker } from '../../components/DateTime'
import { dayjsToDB, dbTodayjs } from '../../utils/date'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { statusObj, roundObj, methodObj } from '../../const/jpstudent'
import { courseObj } from '../../const/student'
import { pickBy } from '../../utils/tools'

const Form = () => {
  const navigate = useNavigate()
  const { jid } = useParams()
  const { db, firebaseApp, role } = useAuth()
  const { pathname } = useLocation()

  const { getCoops, getCompanies } = useLayoutContext()

  const [loading, setLoading] = useState(false)
  const [students, setStudents] = useState()
  const [coops, setCoops] = useState([])
  const [companies, setCompanies] = useState([])
  const [targetCompanies, setTargetCompanies] = useState([])

  const [jpStudentForm] = AntdForm.useForm()

  const readOnly = (pathname.indexOf('/view') !== -1) ? true : (['headfund', 'fund'].indexOf(role) !== -1) ? true : false

  const { value } = useAsync(async () => {
    const coopData = await getCoops(true)
    setCoops(coopData)

    const companyData = await getCompanies(true)
    setCompanies(companyData)

    const studentSnap = await db.collection('/users').where('role', '==', 'student').where('status', 'in', ['learning']).where('disabled', '==', false).get()
    const studentData = studentSnap.docs.reduce((acc, doc, i) => {
      const data = doc.data()
      return (!data?.scn) ? acc : [...acc, {
        key: i,
        id: doc.id,
        value: data.scn,
        title: data?.title ?? '',
        firstname: data?.firstname ?? '',
        lastname: data?.lastname ?? '',
        traveldate: dbTodayjs(data?.traveldate),
        course: data?.course ?? '',
        coopid: data?.coopid,
        companyid: data?.companyid
      }]
    }, [])
    setStudents(studentData)

    if (!jid) {
      return {}
    }

    const snap = await db.collection('/jpstudents').doc(jid).get()
    const data = snap.data()
    setTargetCompanies(companyData.filter((o) => o.coid === data?.coopid))
    return {
      ...data,
      traveldate: dbTodayjs(data?.traveldate),
      createddate: dbTodayjs(data?.createddate)
    }
  }, [jid])

  const handleSubmit = async (formData) => {
    try {
      setLoading(true)
      const id = (jid) ? jid : (formData.id) ? formData.id : null
      formData = {
        ...formData,
        id: null,
        isfund: false,
        traveldate: dayjsToDB(formData?.traveldate),
        createddate: (!formData?.createddate) ? firebaseApp.firestore.FieldValue.serverTimestamp() : null
      }
      const data = pickBy(formData)
      if (id) {
        await db.collection('/jpstudents').doc(id).set(data, {
          merge: true
        })
      } else {
        await db.collection('/jpstudents').add(data)
      }
      setLoading(false)
      navigate('/admin/jpstudents')
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  if (!value) return <Skeleton></Skeleton>
  return (
    <Card style={{ padding: 48, minHeight: '100%' }}>
      <div style={{ marginBottom: 16, fontSize: 24 }}>
        {(!jid) ? 'สร้างรายชื่อ' : ''}นักเรียนไปญี่ปุ่น
      </div>
      <AntdForm
        layout='vertical'
        onFinish={handleSubmit}
        initialValues={value}
        form={jpStudentForm}
        name='jpStudentForm'
      >
        <AntdForm.Item name='id' hidden={true}>
          <Input />
        </AntdForm.Item>
        <AntdForm.Item name='title' hidden={true}>
          <Input />
        </AntdForm.Item>
        <AntdForm.Item name='createddate' hidden={true}>
          <DatePicker />
        </AntdForm.Item>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='scn' label='รหัสนักเรียน' rules={[{ required: true, message: 'กรุณาใส่รหัสนักเรียนค่ะ' }]}>
              <AutoComplete
                disabled={readOnly}
                options={students}
                placeholder='เลือก'
                filterOption={(inputValue, option) =>
                  option.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                }
                onChange={(v) => {
                  const user = students.find((o) => o.value === v)
                  if (user) {
                    const targetCompanies = companies.filter((o) => o.coid === user.coopid)
                    setTargetCompanies(targetCompanies)
                    jpStudentForm.setFieldsValue({
                      ...user,
                      scn: user.value
                    })
                  }
                }}
              />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='firstname' label='ชื่อ' rules={[{ required: true, message: 'กรุณาใส่ชื่อค่ะ' }]}>
              <Input disabled={readOnly} placeholder='ชื่อ' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='lastname' label='นามสกุล' rules={[{ required: true, message: 'กรุณาใส่นามสกุลค่ะ' }]}>
              <Input disabled={readOnly} placeholder='นามสกุล' />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='course' label='หลักสูตร' rules={[{ required: true, message: 'กรุณาเลือกหลักสูตรค่ะ' }]}>
              <Select disabled={readOnly} placeholder='เลือก' >
                {(Object.keys(courseObj).map((o) => (
                  <Select.Option key={o} value={o}>{courseObj[o]?.name ?? ''}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='coopid' label='สหกรณ์' rules={[{ required: true, message: 'กรุณาเลือกสหกรณ์ค่ะ' }]}>
              <Select
                disabled={readOnly}
                placeholder='เลือก'
                onChange={(v) => {
                  const target = companies.filter((o) => o.coid === v)
                  jpStudentForm.setFieldsValue({
                    ...jpStudentForm.getFieldsValue(),
                    companyid: null
                  })
                  setTargetCompanies(target)
                }}
              >
                {((coops).map((o) => (
                  <Select.Option key={o.id} value={o.id}>{o.name}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='companyid' label='บริษัท' rules={[{ required: true, message: 'กรุณาเลือกบริษัทค่ะ' }]}>
              <Select disabled={readOnly} placeholder='เลือก' >
                {((targetCompanies).map((o) => (
                  <Select.Option key={o.id} value={o.id}>{o.name}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='round' label='ประเภทการทำงาน' rules={[{ required: true, message: 'กรุณาเลือกประเภทการทำงานค่ะ' }]}>
              <Select disabled={readOnly} placeholder='เลือก' >
                {(Object.keys(roundObj).map((o) => (
                  <Select.Option key={o} value={o}>{roundObj[o]?.name ?? ''}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='traveldate' label='วันที่เดินทาง' >
              <DatePicker disabled={readOnly} placeholder='เลือก' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='paymethod' label='ประเภทการชำระค่าเรียน' >
              <Select disabled={pathname.indexOf('/view') !== -1} placeholder='เลือก' >
                {(Object.keys(methodObj).map((o) => (
                  <Select.Option key={o} value={o}>{methodObj[o]?.name ?? ''}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='status' label='สถานะ' >
              <Select disabled={readOnly} placeholder='เลือก' >
                {(Object.keys(statusObj).map((o) => (
                  <Select.Option key={o} value={o}>{statusObj[o]?.name ?? ''}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={16}></Col>
        </Row>
        {(pathname.indexOf('/view') === -1) && (
          <div>
            <Button
              loading={loading}
              style={{ width: 168, height: 40, marginTop: 20 }}
              htmlType='submit'
              type='primary'
            ><SaveOutlined style={{ fontSize: 16 }} />บันทึก</Button>
            <Button
              loading={loading}
              style={{ height: 40, marginTop: 20, marginLeft: 20 }}
              onClick={() => {
                navigate(-1)
              }}
            ><CloseOutlined />ปิด</Button>
          </div>
        )}
      </AntdForm>
    </Card>
  )
}

export default Form
