import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Form as AntdForm, Card, Button, Skeleton } from 'antd'
import { SaveOutlined, CloseOutlined } from '@ant-design/icons'

import TableTransfer from '../../components/TableTransfer'

import { useAuth } from '../../contexts/AuthContextProvider'

const Form = () => {
  const navigate = useNavigate()
  const { rid } = useParams()
  const { db, functions } = useAuth()

  const [loading, setLoading] = useState(false)
  const [students, setStudents] = useState()
  const [targetStudents, setTargetStudents] = useState([])

  const { value } = useAsync(async () => {
    if (!rid) {
      return {}
    }

    const studentSnap = await db.collection('/users').where('role', '==', 'student').where('disabled', '==', false).where('status', 'in', ['interest', 'learning', 'suspension']).get()
    const studentData = studentSnap.docs.reduce((acc, doc) => {
      const o = doc.data()
      return (o?.rid && (o.rid !== rid)) ? acc : [...acc, {
        ...o,
        id: doc.id,
        key: doc.id,
        scn: o?.scn ?? ''
      }]
    }, [])
    setStudents(studentData)

    const snap = await db.collection('/rooms').doc(rid).get()
    const data = snap.data()
    setTargetStudents(data?.students ?? [])
    return {
      id: snap.id,
      total: data.total
    }
  }, [rid])

  const handleSubmit = async (formData) => {
    try {
      setLoading(true)
      const updateRoomStudent = functions.httpsCallable('updateRoomStudent-updateRoomStudent')
      await updateRoomStudent({
        id: rid,
        total: value.total,
        students: formData.students
      })
      setLoading(false)
      navigate(`/admin/rooms/${rid}/view`)
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  const columns = [
    {
      title: 'รหัสนักเรียน',
      dataIndex: 'scn',
      width: 110
    },
    {
      title: 'ชื่อเล่น',
      dataIndex: 'nickname'
    },
    {
      title: 'ชื่อ',
      dataIndex: 'firstname',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.firstname.toLowerCase().localeCompare(b.firstname.toLowerCase())
    },
    {
      title: 'นามสกุล',
      dataIndex: 'lastname'
    }
  ]

  if (!value) return <Skeleton></Skeleton>
  return (
    <Card style={{ padding: 48, minHeight: '100%' }}>
      <div style={{ marginBottom: 16, fontSize: 24 }}>
        หอพัก
      </div>
      <AntdForm
        layout='vertical'
        onFinish={handleSubmit}
        initialValues={value}
      >
        <AntdForm.Item name='students' >
          <TableTransfer
            titles={['รายชื่อนักเรียน', 'เลือกแล้ว']}
            locale={{ itemUnit: 'คน', itemsUnit: 'คน' }}
            dataSource={students}
            targetKeys={targetStudents}
            showSearch={true}
            listStyle={{
              width: '100%',
              minHeight: 300,
            }}
            leftColumns={columns}
            rightColumns={columns}
            onChange={(v) => {
              setTargetStudents(v)
            }}
            filterOption={(inputValue, item) => (
              item.scn.indexOf(inputValue) !== -1 ||
              ((item.nickname || '').toLowerCase()).indexOf(inputValue.toLowerCase()) !== -1 ||
              ((item.firstname || '').toLowerCase()).indexOf(inputValue.toLowerCase()) !== -1 ||
              ((item.lastname || '').toLowerCase()).indexOf(inputValue.toLowerCase()) !== -1
            )}
          />
        </AntdForm.Item>
        <div>
          <Button
            loading={loading}
            style={{ width: 168, height: 40, marginTop: 20 }}
            htmlType='submit'
            type='primary'
          ><SaveOutlined style={{ fontSize: 16 }} />บันทึก</Button>
          <Button
            loading={loading}
            style={{ height: 40, marginTop: 20, marginLeft: 20 }}
            onClick={() => {
              navigate(-1)
            }}
          ><CloseOutlined />ปิด</Button>
        </div>
      </AntdForm>
    </Card>
  )
}

export default Form
