const roleObj = {
  create: {
    students: ['superadmin', 'counselor', 'headadmin', 'admin'],
    infoTh: ['superadmin', 'headadmin', 'admin'],
    infoJp: ['superadmin', 'headrecruiter', 'recruiter'],
    documents: ['superadmin', 'headadmin', 'admin'],
    studentBehaviors: ['superadmin', 'headteacher', 'teacher'],
    studentClasses: ['superadmin', 'headteacher', 'teacher'],
    studentFunds: ['superadmin', 'headfund', 'fund'],
    studentInterviews: ['superadmin', 'headrecruiter', 'recruiter'],
    users: ['superadmin'],
    classes: ['superadmin', 'headteacher', 'teacher', 'headadmin', 'admin'],
    jobs: ['superadmin', 'headrecruiter', 'recruiter'],
    rooms: ['superadmin', 'headadmin', 'admin'],
    fees: ['superadmin', 'headadmin', 'admin'],
    jpstudents: ['superadmin', 'headrecruiter', 'recruiter'],
    invoices: ['superadmin', 'headrecruiter', 'recruiter'],
    creditors: ['superadmin', 'headfund', 'fund'],
    installments: ['superadmin', 'headadmin', 'headfund'],
    complains: ['superadmin', 'headteacher', 'teacher', 'headrecruiter', 'recruiter'],
    masters: {
      master: ['superadmin', 'headteacher', 'teacher', 'headadmin', 'admin', 'headrecruiter', 'recruiter', 'headfund', 'fund'],
      campuses: ['superadmin'],
      branches: ['superadmin'],
      subjects: ['superadmin', 'headteacher', 'teacher', 'headadmin', 'admin'],
      assessments: ['superadmin', 'headteacher', 'teacher', 'headadmin', 'admin'],
      evaluations: ['superadmin', 'headteacher', 'teacher', 'headadmin', 'admin'],
      questions: ['superadmin', 'headteacher', 'teacher', 'headadmin', 'admin'],
      coops: ['superadmin', 'headrecruiter', 'recruiter'],
      companies: ['superadmin', 'headrecruiter', 'recruiter'],
      lenders: ['superadmin', 'headfund', 'fund']
    },
    funds: {
      normal: ['superadmin', 'headfund', 'fund'],
      special: ['superadmin', 'headfund', 'fund'],
      sue: ['superadmin', 'headfund', 'fund'],
      early: ['superadmin', 'headfund', 'fund']
    }
  },
  edit: {
    students: ['superadmin', 'headadmin', 'admin'],
    infoTh: ['superadmin', 'headadmin', 'admin'],
    infoJp: ['superadmin', 'headrecruiter', 'recruiter'],
    documents: ['superadmin', 'headadmin', 'admin'],
    studentBehaviors: ['superadmin', 'headteacher', 'teacher'],
    studentClasses: ['superadmin', 'headteacher', 'teacher'],
    studentFunds: ['superadmin', 'headfund', 'fund'],
    studentInterviews: ['superadmin', 'headrecruiter', 'recruiter'],
    studentSCN: ['superadmin'],
    users: ['superadmin'],
    classes: ['superadmin', 'headteacher', 'teacher', 'headadmin', 'admin'],
    jobs: ['superadmin', 'headrecruiter', 'recruiter'],
    rooms: ['superadmin', 'headadmin', 'admin'],
    fees: ['superadmin', 'headadmin'],
    jpstudents: ['superadmin', 'headrecruiter', 'recruiter', 'headfund', 'fund'],
    invoices: ['superadmin', 'headrecruiter', 'recruiter'],
    creditors: ['superadmin', 'headfund', 'fund'],
    installments: ['superadmin', 'headadmin', 'headfund'],
    complains: ['superadmin', 'headteacher', 'teacher', 'headrecruiter', 'recruiter'],
    masters: {
      master: ['superadmin', 'headteacher', 'teacher', 'headadmin', 'admin', 'headrecruiter', 'recruiter', 'headfund', 'fund'],
      campuses: ['superadmin'],
      branches: ['superadmin'],
      subjects: ['superadmin', 'headteacher', 'teacher', 'headadmin', 'admin'],
      assessments: ['superadmin', 'headteacher', 'teacher', 'headadmin', 'admin'],
      evaluations: ['superadmin', 'headteacher', 'teacher', 'headadmin', 'admin'],
      questions: ['superadmin', 'headteacher', 'teacher', 'headadmin', 'admin'],
      coops: ['superadmin', 'headrecruiter', 'recruiter'],
      companies: ['superadmin', 'headrecruiter', 'recruiter'],
      lenders: ['superadmin', 'headfund', 'fund']
    },
    funds: {
      normal: ['superadmin', 'headfund', 'fund'],
      special: ['superadmin', 'headfund', 'fund'],
      sue: ['superadmin', 'headfund', 'fund'],
      early: ['superadmin', 'headfund', 'fund']
    }
  },
  delete: {
    students: ['superadmin', 'headadmin'],
    infoTh: ['superadmin', 'headadmin'],
    infoJp: ['superadmin', 'headrecruiter'],
    documents: ['superadmin', 'headadmin'],
    studentBehaviors: ['superadmin', 'headteacher'],
    studentClasses: ['superadmin', 'headteacher'],
    studentFunds: ['superadmin', 'headfund'],
    studentInterviews: ['superadmin', 'headrecruiter'],
    users: ['superadmin'],
    classes: ['superadmin', 'headteacher', 'headadmin'],
    jobs: ['superadmin', 'headrecruiter'],
    rooms: ['superadmin', 'headadmin'],
    fees: ['superadmin', 'headadmin'],
    jpstudents: ['superadmin', 'headrecruiter'],
    invoices: ['superadmin', 'headrecruiter'],
    creditors: ['superadmin', 'headfund'],
    installments: ['superadmin', 'headadmin', 'headfund'],
    complains: ['superadmin', 'headteacher', 'headrecruiter'],
    masters: {
      master: ['superadmin', 'headteacher', 'teacher', 'headadmin', 'admin', 'headrecruiter', 'headfund'],
      campuses: ['superadmin'],
      branches: ['superadmin'],
      subjects: ['superadmin', 'headteacher', 'teacher', 'headadmin', 'admin'],
      assessments: ['superadmin', 'headteacher', 'teacher', 'headadmin', 'admin'],
      evaluations: ['superadmin', 'headteacher', 'teacher', 'headadmin', 'admin'],
      questions: ['superadmin', 'headteacher', 'teacher', 'headadmin', 'admin'],
      coops: ['superadmin', 'headrecruiter'],
      companies: ['superadmin', 'headrecruiter'],
      lenders: ['superadmin', 'headfund']
    },
    funds: {
      normal: ['superadmin', 'headfund'],
      special: ['superadmin', 'headfund'],
      sue: ['superadmin', 'headfund'],
      early: ['superadmin', 'headfund']
    }
  },
  view: {
    // student: ['student'],
    students: ['superadmin', 'counselor', 'headteacher', 'teacher', 'headrecruiter', 'recruiter', 'headadmin', 'admin', 'headfund', 'fund'],
    infoTh: ['superadmin', 'counselor', 'headteacher', 'teacher', 'headrecruiter', 'recruiter', 'headadmin', 'admin', 'headfund', 'fund'],
    infoJp: ['superadmin', 'counselor', 'headteacher', 'teacher', 'headrecruiter', 'recruiter', 'headadmin', 'admin', 'headfund', 'fund'],
    documents: ['superadmin', 'counselor', 'headteacher', 'teacher', 'headrecruiter', 'recruiter', 'headadmin', 'admin', 'headfund', 'fund'],
    studentBehaviors: ['superadmin', 'counselor', 'headteacher', 'teacher', 'headrecruiter', 'recruiter', 'headadmin', 'admin'],
    studentClasses: ['superadmin', 'counselor', 'headteacher', 'teacher', 'headrecruiter', 'recruiter', 'headadmin', 'admin'],
    studentFunds: ['superadmin', 'counselor', 'headteacher', 'teacher', 'headrecruiter', 'recruiter', 'headadmin', 'admin', 'headfund', 'fund'],
    studentInterviews: ['superadmin', 'counselor', 'headteacher', 'teacher', 'headrecruiter', 'recruiter', 'headadmin', 'admin', 'headfund', 'fund'],
    users: ['superadmin'],
    classes: ['superadmin', 'counselor', 'headteacher', 'teacher', 'headrecruiter', 'recruiter', 'headadmin', 'admin', 'headfund', 'fund'],
    jobs: ['superadmin', 'counselor', 'headteacher', 'teacher', 'headrecruiter', 'recruiter', 'headadmin', 'admin'],
    rooms: ['superadmin', 'counselor', 'headteacher', 'teacher', 'headrecruiter', 'recruiter', 'headadmin', 'admin', 'headfund', 'fund'],
    fees: ['superadmin', 'headteacher', 'teacher', 'headrecruiter', 'recruiter', 'headadmin', 'admin', 'headfund', 'fund'],
    jpstudents: ['superadmin', 'counselor', 'headteacher', 'teacher', 'headrecruiter', 'recruiter', 'headadmin', 'admin', 'headfund', 'fund'],
    invoices: ['superadmin', 'headrecruiter', 'recruiter'],
    creditors: ['superadmin', 'headfund', 'fund'],
    installments: ['superadmin', 'headteacher', 'teacher', 'headrecruiter', 'recruiter', 'headadmin', 'admin', 'headfund'],
    complains: ['superadmin', 'headteacher', 'teacher', 'headrecruiter', 'recruiter', 'headadmin', 'admin'],
    masters: {
      master: ['superadmin', 'headteacher', 'teacher', 'headadmin', 'admin', 'headrecruiter', 'recruiter', 'headfund', 'fund'],
      campuses: ['superadmin'],
      branches: ['superadmin'],
      subjects: ['superadmin', 'headteacher', 'teacher', 'headadmin', 'admin'],
      assessments: ['superadmin', 'headteacher', 'teacher', 'headadmin', 'admin'],
      evaluations: ['superadmin', 'headteacher', 'teacher', 'headadmin', 'admin'],
      questions: ['superadmin', 'headteacher', 'teacher', 'headadmin', 'admin'],
      coops: ['superadmin', 'headrecruiter', 'recruiter'],
      companies: ['superadmin', 'headrecruiter', 'recruiter'],
      lenders: ['superadmin', 'headfund', 'fund']
    },
    funds: {
      all: ['superadmin', 'counselor', 'headrecruiter', 'recruiter', 'headadmin', 'admin', 'headfund', 'fund'],
      normal: ['superadmin', 'headadmin', 'admin', 'headfund', 'fund'],
      special: ['superadmin', 'headfund', 'fund'],
      sue: ['superadmin', 'headfund', 'fund'],
      early: ['superadmin', 'counselor', 'headrecruiter', 'recruiter', 'headadmin', 'admin', 'headfund', 'fund']
    }
  }
}

const standardMenu = { students: 1, fees: 2, rooms: 3, classes: 4, jobs: 5, jpstudents: 6, funds: 7, creditors: 8, invoices: 9, installments: 10, complains: 11, masters: 12, users: 13 }

const menuObj = {
  superadmin: standardMenu,
  counselor: { classes: 5, students: 1, fees: 7, jobs: 2, rooms: 6, funds: 4, masters: 8, users: 9, jpstudents: 3, invoices: 10, installments: 11, creditors: 12, complains: 13 },
  headteacher: standardMenu,
  teacher: standardMenu,
  headrecruiter: standardMenu,
  recruiter: standardMenu,
  headadmin: standardMenu,
  admin: standardMenu,
  headfund: standardMenu,
  fund: standardMenu
}

const isAllowed = (role, verb, section, param) => {
  if (param) {
    return roleObj[verb][section][param].indexOf(role) !== -1
  }
  return roleObj[verb][section].indexOf(role) !== -1
}

export {
  roleObj,
  menuObj,
  isAllowed
}
