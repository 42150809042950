import React from 'react'

import { Modal, Select, Button } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'
import { dbTodayjs } from '../../../utils/date'

import { useAuth } from '../../../contexts/AuthContextProvider'
import { genCsv } from '../../../utils/csv'
import { MonthLongTh } from '../../../const/date'

const SummaryCsv = ({ jobs }) => {
  const { db } = useAuth()

  const confirmDownload = () => {
    let targetYear = dayjs().format('YYYY')
    Modal.confirm({
      title: 'กรุณาเลือกปีของรายงานประจำเดือน',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        download(targetYear)
      },
      onCancel() { },
      content: (
        <Select
          placeholder='เลือก'
          defaultValue={targetYear}
          style={{ width: 150, margin: '16px 0', borderRadius: 8 }}
          onChange={(e) => {
            targetYear = e
          }}
        >
          {([parseInt(targetYear) - 2, parseInt(targetYear) - 1, parseInt(targetYear)]).map((o) => (
            <Select.Option key={o} value={o.toString()}>{o}</Select.Option>
          ))}
        </Select>
      )
    })
  }

  const download = async (targetYear) => {
    let sum = {
      jobs: {
        m: { '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0, '9': 0, '10': 0, '11': 0, '12': 0 },
        f: { '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0, '9': 0, '10': 0, '11': 0, '12': 0 },
        care: { '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0, '9': 0, '10': 0, '11': 0, '12': 0 }
      },
      students: {
        m: { '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0, '9': 0, '10': 0, '11': 0, '12': 0 },
        f: { '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0, '9': 0, '10': 0, '11': 0, '12': 0 },
        care: { '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0, '9': 0, '10': 0, '11': 0, '12': 0 }
      }
    }
    const jobObj = jobs.reduce((acc, o) => {
      acc[o.id] = {
        interviewdate: o.interviewdate
      }
      return acc
    }, {})

    const studentSnap = await db.collection('/users').where('role', '==', 'student').where('startdate', '!=', null).get()
    const students = studentSnap.docs.map(doc => {
      const d = doc.data()
      const date = dbTodayjs(d?.startdate)
      const month = dayjs(date).format('M')
      const year = dayjs(date).format('YYYY')
      const sex = (d.title === 'mr') ? 'm' : 'f'
      const course = d?.course
      if (year === targetYear) {
        sum['students'][sex][month] += (course === 'industry') ? 1 : 0
        sum['students']['care'][month] += (course === 'care') ? 1 : 0
      }
      return {
        id: doc.id,
        date: date,
        month: month,
        year: year,
        sex: sex,
        course: course
      }
    })
    const studentObj = students.reduce((acc, o) => {
      acc[o.id] = {
        sex: o.sex,
        course: o.course
      }
      return acc
    }, {})

    const studentJobSnap = await db.collection('/studentjobs').where('jobstatus', '==', 'pass').get()
    studentJobSnap.docs.forEach(doc => {
      const d = doc.data()
      const date = jobObj[d.jid].interviewdate
      const month = dayjs(date).format('M')
      const year = dayjs(date).format('YYYY')
      const sex = studentObj[d.uid].sex
      const course = studentObj[d.uid].course
      if (year === targetYear) {
        sum['jobs'][sex][month] += (course === 'industry') ? 1 : 0
        sum['jobs']['care'][month] += (course === 'care') ? 1 : 0
      }
    })

    let list = Object.keys(MonthLongTh).reduce((acc, o) => {
      return [...acc, {
        o: o,
        month: MonthLongTh[o],
        jm: sum['jobs']['m'][o],
        jf: sum['jobs']['f'][o],
        jc: sum['jobs']['care'][o],
        jt: sum['jobs']['m'][o] + sum['jobs']['f'][o] + sum['jobs']['care'][o],
        sm: sum['students']['m'][o],
        sf: sum['students']['f'][o],
        sc: sum['students']['care'][o],
        st: sum['students']['m'][o] + sum['students']['f'][o] + sum['students']['care'][o]
      }]
    }, [])
    const jm = Object.keys(sum['jobs']['m']).reduce((acc, o) => acc + sum['jobs']['m'][o], 0)
    const jf = Object.keys(sum['jobs']['f']).reduce((acc, o) => acc + sum['jobs']['f'][o], 0)
    const jc = Object.keys(sum['jobs']['care']).reduce((acc, o) => acc + sum['jobs']['care'][o], 0)
    const sm = Object.keys(sum['students']['m']).reduce((acc, o) => acc + sum['students']['m'][o], 0)
    const sf = Object.keys(sum['students']['f']).reduce((acc, o) => acc + sum['students']['f'][o], 0)
    const sc = Object.keys(sum['students']['care']).reduce((acc, o) => acc + sum['students']['care'][o], 0)
    list.push({
      o: 'รวม',
      month: '',
      jm: jm,
      jf: jf,
      jc: jc,
      jt: jm + jf + jc,
      sm: sm,
      sf: sf,
      sc: sc,
      st: sm + sf + sc
    })

    const rows = list.map((o) => ({
      'ลำดับ': o.o,
      'เดือน': o.month,
      'ภาษณ์งานชาย': o.jm,
      'ภาษณ์งานหญิง': o.jf,
      'ภาษณ์งานบริบาล': o.jc,
      'ภาษณ์งานรวม': o.jt,
      'ชาย': o.sm,
      'หญิง': o.sf,
      'บริบาล': o.sc,
      'รวม': o.st
    }))
    const title = `จำนวนกำหนดการสัมภาษณ์งานและนักเรียนเข้าเรียน ปี${targetYear}
,,กำหนดการสัมภาษณ์งาน,,,,จำนวนนักเรียนเข้าเรียน,,,`
    try {
      await genCsv('monthly_summary', rows, title)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return (
    <Button
      type='primary'
      onClick={() => confirmDownload()}
    >
      สรุปรายงานประจำเดือน
    </Button>
  )
}

export default SummaryCsv
