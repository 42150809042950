import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Form as AntdForm, Card, Row, Col, Table, Modal, Input, Select, Button, Popconfirm, Tag, Space, Skeleton } from 'antd'
import { PlusOutlined, SearchOutlined, PrinterOutlined, EyeOutlined, DeleteOutlined, ReloadOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { roleObj } from '../../const/roles'
import { genCsv } from '../../utils/csv'
import { search } from '../../utils/search'
import { statusObj } from '../../const/users'
import { isAllowed } from '../../route/roles'

const List = () => {
  const navigate = useNavigate()
  const { db, functions, role } = useAuth()
  const { getPageNo, onPageChange, getSearch, setSearch } = useLayoutContext()

  const [updateCount, setUpdateCount] = useState(0)

  const [initValue, setInitValue] = useState([])
  const [value, setValue] = useState([])
  const [qsVisible, setQsVisible] = useState(false)
  const [qs, setQs] = useState({})
  const [tags, setTags] = useState([])

  const [searchForm] = AntdForm.useForm()

  const converter = {
    fromFirestore(snapshot, options) {
      const data = snapshot.data(options)
      return {
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        role: data.role,
        emailVerified: data.emailVerified,
        status: (!data.emailVerified) ? 'waiting' : (data.disabled) ? 'inActive' : 'active'
      }
    }
  }

  const { loading } = useAsync(async () => {
    const snap = await db.collection('/users').where('role', '!=', 'student').withConverter(converter).get()
    const data = snap.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    }))
    const q = getSearch('users')
    setQs(q || {})
    setInitValue(data)
  }, [updateCount])

  useEffect(() => {
    const tagList = Object.keys(qs).reduce((acc, o) => {
      return (!qs[o]) ? acc : [...acc, {
        key: o,
        value: (o === 'role') ? roleObj[qs[o]]?.name : (o === 'status') ? statusObj[qs[o]]?.name : qs[o],
        label: {
          firstname: 'ชื่อ',
          lastname: 'นามสกุล',
          email: 'อีเมล',
          role: 'ตำแหน่ง',
          status: 'สถานะ'
        }[o]
      }]
    }, [])
    setTags(tagList)
    const data = search(initValue, qs)
    setValue(data)
  }, [initValue, qs])

  const handleDelete = async (row) => {
    try {
      await db.collection('/users').doc(row.id).delete()
      setUpdateCount(updateCount + 1)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const handleReverify = async (row) => {
    try {
      const reverifyUser = functions.httpsCallable('reVerifyUser-reVerifyUser')
      await reverifyUser({ email: row.email })
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const handleClose = (key) => {
    const newQs = {
      ...qs,
      [key]: null
    }
    setQs(newQs)
    setSearch('users', newQs)
    searchForm.setFieldsValue(newQs)
  }

  const download = async (data) => {
    const list = data.map((o) => ({
      'ชื่อ': o?.firstname ?? '',
      'นามสกุล': o?.lastname ?? '',
      'อีเมล': o?.email ?? '',
      'ตำแหน่ง': roleObj[o?.role]?.name ?? '',
      'สถานะ': statusObj[o?.status]?.name ?? ''
    }))
    try {
      await genCsv('user_list', list)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const columns = [
    {
      title: 'ชื่อ',
      dataIndex: 'firstname'
    },
    {
      title: 'นามสกุล',
      dataIndex: 'lastname'
    },
    {
      title: 'อีเมล',
      dataIndex: 'email',
      width: 200
    },
    {
      title: 'ตำแหน่ง',
      dataIndex: 'role',
      width: 150,
      render: data => (roleObj[data]?.name ?? '')
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      width: 150,
      render: data => (
        <>
          <span style={{ color: statusObj[data]?.color ?? '#000000' }}>{statusObj[data]?.name ?? ''}</span>
        </>
      )
    },
    {
      title: 'เครื่องมือ',
      dataIndex: 'Operation',
      width: 150,
      render: (_, row) => (
        <div>
          {(isAllowed(role, 'edit', 'users')) && (
            <Button
              type='link'
              style={{ marginRight: '8px' }}
              icon={<EyeOutlined style={{ fontSize: 18 }} />}
              onClick={() => {
                navigate(`/admin/users/${row.id}/edit`)
              }}
            ></Button>
          )}
          {(isAllowed(role, 'delete', 'users')) && (
            <Popconfirm
              title={`Are you sure to delete ${row.email} user?`}
              onConfirm={() => handleDelete(row)}
              okText='Yes'
              cancelText='No'
            >
              <Button
                type='link'
                style={{ marginRight: '8px' }}
                danger
                icon={<DeleteOutlined style={{ fontSize: 18 }} />}
              ></Button>
            </Popconfirm>
          )}
          {(!row?.emailVerified && isAllowed(role, 'edit', 'users')) && (
            <Popconfirm
              title={`You want to reverify ${row.email} again?`}
              onConfirm={() => handleReverify(row)}
              okText='Yes'
              cancelText='No'
            >
              <Button
                type='link'
                icon={<ReloadOutlined style={{ fontSize: 18, color: 'aqua' }} />}
              ></Button>
            </Popconfirm>
          )}
        </div>
      )
    }
  ]

  if (loading) return <Skeleton></Skeleton>
  return (
    <Card style={{ minHeight: '100%' }}>
      <Row>
        <Col flex='auto'>
          <div style={{ fontSize: 24 }}>
            ผู้ใช้งาน
          </div>
          <div style={{ marginTop: 12 }}>
            {tags.map((o) => (
              <Tag
                key={o.key}
                closable
                onClose={() => handleClose(o.key)}
              >
                {o.label}: {o.value}
              </Tag>
            ))}
          </div>
        </Col>
        <Col flex='300px'>
          <div style={{ float: 'right', fontSize: 14 }}>
            {(isAllowed(role, 'create', 'users')) && (
              <div>
                <Button
                  type='primary'
                  icon={<PlusOutlined style={{ fontSize: 14 }} />}
                  onClick={() => {
                    navigate('/admin/users/create')
                  }}
                >
                  สร้างผู้ใช้งาน
                </Button>
              </div>
            )}
            <div style={{ float: 'right', margin: '8px 0 16px 0' }}>
              <Button
                icon={<SearchOutlined />}
                style={{ margin: '0 8px' }}
                onClick={() => {
                  setQsVisible(true)
                }}
              ></Button>
              <Button
                icon={<PrinterOutlined />}
                onClick={() => {
                  download(value)
                }}
              ></Button>
            </div>
          </div>
        </Col>
      </Row >
      <Table
        bordered
        rowKey='id'
        style={{ overflowX: 'auto' }}
        columns={columns}
        dataSource={value}
        pagination={{
          total: value.length,
          current: getPageNo('users'),
          onChange(page) {
            onPageChange('users', page)
          }
        }}
      />
      <Modal
        forceRender
        open={qsVisible}
        title={
          <div style={{ padding: '16px 24px', fontWeight: 'bold', fontSize: '18px', lineHeight: '23px', backgroundColor: '#228B90', color: '#F6F6F6', borderRadius: '5px 5px 0px 0px' }}>
            ค้นหา
          </div>
        }
        width='50%'
        closable={false}
        footer={
          <Row justify='center'>
            <Space align='center'>
              <Col>
                <Button
                  type='secondary'
                  size='medium'
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    const empty = {
                      firstname: null,
                      lastname: null,
                      email: null,
                      role: null,
                      status: null
                    }
                    setQs(empty)
                    setSearch('users', empty)
                    searchForm.setFieldsValue(empty)
                  }}
                >
                  Clear Data
                </Button>
              </Col>
              <Col>
                <Button
                  size='medium'
                  style={{ color: '#FFF', backgroundColor: '#EE7930' }} icon={<CloseOutlined />}
                  onClick={() => {
                    setQsVisible(false)
                  }}
                >
                  ยกเลิก
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  size='medium'
                  icon={<CheckOutlined />}
                  onClick={() => {
                    const data = searchForm.getFieldsValue()
                    setQs(data)
                    setSearch('users', data)
                    setQsVisible(false)
                  }}
                >
                  ยืนยัน
                </Button>
              </Col>
            </Space>
          </Row>
        }
      >
        <AntdForm
          layout='vertical'
          initialValues={qs}
          style={{ margin: '8px 32px' }}
          form={searchForm}
          name='searchForm'
        >
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <AntdForm.Item name='firstname' label='ชื่อ' >
                <Input placeholder='ชื่อ' />
              </AntdForm.Item>
            </Col>
            <Col span={12}>
              <AntdForm.Item name='lastname' label='นามสกุล' >
                <Input placeholder='นามสกุล' />
              </AntdForm.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <AntdForm.Item name='email' label='อีเมล' >
                <Input placeholder='อีเมล' />
              </AntdForm.Item>
            </Col>
            <Col span={12}>
              <AntdForm.Item name='role' label='ตำแหน่ง' >
                <Select placeholder='เลือก' >
                  {((Object.keys(roleObj)).map((o) => (
                    <Select.Option key={o} value={o}>{roleObj[o].name}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <AntdForm.Item name='status' label='สถานะ' >
                <Select placeholder='เลือก' >
                  {((Object.keys(statusObj)).map((o) => (
                    <Select.Option key={o} value={o}>{statusObj[o].name}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
            <Col span={12}></Col>
          </Row>
        </AntdForm>
      </Modal>
    </Card >
  )
}

export default List
