import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useAsync } from 'react-use'

import { Form as AntdForm, Row, Col, Card, Button, Input, Select, Skeleton } from 'antd'
import { SaveOutlined, CloseOutlined } from '@ant-design/icons'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { nameTitleObj } from '../../const/users'
import { recommenderObj } from '../../const/student'
import { pickBy } from '../../utils/tools'

const Form = () => {
  const navigate = useNavigate()
  const { db, functions } = useAuth()
  const { getBranches } = useLayoutContext()
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [branches, setBranches] = useState()

  const uid = id

  const { value } = useAsync(async () => {
    const branchData = await getBranches()
    setBranches(branchData)

    if (!uid) {
      return {}
    }

    const snap = await db.collection('/users').doc(uid).get()
    return {
      ...snap.data(),
      id: snap.id
    }
  }, [uid])

  const handleSubmit = async (formData) => {
    try {
      setLoading(true)
      const data = pickBy({
        ...formData,
        role: 'student'
      })
      if (!uid) {
        const createUser = functions.httpsCallable('createUser-createUser')
        await createUser(data)
      } else {
        await db.collection('/users').doc(uid).set(data, {
          merge: true
        })
      }
      setLoading(false)
      navigate('/admin/students')
      return Promise.resolve()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  if (!value) return <Skeleton></Skeleton>
  return (
    <Card style={{ padding: 48, minHeight: '100%' }}>
      <div style={{ marginBottom: 16, fontSize: 24 }}>
        {(!uid) ? 'สร้าง' : ''}นักเรียน
      </div>
      <AntdForm
        layout='vertical'
        onFinish={handleSubmit}
        initialValues={value}
      >
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='title' label='คำนำหน้า' rules={[{ required: true, message: 'กรุณาเลือกคำนำหน้าค่ะ' }]}>
              <Select placeholder='เลือก' >
                {((Object.keys(nameTitleObj)).map((o) => (
                  <Select.Option key={o} value={o}>{nameTitleObj[o]?.nameTh}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='firstname' label='ชื่อ' rules={[{ required: true, message: 'กรุณาใส่ชื่อค่ะ' }]}>
              <Input placeholder='ชื่อ' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='lastname' label='นามสกุล' rules={[{ required: true, message: 'กรุณาใส่นามสกุลค่ะ' }]}>
              <Input placeholder='นามสกุล' />
            </AntdForm.Item>
          </Col>
        </Row>
        <Row gutter={[24, 32]}>
          <Col span={8}>
            <AntdForm.Item name='tel' label='เบอร์ติดต่อ' rules={[{ required: true, message: 'กรุณาใส่เบอร์ติดต่อค่ะ' }]}>
              <Input placeholder='เบอร์ติดต่อ' />
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='branchid' label='สำนักงานที่รับผิดชอบ' rules={[{ required: true, message: 'กรุณาเลือกสำนักงานที่รับผิดชอบค่ะ' }]}>
              <Select
                placeholder='เลือก'
                filterSort={(optionA, optionB) =>
                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
              >
                {((branches).map((o) => (
                  <Select.Option key={o.id} value={o.id}>{o.name}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
          <Col span={8}>
            <AntdForm.Item name='recommend' label='ผู้แนะนำ' rules={[{ required: true, message: 'กรุณาเลือกผู้แนะนำค่ะ' }]}>
              <Select placeholder='เลือก' >
                {((Object.keys(recommenderObj)).map((o) => (
                  <Select.Option key={o} value={o}>{recommenderObj[o].name}</Select.Option>
                )))}
              </Select>
            </AntdForm.Item>
          </Col>
        </Row>
        <div>
          <Button
            loading={loading}
            style={{ width: 168, height: 40, marginTop: 20 }}
            htmlType='submit'
            type='primary'
          ><SaveOutlined style={{ fontSize: 16 }} />บันทึก</Button>
          <Button
            loading={loading}
            style={{ height: 40, marginTop: 20, marginLeft: 20 }}
            onClick={() => {
              navigate(-1)
            }}
          ><CloseOutlined />ปิด</Button>
        </div>
      </AntdForm>
    </Card>
  )
}

export default Form
