import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { Form, Button, Input, message, Row, Col, Checkbox } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'

import { useAuth } from '../../contexts/AuthContextProvider'

const Login = () => {
  const { auth } = useAuth()
  const [loading, setLoading] = useState(false)

  const handleChange = async ({ email, password }) => {
    try {
      setLoading(true)
      const formatedEmail = email.trim().toLowerCase()
      await auth().signInWithEmailAndPassword(formatedEmail, password)
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form layout='vertical' onFinish={handleChange}>
      <Form.Item name='email' rules={[{ required: true, message: 'Please input your Email!' }]}>
        <Input className='input-auth' prefix={<UserOutlined />} placeholder='Email' />
      </Form.Item>
      <Form.Item name='password' rules={[{ required: true, message: 'Please input your Password!' }]}>
        <Input.Password className='input-auth' prefix={<LockOutlined />} placeholder='Password' />
      </Form.Item>
      <Row>
        <Col span={15}>
          <Checkbox>Remember me</Checkbox>
        </Col>
        <Col span={9}>
          <Link to='/forget-password' style={{ textDecoration: 'underline', float: 'right' }}>ลืมรหัสผ่าน</Link>
        </Col>
      </Row>
      <Form.Item>
        <Button type='primary' className='input-auth' htmlType='submit' style={{ width: '100%' }} loading={loading}>
          <span style={{ fontSize: '16px', fontFamily: 'Roboto' }}>เข้าสู่ระบบ</span>
        </Button>
      </Form.Item>
      {/* <div style={{ textAlign: 'center', fontSize: '12px', fontFamily: 'Prompt' }}>
        <Link to='/register' style={{ textDecoration: 'underline' }}>สมัครเข้าเรียน</Link>
      </div> */}
    </Form>
  )
}

export default Login
