import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Form as AntdForm, Card, Row, Col, Table, Modal, Input, Select, Button, Popconfirm, Tag, Space, Skeleton } from 'antd'
import { PlusOutlined, SearchOutlined, PrinterOutlined, EyeOutlined, DeleteOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'
import { dbTodayjs } from '../../utils/date'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { genCsv } from '../../utils/csv'
import { search } from '../../utils/search'
import { statusObj, allowObj } from '../../const/classes'
// import { levelObj } from '../../const/student'
import { isAllowed } from '../../route/roles'

const List = () => {
  const navigate = useNavigate()
  const { db, role } = useAuth()
  const { getPageNo, onPageChange, getSearch, setSearch, getSubjects } = useLayoutContext()

  const [updateCount, setUpdateCount] = useState(0)

  const [initValue, setInitValue] = useState([])
  const [value, setValue] = useState([])
  const [qsVisible, setQsVisible] = useState(false)
  const [qs, setQs] = useState({})
  const [tags, setTags] = useState([])
  const [teachers, setTeachers] = useState([])
  const [teacherObj, setTeacherObj] = useState()
  const [subjectObj, setSubjectObj] = useState()

  const [searchForm] = AntdForm.useForm()

  const { loading } = useAsync(async () => {
    const teacherSnap = await db.collection('/users').where('role', 'in', ['headteacher', 'teacher', 'headadmin', 'admin']).where('disabled', '==', false).where('emailVerified', '==', true).get()
    const teacherData = teacherSnap.docs.map(doc => {
      const data = doc.data()
      return {
        id: doc.id,
        key: doc.id,
        name: `${data.firstname} ${data.lastname}`
      }
    })
    setTeachers(teacherData)
    const tobj = teacherData.reduce((acc, o) => {
      acc[o.id] = o.name
      return acc
    }, [])
    setTeacherObj(tobj)

    const subjects = await getSubjects()
    const subjectData = subjects.reduce((acc, o) => {
      acc[o.id] = o.name
      return acc
    }, [])
    setSubjectObj(subjectData)

    const scSnap = await db.collection('/studentclasses').where('disabled', '==', false).get()
    const scObj = scSnap.docs.reduce((acc, o) => {
      acc[o.data().uid] = o.id
      return acc
    }, {})

    const snap = await db.collection('/classes').get()
    const data = snap.docs.map(doc => {
      const d = doc.data()
      return {
        ...d,
        id: doc.id,
        startdate: dbTodayjs(d?.startdate),
        enddate: dbTodayjs(d?.enddate),
        allow: (d?.allow === 'yes') ? 'yes' : 'no',
        no: (!d?.students?.length) ? 0 : d.students.filter((o) => scObj[o]).length
      }
    })
    const q = getSearch('classes')
    setQs(q || {})
    setInitValue(data)
  }, [updateCount])

  useEffect(() => {
    const tagList = Object.keys(qs).reduce((acc, o) => {
      return (!qs[o]) ? acc : [...acc, {
        key: o,
        value: (o === 'subject') ? subjectObj[qs[o]] ?? '' : (o === 'teachers') ? teacherObj[qs[o]] ?? '' : (o === 'allow') ? allowObj[qs[o]]?.name : (o === 'status') ? statusObj[qs[o]]?.name : qs[o],
        label: {
          name: 'ชื่อวิชา',
          subject: 'หมวดวิชา',
          teachers: 'ครูผู้สอน',
          allow: 'อนุมัติ',
          status: 'สถานะ'
        }[o]
      }]
    }, [])
    setTags(tagList)
    const data = search(initValue, qs)
    setValue(data)
  }, [initValue, qs, teacherObj, subjectObj])

  const handleDelete = async (row) => {
    try {
      await db.collection('/classes').doc(row.id).delete()
      setUpdateCount(updateCount + 1)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const handleClose = (key) => {
    const newQs = {
      ...qs,
      [key]: null
    }
    setQs(newQs)
    setSearch('classes', newQs)
    searchForm.setFieldsValue(newQs)
  }

  const download = async (data) => {
    const list = data.map((o) => ({
      'รายวิชา': o?.name ?? '',
      'หมวดวิชา': subjectObj[o?.subject] ?? '',
      'วันเริ่มต้นรายวิชา': (o?.startdate) ? dayjs(o.startdate).format('D MMM BBBB') : '',
      'วันสิ้นสุดรายวิชา': (o?.enddate) ? dayjs(o.enddate).format('D MMM BBBB') : '',
      'จำนวนชั่วโมง': o?.hrs ?? '',
      'ครูผู้สอน': (o?.teachers ?? []).map((o) => (teacherObj[o])).join(', '),
      'จำนวนนักเรียน': o?.no ?? '',
      'อนุมัติ': allowObj[o?.allow]?.name ?? 'ยังไม่อนุมัติ',
      'สถานะ': statusObj[o?.status]?.name ?? ''
    }))
    try {
      await genCsv('class_list', list)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const columns = [
    {
      title: 'รายวิชา',
      dataIndex: 'name',
      width: 150
    },
    {
      title: 'หมวดวิชา',
      dataIndex: 'subject',
      width: 110,
      render: data => (
        <span>{subjectObj[data] ?? ''}</span>
      )
    },
    {
      title: 'วันเริ่มต้นรายวิชา',
      dataIndex: 'startdate',
      width: 120,
      render: data => (
        <span>{(data) ? dayjs(data).format('D MMM BBBB') : ''}</span>
      )
    },
    {
      title: 'วันสิ้นสุดรายวิชา',
      dataIndex: 'enddate',
      width: 120,
      render: data => (
        <span>{(data) ? dayjs(data).format('D MMM BBBB') : ''}</span>
      )
    },
    {
      title: 'จำนวนชั่วโมง',
      dataIndex: 'hrs',
      width: 120,
      align: 'center'
    },
    {
      title: 'ครูผู้สอน',
      dataIndex: 'teachers',
      width: 120,
      render: data => ((data || []).map((o) => (teacherObj[o])).join(', '))
    },
    {
      title: 'จำนวนนักเรียน',
      dataIndex: 'no',
      width: 125,
      align: 'center'
    },
    {
      title: 'อนุมัติ',
      dataIndex: 'allow',
      width: 120,
      render: data => (
        <span style={{ color: allowObj[data]?.color ?? '#D35C3B' }}>{allowObj[data]?.name ?? 'ยังไม่อนุมัติ'}</span>
      )
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      width: 120,
      render: data => (statusObj[data]?.name ?? '')
    },
    {
      title: 'เครื่องมือ',
      dataIndex: 'Operation',
      width: 150,
      render: (_, row) => (
        <div>
          <Button
            type='link'
            style={{ marginRight: '8px' }}
            icon={<EyeOutlined style={{ fontSize: 18 }} />}
            onClick={() => {
              navigate(`/admin/classes/${row.id}/view`)
            }}
          ></Button>
          {(isAllowed(role, 'delete', 'classes')) && (
            <Popconfirm
              title={`Are you sure to delete ${row.name} class?`}
              onConfirm={() => handleDelete(row)}
              okText='Yes'
              cancelText='No'
            >
              <Button
                type='link'
                style={{ marginRight: '8px' }}
                danger
                icon={<DeleteOutlined style={{ fontSize: 18 }} />}
              ></Button>
            </Popconfirm>
          )}
        </div>
      )
    }
  ]

  if (loading) return <Skeleton></Skeleton>
  return (
    <Card style={{ minHeight: '100%' }}>
      <Row>
        <Col flex='auto'>
          <div style={{ fontSize: 24 }}>
            ห้องเรียน
          </div>
          <div style={{ marginTop: 12 }}>
            {tags.map((o) => (
              <Tag
                key={o.key}
                closable
                onClose={() => handleClose(o.key)}
              >
                {o.label}: {o.value}
              </Tag>
            ))}
          </div>
        </Col>
        <Col flex='300px'>
          <div style={{ float: 'right', fontSize: 14 }}>
            <div>
              {(isAllowed(role, 'create', 'classes')) && (
                <Button
                  type='primary'
                  icon={<PlusOutlined style={{ fontSize: 14 }} />}
                  onClick={() => {
                    navigate('/admin/classes/create')
                  }}
                >
                  สร้างห้องเรียน
                </Button>
              )}
            </div>
            <div style={{ float: 'right', margin: '8px 0 16px 0' }}>
              <Button
                icon={<SearchOutlined />}
                style={{ margin: '0 8px' }}
                onClick={() => {
                  setQsVisible(true)
                }}
              ></Button>
              <Button
                icon={<PrinterOutlined />}
                onClick={() => {
                  download(value)
                }}
              ></Button>
            </div>
          </div>
        </Col>
      </Row >
      <Table
        bordered
        rowKey='id'
        style={{ overflowX: 'auto' }}
        columns={columns}
        dataSource={value}
        pagination={{
          total: value.length,
          current: getPageNo('classes'),
          onChange(page) {
            onPageChange('classes', page)
          }
        }}
      />
      <Modal
        forceRender
        open={qsVisible}
        title={
          <div style={{ padding: '16px 24px', fontWeight: 'bold', fontSize: '18px', lineHeight: '23px', backgroundColor: '#228B90', color: '#F6F6F6', borderRadius: '5px 5px 0px 0px' }}>
            ค้นหา
          </div>
        }
        width='50%'
        closable={false}
        footer={
          <Row justify='center'>
            <Space align='center'>
              <Col>
                <Button
                  type='secondary'
                  size='medium'
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    const empty = {
                      name: null,
                      subject: null,
                      teachers: null,
                      allow: null,
                      status: null
                    }
                    setQs(empty)
                    setSearch('classes', empty)
                    searchForm.setFieldsValue(empty)
                  }}
                >
                  Clear Data
                </Button>
              </Col>
              <Col>
                <Button
                  size='medium'
                  style={{ color: '#FFF', backgroundColor: '#EE7930' }} icon={<CloseOutlined />}
                  onClick={() => {
                    setQsVisible(false)
                  }}
                >
                  ยกเลิก
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  size='medium'
                  icon={<CheckOutlined />}
                  onClick={() => {
                    const data = searchForm.getFieldsValue()
                    setQs(data)
                    setSearch('classes', data)
                    setQsVisible(false)
                  }}
                >
                  ยืนยัน
                </Button>
              </Col>
            </Space>
          </Row>
        }
      >
        <AntdForm
          layout='vertical'
          initialValues={qs}
          style={{ margin: '8px 32px' }}
          form={searchForm}
          name='searchForm'
        >
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <AntdForm.Item name='name' label='รายวิชา' >
                <Input placeholder='รายวิชา' />
              </AntdForm.Item>
            </Col>
            <Col span={12}>
              <AntdForm.Item name='subject' label='หมวดวิชา' >
                <Select placeholder='เลือก' >
                  {((Object.keys(subjectObj)).map((o) => (
                    <Select.Option key={o} value={o}>{subjectObj[o]}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <AntdForm.Item name='teachers' label='ครูผู้สอน' >
                <Select placeholder='เลือก' >
                  {((teachers).map((o) => (
                    <Select.Option key={o.id} value={o.id}>{o.name}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
            <Col span={12}>
              <AntdForm.Item name='allow' label='อนุมัติ' >
                <Select placeholder='เลือก' >
                  {((Object.keys(allowObj)).map((o) => (
                    <Select.Option key={o} value={o}>{allowObj[o].name}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <AntdForm.Item name='status' label='สถานะ' >
                <Select placeholder='เลือก' >
                  {((Object.keys(statusObj)).map((o) => (
                    <Select.Option key={o} value={o}>{statusObj[o].name}</Select.Option>
                  )))}
                </Select>
              </AntdForm.Item>
            </Col>
            <Col span={12}></Col>
          </Row>
        </AntdForm>
      </Modal>
    </Card >
  )
}

export default List
