import React, { useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import { Skeleton } from 'antd'

import { useAuth } from '../contexts/AuthContextProvider'

const PrivateRoute = ({ children, roles, param }) => {
  const navigate = useNavigate()
  const { role } = useAuth()
  const params = useParams()

  let redirect = false
  let roleList = roles

  if (param) {
    roleList = roles[params[param]]
  }

  useEffect(() => {
    if (redirect) {
      navigate('/403')
    }
    return () => { }
  }, [redirect, navigate])

  if (roleList.indexOf(role) === -1) {
    redirect = true
    return <Skeleton />
  }
  return (children) ? children : <Outlet />
}

export default PrivateRoute