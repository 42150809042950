const search = (initValue, qs) => {
  const searchKeys = Object.keys(qs)
  return initValue.reduce((acc, o) => {
    const n = searchKeys.filter((p) => (
      !qs[p] ||
      (o[p] &&
        (
          Array.isArray(qs[p]) ? (qs[p].indexOf(o[p]) !== -1) :
            Array.isArray(o[p]) ? (o[p].indexOf(qs[p]) !== -1) :
              isNaN(o[p]) ? (o[p].toLowerCase().indexOf(qs[p].toLowerCase()) !== -1) :
                (o[p].toString().indexOf(qs[p]) !== -1)
        )
      )
    ))
    return (
      !searchKeys.length ||
      searchKeys.length === n.length
    ) ? [...acc, o] : acc
  }, [])
}

export { search }